import React from "react";
import { connect, ConnectedProps } from "react-redux";

import DonutChart from "components/DonutChart";
import LegendColorIndicator from "components/LegendColorIndicator";
import Loading from "components/Loading";
import Constants from "helpers/constants";
import { Project } from "interfaces/project";
import { State } from "state";
import { fetchValidationSummaryDashboard } from "state/validation_summary_dashboard";

interface ValidationSummaryDashboardOwnProps {
  isMobile?: boolean;
  project: Project;
}

type ValidationSummaryDashboardProps = ValidationSummaryDashboardOwnProps & PropsFromRedux;

export const ALLOWED_ROLES = [
  Constants.AB_WHOLESALER,
  Constants.COMPETITOR_CSM,
  Constants.COMPETITOR_WHOLESALER,
];

class ValidationSummaryDashboard extends React.Component<ValidationSummaryDashboardProps> {
  protected DASHBOARD_COLORS = {
    "Validations Remaining": "#fcbd00",
    "Validations Completed": "#26c492",
    "Validations Skipped": "#e13c37",
  };

  public componentDidMount() {
    this.updateChartData();
  }

  public componentDidUpdate(prevProps: ValidationSummaryDashboardProps) {
    const {
      project: { id },
    } = prevProps;

    if (id !== this.props.project.id) {
      this.updateChartData();
    }
  }

  public updateChartData() {
    this.props.fetchValidationSummaryDashboard(this.props.project.id);
  }

  public render() {
    const { data, labels, colors, total } = this.props.validationsummarydashboard;

    if (!data) {
      return <Loading />;
    }

    return (
      <div className="chart-wrapper" data-qa="validation-summary-dashboard">
        <DonutChart
          isMobile={this.props.isMobile}
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor: colors,
              },
            ],
          }}
          total={total || 0}
        />
        <div className="legend">
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="yellow" />
              Validations Remaining
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="green" />
              Validations Completed
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="red" />
              Validations Skipped
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    validationsummarydashboard: state.validationsummarydashboard.content,
  }),
  { fetchValidationSummaryDashboard }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ValidationSummaryDashboard);
