import React from "react";
import Popover from "react-popover";

import editSvg from "assets/img/edit.svg";
import Button from "components/Button";
import Icon from "components/Icon";

interface CommentPopoverProps {
  onSave?: (textValue?: string) => Promise<any> | void;
  onCancel?: () => Promise<any> | void;
  disabled?: boolean;
  initialValue: string;
  maxLength?: number;
  errorMessage?: string;
}

interface CommentPopoverState {
  isOpen: boolean;
  textareaValue: string;
  pastMaxLength: boolean;
}

export default class CommentPopover extends React.Component<
  CommentPopoverProps,
  CommentPopoverState
> {
  constructor(props: CommentPopoverProps) {
    super(props);
    this.state = {
      isOpen: false,
      pastMaxLength: false,
      textareaValue: props.initialValue || "",
    };
  }

  public handleOnSave = () => {
    this.setState({ isOpen: false });
    if (this.props.onSave) {
      this.props.onSave(this.state.textareaValue);
    }
  };

  public handleOnCancel = () => {
    this.setState({ isOpen: false, pastMaxLength: false });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  public renderPreviewComment() {
    if (this.props.initialValue) {
      if (this.props.initialValue.length <= 10) {
        return this.props.initialValue;
      } else {
        return `${this.props.initialValue.substr(0, 10)}...`;
      }
    } else {
      return "";
    }
  }

  public render() {
    return (
      <Popover
        body={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {this.state.pastMaxLength && (
              <div className="error" data-qa="popover-error">
                {this.props.errorMessage ||
                  `Comment cannot exceed ${this.props.maxLength} characters`}
              </div>
            )}
            <textarea
              rows={4}
              cols={40}
              defaultValue={this.props.initialValue}
              data-qa={"popover-text-area"}
              style={{
                fontFamily: "sans-serif",
                fontSize: "14px",
                marginBottom: "10px",
                padding: "10px",
                borderColor: "#8d8d92",
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (this.props.maxLength && value.length > this.props.maxLength) {
                  this.setState({
                    pastMaxLength: true,
                    textareaValue: value.substr(0, this.props.maxLength),
                  });
                } else {
                  this.setState({
                    pastMaxLength: false,
                    textareaValue: e.target.value,
                  });
                }
              }}
            />
            <div style={{ display: "flex" }}>
              <Button
                style={{ marginLeft: "auto" }}
                secondary={true}
                onClick={this.handleOnCancel}
                data-qa={"explanation-cancel-button"}
              >
                Cancel
              </Button>
              <Button
                style={{ marginRight: "unset" }}
                disabled={this.state.pastMaxLength}
                onClick={this.handleOnSave}
                data-qa={"explanation-save-button"}
              >
                Save
              </Button>
            </div>
          </div>
        }
        isOpen={this.state.isOpen}
      >
        <div style={{ display: "flex" }}>
          <span style={{ paddingTop: "5px" }}>{this.renderPreviewComment()}</span>
          <button
            style={{
              border: "none",
              outline: "none",
              marginLeft: "auto",
              background: "none",
            }}
            onClick={() => this.setState({ isOpen: true })}
            disabled={this.props.disabled}
          >
            {!this.props.disabled && <Icon small={true} src={editSvg} />}
          </button>
        </div>
      </Popover>
    );
  }
}
