import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { setItem } from "helpers/storage";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { State } from "state";

// Actions Types
export const FETCH_PERMISSIONS_REQUEST = "FETCH_PERMISSIONS_REQUEST";
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const FETCH_PERMISSIONS_FAILED = "FETCH_PERMISSIONS_FAILED";

const hydratePermissions = () => (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  if (state.auth.content && state.permissions && !state.permissions.content) {
    dispatch(fetchPermissions());
  }
};

const fetchPermissions: ThunkActionCreator = (token: string) => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PERMISSIONS_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/roles/me`, getHeaders())
    .then((response) => {
      dispatch({ type: FETCH_PERMISSIONS, payload: response.data });
      setItem("permissions", response.data);
    })
    .catch((error) => {
      dispatch({ type: FETCH_PERMISSIONS_FAILED });
      throw error;
    });
};

export { fetchPermissions, hydratePermissions };

// Actions
const actions = {
  [FETCH_PERMISSIONS_REQUEST]: (state: PermissionsState): PermissionsState => ({
    ...state,
    loading: true,
  }),
  [FETCH_PERMISSIONS]: (state: PermissionsState, action: AnyAction) => ({
    ...state,
    error: false,
    loading: false,
    content: { ...action.payload },
  }),
  [FETCH_PERMISSIONS_FAILED]: (state: PermissionsState): PermissionsState => ({
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
export interface Permission {
  view_pog: boolean;
  comment_pog: boolean;
  certify_pog: boolean;
  viewmy_comment: boolean;
  viewall_comment: boolean;
  accept_comment: boolean;
  reject_comment: boolean;
  set_reset: boolean;
  comment_reset: boolean;
  validate_reset: boolean;
  take_survey: boolean;
}

export interface PermissionsState {
  readonly error?: boolean;
  readonly loading?: boolean;
  readonly content?: Permission;
}

// Initial State
const initialState: PermissionsState = {
  error: false,
  loading: false,
  content: undefined,
};

// Reducer
export default handleActions<PermissionsState, AnyAction>(actions, initialState);
