import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { Store } from "interfaces/store";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
export const FETCH_STORE_REQUEST = "FETCH_STORE_REQUEST";
export const FETCH_STORE = "FETCH_STORE";
export const FETCH_STORE_FAILED = "FETCH_STORE_FAILED";

export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const UPDATE_STORE = "UPDATE_STORE";
export const UPDATE_STORE_FAILED = "UPDATE_STORE_FAILED";

export const TOGGLE_STORE_STARRING_REQUEST = "TOGGLE_STORE_STARRING_REQUEST";
export const TOGGLE_STORE_STARRING = "TOGGLE_STORE_STARRING";
export const TOGGLE_STORE_STARRING_FAILED = "TOGGLE_STORE_STARRING_FAILED";

// Interfaces
export interface FetchStoreOptions {
  projectId: number | string;
  storeId: number | string;
}

// Thunk Action Creator
const fetchStore: ThunkActionCreator<Promise<void>> =
  ({ storeId, projectId }: FetchStoreOptions) =>
  (dispatch: Dispatch) => {
    dispatch({ type: FETCH_STORE_REQUEST });
    return axios
      .get(`${Config.API_SERVICE_URL}/api/projects/${projectId}/stores/${storeId}`, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_STORE, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: FETCH_STORE_FAILED });
      });
  };

const updateStore: ThunkActionCreator =
  (id: number | string, validated: boolean) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_STORE_REQUEST });

    return axios
      .patch(
        `${Config.API_SERVICE_URL}/api/stores/${id}`,
        JSON.stringify({ validated }),
        getHeaders({ "Content-Type": "application/json" })
      )
      .then((response) => dispatch({ type: UPDATE_STORE, payload: response.data }))
      .catch(() => dispatch({ type: UPDATE_STORE_FAILED }));
  };

const toggleStoreStarring: ThunkActionCreator<Promise<void>> = ( id: string, starred: boolean ) => (dispatch: Dispatch) => {
    dispatch({ type: TOGGLE_STORE_STARRING_REQUEST });

    return axios
      .post(
        `${Config.API_SERVICE_URL}/api/stores/starred`,
        JSON.stringify({id, starred}),
        getHeaders({ "Content-Type": "application/json" })
      )
      .then((response) => {
        setTimeout(() => {
          console.log("And now here we are");
          dispatch({ type: TOGGLE_STORE_STARRING, payload: response.data });
        }, 5000)
      })
      .catch((_) => {
        dispatch({ type: TOGGLE_STORE_STARRING_FAILED });
      });
  };

export { fetchStore, updateStore, toggleStoreStarring };

// Actions
const actions = {
  [FETCH_STORE_REQUEST]: (state: StoreState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_STORE]: (state: StoreState, action: AnyAction): StoreState => ({
    ...state,
    loading: false,
    content: { ...action.payload, validated: action.payload.validated === 1 },
  }),
  [FETCH_STORE_FAILED]: (state: StoreState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [UPDATE_STORE_REQUEST]: (state: StoreState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_STORE]: (state: StoreState, action: AnyAction): StoreState => ({
    ...state,
    loading: false,
    content: {
      ...action.payload,
      validated: action.payload.validated === 1,
    },
  }),
  [UPDATE_STORE_FAILED]: (state: StoreState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [TOGGLE_STORE_STARRING_REQUEST]: (state: StoreState) => ({
    ...state,
    loading: true,
  }),
  [TOGGLE_STORE_STARRING]: (state: StoreState, action: AnyAction) => ({
    ...state,
    content: state.content ? 
      { ...state.content, starred: action.payload.starred } :
      undefined,
    error: false,
    loading: false,
  }),
  [TOGGLE_STORE_STARRING_FAILED]: (state: StoreState) => ({
    ...state,
    loading: false,
    error: true,
  }),
};

// Reducer Interface
export interface StoreState {
  error?: boolean;
  loading?: boolean;
  content?: Store;
}

// Initial State
const initialState: StoreState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<StoreState, AnyAction>(actions, initialState);
