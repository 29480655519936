import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
const FETCH_USERS_TO_STORES_REQUEST = "FETCH_USERS_TO_STORES_REQUEST";
const FETCH_USERS_TO_STORES = "FETCH_USERS_TO_STORES";
const FETCH_USERS_TO_STORES_FAILED = "FETCH_USERS_TO_STORES_FAILED";
const FILTER_USERS_TO_STORES_REQUEST = "FILTER_USERS_TO_STORES_REQUEST";
const FILTER_USERS_TO_STORES = "FILTER_USERS_TO_STORES";
const FILTER_USERS_TO_STORES_FAILED = "FILTER_USERS_TO_STORES_FAILED";
const EXPORT_USERS_TO_STORES = "EXPORT_USERS_TO_STORES";
const DELETE_USERS_TO_STORES_REQUEST = "DELETE_USERS_TO_STORES_REQUEST";
const DELETE_USERS_TO_STORES = "DELETE_USERS_TO_STORES";
const DELETE_USERS_TO_STORES_FAILED = "DELETE_USERS_TO_STORES_FAILED";
const DELETE_ALL_USERS_TO_STORES_REQUEST = "DELETE_ALL_USERS_TO_STORES_REQUEST";
const DELETE_ALL_USERS_TO_STORES = "DELETE_ALL_USERS_TO_STORES";
const DELETE_ALL_USERS_TO_STORES_FAILED = "DELETE_ALL_USERS_TO_STORES_FAILED";
const DELETE_USER_TO_CHAIN_REQUEST = "DELETE_USER_TO_CHAIN_REQUEST";
const DELETE_USER_TO_CHAIN = "DELETE_USER_TO_CHAIN";
const DELETE_USER_TO_CHAIN_FAILED = "DELETE_USER_TO_CHAIN_FAILED";

export {
  FETCH_USERS_TO_STORES,
  FETCH_USERS_TO_STORES_FAILED,
  FETCH_USERS_TO_STORES_REQUEST,
  FILTER_USERS_TO_STORES,
  FILTER_USERS_TO_STORES_FAILED,
  FILTER_USERS_TO_STORES_REQUEST,
  EXPORT_USERS_TO_STORES,
  DELETE_USERS_TO_STORES_REQUEST,
  DELETE_USERS_TO_STORES,
  DELETE_USERS_TO_STORES_FAILED,
  DELETE_ALL_USERS_TO_STORES_REQUEST,
  DELETE_ALL_USERS_TO_STORES,
  DELETE_ALL_USERS_TO_STORES_FAILED,
};

// Thunk Action Creator
export const fetchUsersToStoresByChainId: ThunkActionCreator<Promise<void>> =
  (chainId: number, queryParams?: { page: number, size: number, sort: string }) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_USERS_TO_STORES_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/users-stores?chainId=${chainId}`;

    if (queryParams) {
      url += `&page=${queryParams.page}&size=${queryParams.size}&sort=${queryParams.sort}`;
    }

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_USERS_TO_STORES, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: FETCH_USERS_TO_STORES_FAILED });
      });
  };

export const filterUsersToStoresByChainId: ThunkActionCreator<Promise<void>> =
  (chainId: number, queryParams?: { page: number, size: number, sort: string, email: string }) => (dispatch: Dispatch) => {
    dispatch({ type: FILTER_USERS_TO_STORES_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/users-stores?chainId=${chainId}`;

    if (queryParams) {
      url += `&page=${queryParams.page}&size=${queryParams.size}&sort=${queryParams.sort}&email=${queryParams.email}`;
    }

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: FILTER_USERS_TO_STORES, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: FILTER_USERS_TO_STORES_FAILED });
      });
  };


export const exportUsersToStoresByChainId: ThunkActionCreator<Promise<void>> =
  (chainId: number, queryParams?: { email: string }) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_USERS_TO_STORES_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/users-stores?chainId=${chainId}`;
    if (queryParams) {
      url += `&email=${queryParams.email}`;
    }

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: EXPORT_USERS_TO_STORES, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: FETCH_USERS_TO_STORES_FAILED });
      });
  };

export const deleteUserToStore: ThunkActionCreator<Promise<void>> =
  (id) => (dispatch: Dispatch) => {
    dispatch({ type: DELETE_USERS_TO_STORES_REQUEST });
    return axios
      .delete(`${Config.API_SERVICE_URL}/api/users-stores/${id}`, getHeaders())
      .then((_) => {
        dispatch({ type: DELETE_USERS_TO_STORES, payload: { id } });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_USERS_TO_STORES_FAILED });
      });
  };

export const deleteUserToChainStoreMappings: ThunkActionCreator<Promise<void>> =
  (userId, chainId) => (dispatch: Dispatch) => {
    dispatch({ type: DELETE_USER_TO_CHAIN_REQUEST });
    return axios
      .delete(`${Config.API_SERVICE_URL}/api/users/${userId}/user-stores/${chainId}`, getHeaders())
      .then(() => {
        dispatch({ type: DELETE_USER_TO_CHAIN, payload: { userId, chainId } });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_USER_TO_CHAIN_FAILED });
      });
  };

export const deleteAllUserToStoreMappings: ThunkActionCreator<Promise<void>> =
  (userId) => (dispatch: Dispatch) => {
    dispatch({ type: DELETE_ALL_USERS_TO_STORES_REQUEST });
    return axios
      .delete(`${Config.API_SERVICE_URL}/api/users/${userId}/user-stores`, getHeaders())
      .then(() => {
        dispatch({ type: DELETE_ALL_USERS_TO_STORES, payload: { userId } });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_ALL_USERS_TO_STORES_FAILED });
      });
  };

// Actions
const actions = {
  [FETCH_USERS_TO_STORES_REQUEST]: (state: Users2StoresState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_USERS_TO_STORES]: (state: Users2StoresState, action: AnyAction): Users2StoresState => ({
    ...state,
    loading: false,
    content: [...action.payload.mappings],
    pageCount: action.payload.pages,
  }),
  [FETCH_USERS_TO_STORES_FAILED]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [FILTER_USERS_TO_STORES_REQUEST]: (state: Users2StoresState) => ({
    ...state,
    loading: true,
  }),
  [FILTER_USERS_TO_STORES]: (state: Users2StoresState, action: AnyAction): Users2StoresState => ({
    ...state,
    loading: false,
    content: [...action.payload.mappings],
    pageCount: action.payload.pages,
  }),
  [FILTER_USERS_TO_STORES_FAILED]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [EXPORT_USERS_TO_STORES]: (state: Users2StoresState, action: AnyAction): Users2StoresState => ({
    ...state,
    loading: false,
    exportContent: [...action.payload.mappings],
  }),
  [DELETE_USERS_TO_STORES_REQUEST]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: true,
  }),
  [DELETE_USERS_TO_STORES]: (state: Users2StoresState, action: AnyAction): Users2StoresState => {
    const id = action.payload.id.toString();
    // instead of re-fetching all user2store entries after deleting a single one, filter out deleted id from previously fetched entries
    return {
      ...state,
      loading: false,
      content: (state.content || []).filter((c) => c.id.toString() !== id),
    };
  },
  [DELETE_USERS_TO_STORES_FAILED]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: false,
    error: true,
  }),
  [DELETE_ALL_USERS_TO_STORES_REQUEST]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: true,
  }),
  [DELETE_ALL_USERS_TO_STORES]: (
    state: Users2StoresState,
    action: AnyAction
  ): Users2StoresState => {
    const userId = action.payload.userId.toString();

    // instead of re-fetching all user2store entries after deleting a single one, filter out deleted id from previously fetched entries
    return {
      ...state,
      loading: false,
      content: (state.content || []).filter((c) => c.user_id.toString() !== userId),
    };
  },
  [DELETE_ALL_USERS_TO_STORES_FAILED]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: false,
    error: true,
  }),
  [DELETE_USER_TO_CHAIN_REQUEST]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: true,
    error: false,
  }),
  [DELETE_USER_TO_CHAIN]: (state: Users2StoresState, action: AnyAction): Users2StoresState => {
    const userId = action.payload.userId.toString();
    const chainId = action.payload.chainId.toString();

    // instead of re-fetching all user2store entries after deleting a single one, filter out deleted id from previously fetched entries
    return {
      ...state,
      loading: false,
      error: false,
      content: (state.content || []).filter(
        (c) => c.chainId.toString() !== chainId || c.user_id.toString() !== userId
      ),
    };
  },
  [DELETE_USER_TO_CHAIN_FAILED]: (state: Users2StoresState): Users2StoresState => ({
    ...state,
    loading: false,
    error: true,
  }),
};

// Reducer Interface
export interface User2Store {
  readonly id: number;
  readonly user_id: number;
  readonly chainId: number;
  readonly retailStoreNumber: string;
  readonly email: string;
  readonly chainName: string;
}

export interface Users2StoresState {
  error?: boolean;
  loading?: boolean;
  content?: User2Store[];
  exportContent?: User2Store[];
  pageCount?: number;
}

// Initial State
const initialState: Users2StoresState = {
  loading: false,
  error: false,
  content: [],
  exportContent: [],
  pageCount: 1,
};

// reducer
export default handleActions<Users2StoresState, AnyAction>(actions, initialState);
