import React from "react";

import { ColumnProps } from "components/Table";

type TableObject = { [key: string]: any };
interface MobileTableProps {
  columns: ColumnProps[];
  data: TableObject;
  row?: any;
  onRowClick?: (row: any) => void;
  isScrollable?: boolean;
  index: number;
  showEmptyHeader?: boolean;
}

interface Row {
  rowHeader: string;
  rowField: string;
  formatter?: any;
}

const MobileTable: React.FunctionComponent<MobileTableProps> = ({
  columns,
  data,
  onRowClick,
  isScrollable,
  index,
  showEmptyHeader = true,
}) => {
  const renderTableRow = (row: Row, data: TableObject) => {
    if (row.rowField === "") {
      return (
        <tbody key={row.rowField}>
          <tr className="mobile-table-row">
            {showEmptyHeader && <th className="mobile-table-row-header" />}
            <td className="mobile-table-row-data">
              {row.formatter ? row.formatter(data, index) : ""}
            </td>
          </tr>
        </tbody>
      );
    } else {
      return (
        data &&
        (data[row.rowField] || row.rowField === "showMobileRow") && (
          <tbody key={row.rowField}>
            <tr className="mobile-table-row">
              <th className="mobile-table-row-header">{row.rowHeader}</th>
              <td className="mobile-table-row-data">
                {row.formatter
                  ? row.formatter(data[row.rowField], data, index)
                  : data[row.rowField]}
              </td>
            </tr>
          </tbody>
        )
      );
    }
  };

  const rows: Row[] = columns.map((column) => {
    return {
      rowHeader: column.header,
      rowField: column.field,
      formatter: column.formatter && column.formatter,
    };
  });

  return (
    <div
      className="mobile-table-container"
      style={isScrollable ? { overflowY: "scroll" } : { overflow: "visible" }}
    >
      <table className="mobile-table" onClick={(e: any) => onRowClick && onRowClick(data)}>
        {rows.map((row) => renderTableRow(row, data))}
      </table>
    </div>
  );
};

export default MobileTable;
