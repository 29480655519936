import cx from "classnames";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import download_icon from "assets/img/download.svg";
import Base64Logo from "components/Base64Logo";
import { Card, CardList, CollapsableCard } from "components/Card";
import Icon from "components/Icon";
import Loading from "components/Loading";
import { getPillProps, Pill } from "components/Pill";
import Table from "components/Table";
import DataTable, { parseSortParam } from "components/DataTable";
import { Body } from "components/Typography";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import FeedbackReviewDashboard, {
  ALLOWED_ROLES as FeedbackReviewDashboard_ALLOWED_ROLES,
} from "containers/FeedbackReviewDashboard";
import ProjectStatusDashboard, {
  ALLOWED_ROLES as ProjectStatusDashboard_ALLOWED_ROLES,
} from "containers/ProjectStatusDashboard";
import ResetStatusDashboard, {
  ALLOWED_ROLES as ResetStatusDashboard_ALLOWED_ROLES,
} from "containers/ResetStatusDashboard";
import ValidationSummaryDashboard, {
  ALLOWED_ROLES as ValidationSummaryDashboard_ALLOWED_ROLES,
} from "containers/ValidationSummaryDashboard";
import Constants from "helpers/constants";
import { File } from "interfaces/file";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { fetchFilesByProjectId } from "state/files";
import { fetchProject } from "state/project";
import { fetchStoresByProjectId } from "state/stores";
import { Column } from "react-table-latest";
import { Store } from "interfaces/store";
import { PaginatedQuery } from "interfaces/paginated-query";
import { trackPageView, PAGEVIEW_EVENTS } from "state/userEvents";
import Button from "../components/Button";

interface ProjectOwnProps extends RouteComponentProps<{ id: string }> {}
type ProjectProps = ProjectOwnProps & PropsFromRedux;

class Project extends React.Component<ProjectProps> {
  private PROJECT_PAGE_SIZE = 20;

  //See componentDidMount() for the default sorting parameter
  private PROJECT_DEFAULT_SORT = "";

  private defaultPaginationParams: PaginatedQuery = {
    sort: this.PROJECT_DEFAULT_SORT,
    size: this.PROJECT_PAGE_SIZE,
    page: 0,
  };

  public state = { ...this.defaultPaginationParams, initialMountComplete: false};

  public componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.fetchProject(id).then(() => {
      const {
        user,
        project,
        location: { pathname },
      } = this.props;

      if (project && project.chain && project.project_type) {
        this.props.createAnalyticsEvent({
          event: EventTypes.PAGE_VIEW,
          eventType: "Pageview",
          pageName: "Project Detail",
          pageUrl: pathname,
          projectName: `${project.chain.name} - ${project.name}`,
          projectType: project.project_type,
        });
      }

      if (project) {
        this.props.trackPageView({
          event_type: PAGEVIEW_EVENTS.PROJECT_DETAIL_PAGEVIEW,
          user_id: user?.id,
          store_id: null,
          project_id: id,
          timestamp: Date.now(),
        });
      }

      const isFileSharing = project?.project_type === Constants.FILE_SHARING;
      const initialSortParam = isFileSharing ? "actual_reset_date.desc" : "store_number.asc";

      this.setState({ ...this.defaultPaginationParams, sort: initialSortParam });
      this.props.fetchStoresByProjectId(id, {
        ...this.defaultPaginationParams,
        sort: initialSortParam,
      });
      this.props.fetchFilesByProjectId(id);
    }).then(()=>{
      //Delays initial render of Stores DataTable until after redux store is properly updated
      //Prevents initialization of DataTable's initalState to stale data from previous pages
      this.setState({ ...this.state, initialMountComplete: true});
    });
  }

  //React-table does not allow multiple columns to use the same accessor.
  //Unfortunately, 'Days Left' and 'Deadline Date' both use 'store-validation_deadline' as the accessor
  //Modifying the 'id' property in days_left will stop the duplicate columns error but cause the query sort parameter to be incorrect
  //This function is a hardcoded fix for this edge case
  private paginationQueryOverrride = (state: PaginatedQuery) => {
    if (state.sort && state.sort.includes("days_left")) {
      let newSortparam = state.sort.replace("days_left", "store_validation_deadline");
      return { ...state, sort: newSortparam };
    } else {
      return state;
    }
  };

  public componentDidUpdate(prevProps: ProjectProps, prevState: PaginatedQuery) {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (
      prevState.page !== this.state.page ||
      prevState.sort !== this.state.sort ||
      prevState.size !== this.state.size
    ) {
      //Fixes edge case on sort parameter
      let paginationQuery = this.paginationQueryOverrride(this.state);
      this.props.fetchStoresByProjectId(id, paginationQuery);
      this.props.fetchFilesByProjectId(id);
    }
  }

  public handleDownloadClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    { blob_url: blobUrl }: File
  ) => {
    return window.open(blobUrl);
  };

  public render() {
    const {
      project,
      projectError,
      stores,
      files,
      auth_user,
      isMobile,
      storesPageCount,
      storesPageIndex,
      isLoadingStores,
      recordCount,
    } = this.props;

    if (!project && projectError) {
      throw new Error(Constants.ERROR_404);
    }

    if (!project || !stores || !files || !auth_user) {
      return <Loading />;
    }

    const CardComponent: React.ComponentType = isMobile ? CollapsableCard : Card;
    const cardProps = CardComponent.name === CollapsableCard.name ? { defaultCollapsed: true } : {};

    const isFileSharing = project.project_type === Constants.FILE_SHARING;

    const tableColumnsConfig = [
      {
        accessor: "store_number",
        Header: "Store No",
        dataQa: "store_number",
        minWidth: 80,
      },
      {
        accessor: "store_name",
        Header: "Store Name",
        dataQa: "store_name",
        minWidth: 150,
      },
      {
        accessor: "store_address",
        Header: "Address",
        dataQa: "address",
        show: !isMobile,
      },
      {
        accessor: "store_status",
        Header: "Status",
        Cell: ({ value }) => {
          const pill = getPillProps(value, project.project_type);
          return <Pill {...pill} />;
        },
        dataQa: "store_status",
      },
      {
        accessor: "store_last_file_upload",
        Header: "File Last Uploaded",
        Cell: (value: any) => {
          if (!value || !value.value) {
            return "--"
          }
          const deadline = moment(value.value);

          if (!deadline.isValid()) {
            return "--";
          }

          return deadline.format("M/D/YYYY");
        },
        dataQa: "store_last_file_upload",
      },
      {
        show: !isFileSharing,
        accessor: "store_validation_deadline",
        Header: "Deadline Date",
        Cell: (value: any) => {
          const deadline = moment(value.value);

          if (!deadline.isValid()) {
            return "--";
          }

          return deadline.format("M/D/YYYY");
        },
        dataQa: "validation_deadline",
      },
      {
        show: !isFileSharing,
        accessor: "store_validation_deadline",
        id: "days_left",
        Header: "Days Left",
        Cell: (value: any) => {
          const diff = moment(value.value).diff(moment().format(), "days");

          if (Number.isNaN(diff)) {
            return "--";
          }

          return diff.toString();
        },

        dataQa: "days_left",
        minWidth: 90,
        maxWidth: 100,
        textAlign: isMobile ? "center" : "",
      },
      {
        show: isFileSharing,
        accessor: "actual_reset_date",
        Header: "Actual Reset Date",
        Cell: ({ value }: { value: string }) => {
          return value ? moment(value).tz("America/Chicago").format("M/DD/YYYY") : "";
        },
        dataQa: "actual_reset_date",
      },
    ] as (Column<Store> & { dataQa: string; show: boolean })[];

    return (
      <section className="project-details-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: auth_user.role === Constants.AB_SYSTEM_ADMIN ? "/projects" : "/projects/me",
                crumb: "VIEW ALL PROJECTS",
                dataQa: "view-all-projects-link",
              },
            ],
          }}
        />
        <div className="header-area">
          <h2 className="header" data-qa="project-name">
            {project.name}
          </h2>
          <div className="create-project-buttons">
            {
              this.props.auth_user?.role === Constants.AB_SYSTEM_ADMIN || false
                ?
                  <Button
                    secondary={true}
                    onClick={() => {
                      const {history} = this.props;
                      if (this.props.project?.id) {
                        history.push("/create-project/" + this.props.project.id);
                      }
                    }}
                    data-qa="button"
                    className="button"
                  >
                    <p>EDIT</p>
                  </Button>
             :
                <></>
            }
          </div>
        </div>
        <CardList className="project-status-cards">
          <CardComponent {...cardProps}>
          <Card.Header>
              <h4>Project Details</h4>
            </Card.Header>
            <Card.Body>
              <label>
                <h6>Chain</h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Base64Logo
                    data={project.chain && project.chain.logo}
                    logo_filename={project.chain && project.chain.logo_filename}
                    data-qa="project-details-chain-image"
                  />
                  <Body type="1" style={{marginLeft: 11}} data-qa="project-details-chain-name">
                    {project.chain && project.chain.name}
                  </Body>
                </div>
              </label>
              <label>
                <h6>Estimated Completion Date</h6>
                <Body type="1" data-qa="project-details-estimated-completion-date">
                  {/* 160 is &nbsp; */}
                  {project.est_complete_date
                    ? moment(project.est_complete_date).format("MMM DD, YYYY")
                    : String.fromCharCode(160)}
                </Body>
              </label>
              <label>
                <h6>Number of Stores</h6>
                <Body type="1" data-qa="project-details-number-of-stores">
                  {recordCount ? recordCount : (stores || []).length}
                </Body>
              </label>
            </Card.Body>
          </CardComponent>
          {!isFileSharing &&
            ProjectStatusDashboard_ALLOWED_ROLES.some((r) => r === auth_user.role) && (
              <CardComponent {...cardProps}>
                <Card.Header>
                  <h4>Project Status</h4>
                </Card.Header>
                <Card.Body>
                  <ProjectStatusDashboard isMobile={isMobile} project={project}/>
                </Card.Body>
              </CardComponent>
            )}
          {!isFileSharing &&
            ValidationSummaryDashboard_ALLOWED_ROLES.some((r) => r === auth_user.role) && (
              <CardComponent {...cardProps}>
                <Card.Header>
                  <h4>Validation Summary</h4>
                </Card.Header>
                <Card.Body>
                  <ValidationSummaryDashboard isMobile={isMobile} project={project}/>
                </Card.Body>
              </CardComponent>
            )}
          {!isFileSharing &&
            FeedbackReviewDashboard_ALLOWED_ROLES.some((r) => r === auth_user.role) && (
              <CardComponent {...cardProps}>
                <Card.Header>
                  <h4>Feedback Review</h4>
                </Card.Header>
                <Card.Body>
                  <FeedbackReviewDashboard isMobile={isMobile} project={project}/>
                </Card.Body>
              </CardComponent>
            )}
          {ResetStatusDashboard_ALLOWED_ROLES.some((r) => r === auth_user.role) && (
            <CardComponent {...cardProps}>
              <Card.Header>
                <h4>Reset Status</h4>
              </Card.Header>
              <Card.Body>
                <ResetStatusDashboard isMobile={isMobile} project={project}/>
              </Card.Body>
            </CardComponent>
          )}
        </CardList>
        <CardList>
          <Card>
            <Card.Header>
              <h4>Project Files</h4>
            </Card.Header>
            <Table
              isMobile={isMobile}
              data={files as object[]}
              emptyState={"No project files found"}
              columns={[
                {
                  field: "file_name",
                  header: "file name",
                  dataQa: "file_name",
                  sortable: false,
                },
                {
                  field: "user_name",
                  header: "Uploaded By",
                  wrap: true,
                  dataQa: "uploaded-by",
                  sortable: false,
                },
                {
                  field: "file_updated_at",
                  header: "Upload Date",
                  formatter: (row: any) => {
                    const date = moment(row);
                    if (!date.isValid()) {
                      return "--";
                    }
                    return date.format("M/D/YYYY h:mm A");
                  },
                  dataQa: "created_date",
                  sortable: false,
                },
                {
                  field: "",
                  header: "",
                  sortable: false,
                  formatter: (row: any, value: string) => {
                    return (
                      <div className="project-download-button-container">
                        <Body className="download-label" type="2">
                          Download
                        </Body>
                        <button
                          className={cx(
                            isMobile ? "mobile-table-button download" : "download-button"
                          )}
                          onClick={(e) => this.handleDownloadClick(e, row)}
                        >
                          <Icon src={download_icon}/>
                        </button>
                      </div>
                    );
                  },
                },
              ]}
            />
          </Card>
          <Card>
            <Card.Header>
              <h4>Work Items</h4>
              <h4 className="total-counter">{recordCount ? recordCount : stores.length} total</h4>
            </Card.Header>
            {/* TODO Figure out equivalent of isMobile={isMobile}. Consider responsiveLayout prop */}
            {this.state.initialMountComplete && this.state.sort && (
              <DataTable
                isLoading={isLoadingStores}
                data={stores}
                columns={tableColumnsConfig}
                sortable
                paginated
                manualPagination
                pageCount={storesPageCount}
                initialState={{
                  pageSize: this.PROJECT_PAGE_SIZE,
                  pageIndex: storesPageIndex,
                  sortBy: parseSortParam(this.state.sort),
                  //Allows for the use of the 'show' property
                  hiddenColumns: tableColumnsConfig
                    .filter((col: any) => col.show === false)
                    .map((col) => col.id || col.accessor) as any,
                }}
                getRowId={(row) => String(row.store_id)}
                getRowProps={(baseProps, {row}) => {
                  const originalData = row.original as any;
                  return {
                    ...baseProps,
                    className: `--clickable`,
                    "data-projectid": originalData.project_id,
                    id: row.id,
                  };
                }}
                handleEvent={(event) => {
                  if (event.type === "click") {
                    const clickedRow = (event.target as HTMLElement).closest("tr");
                    if (clickedRow) {
                      const projectId = this.props.project?.id;
                      const storeId = clickedRow.id;
                      const {history} = this.props;
                      if (projectId && storeId) {
                        history.push(`/projects/${projectId}/stores/${storeId}`);
                      }
                    }
                  }
                }}
                onPageChange={(page: number, size: number, sort: string) => {
                  if (
                    page !== this.state.page ||
                    size !== this.state.size ||
                    sort !== this.state.sort
                  ) {
                    this.setState({...this.state, size: size, sort: sort, page: page});
                  }
                }}
              />
            )}
          </Card>
        </CardList>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    auth_user: state.auth_user.content,
    user: state.auth_user.content,
    project: state.project.content,
    projectError: state.project.error,
    stores: state.stores.content?.data,
    storesPageCount: state.stores.content?.pageCount,
    storesPageIndex: state.stores.content?.pageIndex,
    files: state.files.content?.data,
    isMobile: state.breakpoint.content === "mobile",
    isLoadingStores: state.stores.loading,
    recordCount: state.stores.content?.recordCount,
  }),
  {
    createAnalyticsEvent,
    fetchProject,
    fetchStoresByProjectId,
    fetchFilesByProjectId,
    trackPageView,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(Project));
