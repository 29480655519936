import deleteTrashCan from "assets/img/delete-trash-can.svg";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import Icon from "components/Icon";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import ProjectFilter, { FilterOptions } from "containers/ProjectFilter";
import { File } from "interfaces/file";
import React from "react";
import Popover from "react-popover";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { deleteFileMappings, fetchAllFiles } from "state/files";
import moment from "moment";

type ManageFilesProps = PropsFromRedux & RouteProps;

interface ManageFilesState {
  tableData: File[];
  projectId: number;
  popoverOpenId: number | null;
}

class ManageFiles extends React.Component<ManageFilesProps, ManageFilesState> {
  state = { tableData: [], projectId: 0, popoverOpenId: null };

  public handleDelete(mappingId: string | number, maptype: "Store" | "Project") {
    this.props.deleteFileMappings(mappingId, maptype).then(() => {
      this.props.fetchAllFiles(this.state.projectId).then(() => {
        this.setState({
          tableData: this.props.allMapFiles || [],
        });
      });
    });
  }

  public togglePopoverOpenId(id: number) {
    this.setState({ popoverOpenId: this.state.popoverOpenId ? null : id });
  }

  public render() {
    return (
      <section className="manage-data-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: "/manage-all-data",
                crumb: "VIEW ALL DATA",
                dataQa: "view-all-data-link",
              },
            ],
          }}
        />
        <div className="manage-data-body">
          <h1 className="header">Manage Files</h1>
          <div className="manage-data-table-controls" data-qa="project-dropdown">
            <ProjectFilter
              onChange={({ id }: FilterOptions) => {
                this.props.fetchAllFiles(id).then(() => {
                  this.setState({
                    tableData: this.props.allMapFiles || [],
                    projectId: id,
                  });
                });
              }}
              isSearchable
            />
          </div>
          <CardList>
            <Card>
              <Table
                data={this.state.tableData || []}
                emptyState="Select a project and search to view file data"
                columns={[
                  {
                    field: "maptype",
                    header: "FILE TYPE",
                    dataQa: "file-type",
                  },
                  {
                    field: "name",
                    header: "FILE NAME",
                    dataQa: "file-name",
                  },
                  {
                    field: "storeno",
                    header: "STORE NO.",
                    dataQa: "store-number",
                  },
                  {
                    field: "user_name",
                    header: "Uploaded By",
                    wrap: true,
                    dataQa: "uploaded-by",
                  },
                  {
                    field: "updated_at",
                    header: "Upload Date",
                    wrap: true,
                    dataQa: "upload-date",
                    formatter: (value) => {
                      const date = moment(value);

                      if (!date.isValid()) {
                        return "--";
                      }

                      return date.format("M/D/YYYY h:mm A");
                    },
                  },
                  {
                    field: "",
                    header: "",
                    sortable: false,
                    dataQa: "delete-filemap",
                    formatter: (row: any, index: number) => {
                      return (
                        <Popover
                          body={
                            <div
                              style={{
                                display: "flex-inline",
                              }}
                            >
                              <p>Delete mapping for this file?</p>
                              <Button
                                data-qa="confirm-delete-button"
                                warning={true}
                                onClick={(e) => this.handleDelete(row.mapid, row.maptype)}
                              >
                                DELETE
                              </Button>
                              <Button
                                data-qa="cancel-delete-button"
                                secondary={true}
                                onClick={(e) => this.togglePopoverOpenId(index)}
                              >
                                CANCEL
                              </Button>
                            </div>
                          }
                          isOpen={this.state.popoverOpenId === index}
                          preferPlace="left"
                        >
                          <button
                            style={{ outline: "none" }}
                            className="delete-comment-button"
                            data-qa="delete-button"
                            onClick={(e) => this.togglePopoverOpenId(index)}
                          >
                            <Icon medium={true} src={deleteTrashCan} />
                          </button>
                        </Popover>
                      );
                    },
                  },
                ]}
              />
            </Card>
          </CardList>
        </div>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    projects: state.my_projects.content,
    allMapFiles: state.files.content?.data,
  }),
  { fetchAllFiles, deleteFileMappings }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageFiles);
