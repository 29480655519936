import React from "react";
import ReactDropzone, { DropzoneProps } from "react-dropzone";

const Dropzone: React.FunctionComponent<DropzoneProps> = ({ children, ...props }) => (
  <ReactDropzone
    inputProps={{
      onClick: (event: React.MouseEvent<any>) => {
        // unset target of file input to allow upload file with same name multiple times
        if (event && event.target) {
          (event.target as HTMLInputElement).value = "";
        }
      },
    }}
    {...props}
  >
    {children}
  </ReactDropzone>
);

export default Dropzone;
