import downloadSvg from "assets/img/download.svg";
import downloadInactiveSvg from "assets/img/downloads-inactive.svg";
import cx from "classnames";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import Dropdown from "components/Dropdown";
import Icon from "components/Icon";
import Page from "components/Page";
import Table from "components/Table";
import { Body } from "components/Typography";
import { RouteProps } from "containers/App";
import ProjectFilter from "containers/ProjectFilter";
import Constants from "helpers/constants";
import { filterItemsByUser } from "helpers/filter-functions";
import { ProjectType } from "interfaces/project";
import { ProjectValidation } from "interfaces/project-validation";
import { ReactSelectObject } from "interfaces/select";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { fetchProjectValidation } from "state/project_validation";
import { buildExportFilename } from "utils/helpers";

type ValidationCompletionProps = PropsFromRedux & RouteProps;

interface ValidationCompletionState {
  selectedProjectId?: number;
  selectedProjectName?: string;
  selectedProjectType?: string;
  selectedStoreId?: number;
  selectedUsers?: ReactSelectObject[];
  formattedTableData?: undefined | object[];
}

class ValidationCompletion extends React.Component<
  ValidationCompletionProps,
  ValidationCompletionState
> {
  protected CSV_HEADERS = [
    { label: "User Name", key: "userName" },
    { label: "Store Number", key: "storeNumber" },
    { label: "Validation Completion", key: "validated" },
    { label: "Validation Deadline", key: "validationDeadline" },
  ];

  public constructor(props: ValidationCompletionProps) {
    super(props);

    this.state = {
      selectedProjectId: undefined,
      selectedUsers: [],
      formattedTableData: [],
    };
  }

  public componentDidMount() {
    const { selectedProjectId } = this.state;

    return selectedProjectId && this.props.fetchProjectValidation(selectedProjectId);
  }

  public reloadTableData(id: number, name: string, type: string) {
    this.setState({ formattedTableData: [] });

    return this.props.fetchProjectValidation(id).then(() => {
      const { project_validation = [] } = this.props;

      this.setState({
        selectedProjectId: id,
        selectedProjectName: name,
        selectedProjectType: type,
        formattedTableData: this.formatCSVData(project_validation),
      });
    });
  }

  public filterTableData() {
    const { project_validation = [] } = this.props;
    const { selectedStoreId, selectedUsers = [] } = this.state;

    this.setState({
      formattedTableData: this.formatCSVData(
        filterItemsByUser(
          selectedUsers,
          selectedStoreId === -1 || !selectedStoreId
            ? project_validation
            : project_validation.filter((validation) => validation.storeId === selectedStoreId)
        )
      ),
    });
  }

  public formatCSVData(tableData: ProjectValidation) {
    return tableData.map((row: any) => ({
      userName: row.userName,
      storeNumber: row.storeNumber,
      validated: row.validated ? "YES" : "NO",
      validationDeadline: moment(row.validationDeadline).format("M/D/YYYY hh:mm A"),
    }));
  }

  public render() {
    const { selectedProjectId, selectedProjectName, selectedUsers, formattedTableData } =
      this.state;
    const { project_validation = [], loadingValidation } = this.props;

    const isDisabled = !selectedProjectId || selectedProjectId === -1;
    const exportIcon = isDisabled ? downloadInactiveSvg : downloadSvg;

    const storeNumDropdownOptions = [
      { label: "All Stores", value: "", storeId: -1, "data-qa": "all-stores" },
    ].concat(
      uniqBy(
        project_validation
          .map((validation) => ({
            label: validation.storeNumber,
            value: validation.storeNumber,
            storeId: validation.storeId,
            "data-qa": validation.storeNumber,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        "storeId"
      )
    );

    const userDropdownOptions = uniqBy(
      project_validation
        .map((validation) => ({
          label: validation.userName,
          value: validation.userName,
          "data-qa": validation.userName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      "value"
    );

    return (
      <Page className="validation-completion-container">
        <header className="section-header">
          <h1 className="layout-header" data-qa="validation-completion-header">
            Validation Completion
          </h1>
          <ProjectFilter
            onChange={({ id, name, type }) => {
              this.reloadTableData(id, name, type);
            }}
            dataQa={"select-project"}
            exclude={Constants.FILE_SHARING}
            isSearchable
          />
          <div className="section-header-button-group">
            {formattedTableData && (
              <CSVDownload
                className="csv-link"
                data={formattedTableData}
                headers={this.CSV_HEADERS}
                filename={buildExportFilename(
                  "csv",
                  `${
                    selectedProjectName ? selectedProjectName.toLocaleLowerCase() : ""
                  }-validation-completion`
                )}
                onClick={() => {
                  const { selectedProjectName, selectedProjectType } = this.state;

                  this.props.createAnalyticsEvent({
                    event: EventTypes.REPORT_EXPORT,
                    eventType: "Action",
                    projectName: selectedProjectName as string,
                    projectType: selectedProjectType as ProjectType,
                    pageName: "Validation Completion",
                    reportRowCount: formattedTableData.length,
                  });
                }}
              >
                <button
                  disabled={isDisabled}
                  className={cx("import-export-button", {
                    disabled: isDisabled,
                  })}
                  data-qa="validation-completion-export-button"
                >
                  Export <Icon src={exportIcon} />
                </button>
              </CSVDownload>
            )}
          </div>
        </header>
        <CardList>
          <Card>
            <Card.Header>
              <Body type="4" className="card-header-filters-header">
                Filters
              </Body>
              <ul className="card-header-filters-list">
                <li className="card-header-filter">
                  <Body type="4" className="card-header-filter-label">
                    User Name
                  </Body>
                  <Dropdown
                    options={userDropdownOptions}
                    dataQa={"user-name-filter"}
                    placeholder="Select"
                    isDisabled={isDisabled}
                    containerStyles={{
                      marginLeft: "unset",
                      fontWeight: "normal",
                      marginBottom: "20px",
                      width: "250px",
                    }}
                    onChange={(newSelectedUsers: ReactSelectObject[]) => {
                      this.setState({ selectedUsers: newSelectedUsers }, this.filterTableData);
                    }}
                    value={selectedUsers}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    isSearchable
                  />
                </li>
                <li className="card-header-filter">
                  <Body type="4" className="card-header-filter-label">
                    Store Number
                  </Body>
                  <Dropdown
                    value={
                      storeNumDropdownOptions.find(
                        (opt) => opt.storeId === this.state.selectedStoreId
                      ) || storeNumDropdownOptions[0]
                    }
                    options={storeNumDropdownOptions}
                    dataQa={"store-number-filter"}
                    placeholder="Select"
                    isDisabled={isDisabled}
                    containerStyles={{
                      marginLeft: "unset",
                      marginBottom: "20px",
                      width: "250px",
                    }}
                    onChange={(store) => {
                      this.setState({ selectedStoreId: store.storeId }, this.filterTableData);
                    }}
                    isSearchable
                  />
                </li>
              </ul>
            </Card.Header>
            {formattedTableData && (
              <Table
                loading={loadingValidation}
                data={loadingValidation ? [] : formattedTableData}
                emptyState={
                  selectedProjectId && selectedProjectId > -1
                    ? "No validation found for selected project"
                    : "Select a project to view validation"
                }
                columns={[
                  {
                    field: "userName",
                    header: "User Name",
                    dataQa: "userName",
                  },
                  {
                    field: "storeNumber",
                    header: "Store No",
                    dataQa: "storeNumber",
                  },
                  {
                    field: "validated",
                    header: "Validation Completion",
                    dataQa: "validationCompletion",
                  },
                  {
                    field: "validationDeadline",
                    header: "Validation Deadline",
                    dataQa: "validationDeadline",
                    formatter: (value) => {
                      const deadline = moment(value);

                      if (!deadline.isValid()) {
                        return "--";
                      }

                      return deadline.format("M/D/YYYY hh:mm A");
                    },
                  },
                ]}
              />
            )}
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    project_validation: state.project_validation.content,
    loadingValidation: state.project_validation.loading,
  }),
  { fetchProjectValidation, createAnalyticsEvent }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ValidationCompletion);
