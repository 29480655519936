import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
// import { Chain } from "interfaces/chain";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Action Types
export const FETCH_RECENT_PROJECTS_REQUEST = "FETCH_RECENT_PROJECTS_REQUEST";
export const FETCH_RECENT_PROJECTS_SUCCESS = "FETCH_RECENT_PROJECTS_SUCCESS";
export const FETCH_RECENT_PROJECTS_FAILURE = "FETCH_RECENT_PROJECTS_FAILURE";

// Thunk Action Creators
export const fetchRecentProjects: ThunkActionCreator<Promise<void>> = (userId: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_RECENT_PROJECTS_REQUEST });
    const url = `${Config.API_SERVICE_URL}/api/recent-projects?userId=${userId}&eventType=PROJECT_DETAIL_PAGEVIEW`;
    return axios
        .get(url, getHeaders())
        .then(response => {
            dispatch({ type: FETCH_RECENT_PROJECTS_SUCCESS, payload: response.data });
        })
        .catch( error => {
            dispatch({ type: FETCH_RECENT_PROJECTS_FAILURE, payload: error });
        });
};

// Actions
const actions = {
    [FETCH_RECENT_PROJECTS_REQUEST]: (state: RecentProjectsState) => ({
        ...state,
        loading: true,
    }),
    [FETCH_RECENT_PROJECTS_SUCCESS]: (state: RecentProjectsState, action: AnyAction) => {
        return {
            ...state,
            content: action.payload,
            loading: false,
            error: false,
        };
    },
    [FETCH_RECENT_PROJECTS_FAILURE]: (state: RecentProjectsState) => ({
        ...state,
        loading: false,
        error: true,
    }),
};

// Reducer Interface
// TODO: Add type to content
export interface RecentProjectsState {
    content: any[];
    error?: boolean;
    loading?: boolean;
}

// Initial State
const initialState: RecentProjectsState = {
    loading: false,
    error: false,
    content: [],
};

// Reducer
export default handleActions<RecentProjectsState, AnyAction>(actions, initialState);