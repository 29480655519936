import deleteTrashCan from "assets/img/delete-trash-can.svg";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import CSVDownload2 from "components/CSVDownload2";
import DataTable, { parseSortParam } from "components/DataTable";
import Form from "components/Form";
import Icon from "components/Icon";
import Config from "config";
import { RouteProps } from "containers/App";
import ChainFilter from "containers/ChainFilter";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import FileUpload from "containers/FileUpload";
import ManageDataPreview from "containers/ManageDataPreview";
import React from "react";
import Popover from "react-popover";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import {
  deleteAllUserToStoreMappings,
  deleteUserToChainStoreMappings,
  deleteUserToStore,
  fetchUsersToStoresByChainId,
  filterUsersToStoresByChainId,
  exportUsersToStoresByChainId,
} from "state/user2stores";
import { buildExportFilename } from "utils/helpers";

type UsersToStores = {
  id: number;
  user_id: number;
  chainId: number;
  retailStoreNumber: string;
  email: string;
  chainName: string;
}[];

type ManageMappingDataProps = PropsFromRedux & RouteProps;

export const USER_STORES_PAGE_SIZE = 100;
export const USER_STORES_DEFAULT_SORT = "retailStoreNumber.asc";

interface ManageMappingDataState {
  tableData: UsersToStores;
  deleteSinglePopoverOpenId: number | null;
  deleteAllPopoverOpenKey: number | string | null;
  deleteChainPopoverOpenKey: number | string | null;
  chainId: number;
  resetTable: boolean;
  email: string | null;
  page: number | null;
  sort: string | null;
  size: number | null;
}

class ManageMappingData extends React.Component<ManageMappingDataProps, ManageMappingDataState> {
  protected CSV_HEADERS = [
    { label: "Chain Name", key: "chainName" },
    { label: "Retail Store Number", key: "retailStoreNumber" },
    { label: "Email Address", key: "email" },
  ];

  protected MAX_DELETE_COLUMN_WIDTH = 100;
  protected POPOVER_TRANSITION_MS = 100;

  state = {
    tableData: [] as UsersToStores,
    deleteSinglePopoverOpenId: null,
    deleteAllPopoverOpenKey: null,
    deleteChainPopoverOpenKey: null,
    chainId: -1,
    resetTable: false,
    email: "",
    page: 0,
    sort: USER_STORES_DEFAULT_SORT,
    size: USER_STORES_PAGE_SIZE
  };

  public componentDidUpdate(prevProps: ManageMappingDataProps) {
    // when an entry is deleted ensure that we update our table view
    if (
      prevProps.usersToStores &&
      this.props.usersToStores &&
      prevProps.usersToStores.length !== this.props.usersToStores.length
    ) {
      this.updateTableData();
    }
  }

  public toggleDeleteSinglePopoverOpenId(id: number) {
    this.setState({
      deleteSinglePopoverOpenId: this.state.deleteSinglePopoverOpenId ? null : id,
    });
  }

  public toggleDeleteChainPopoverOpenId(key: string) {
    this.setState({
      deleteChainPopoverOpenKey: this.state.deleteChainPopoverOpenKey ? null : key,
    });
  }

  public handleDelete(mappingId: number) {
    this.toggleDeleteSinglePopoverOpenId(mappingId);
    this.props.deleteUserToStore(mappingId);
  }

  public toggleDeleteAllPopoverOpenId(key: string) {
    this.setState({
      deleteAllPopoverOpenKey: this.state.deleteAllPopoverOpenKey ? null : key,
    });
  }

  public handleDeleteChain(userId: number) {
    const { chainId } = this.state;

    this.props.deleteUserToChainStoreMappings(userId, chainId);
  }

  public handleDeleteAll(userId: number) {
    this.props.deleteAllUserToStoreMappings(userId);
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  public handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { chainId } = this.state;
    // reset form and inputs
    this.setState({ email: "", page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT });
    this.props.fetchUsersToStoresByChainId(chainId, { page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT }).then(() => {
      this.setState({ resetTable: true });
      this.updateTableData();
      this.setState({ resetTable: false, page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT });
    });
  };

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { chainId, email, page, size, sort } = this.state;
    this.props.filterUsersToStoresByChainId(chainId, { page: page, size: size, sort: sort, email: email }).then(() => {
      this.updateTableData();
    });
  };


  public updateTableData() {
    const { usersToStores = [] } = this.props;

    this.setState({
      tableData: [...usersToStores],
    });
  }
  public renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group title="Search">
          <Form.Input
            type="text"
            placeholder="Email"
            data-qa="store-email-field"
            value={this.state.email}
            name="email"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.ButtonGroup>
          <Form.CancelButton onClick={this.handleClear} data-qa="clear-button">
            Clear
          </Form.CancelButton>
          <Form.ConfirmButton data-qa="search-button">Search</Form.ConfirmButton>
        </Form.ButtonGroup>
      </Form>
    );
  }
  public render() {
    const { loading, pageCount } = this.props;
    const { resetTable } = this.state;

    return (
      <section className="manage-data-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: "/manage-all-data",
                crumb: "VIEW ALL DATA",
                dataQa: "view-all-data-link",
              },
            ],
          }}
        />
        <div className="manage-data-body">
          <h1 className="header">Manage Store-User Data</h1>
          <ul className="file-upload-list">
            <li className="file-upload-item">
              <FileUpload
                prefix={"USER2STORE"}
                url={`${Config.API_SERVICE_URL}/api/users-stores`}
                label={"User to Store Mapping"}
                dataQa={"user-store-mapping-file-upload"}
                afterUploadFunction={() => {
                  const { chainId } = this.state;
                  this.props.fetchUsersToStoresByChainId(chainId, { page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT }).then(() => {
                    this.setState({ resetTable: true });
                    this.updateTableData();
                    this.setState({ resetTable: false, page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT });
                  });
                }}
              />
            </li>
          </ul>
          <CardList>
            <Card>
              <ManageDataPreview prefix={"USER2STORE"} label={"Store-User Mapping(s)"} />
            </Card>
          </CardList>
          <div className="manage-data-table-controls" data-qa="chain-dropdown">
            <ChainFilter
              onChange={({ id }) =>
                this.props.fetchUsersToStoresByChainId(id, { page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT }).then(() => {
                  this.setState({ chainId: id, resetTable: true });
                  this.updateTableData();
                  this.setState({ resetTable: false, page: 0, size: USER_STORES_PAGE_SIZE, sort: USER_STORES_DEFAULT_SORT });
                })
              }
              isSearchable
            />
            <CSVDownload2
              data={() =>
                this.props.exportUsersToStoresByChainId(this.state.chainId, { email: this.state.email }).then(() => this.props.exportContent || [])
              }
              headers={this.CSV_HEADERS}
              filename={buildExportFilename("csv", "users-stores")}
            />
          </div>
          <CardList>
            <Card>
              {this.renderForm()}
            </Card>
            <Card>
              <DataTable
                data={this.state.tableData}
                renderEmpty="Select a chain to view mapping data"
                isLoading={loading}
                reset={resetTable}
                paginated
                manualPagination
                sortable
                pageCount={pageCount}
                initialState={{
                  pageSize: USER_STORES_PAGE_SIZE,
                  sortBy: parseSortParam(USER_STORES_DEFAULT_SORT),
                }}
                onPageChange={(page: number, size: number, sort: string) => {
                  if (this.state.email) {
                    this.props.filterUsersToStoresByChainId(this.state.chainId, { page, size, sort, email: this.state.email }).then(() => {
                      this.updateTableData();
                      this.setState({ page: page, size: size, sort: sort });
                    })
                  }
                  else {
                    this.props.fetchUsersToStoresByChainId(this.state.chainId, { page, size, sort }).then(() => {
                      this.updateTableData();
                      this.setState({ page: page, size: size, sort: sort });
                    })

                  }
                }}
                columns={[
                  {
                    id: "chainName",
                    Header: "CHAIN NAME",
                    accessor: "chainName",
                    dataQa: "chain-name",
                  },
                  {
                    id: "retailStoreNumber",
                    Header: "RETAIL STORE NUMBER",
                    accessor: "retailStoreNumber",
                    dataQa: "store-number",
                  },
                  {
                    id: "email",
                    Header: "EMAIL",
                    accessor: "email",
                    dataQa: "email",
                  },
                  {
                    id: "delete_all",
                    Header: "",
                    accessor: "user_id",
                    dataQa: "delete-all",
                    disableSortBy: true,
                    Cell: (meta: any) => {
                      const key = `${meta.value}-${meta.row.values.retailStoreNumber}`;
                      return (
                        <Popover
                          body={
                            <div
                              style={{
                                display: "flex-inline",
                              }}
                            >
                              <p>Delete all mappings for this user?</p>
                              <Button
                                warning={true}
                                data-qa="confirm-delete-button"
                                onClick={() => {
                                  this.handleDeleteAll(meta.value);
                                  this.toggleDeleteAllPopoverOpenId(key);
                                }}
                              >
                                DELETE
                              </Button>
                              <Button
                                secondary={true}
                                data-qa="cancel-delete-mappings"
                                onClick={() => this.toggleDeleteAllPopoverOpenId(key)}
                              >
                                CANCEL
                              </Button>
                            </div>
                          }
                          isOpen={this.state.deleteAllPopoverOpenKey === key}
                          preferPlace="left"
                          enterExitTransitionDurationMs={this.POPOVER_TRANSITION_MS}
                        >
                          <button
                            style={{ outline: "none" }}
                            className="delete-all-mappings"
                            data-qa={`delete-all-mappings-for-user-${meta.row.values.email}`}
                            onClick={(e) => this.toggleDeleteAllPopoverOpenId(key)}
                          >
                            Delete All Mappings
                          </button>
                        </Popover>
                      );
                    },
                  },
                  {
                    id: "delete_all_chain",
                    Header: "",
                    accessor: "user_id",
                    dataQa: "delete-all-chain",
                    disableSortBy: true,
                    Cell: (meta: any) => {
                      const key = `${meta.value}-${meta.row.values.retailStoreNumber}`;
                      return (
                        <Popover
                          body={
                            <div
                              style={{
                                display: "flex-inline",
                              }}
                            >
                              <p>Delete all mappings for this user for this chain?</p>
                              <Button
                                warning={true}
                                data-qa="confirm-delete-button"
                                onClick={(e) => {
                                  this.handleDeleteChain(meta.value);
                                  this.toggleDeleteChainPopoverOpenId(key);
                                }}
                              >
                                DELETE
                              </Button>
                              <Button
                                secondary={true}
                                data-qa="cancel-delete-mappings"
                                onClick={(e) => this.toggleDeleteChainPopoverOpenId(key)}
                              >
                                CANCEL
                              </Button>
                            </div>
                          }
                          isOpen={this.state.deleteChainPopoverOpenKey === key}
                          preferPlace="left"
                          enterExitTransitionDurationMs={this.POPOVER_TRANSITION_MS}
                        >
                          <button
                            style={{
                              outline: "none",
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="delete-all-mappings"
                            data-qa={`delete-chain-mappings-for-user-${meta.row.values.email}`}
                            onClick={(e) => this.toggleDeleteChainPopoverOpenId(key)}
                          >
                            Delete Chain Mappings
                          </button>
                        </Popover>
                      );
                    },
                  },
                  {
                    id: "id",
                    Header: "",
                    accessor: "id",
                    dataQa: "id",
                    disableSortBy: true,
                    maxWidth: this.MAX_DELETE_COLUMN_WIDTH,
                    Cell: (meta: any) => {
                      const rowId = meta.value;
                      return (
                        <Popover
                          body={
                            <div
                              style={{
                                display: "flex-inline",
                              }}
                            >
                              <p>Delete mapping for this user?</p>
                              <Button
                                data-qa="confirm-delete-button"
                                warning={true}
                                onClick={(e) => this.handleDelete(rowId)}
                              >
                                DELETE
                              </Button>
                              <Button
                                data-qa="cancel-button"
                                secondary={true}
                                onClick={(e) => this.toggleDeleteSinglePopoverOpenId(rowId)}
                              >
                                CANCEL
                              </Button>
                            </div>
                          }
                          isOpen={this.state.deleteSinglePopoverOpenId === rowId}
                          preferPlace="left"
                          enterExitTransitionDurationMs={this.POPOVER_TRANSITION_MS}
                        >
                          <button
                            style={{ outline: "none" }}
                            className="delete-comment-button"
                            data-qa="delete-comment-button"
                            onClick={(e) => this.toggleDeleteSinglePopoverOpenId(rowId)}
                          >
                            <Icon medium={true} src={deleteTrashCan} />
                          </button>
                        </Popover>
                      );
                    },
                  },
                ] as any[]}
              />
            </Card>
          </CardList>
        </div>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    usersToStores: state.users_to_stores.content,
    exportContent: state.users_to_stores.exportContent,
    loading: state.users_to_stores.loading,
    pageCount: state.users_to_stores.pageCount,
  }),
  {
    fetchUsersToStoresByChainId,
    filterUsersToStoresByChainId,
    exportUsersToStoresByChainId,
    deleteUserToStore,
    deleteAllUserToStoreMappings,
    deleteUserToChainStoreMappings,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageMappingData);
