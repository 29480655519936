import Constants from "./constants";

export type Reason =
  | Constants.REASON_APPROVED_CORE_NEW_ITEM_LABEL
  | Constants.REASON_CAPACITY_LABEL
  | Constants.REASON_DAYS_OF_SUPPLY_LABEL
  | Constants.REASON_FLOW_LABEL
  | Constants.REASON_HURDLE_CRITERIA_LABEL
  | Constants.REASON_ITEM_NOT_APPROVED_LABEL
  | Constants.REASON_MARKET_PERFORMANCE_LABEL
  | Constants.REASON_OTHER_LABEL
  | Constants.REASON_PLACEMENT_ORIENTATION_LABEL
  | Constants.REASON_PRODUCT_UNAVAILABLE_LABEL;

export type ReasonID =
  | Constants.REASON_APPROVED_CORE_NEW_ITEM
  | Constants.REASON_CAPACITY
  | Constants.REASON_DAYS_OF_SUPPLY
  | Constants.REASON_FLOW
  | Constants.REASON_HURDLE_CRITERIA
  | Constants.REASON_ITEM_NOT_APPROVED
  | Constants.REASON_MARKET_PERFORMANCE
  | Constants.REASON_OTHER
  | Constants.REASON_PLACEMENT_ORIENTATION
  | Constants.REASON_PRODUCT_UNAVAILABLE;

export const reasons: { [key in ReasonID]: Reason } = {
  [Constants.REASON_APPROVED_CORE_NEW_ITEM]: Constants.REASON_APPROVED_CORE_NEW_ITEM_LABEL,
  [Constants.REASON_CAPACITY]: Constants.REASON_CAPACITY_LABEL,
  [Constants.REASON_DAYS_OF_SUPPLY]: Constants.REASON_DAYS_OF_SUPPLY_LABEL,
  [Constants.REASON_FLOW]: Constants.REASON_FLOW_LABEL,
  [Constants.REASON_HURDLE_CRITERIA]: Constants.REASON_HURDLE_CRITERIA_LABEL,
  [Constants.REASON_ITEM_NOT_APPROVED]: Constants.REASON_ITEM_NOT_APPROVED_LABEL,
  [Constants.REASON_MARKET_PERFORMANCE]: Constants.REASON_MARKET_PERFORMANCE_LABEL,
  [Constants.REASON_OTHER]: Constants.REASON_OTHER_LABEL,
  [Constants.REASON_PLACEMENT_ORIENTATION]: Constants.REASON_PLACEMENT_ORIENTATION_LABEL,
  [Constants.REASON_PRODUCT_UNAVAILABLE]: Constants.REASON_PRODUCT_UNAVAILABLE_LABEL,
};

export type Action =
  | Constants.ACTION_GENERAL_COMMENT_LABEL
  | Constants.ACTION_ADD_PRODUCT_LABEL
  | Constants.ACTION_MODIFY_PRODUCT_LABEL
  | Constants.ACTION_REMOVE_PRODUCT_LABEL
  | Constants.ACTION_SWAP_PRODUCT_LABEL;

export type ActionID =
  | Constants.ACTION_GENERAL_COMMENT
  | Constants.ACTION_ADD_PRODUCT
  | Constants.ACTION_MODIFY_PRODUCT
  | Constants.ACTION_REMOVE_PRODUCT
  | Constants.ACTION_SWAP_PRODUCT;

export interface ActionReason {
  action: Action;
  "data-qa": string;
  hasProduct: boolean;
  hasSecondProduct: boolean;
  reasons: ReasonID[];
}

const actionsReasons: { [key in ActionID]: ActionReason } = {
  [Constants.ACTION_GENERAL_COMMENT]: {
    action: Constants.ACTION_GENERAL_COMMENT_LABEL,
    "data-qa": "general-comment",
    hasProduct: false,
    hasSecondProduct: false,
    reasons: [],
  },
  [Constants.ACTION_ADD_PRODUCT]: {
    action: Constants.ACTION_ADD_PRODUCT_LABEL,
    "data-qa": "add-product",
    hasProduct: true,
    hasSecondProduct: false,
    reasons: [
      Constants.REASON_HURDLE_CRITERIA,
      Constants.REASON_APPROVED_CORE_NEW_ITEM,
      Constants.REASON_MARKET_PERFORMANCE,
      Constants.REASON_OTHER,
    ],
  },
  [Constants.ACTION_MODIFY_PRODUCT]: {
    action: Constants.ACTION_MODIFY_PRODUCT_LABEL,
    "data-qa": "modify-product",
    hasProduct: true,
    hasSecondProduct: false,
    reasons: [
      Constants.REASON_DAYS_OF_SUPPLY,
      Constants.REASON_CAPACITY,
      Constants.REASON_FLOW,
      Constants.REASON_PLACEMENT_ORIENTATION,
      Constants.REASON_OTHER,
    ],
  },
  [Constants.ACTION_REMOVE_PRODUCT]: {
    action: Constants.ACTION_REMOVE_PRODUCT_LABEL,
    "data-qa": "remove-product",
    hasProduct: true,
    hasSecondProduct: false,
    reasons: [
      Constants.REASON_HURDLE_CRITERIA,
      Constants.REASON_PRODUCT_UNAVAILABLE,
      Constants.REASON_ITEM_NOT_APPROVED,
      Constants.REASON_OTHER,
    ],
  },
  [Constants.ACTION_SWAP_PRODUCT]: {
    action: Constants.ACTION_SWAP_PRODUCT_LABEL,
    "data-qa": "swap-product",
    hasProduct: true,
    hasSecondProduct: true,
    reasons: [],
  },
};

export default actionsReasons;
