import React from "react";

import Dropdown from "components/Dropdown";
import { File } from "interfaces/file";
import { ReactSelectObject } from "interfaces/select";

interface FileTypeFilterProps {
  fileTypeOptions: ReactSelectObject[];
  onChange?: (selected: ReactSelectObject[]) => any;
  isDisabled?: boolean;
  dataQa?: string;
  value: any;
  isSearchable?: boolean;
}

const FileTypeFilter: React.FunctionComponent<FileTypeFilterProps> = ({
  fileTypeOptions,
  onChange,
  isDisabled,
  value,
  isSearchable,
}) => (
  <Dropdown
    containerStyles={{ width: "300px", minWidth: 230, marginLeft: 10 }}
    placeholder="Select File Type"
    options={fileTypeOptions}
    onChange={onChange}
    isDisabled={isDisabled}
    value={value}
    isMulti={true}
    closeMenuOnSelect={false}
    blurInputOnSelect={false}
    isSearchable={isSearchable}
  />
);

export const filterFilesByType = (selectedTypes: ReactSelectObject[], files: File[]) => {
  const filteredFiles: File[] = [];
  if (selectedTypes.length > 0) {
    for (const fileType of selectedTypes) {
      for (const file of files) {
        if (file.extension.toUpperCase() === fileType.value.toUpperCase()) {
          filteredFiles.push(file);
        }
      }
    }
    return filteredFiles;
  } else {
    return files;
  }
};

export default FileTypeFilter;
