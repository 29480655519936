import cx from "classnames";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { reject, take } from "lodash";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Card } from "components/Card";
import CommentForm from "components/CommentForm";
import Popover from "components/DeletePopover";
import Table from "components/Table";
import actionsReasons, { ActionID, ReasonID, reasons } from "helpers/actions-reasons";
import Constants from "helpers/constants";
import { State } from "state";
import { deleteComment, postComment } from "state/comment";
import { fetchMyProjectComments } from "state/project_comments";
import { fetchComments } from "state/comments";
import { fetchProducts } from "state/products";
import Dialog, {DialogIcon} from "components/Dialog";

import ButtonWithCopyComments from "components/ButtonWithCopyComments";


interface CommentsUState {
  actionindex?: string;
  thecomment?: string;
  reason_id?: string;
  product_id?: string;
  product_sub_id?: string;
  openAddCommentErrorDialog: boolean;
}

interface CommentsUIOwnProps {
  isMobile: boolean;
  storeId: number | string;
  projectId: number | string;
}

type CommentsUIProps = CommentsUIOwnProps & PropsFromRedux;

class CommentsUI extends React.Component<CommentsUIProps, CommentsUState> {

  private previousFormFields: {
    action_id?: any;
    comment?: string;
    reason_id?: string;
    product_id?: string;
    product_sub_id?: string;
    product_name?: string;
    product_sub_name?: string;
    reason?: string;
  } | null = null;

  //During the inital "Add Comment" click, the formResetter function gets saved for later use in the "Try Again" button
  private formResetter: (() => void) | null = null;

  public componentDidMount() {
    const { storeId, projectId} = this.props;
    this.setState({...this.state, openAddCommentErrorDialog: false})
    this.props.fetchComments({ storeId, projectId });
    this.props.fetchMyProjectComments(projectId);
    this.props.fetchProducts();
  }

  public handleOnSubmit = (
    e: React.FormEvent,
    formFields: {
      action_id?: any;
      comment?: string;
      reason_id?: string;
      product_id?: string;
      product_sub_id?: string;
      product_name?: string;
      product_sub_name?: string;
      reason?: string;
    } | null = this.previousFormFields,
    handleFormClear: (() => void)| null = this.formResetter
  ) => {

    e?.preventDefault();

    let noErrors = true;


    if (this.props.auth_user) {
      const {
        storeId,
        projectId,
        auth_user: { id },
        store,
      } = this.props;

      const {
        action_id,
        comment,
        reason_id,
        product_id,
        product_sub_id,
        product_name,
        product_sub_name,
        reason,
      } = formFields || {};


      this.previousFormFields = formFields;
      this.formResetter = handleFormClear;

      if (store) {
        const { project_name, project_type, chain_name, store_name } = store;

        //Close the Try Again Dialog if it is currently being rendered
        this.setState({...this.state, openAddCommentErrorDialog: false});
        this.props
          .postComment(
            {
              user_id: id,
              store_id: [storeId],
              project_id: projectId,
              action_id,
              reason_id,
              product_id,
              product_sub_id,
              comment,
            },
            {
              name: `${chain_name} - ${project_name}`,
              type: project_type,
              storeName: store_name,
              product_sub_name,
              product_name,
              reason,
            }
          ).catch(()=>{
            //Post Comment Failed
            this.setState({...this.setState, openAddCommentErrorDialog: true})
            noErrors = false;
          })
          .then(() => {
            if(noErrors){
              //Clear the comment form values
              if(handleFormClear){
                handleFormClear();
              }
              this.previousFormFields = null;
            }
            this.props.fetchComments({ storeId, projectId });
            this.props.fetchMyProjectComments(projectId);
          });

        return true;
      }

      return false;
    }

    return false;
  };

  public handleDelete = (commentId: number, { comment, action_id }: any) => {
    return new Promise<void>((resolve) => {
      const { store } = this.props;

      if (store) {
        const { project_name, project_type, chain_name, store_name } = store;

        return this.props
          .deleteComment(commentId, {
            name: `${chain_name} - ${project_name}`,
            type: project_type,
            storeName: store_name,
            comment,
            action_id,
          })
          .then(() => resolve());
      }
    });
  };

  private setOpenAddCommentErrorDialog: React.Dispatch<React.SetStateAction<boolean>> = (open: any) => {
      this.setState({...this.state, openAddCommentErrorDialog: open})
  }

  public render() {

    const { store, comments = [] } = this.props;

    if (!store || !comments) {
      return null;
    }
    const { validation_deadline, feedback_limit } = store;

    const isDisabled = moment(validation_deadline!).isSameOrBefore(moment());

    return (
      <>
      {this.state?.openAddCommentErrorDialog && <Dialog
                open={this.state.openAddCommentErrorDialog}
                setOpen={this.setOpenAddCommentErrorDialog}
                onPrimaryAction={
                  this.handleOnSubmit
                }

                icon={DialogIcon.ERROR}
                primaryContent={() => <p style={{ color: "#E13C37" }}>Error</p>}
                secondaryContent={() => {
                  return (
                    <div className="items-center">
                      <p>The action you requested could not be completed.</p>
                      <p>Please try to submit the action again.</p>
                    </div>
                  );
                }}
                primaryButtonContent="Try Again"
                removeSecondaryButton
              />}
      <Card>
        <Card.Header>
          <h3 style={{ marginRight: 20 }}>Add Comments</h3>
        </Card.Header>
        <CommentForm
          onSubmit={this.handleOnSubmit}
          isDisabled={
            isDisabled ||
            feedback_limit === 0 ||
            (feedback_limit && comments.length >= feedback_limit)
          }
          products={this.props.products ? this.props.products : []}
          projectComments={this.props.projectComments ? this.props.projectComments : []}
          breakpoint={this.props.breakpoint || ""}
        />
        <span
          data-qa="comments-count"
          className={cx("comments-count", {
            warn: feedback_limit === 0 || (feedback_limit && comments.length >= feedback_limit),
          })}
        >
          {comments.length} /{" "}
          {!feedback_limit && feedback_limit !== 0 ? "Unlimited " : feedback_limit} Comments Submitted
        </span>
        <Table
          data={comments}
          isMobile={this.props.isMobile}
          columns={[
            {
              field: "action_id",
              header: "Action Type",
              sortable: false,
              formatter: (value) => actionsReasons[value as ActionID].action,
              dataQa: "action",
              wrap: true,
              maxWidth: 150,
            },
            {
              field: "product_name",
              dataQa: "product_name",
              header: "Product",
              sortable: false,
              wrap: true,
              maxWidth: 225,
            },
            {
              field: "reason_id",
              header: "Reason/Product",
              dataQa: "reason",
              sortable: false,
              wrap: true,
              maxWidth: 200,
              formatter: (reason, row) => {
                // Supports both a reason and a product substitution
                return row &&
                  row.action_id &&
                  row.action_id.toString() === Constants.ACTION_SWAP_PRODUCT
                  ? row.product_sub_name
                  : reasons[reason as ReasonID];
              },
            },
            {
              field: "product_sub_name",
              header: "Sub Product",
              dataQa: "reason",
              show: false,
              sortable: false,
              wrap: true,
              maxWidth: 200,
            },
            {
              dataQa: "comment",
              field: "comment",
              header: "Comment",
              sortable: false,
              wrap: true,
            },
            {
              dataQa: "copy-comments",
              field: "comment_id", //Adding this avoids duplicate key error without needing to refactor MobileTable
              header: "",
              sortable: false,
              wrap: true,
              maxWidth: 65,
              formatter: (value:any, row: any, index: any) => {
                return (
                  <ButtonWithCopyComments store={store} feedback_limit={feedback_limit} comment={row} stores={this.props.stores.data} projectId={this.props.projectId} project={this.props.project!}/>
                );
              },
            },
            {
              dataQa: "delete-comment",
              field: "",
              header: "",
              sortable: false,
              wrap: true,
              maxWidth: 65,
              formatter: (row: any, index: number) => {
                return (
                  <Popover
                    message={"Delete this comment"}
                    id={row.id}
                    index={index}
                    onConfirm={(id) => this.handleDelete(id, row)}
                    hidden={isDisabled}
                    isMobile={this.props.isMobile}
                    buttonStyles={{paddingLeft: 0, paddingRight: 15}}
                  />
                );
              },
            },
          ]}
          emptyState="No comments found"
        />
      </Card>
      </>
    );
  }
}


const connector = connect(
  (state: State) => ({
    auth_user: state.auth_user.content,
    comments: uniqBy(state.comments.content, "id"),
    projectComments: take(
      orderBy(
        reject(
          uniqBy(state.project_comments.content, function(elem) { return [elem.actionId, elem.reasonId, elem.productId, elem.productSubId, elem.comment].join(); })
          , ["created_at", null]),
          "created_at", "desc"),
        5),
    store: state.store.content,
    products: state.products.content,
    isMobile: state.breakpoint.content === "mobile",
    breakpoint: state.breakpoint.content,
    stores: state.stores.content,
    project: state.project.content
  }),
  {
    fetchProducts,
    fetchComments,
    fetchMyProjectComments,
    deleteComment,
    postComment,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CommentsUI);
