import downloadSvg from "assets/img/download.svg";
import downloadInactiveSvg from "assets/img/downloads-inactive.svg";
import cx from "classnames";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import Dropdown from "components/Dropdown";
import Icon from "components/Icon";
import Page from "components/Page";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import ProjectFilter from "containers/ProjectFilter";
import { ProjectType } from "interfaces/project";
import { ResetNote } from "interfaces/reset-notes";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { fetchProjectResetNotes } from "state/resetnotes";
import { allFieldsEmpty, buildExportFilename } from "utils/helpers";

type StoreResetFeedbackProps = PropsFromRedux & RouteProps;

interface StoreResetFeebackComponentState {
  tableData: ResetNote[];
  selectedProjectId?: number;
  selectedStoreNumber?: string | null;
  selectedProjectName?: string;
  selectedProjectType?: string;
}

class StoreResetFeedback extends React.Component<
  StoreResetFeedbackProps,
  StoreResetFeebackComponentState
> {
  protected CSV_HEADERS = [
    { label: "Store Number", key: "store_number" },
    { label: "Implementation", key: "implementation" },
    { label: "Note", key: "resetnote" },
    { label: "Note Date", key: "note_date" },
  ];

  constructor(props: StoreResetFeedbackProps) {
    super(props);
    this.state = { tableData: [] };
  }

  public getFeedbackToExport() {
    const { feedback = [] } = this.props;

    return feedback
      .filter((fb) => fb.project_id === this.state.selectedProjectId)
      .map((fb) => ({
        store_number: fb.store_number,
        implementation: fb.implementation,
        resetnote: fb.resetnote,
        note_date: moment(fb.note_date).format("MM/DD/YYYY hh:mm A"),
      }));
  }

  public updateTableData(projectId: number) {
    this.props.fetchProjectResetNotes(projectId).then(() => {
      const { feedback = [] } = this.props;

      this.setState({
        selectedProjectId: projectId,
        selectedStoreNumber: null,
        tableData: feedback.filter((fb) => fb.project_id === projectId),
      });
    });
  }

  public render() {
    const { feedback = [] } = this.props;

    const storeNumDropdownOptions = [
      { label: "All Stores", value: "-1", "data-qa": "all-notes" },
    ].concat(
      uniqBy(
        feedback
          .filter((fb) => fb.project_id === this.state.selectedProjectId)
          .map((fb) => ({
            label: fb.store_number,
            value: fb.store_number,
            "data-qa": fb.store_number,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        "value"
      )
    );

    const data = this.getFeedbackToExport();
    return (
      <Page>
        <header className="section-header">
          <h1 className="layout-header" data-qa="my-feedback-header">
            Reset Execution Feedback
          </h1>
          <ProjectFilter
            dataQa={"store-reset-feedback-project-dropdown"}
            onChange={({ id, name, type }) => {
              this.updateTableData(id);
              this.setState({
                selectedProjectName: name,
                selectedProjectType: type,
              });
            }}
            isSearchable
          />
          <div className="section-header-button-group">
            <CSVDownload
              className="my-feedback-export-link"
              data={data}
              headers={this.CSV_HEADERS}
              filename={buildExportFilename("csv", "store-reset-feedback")}
              onClick={() => {
                const { selectedProjectName, selectedProjectType } = this.state;

                this.props.createAnalyticsEvent({
                  event: EventTypes.REPORT_EXPORT,
                  eventType: "Action",
                  projectName: selectedProjectName as string,
                  projectType: selectedProjectType as ProjectType,
                  pageName: "Reset Feedback",
                  reportRowCount: data.length,
                });
              }}
            >
              <button
                data-qa="export-feedback-btn"
                className={cx("export-feedback-button import-export-button", {
                  disabled: allFieldsEmpty(this.state.tableData),
                })}
                disabled={allFieldsEmpty(this.state.tableData)}
              >
                Export{" "}
                <Icon
                  src={allFieldsEmpty(this.state.tableData) ? downloadInactiveSvg : downloadSvg}
                />
              </button>
            </CSVDownload>
          </div>
        </header>
        <CardList>
          <Card>
            <div className="my-feedback-filter-container">
              <p className="header">Filters</p>
              <div className="my-feedback-store-dropdown-container">
                <p>Store Number</p>
                <Dropdown
                  dataQa="store-reset-feedback-store-dropdown"
                  value={
                    storeNumDropdownOptions.find(
                      (opt) => opt.value === this.state.selectedStoreNumber
                    ) || storeNumDropdownOptions[0]
                  }
                  options={storeNumDropdownOptions}
                  placeholder="Select"
                  isDisabled={!this.state.selectedProjectId}
                  containerStyles={{
                    marginLeft: "unset",
                    fontWeight: "normal",
                    marginBottom: "10px",
                  }}
                  onChange={(option) => {
                    this.setState({
                      selectedStoreNumber: option.value,
                      tableData:
                        option.value === "-1"
                          ? feedback.filter(
                              (resetnote) => resetnote.project_id === this.state.selectedProjectId
                            )
                          : feedback.filter(
                              (resetnote) =>
                                resetnote.store_number === option.value &&
                                resetnote.project_id === this.state.selectedProjectId
                            ),
                    });
                  }}
                  isSearchable
                />
              </div>
            </div>
          </Card>
          <Card>
            <Table
              data={this.state.tableData}
              style={{ marginTop: "unset" }}
              emptyState={
                this.state.selectedProjectId && this.state.selectedProjectId !== -1
                  ? "No store reset feedback found for selected project"
                  : "Select a project to view store reset feedback"
              }
              columns={[
                {
                  field: "store_number",
                  header: "Store No.",
                  dataQa: "storeNumber",
                },
                {
                  field: "implementation",
                  header: "Implementation",
                  dataQa: "implementation",
                },

                {
                  field: "resetnote",
                  header: "Note",
                  dataQa: "note",
                },
                {
                  field: "note_date",
                  header: "Note Date",
                  formatter: (value) => moment(value).format("M/D/YYYY"),
                  dataQa: "note-date",
                },
              ]}
            />
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    feedback: state.resetnotes.content,
    auth_user: state.auth_user.content,
  }),
  { fetchProjectResetNotes, createAnalyticsEvent }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(StoreResetFeedback);
