// src/state/config.ts

import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Action Types
export const FETCH_CONFIG_REQUEST = "FETCH_CONFIG_REQUEST";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAILED = "FETCH_CONFIG_FAILED";

// Thunk Action Creator
export const fetchConfig: ThunkActionCreator<Promise<void>> = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CONFIG_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/react-config`, getHeaders())
    .then((response) => {
      dispatch({ type: FETCH_CONFIG_SUCCESS, payload: response.data });
    })
    .catch((error: Error) => {
      dispatch({ type: FETCH_CONFIG_FAILED });
    });
};

export interface ConfigState {
  loading: boolean;
  error: boolean;
  data?: {
    reactAppGoogleApiKey: string;
  };
}

const actions = {
  [FETCH_CONFIG_REQUEST]: (state: ConfigState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_CONFIG_SUCCESS]: (state: ConfigState, action: AnyAction): ConfigState => ({
    ...state,
    loading: false,
    data: action.payload,
  }),
  [FETCH_CONFIG_FAILED]: (state: ConfigState) => ({
    ...state,
    loading: false,
    error: true,
  }),
};

// Initial State
const initialState: ConfigState = {
  loading: false,
  error: false,
  data: {
    reactAppGoogleApiKey: ""
  },
};

// Reducer
export default handleActions<ConfigState, AnyAction>(actions, initialState);
