import React, { InputHTMLAttributes } from "react";

interface IndeterminateCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  indeterminate?: boolean;
}

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({ indeterminate = false, ...rest }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null!);
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if ("current" in resolvedRef && resolvedRef.current !== null) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export default IndeterminateCheckbox;
