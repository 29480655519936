import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ProjectType } from "interfaces/project";
import { CreateResetNote, FetchResetNotesOptions, ResetNote } from "interfaces/reset-notes";
import { ThunkActionCreator } from "interfaces/thunk";
import { AnalyticsProjectFields, EventTypes } from "./analytics";

export const FETCH_RESETNOTES_REQUEST = "FETCH_RESETNOTES_REQUEST";
export const FETCH_RESETNOTES = "FETCH_RESETNOTES";
export const FETCH_RESETNOTES_FAILED = "FETCH_RESETNOTES_FAILED";

export const CREATE_RESETNOTE_REQUEST = "CREATE_RESETNOTE_REQUEST";
export const CREATE_RESETNOTE = "CREATE_RESETNOTE";
export const CREATE_RESETNOTE_FAILED = "CREATE_RESETNOTE_FAILED";

// Thunk Action Creator
export const fetchProjectResetNotes: ThunkActionCreator<Promise<void>> =
  (projectId: number) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_RESETNOTES_REQUEST });

    return axios
      .get(`${Config.API_SERVICE_URL}/api/projects/${projectId}/resetnotes`, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_RESETNOTES, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: FETCH_RESETNOTES_FAILED });
      });
  };

export const fetchResetnotes: ThunkActionCreator<Promise<void>> =
  (options: FetchResetNotesOptions) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_RESETNOTES_REQUEST });

    return axios
      .get(
        `${Config.API_SERVICE_URL}/api/projects/${options.projectId}/stores/${options.storeId}/resetnotes`,
        getHeaders()
      )
      .then((response) => {
        dispatch({ type: FETCH_RESETNOTES, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: FETCH_RESETNOTES_FAILED });
      });
  };

export const createResetNote: ThunkActionCreator<Promise<void>> =
  (
    resetNote: CreateResetNote,
    { name, type, storeName }: AnalyticsProjectFields & { storeName: string }
  ) =>
  (dispatch: Dispatch) => {
    dispatch({ type: CREATE_RESETNOTE_REQUEST });

    return axios
      .post(
        `${Config.API_SERVICE_URL}/api/projects/${resetNote.projectId}/stores/${resetNote.storeId}/resetnotes`,
        resetNote,
        getHeaders()
      )
      .then((response) => {
        dispatch({
          type: CREATE_RESETNOTE,
          payload: response.data,
          meta: {
            analytics: {
              event: EventTypes.STORE_DETAIL_RESET_NOTE_ADD,
              eventType: "Action",
              projectName: name,
              projectType: type as ProjectType,
              storeName: storeName,
              storeDetailResetImplementation:
                resetNote.implementation === "Yes"
                  ? "Yes"
                  : resetNote.implementation === "No"
                  ? "No"
                  : "N/A",
              noteCharacterCount: resetNote.resetnote ? resetNote.resetnote.length : 0,
            },
          },
        });
      })
      .catch((_) => {
        dispatch({ type: CREATE_RESETNOTE_FAILED });
      });
  };

export interface ResetNotesState {
  loading: boolean;
  error: boolean;
  content?: ResetNote[];
}

const actions = {
  [FETCH_RESETNOTES_REQUEST]: (state: ResetNotesState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_RESETNOTES]: (state: ResetNotesState, action: AnyAction): ResetNotesState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_RESETNOTES_FAILED]: (state: ResetNotesState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [CREATE_RESETNOTE_REQUEST]: (state: ResetNotesState) => ({
    ...state,
    loading: true,
  }),
  [CREATE_RESETNOTE]: (state: ResetNotesState, action: AnyAction) => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [CREATE_RESETNOTE_FAILED]: (state: ResetNotesState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Initial State
const initialState: ResetNotesState = {
  loading: false,
  error: false,
  content: [],
};

// Reducer
export default handleActions<ResetNotesState, AnyAction>(actions, initialState);
