import Button from "components/Button";
import { Card, CardList } from "components/Card";
import Page from "components/Page";
import Config from "config";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import FileUpload from "containers/FileUpload";
import ManageDataPreview from "containers/ManageDataPreview";
import MappingPreview from "containers/MappingPreview";
import ProjectInfo from "containers/ProjectInfo";
import UserPreview from "containers/UserPreview";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { State } from "state";
import { createAnalyticsEvent, EventTypes, Status } from "state/analytics";
import { updateNotifications } from "state/notifications";
import { deleteProject, fetchProject, updateProject } from "state/project";


interface CreateProjectOwnProps extends RouteComponentProps<{ id: string }> {}
type CreateProjectProps = CreateProjectOwnProps & PropsFromRedux;

class CreateProject extends React.Component<CreateProjectProps> {
  public componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.props.fetchProject(id);
  }

  public handleAfterUpload = (
    type: "Project File" | "New Stores" | "New Users" | "User to Store Mapping"
  ) => {
    const { project } = this.props;

    if (project) {
      const hasError =
        type === "Project File"
          ? this.props["Project File Error"]
          : type === "New Users"
          ? this.props["New Users Error"]
          : type === "New Stores"
          ? this.props["New Stores Error"]
          : this.props["User to Store Mapping Error"];

      this.props.createAnalyticsEvent({
        event: EventTypes.CREATE_NEW_PROJECT_UPLOAD,
        eventType: "Action",
        ...(project.name &&
          project.chain &&
          project.chain.name && {
            projectName: `${project.chain.name} - ${project.name}`,
          }),
        projectType: project.project_type,
        fileUploadType: type,
        fileUploadStatus: hasError ? Status.FAILURE : Status.SUCCESS,
      });
    }
  };

  public handleClickAnalytics = (action: "Edit" | "Save" | "Delete" | "Publish" | "Unpublish") => {
    const { project } = this.props;

    if (project) {
      this.props.createAnalyticsEvent({
        event: EventTypes.CREATE_NEW_PROJECT,
        eventType: "Action",
        ...(project.name &&
          project.chain &&
          project.chain.name && {
            projectName: `${project.chain.name} - ${project.name}`,
          }),
        projectType: project.project_type,
        createNewProjectAction: action,
      });
    }
  };

  public handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;

    this.props.deleteProject(id).then(() => history.push("/projects"));
    this.handleClickAnalytics("Delete");
  };

  public handlePublishClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      match: {
        params: { id },
      },
      project,
    } = this.props;

    if (project) {
      this.props.updateProject(id, { published: !project.published }).then(() => {
        const { project: updatedProject } = this.props;
        if (updatedProject && updatedProject.published) {
          this.props.updateNotifications(id);
        }

        if (updatedProject && updatedProject.published) {
          this.handleClickAnalytics("Publish");
        } else {
          this.handleClickAnalytics("Unpublish");
        }
      });
    }
  };

  public render() {
    const {
      project,
      match: {
        params: { id: projectId },
      },
    } = this.props;

    if (!project) {
      return null;
    }

    return (
      <Page className="create-project-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: "/projects",
                crumb: "VIEW ALL PROJECTS",
                dataQa: "view-all-projects-link",
              },
            ],
          }}
        />
        <div className="header-area">
          <h1 className="header">Create New Project</h1>
          <div className="create-project-buttons">
            <Button
              secondary={true}
              onClick={this.handleDeleteClick}
              data-qa="delete-button"
              className="delete-button"
            >
              <p>DELETE</p>
            </Button>
            <Button
              onClick={this.handlePublishClick}
              data-qa="publish-unpublish-button"
              className="publish-button"
            >
              <p>{project.published ? "UNPUBLISH" : "PUBLISH"}</p>
            </Button>
            <Button
              secondary={true}
              onClick={() => {
                const {history} = this.props;
                if (this.props.project?.id) {
                  history.push("/projects/" + this.props.project.id);
                }
              }}
              data-qa="button"
              className="button"
            >
              <p>VIEW</p>
            </Button>
          </div>
        </div>
        <div className="create-project-info">
          <ProjectInfo onEditOrSaveClick={this.handleClickAnalytics} />
        </div>
        <ul className="file-upload-list">
          <li className="file-upload-item">
            <FileUpload
              prefix={"STORE2POG"}
              url={`${Config.API_SERVICE_URL}/api/stores-pogs`}
              label={"Upload Project File"}
              dataQa={"store-pog-mapping-file-upload"}
              projectId={projectId}
              afterUploadFunction={() => {
                // Used to update the updated_at field of the projects database table
                this.props.updateProject(projectId, {});

                // handle analytics
                this.handleAfterUpload("Project File");
              }}
            />
          </li>
          <li className="file-upload-item">
            <FileUpload
              prefix={"STORES"}
              url={`${Config.API_SERVICE_URL}/api/stores`}
              label={"Upload New Stores"}
              dataQa={"stores-file-upload"}
              afterUploadFunction={() => {
                // Used to update the updated_at field of the projects database table
                this.props.updateProject(projectId, {});

                // handle analytics
                this.handleAfterUpload("New Stores");
              }}
            />
          </li>
          <li className="file-upload-item">
            <FileUpload
              prefix={"USERS"}
              url={`${Config.API_SERVICE_URL}/api/users`}
              label={"Upload New Users"}
              dataQa={"users-file-upload"}
              afterUploadFunction={() => {
                // Used to update the updated_at field of the projects database table
                this.props.updateProject(projectId, {});

                // handle analytics
                this.handleAfterUpload("New Users");
              }}
            />
          </li>
          <li className="file-upload-item">
            <FileUpload
              prefix={"USER2STORE"}
              url={`${Config.API_SERVICE_URL}/api/users-stores`}
              label={"User to Store Mapping"}
              dataQa={"user-store-mapping-file-upload"}
              afterUploadFunction={() => {
                // Used to update the updated_at field of the projects database table
                this.props.updateProject(projectId, {});

                // handle analytics
                this.handleAfterUpload("User to Store Mapping");
              }}
            />
          </li>
        </ul>
        <CardList className="data-preview">
          <Card>
            <ManageDataPreview prefix={"STORE2POG"} />
          </Card>
          <Card>
            <ManageDataPreview prefix={"STORES"} />
          </Card>
          <Card>
            <ManageDataPreview prefix={"USERS"} />
          </Card>
          <Card>
            <ManageDataPreview
              prefix={"USER2STORE"}
              label={"User-Store Mappings and User-Chain Mappings"}
            />
          </Card>
        </CardList>

        <CardList>
          <Card>
            <Card.Header>Stores In Project</Card.Header>
            <MappingPreview
              prefix={"STORE2POG"}
              errorPrefixes={["STORE2POG", "STORES"]}
              projectId={projectId}
              showError={false}
            />
          </Card>
          <Card>
            <Card.Header>Users In Project</Card.Header>
            <UserPreview
              prefix={"STORE2POG"}
              errorPrefixes={["USERS", "USER2STORE"]}
              projectId={projectId}
              showError={false}
            />
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connector = connect(
  (state: State) => ({
    project: state.project.content,
    "Project File Error": state.STORE2POG_file_upload.hasError || false,
    "New Users Error": state.USERS_file_upload.hasError || false,
    "New Stores Error": state.STORES_file_upload.hasError || false,
    "User to Store Mapping Error": state.USER2STORE_file_upload.hasError || false,
  }),
  {
    fetchProject,
    deleteProject,
    updateProject,
    updateNotifications,
    createAnalyticsEvent,
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(CreateProject));
