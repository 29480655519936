import cx from "classnames";
import React from "react";

const Page: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <section className={cx("page", className)} {...props}>
    {children}
  </section>
);

export default Page;
