import { List, ListItem, TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import debounce from "lodash/debounce";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { State } from "../state";
import { getSearch, resetSearch } from "../state/search";

interface SearchBarState {
  showResults: boolean;
  searchFor: string;
  textFieldValue: string;
}
interface QueryParams {
  query: string;
  serachFor: string;
}

class SearchBar extends React.Component<PropsFromRedux & RouteComponentProps, SearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showResults: false,
      searchFor: "projects",
      textFieldValue: "",
    };
  }

  public handleSearchFor = (value: string) => {
    this.setState({
      searchFor: value,
    });
  };

  public handleSearch = (queryParams: QueryParams) => {
    this.props.getSearch(queryParams);
  };

  public handleDebounceSearch = debounce((queryParams: QueryParams) => {
    this.handleSearch(queryParams);
  }, 500);

  public handleChange = (queryParams: QueryParams) => {
    this.handleDebounceSearch(queryParams);
  };

  public handleLostFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.relatedTarget) {
      this.setState({
        showResults: false,
      });
    }
  };

  public handleGainFocus = () => {
    this.setState({
      showResults: true,
    });
  };

  public render() {
    return (
      <div
        className="search"
        style={{
          zIndex: 100,
          width: "300px",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        onBlur={(e) => {
          this.handleLostFocus(e);
        }}
      >
        <div
          className="searchWithCategory"
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "290px",
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            margin={"dense"}
            value={this.state.textFieldValue}
            fullWidth
            placeholder={`Search for ${
              this.state.searchFor.charAt(0).toUpperCase() +
              this.state.searchFor.slice(1).toLowerCase()
            }`}
            onChange={(e) => {
              this.setState({ textFieldValue: e.target.value });
              this.handleChange({ query: e.target.value, serachFor: this.state.searchFor });
            }}
            onFocus={this.handleGainFocus}
            InputProps={{
              startAdornment: (
                <Select
                  variant="outlined"
                  name="searchFor"
                  value={this.state.searchFor}
                  onChange={(e) => {
                    this.setState({ textFieldValue: "", showResults: false });
                    this.handleSearchFor(e.target.value as string);
                  }}
                  displayEmpty
                  autoWidth
                  style={{ height: "100%" }}
                >
                  <MenuItem value="projects" style={{ fontSize: "x-small" }}>{`Projects`}</MenuItem>
                  <MenuItem value="stores" style={{ fontSize: "x-small" }}>{`Stores`}</MenuItem>
                </Select>
              ),
            }}
          />
        </div>
        {this.props.searchBarState.content?.length === 0 ||
        !this.state.showResults ||
        !this.state.textFieldValue ? (
          <></>
        ) : (
          <List
            style={{
              background: "white",
              border: "1px solid black",
              borderRadius: "0px",
              position: "absolute",
              width: "100%",
              top: 50,
              padding: "0px",
              maxHeight: "50vh",
              overflowY: "auto",
            }}
          >
            {this.props.searchBarState.content.map((item) => {
              if (item.projectName === "noMatch") {
                return (
                  <ListItem style={{ padding: "2px 5px 2px 5px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      key={"noMatch"}
                    >
                      <text
                        style={{
                          opacity: "50%",
                          fontWeight: "lighter",
                          fontSize: "small",
                        }}
                      >
                        {`No Matches Found`}
                      </text>
                    </div>
                  </ListItem>
                );
              } else {
                if (item.source === "PROJECT") {
                  return (
                    <ListItem
                      style={{ padding: "6px 8px 6px 16px" }}
                      button
                      onClick={() => this.props.history.push("/projects/" + item.projectId)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        key={item.projectId + "-" + item.chainId + "-" + item.storeId}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <text style={{ fontSize: "small" }}>{`${item.projectName}`}</text>
                        </div>
                        <text
                          style={{
                            opacity: "50%",
                            fontWeight: "bolder",
                            fontSize: "small",
                          }}
                        >
                          {`${item.chainName}`}
                        </text>
                      </div>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      style={{ padding: "6px 8px 6px 16px" }}
                      button
                      onClick={() =>
                        this.props.history.push(
                          "/projects/" + item.projectId + "/stores/" + item.storeId
                        )
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        key={item.projectId + "-" + item.chainId + "-" + item.storeId}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <text style={{ fontSize: "small" }}>{`${item.storeName}`}</text>
                        </div>
                        <text style={{ opacity: "50%", fontWeight: "bolder", fontSize: "small" }}>
                          {`${item.chainName}`}
                        </text>
                      </div>
                    </ListItem>
                  );
                }
              }
            })}
          </List>
        )}
      </div>
    );
  }
}

const connector = connect(
  (state: State) => ({
    searchBarState: state.searchBar,
  }),
  {
    getSearch,
    resetSearch,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(SearchBar));
