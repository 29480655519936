import cx from "classnames";
import React from "react";

import Collapsable from "components/Collapsable";

const CardHeader: React.FunctionComponent<React.HTMLAttributes<{}>> = ({ children, className }) => (
  <div className={cx(className, "card-header")}>{children}</div>
);

const CardFooter: React.FunctionComponent<React.HTMLAttributes<{}>> = ({ children, className }) => (
  <div className={cx(className, "card-footer")}>{children}</div>
);

const CardBody: React.FunctionComponent<React.HTMLAttributes<{}>> = ({ children, className }) => (
  <div className={cx(className, "card-body")}>{children}</div>
);

class Card extends React.Component<React.HTMLAttributes<{}>> {
  public static Header = CardHeader;
  public static Footer = CardFooter;
  public static Body = CardBody;

  public render() {
    const { children, className } = this.props;

    return <div className={cx("card", className)}>{children}</div>;
  }
}

// tslint:disable-next-line:max-classes-per-file
class CollapsableCard extends React.Component<{ defaultCollapsed?: boolean }> {
  public static Header = CardHeader;
  public static Body = CardBody;

  public render() {
    const { children, ...rest } = this.props;
    const [header, body] = React.Children.toArray(children);

    return (
      <Card>
        <Collapsable {...rest} header={header as JSX.Element}>
          {body}
        </Collapsable>
      </Card>
    );
  }
}

const CardList: React.FunctionComponent<React.HTMLAttributes<{}>> = ({ className, children }) => {
  // toArray(children) filters our null elements
  return (
    <ul className={cx("card-list", className)}>
      {React.Children.toArray(children).map((c: any) => {
        return (
          <li key={`card-list-item-${c.key}`} className="card-list-item">
            {c}
          </li>
        );
      })}
    </ul>
  );
};

export { Card, CardList, CollapsableCard };
