import deleteTrashCan from "assets/img/delete-trash-can.svg";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import CSVDownload3 from "components/CSVDownload3";
import Icon from "components/Icon";
import Table from "components/Table";
import Config from "config";
import { RouteProps } from "containers/App";
import ChainFilter from "containers/ChainFilter";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import FileUpload from "containers/FileUpload";
import ManageDataPreview from "containers/ManageDataPreview";
import { Store } from "interfaces/store";
import React from "react";
import Popover from "react-popover";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { deleteStoreById, fetchStores } from "state/stores";
import { buildExportFilename } from "utils/helpers";

type ManageStoresProps = PropsFromRedux & RouteProps;

interface ManageStoresState {
  tableData: Store[];
  chainId: number;
  deletePopoverOpenId: number | null;
}

class ManageStores extends React.Component<ManageStoresProps, ManageStoresState> {
  public state = {
    tableData: [],
    chainId: -1,
    chainName: "",
    deletePopoverOpenId: null,
  };

  protected CSV_HEADERS = [
    { label: "Chain Name", key: "chain_name" },
    { label: "Retail Store Number", key: "retail_store_num" },
    { label: "Store Name", key: "name" },
    { label: "Address Line 1", key: "address_line1" },
    { label: "Address Line 2", key: "address_line2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Country", key: "country" },
    { label: "Zip Code", key: "zip" },
    { label: "Status", key: "status" },
    { label: "Cluster Store Group", key: "cluster_store_group" },
    { label: "Ad Zone", key: "ad_zone" },
    { label: "ABInBev Wholesaler", key: "abinbev_wholesaler" },
    { label: "Miller Wholesaler", key: "miller_wholesaler" },
    { label: "Coors Wholesaler", key: "coors_wholesaler" },
    { label: "Third Party Wholesaler", key: "third_party_wholesaler" },
    { label: "Catalyst ID", key: "cid" },
  ];

  protected MAX_DELETE_COLUMN_WIDTH = 100;

  protected POPOVER_TRANSITION_MS = 100;

  public updateTableData(id: number = this.state.chainId) {
    const { stores = [] } = this.props;
    let tableData = stores.filter((mapping) => mapping.chain_id === id);

    this.setState({
      chainId: id,
      tableData: tableData,
    });
  }

  public componentDidMount() {
    this.props.fetchStores();
  }

  public toggleDeletePopoverOpenId(storeId: number) {
    this.setState({
      deletePopoverOpenId: this.state.deletePopoverOpenId ? null : storeId,
    });
  }

  public handleDelete(storeId: number) {
    this.toggleDeletePopoverOpenId(storeId);
    this.props.deleteStore(storeId).then(() => {
      this.setState({
        tableData: this.state.tableData.filter(
          (row: Store) => row.id.toString() !== storeId.toString()
        ),
      });
    });
  }

  public render() {
    const { tableData } = this.state;

    return (
      <section className="manage-data-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: "/manage-all-data",
                crumb: "VIEW ALL DATA",
                dataQa: "view-all-data-link",
              },
            ],
          }}
        />
        <div className="manage-data-body">
          <h1 className="header">Manage Store Data</h1>
          <ul className="file-upload-list">
            <li className="file-upload-item">
              <FileUpload
                prefix={"STORES"}
                url={`${Config.API_SERVICE_URL}/api/stores`}
                label={"Upload New Stores"}
                dataQa={"manage-stores-file-upload"}
                afterUploadFunction={() =>
                  this.props.fetchStores().then(() => {
                    this.updateTableData();
                  })
                }
              />
            </li>
            <li>
              <CSVDownload
                data={this.props.stores}
                headers={this.CSV_HEADERS}
                filename={buildExportFilename("csv", "stores")}
              >
                <Button data-qa="export-all-data-btn">EXPORT ALL DATA</Button>
              </CSVDownload>

            </li>
          </ul>
          <CardList>
            <Card>
              <ManageDataPreview prefix={"STORES"} />
            </Card>
          </CardList>
          <div className="manage-data-table-controls" data-qa="chain-dropdown">
            <ChainFilter
              onChange={({ id }) => {
                this.updateTableData(id);
              }}
              isSearchable
            />
            <CSVDownload3
              data={this.state.tableData}
              headers={this.CSV_HEADERS}
              filename={buildExportFilename("csv", "stores")}
              disabled={this.state.tableData.length === 0}
            >
            </CSVDownload3>
          </div>
          <CardList>
            <Card>
              <Table
                data={this.state.tableData}
                columns={[
                  {
                    field: "retail_store_num",
                    header: "Store No.",
                    dataQa: "store_number",
                  },
                  {
                    field: "name",
                    header: "Store Name",
                    dataQa: "store_name",
                  },
                  {
                    field: "store_address",
                    header: "Address",
                    dataQa: "store_address",
                  },
                  {
                    field: "id",
                    header: "",
                    sortable: false,
                    maxWidth: this.MAX_DELETE_COLUMN_WIDTH,
                    formatter: (value: string) => {
                      const rowId = parseInt(value, 10);
                      const storeInfo = this.state.tableData.find(
                        (store: Store) => store.id === rowId
                      );
                      const deleteMessage = storeInfo
                        ? `Delete ${(storeInfo as Store).name}?`
                        : `Delete this store?`;
                      return (
                        <Popover
                          body={
                            <div
                              style={{
                                display: "flex-inline",
                              }}
                            >
                              <p>{deleteMessage}</p>
                              <Button
                                data-qa="confirm-delete-button"
                                warning={true}
                                onClick={() => this.handleDelete(rowId)}
                              >
                                DELETE
                              </Button>
                              <Button
                                data-qa="cancel-button"
                                secondary={true}
                                onClick={() => this.toggleDeletePopoverOpenId(rowId)}
                              >
                                CANCEL
                              </Button>
                            </div>
                          }
                          isOpen={this.state.deletePopoverOpenId === rowId}
                          preferPlace="left"
                          enterExitTransitionDurationMs={this.POPOVER_TRANSITION_MS}
                        >
                          <button
                            style={{ outline: "none" }}
                            className="delete-comment-button"
                            data-qa="delete-store-button"
                            onClick={() => this.toggleDeletePopoverOpenId(rowId)}
                          >
                            <Icon medium={true} src={deleteTrashCan} />
                          </button>
                        </Popover>
                      );
                    },
                  },
                ]}
                emptyState="Select a chain to view store data"
              />
            </Card>
          </CardList>
        </div>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    stores: state.stores.content?.data,
  }),
  { fetchStores, deleteStore: deleteStoreById }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageStores);
