import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { getItem, removeItem } from "helpers/storage";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ILoginService } from "interfaces/login-service";
import { ThunkActionCreator } from "interfaces/thunk";
import LoginService from "services/LoginService";
import { EventTypes, Status } from "state/analytics";
import {hydrateUser, UserAuthenticationError} from "state/auth_user";
import { hydratePermissions } from "state/permissions";
import {datadogRum} from "@datadog/browser-rum";
import {SeverityLevel, ToastService} from "../services/toastService";

export const STORAGE_KEY = "msal.idtoken";
export const FORGOT_PASSWORD_STORAGE_KEY = "msal.login.error";

// Action Types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_CANCELLED = "LOGIN_CANCELLED";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const PASSWORD_RESET_FORGOT_REQUEST = "PASSWORD_RESET_FORGOT_REQUEST";
export const PASSWORD_RESET_FORGOT_SUCCESS = "PASSWORD_RESET_FORGOT_SUCCESS";
export const PASSWORD_RESET_FORGOT_FAILED = "PASSWORD_RESET_FORGOT_FAILED";
export const PASSWORD_RESET_FORGOT_CANCELED = "PASSWORD_RESET_FORGOT_CANCELED";

// Factory method to provide the singelton LoginService object
class LoginFactory {
  public static getLoginService = () => {
    if (!LoginFactory.loginService) {
      LoginFactory.loginService = new LoginService();
    }
    return LoginFactory.loginService;
  };

  private static loginService: ILoginService;
}

const clearAuthCache = () => {
  removeItem("user");
  removeItem("permissions");
  removeItem("nearbyStoresData");

  // there is no API method in MSAL to clear out the tokens that are used without calling logout()
  // and logout() causes a redirect back to the /signin page which loses all redux state
  // clear these items out manually
  Object.keys(window.localStorage || []).forEach((key) => {
    if (/^msal/.test(key)) {
      removeItem(key);
    }
  });
};

// Thunk Action Creator
const hydrateAuth: ThunkActionCreator<Promise<any>> =
  (loginService: ILoginService = LoginFactory.getLoginService()) =>
  (dispatch: Dispatch) => {
    const token = getItem(STORAGE_KEY);
    const forgotPasswordError = getItem(FORGOT_PASSWORD_STORAGE_KEY);

    // If this is a redirect and not a popup, we have to catch it here.
    if (
      Config.ENV === "test" &&
      forgotPasswordError &&
      forgotPasswordError.indexOf("AADB2C90118") !== -1
    ) {
      removeItem(FORGOT_PASSWORD_STORAGE_KEY);
      dispatch(resetPassword());
    } else if (token) {
      // ensure that token is not expired by attempting to refresh it
      // if it is expired, a new id token will be requested
      // if it is _not_ expired, the cached token (from localStorage) will be returned
      return loginService
        .acquireTokenSilent()
        .then((idToken: string) => {
          dispatch({ type: LOGIN, payload: idToken });
        })
        .catch(() => {
          // Could not refresh the token silently, ask the user to log in again
          return loginService
            .acquireToken()
            .then((idToken: string) => {
              dispatch({ type: LOGIN, payload: idToken });
            })
            .catch((error: Error) => {
              // tslint:disable-next-line:no-console
              console.error(error);
            });
        });
    }

    return Promise.resolve();
  };

const login: ThunkActionCreator<void> =
  (loginService: ILoginService = LoginFactory.getLoginService()) => {
    return (dispatch: Dispatch) => {
      return new Promise<void>((resolve, reject) => {
        dispatch({
          type: LOGIN_REQUEST,
          meta: {
            analytics: {
              event: EventTypes.SIGN_IN_START,
              eventType: "Action",
            },
          },
        });
        resolve();
      })
        .then(() => loginService.login())
        .catch((e: Error | string) => {
          if (
            typeof e === "string" &&
            (e.indexOf("user has canceled") !== -1 || e.indexOf("User closed") !== -1)
          ) {
            dispatch({
              type: LOGIN_CANCELLED,
              meta: {
                analytics: {
                  event: EventTypes.SIGN_IN_END,
                  eventType: "Action",
                  "loginStatus": Status.CANCEL,
                },
              },
            });
          } else if (typeof e === "string" && e.indexOf("AADB2C90118") !== -1) {
            removeItem(FORGOT_PASSWORD_STORAGE_KEY);
            dispatch(resetPassword());
          } else {
            clearAuthCache();
            dispatch({
              type: LOGIN_FAILED,
              payload: e instanceof Error ? e.message : e,
              meta: {
                analytics: {
                  event: EventTypes.SIGN_IN_END,
                  eventType: "Action",
                  "loginStatus": Status.FAILURE,
                },
              },
            });
          }
        });
    }
  };

const postLogin: ThunkActionCreator<void> = () => {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const token = getItem(STORAGE_KEY);
    await dispatch(isAuthorized())
      .then(() => dispatch(hydrateUser()))
      .then(() => dispatch(hydratePermissions()))
      .then(() => {
        const {auth_user} = getState();
        if (auth_user && auth_user.content) {
          const user = auth_user.content;
          dispatch({
            type: LOGIN,
            payload: token,
            meta: {
              analytics: {
                event: EventTypes.SIGN_IN_END,
                eventType: "Action",
                distinctId: user.email,
                email: user.email,
                name:
                  !user.first_name && !user.last_name
                    ? undefined
                    : `${user.first_name || "N/A"} ${user.last_name || "N/A"}`,
                created: new Date(),
                last_login: new Date(),
                brewBox360Role: user.role,
                company: user.company ? user.company : undefined,
                employer: user.employer ? user.employer : undefined,
                loginStatus: Status.SUCCESS,
                brewBox360UserId: user.id.toString(),
              },
            },
          });
        }
      }).catch((error: any) => {
        console.error(error)
        ToastService.getInstance().publishAuthErrorToast(new UserAuthenticationError(), SeverityLevel.ERROR);
    })
  }
};

const isAuthorized: ThunkActionCreator<any> = () =>  {
  return (dispatch: Dispatch) => {
    return axios.get(`${Config.API_SERVICE_URL}/api/login`, getHeaders());
  };
}

const logout: ThunkActionCreator<Promise<any>> =
  (loginService: ILoginService = LoginFactory.getLoginService()) => {
    return (dispatch: Dispatch) => {
      return new Promise<void>((resolve, reject) => {
        dispatch({
          type: LOGOUT_REQUEST,
          meta: {
            analytics: {event: EventTypes.SIGN_OUT, eventType: "Action"},
          },
        });
        resolve();
      }).then(() => {
        datadogRum.clearUser();
        datadogRum.addAction(EventTypes.SIGN_OUT, {eventType: "Action"});
      })
        .then(() => loginService.logout())
        .then(() => {
          dispatch({type: LOGOUT});
          clearAuthCache();
        })
        .catch((e: Error) => dispatch({type: LOGOUT_FAILED}));

    }
  };

const resetPassword: ThunkActionCreator =
  (loginService: ILoginService = LoginFactory.getLoginService()) => {
    return (dispatch: Dispatch) => {
      dispatch({
        type: PASSWORD_RESET_FORGOT_REQUEST,
      });

      loginService
        .resetPassword()
        .then(() => {
          dispatch({
            type: PASSWORD_RESET_FORGOT_SUCCESS,
            meta: {
              analytics: {
                event: EventTypes.RESET_PASSWORD,
                eventType: "Action",
                "resetPasswordStatus": Status.SUCCESS,
              },
            },
          });
          clearAuthCache();
        })
        .catch((e: Error | string) => {
          if (
            typeof e === "string" &&
            (e.indexOf("user has cancelled") !== -1 || e.indexOf("User closed") !== -1)
          ) {
            dispatch({
              type: PASSWORD_RESET_FORGOT_CANCELED,
              meta: {
                analytics: {
                  event: EventTypes.RESET_PASSWORD,
                  eventType: "Action",
                  "resetPasswordStatus": Status.CANCEL,
                },
              },
            });
          } else {
            clearAuthCache();
            dispatch({
              type: PASSWORD_RESET_FORGOT_FAILED,
              payload: e instanceof Error && e.message,
              meta: {
                analytics: {
                  event: EventTypes.RESET_PASSWORD,
                  eventType: "Action",
                  "resetPasswordStatus": Status.FAILURE,
                },
              },
            });
          }
        });
    }
  };

export { login, logout, hydrateAuth, resetPassword, isAuthorized, postLogin };

// Actions
const actions = {
  [LOGIN_REQUEST]: (state: AuthState): AuthState => ({
    ...state,
    hasError: false,
    loading: true,
  }),
  [LOGIN]: (state: AuthState, action: AnyAction): AuthState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [LOGIN_FAILED]: (state: AuthState, action: AnyAction): AuthState => ({
    ...state,
    loading: false,
    hasError: true,
    error: action.payload,
    content: undefined,
  }),
  [LOGIN_CANCELLED]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    hasError: false,
    content: undefined,
  }),
  [LOGOUT_REQUEST]: (state: AuthState): AuthState => ({
    ...state,
    hasError: false,
    loading: true,
  }),
  [LOGOUT]: (state: AuthState): AuthState => ({
    ...state,
    ...initialState,
  }),
  [LOGOUT_FAILED]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    hasError: true,
    content: undefined,
  }),
  [PASSWORD_RESET_FORGOT_REQUEST]: (state: AuthState): AuthState => ({
    ...state,
    hasError: false,
    loading: true,
  }),
  [PASSWORD_RESET_FORGOT_SUCCESS]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    hasError: true, // Not really... But we want the user to know their password got changed.
    content: "Password has been changed.",
  }),
  [PASSWORD_RESET_FORGOT_FAILED]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    hasError: true,
    content: undefined,
  }),
  [PASSWORD_RESET_FORGOT_CANCELED]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    hasError: false,
    content: undefined,
  }),
};

// Reducer Interface
export interface AuthState {
  readonly hasError?: boolean;
  readonly error?: object;
  readonly loading?: boolean;
  readonly content?: string;
}

// Initial State
const initialState: AuthState = {
  loading: false,
  hasError: false,
  error: undefined,
  content: undefined,
};

// Reducer
export default handleActions<AuthState, string | undefined>(actions, initialState);
