import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import errorSvg from "assets/img/error-red.svg";
import { Card, CardList } from "components/Card";
import Form from "components/Form";
import Link from "components/Link";
import Loading from "components/Loading";
import NearbyStoresGoogleMap from "components/NearbyStoresGoogleMap";
import Table from "components/Table";
import { Body } from "components/Typography";
import Constants from "helpers/constants";
import { getItemJson, setItem } from "helpers/storage";
import { Store } from "interfaces/store";
import { State } from "state";
import { createAnalyticsEvent, EventTypes, Status } from "state/analytics";
import { getLatLongFromZip, getUserZipCode } from "state/location";
import { fetchUserStores } from "state/stores";
import Page from "components/Page";

interface NearbyStoreOwnProps extends RouteComponentProps {}
type NearbyStoresProps = NearbyStoreOwnProps & PropsFromRedux;

interface NearbyStoresState {
  tableData: Store[];
  zipCode: string;
  mapLoaded: boolean;
  geolocationError?: number;
  latitude?: number;
  longitude?: number;
}

const defaultState: NearbyStoresState = {
  tableData: [],
  zipCode: "",
  mapLoaded: false,
};

class NearbyStores extends React.Component<NearbyStoresProps, NearbyStoresState> {
  public state = defaultState;

  public componentDidMount() {
    if (!getItemJson("nearbyStoresData")) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.setState({
              latitude,
              longitude,
            });

            this.props.getUserZipCode({ latitude, longitude }).then(() => {
              const { currentZip = "" } = this.props;
              this.props.fetchUserStores({ zip: currentZip }).then(() => {
                const { stores = [] } = this.props;
                this.setNearbyStoresData(currentZip, latitude, longitude, stores);

                this.setState({
                  zipCode: currentZip,
                  tableData: stores,
                  mapLoaded: true,
                });
              });
            });
          },
          (e) => this.setState({ ...this.state, geolocationError: e.code })
        );
      }
    } else {
      const nearbyStoresData = {
        ...getItemJson("nearbyStoresData"),
        mapLoaded: true,
      };
      this.setState(nearbyStoresData);
    }
  }

  public renderErrorScreen(errorMessage: string) {
    const myStoresLink = (
      <Link className="body-link" to="/stores/me">
        My Stores
      </Link>
    );

    return (
      <Card>
        <Card.Header>
          <img src={errorSvg} alt="Error Icon" data-qa="error-icon" />
          <Body type={"1"} data-qa="error-text">
            {errorMessage} You can enter a zip code below or visit {myStoresLink} to search for
            stores near you.
          </Body>
        </Card.Header>
      </Card>
    );
  }

  // Save the nearby stores data in localstorage so we don't have to make an API
  // call when the user navigates away from the page and then back.
  public setNearbyStoresData = (
    zipCode: string,
    latitude: number,
    longitude: number,
    tableData: Store[]
  ) => {
    setItem("nearbyStoresData", {
      zipCode,
      latitude,
      longitude,
      tableData,
    });
  };

  public getLocationFromZip = (zip: string) => {
    this.props.getLatLongFromZip(zip).then((location) => {
      if (!this.props.hasError) {
        this.setState(location.payload);
        this.props.fetchUserStores({ zip }).then(() => {
          const { stores = [] } = this.props;

          this.setNearbyStoresData(
            zip,
            location.payload.latitude,
            location.payload.longitude,
            stores
          );
          this.setState({
            tableData: stores,
            mapLoaded: true,
          });

          this.props.createAnalyticsEvent({
            event: EventTypes.NEARBY_STORES_SEARCH,
            eventType: "Action",
            "nearbyStoresSearchStatus": Status.SUCCESS,
            "nearbyStoresSearchQuery": zip,
            "searchResults": stores.length,
          });
        });
      } else {
        this.props.createAnalyticsEvent({
          event: EventTypes.NEARBY_STORES_SEARCH,
          eventType: "Action",
          nearbyStoresSearchStatus: Status.FAILURE,
          nearbyStoresSearchQuery: zip,
          searchResults: 0,
        });
        this.setState(defaultState);
      }
    });
  };

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const zip = this.state.zipCode;
    this.getLocationFromZip(zip);
  };

  public render() {
    const { hasError, isLoadingLocation, isLoadingStores, currentZip = "", isMobile } = this.props;
    const { geolocationError, latitude, longitude, zipCode } = this.state;

    let errorMessage;

    if (hasError) {
      errorMessage = "We were unable to find your location.";
    }

    if (geolocationError) {
      errorMessage =
        geolocationError === 1
          ? "Geolocation is disabled in your browser."
          : "We were unable to find your location.";
    }

    return (
      <Page className="nearby-stores-container">
        <h1 data-qa="nearby-stores-header" className="header">
          Nearby Stores
        </h1>
        <CardList>
          {errorMessage && this.renderErrorScreen(errorMessage)}
          <Card>
            <Card.Header>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group htmlFor="select-project">
                  <Form.Input
                    type="text"
                    placeholder="Search by Zip Code"
                    data-qa="nearby-stores-zipcode-field"
                    maxLength={5}
                    value={zipCode}
                    onChange={(event) => {
                      const zipCodeInput = event.target.value;
                      if (zipCodeInput.length === 0 || /[0-9]+$/.test(zipCodeInput)) {
                        this.setState({
                          zipCode: event.target.value,
                        });
                      }
                    }}
                  />
                  <Form.ButtonGroup>
                    <Form.ConfirmButton data-qa="submit-zipcode" disabled={false}>
                      Search
                    </Form.ConfirmButton>
                  </Form.ButtonGroup>
                </Form.Group>
              </Form>
            </Card.Header>
            <Card.Body className="map-and-stores-list-container">
              {(isLoadingLocation || isLoadingStores) && <Loading />}
              {!(isLoadingLocation || isLoadingStores) && (
                <div>
                  {this.state.mapLoaded && (
                    <NearbyStoresGoogleMap
                      currentZip={currentZip}
                      position={{ lat: latitude!, lng: longitude! }}
                      stores={this.state.tableData}
                    />
                  )}
                  <Table
                    isMobile={isMobile}
                    data={this.state.tableData}
                    emptyState="No stores found"
                    onRowClick={(row: any) => {
                      const { history } = this.props;

                      history.push(`/projects/${row.project_id}/stores/${row.id}`);
                    }}
                    columns={[
                      {
                        field: "retail_store_num",
                        header: "Store No.",
                        dataQa: "store-number",
                      },
                      {
                        field: "name",
                        header: "Store Name",
                        dataQa: "store-name",
                      },
                      {
                        field: "store_address",
                        header: "Address",
                        wrap: true,
                        minWidth: 200,
                        dataQa: "address",
                      },
                      {
                        field: "chain_name",
                        header: "Chain Name",
                        dataQa: "chain-name",
                      },
                      {
                        field: "project_name",
                        header: "Project Name",
                        dataQa: "project-name",
                      },
                    ]}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    currentZip: state.location.content && state.location.content.zip,
    stores: state.stores.content?.data,
    hasError: state.location.error,
    isLoadingLocation: state.location.loading,
    isLoadingStores: state.stores.loading,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  {
    createAnalyticsEvent,
    getUserZipCode,
    getLatLongFromZip,
    fetchUserStores,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(NearbyStores));
