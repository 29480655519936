import React, { ReactNode } from "react";
import Button from "components/Button";
import Dialog, { DialogIcon } from "components/Dialog";

interface ButtonWithConfirmationProps {
  onPrimaryAction: (...arg: any[]) => void;
  disabled?: boolean;
  openDialogButtonContent?: string;
  primaryButtonContent?: string;
  secondaryButtonContent?: string;
  dialogTitleText?: string;
  primaryContent: () => ReactNode;
  secondaryContent?: () => ReactNode;
  icon?: DialogIcon | (() => ReactNode);
  removeSecondaryButton?: boolean;
  removePrimaryButton?: boolean;
  closeDialogIcon?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  getPrimaryButtonProps?: object;
  getSecondaryButtonProps?: object;
  getIconProps?: object;
  getDialogActionsProps?: object;
  closeDialogAfterPrimaryAction?: boolean;
  scroll?: "paper" | "body";
  typeConfiguration?: Object
}

/**
 * Wrapper around components/Dialog.tsx which is based on [Material-UI's Dialog](https://material-ui.com/api/dialog/).
 *
 * @example
 * Checkout Commit ec991ba2: updated TestingChamber for various examples on how to use Dialog/ButtonWithConfirmation
 * 
 * @see Commit Link: [Azure DevOps](https://dev.azure.com/ab-inbev/ghq-brewbox-360/_git/ghq-brewbox-360/commit/ec991ba2471b0413bf5f5b36a2a0f51ffb47be77?refName=refs%2Fheads%2Fbugfix%2Fbutton-with-copy-comments&path=%2Fsrc%2Fcontainers%2FMyStores.tsx).
 *
 */
const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
  typeConfiguration = { primary: true},
  disabled = false,
  openDialogButtonContent = "OPEN DIALOG",
  primaryButtonContent = "Confirm",
  secondaryButtonContent = "Back",
  removeSecondaryButton = false,
  removePrimaryButton = false,
  closeDialogIcon = true,
  children,
  ...restProps
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        className="dialog__primary-button-content"
        data-qa="button-with-confirmation"
        {...typeConfiguration}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {openDialogButtonContent}
      </Button>
      <Dialog
        primaryButtonContent={primaryButtonContent}
        secondaryButtonContent={secondaryButtonContent}
        removeSecondaryButton={removeSecondaryButton}
        removePrimaryButton={removePrimaryButton}
        open={open}
        setOpen={setOpen}
        closeDialogIcon={closeDialogIcon}
        {...restProps}
      >
        {children}
      </Dialog>
    </>
  );
};
export {DialogIcon};
export default ButtonWithConfirmation;
