import Button from "components/Button";
import { Card, CardList } from "components/Card";
import Page from "components/Page";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import Constants from "helpers/constants";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { fetchMySurveys } from "state/surveys";
import { trackPageView, PAGEVIEW_EVENTS } from "state/userEvents";
import { navigateToUrl } from "utils/helpers";

type IMySurveyProps = PropsFromRedux & RouteProps;

class MySurveys extends React.Component<IMySurveyProps> {
  public componentDidMount() {
    this.props.fetchMySurveys();
  }

  public handleSurveyClick(value: string, data: any) {
    const { project_name, project_type, chain_name, survey_url } = data;

    this.props.createAnalyticsEvent({
      event: EventTypes.TAKE_SURVEY,
      eventType: "Action",
      pageName: "My Surveys",
      projectName: `${chain_name} - ${project_name}`,
      projectType: project_type,
      surveyLink: survey_url,
    });

    navigateToUrl(value);
  }

  public render() {
    const { isMobile, surveys = [], user } = this.props;

    return (
      <Page>
        <h1 data-qa="my-survey-header">My Surveys</h1>
        <CardList>
          <Card>
            <Card.Header>
              <h2 data-qa="all-surveys-header">All Surveys</h2>
            </Card.Header>
            <Table
              isMobile={isMobile}
              data={surveys}
              columns={[
                {
                  field: "chain_name",
                  header: "Chain",
                  dataQa: "chain_name",
                  maxWidth: 150,
                },
                {
                  field: "name",
                  header: "Survey Name",
                  dataQa: "survey_name",
                  maxWidth: 200,
                },
                {
                  field: "project_name",
                  header: "Project",
                  dataQa: "project_name",
                },
                {
                  field: "firstName",
                  header: "Created By",
                  dataQa: "created-by",
                  formatter: (value: string, row: any) => `${value} ${row.lastName}`,
                  maxWidth: 300,
                },
                {
                  field: "survey_url",
                  header: "Survey",
                  dataQa: "survey_url",
                  sortable: false,
                  formatter: (value: string, row: any) => {
                    return (
                      <Button
                      secondary={true}
                      onClick={() => {
                        this.props.trackPageView({
                          event_type: PAGEVIEW_EVENTS.SURVEYS_PAGEVIEW,
                          user_id: user?.id,
                          survey_id: row.id,
                          store_id: null,
                          project_id: row.project_id,
                          timestamp: Date.now(),
                        });
                        this.handleSurveyClick(value, row);
                      }}
                      >
                        Take Survey
                      </Button>
                    );
                  },
                  maxWidth: 500,
                },
              ]}
              emptyState="No surveys found"
            />
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    user: state.auth_user.content,
    surveys: state.surveys.content,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  { fetchMySurveys, createAnalyticsEvent, trackPageView }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(MySurveys);
