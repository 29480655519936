import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { CreateSurvey, Survey } from "interfaces/survey";
import { ThunkActionCreator } from "interfaces/thunk";
import { AnalyticsProjectFields, EventTypes } from "state/analytics";

// Action types
const FETCH_MY_SURVEYS_REQUEST = "FETCH_MY_SURVEYS_REQUEST";
const FETCH_MY_SURVEYS = "FETCH_MY_SURVEYS";
const FETCH_MY_SURVEYS_FAILED = "FETCH_MY_SURVEYS_FAILED";

const CREATE_SURVEY_REQUEST = "CREATE_SURVEY_REQUEST";
const CREATE_SURVEY = "CREATE_SURVEY";
const CREATE_SURVEY_FAILED = "CREATE_SURVEY_FAILED";

const DELETE_SURVEY_REQUEST = "DELETE_SURVEY_REQUEST";
const DELETE_SURVEY = "DELETE_SURVEY";
const DELETE_SURVEY_FAILED = "DELETE_SURVEY_FAILED";

export {
  FETCH_MY_SURVEYS_REQUEST,
  FETCH_MY_SURVEYS,
  FETCH_MY_SURVEYS_FAILED,
  CREATE_SURVEY_REQUEST,
  CREATE_SURVEY,
  CREATE_SURVEY_FAILED,
  DELETE_SURVEY_REQUEST,
  DELETE_SURVEY,
  DELETE_SURVEY_FAILED,
};

// Thunk Action Creator
export const fetchMySurveys: ThunkActionCreator<Promise<void>> =
  (projectid?: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MY_SURVEYS_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/surveys`;

    if (projectid) {
      url = url.concat(`?projectId=${projectid}`);
    }

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_MY_SURVEYS, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_MY_SURVEYS_FAILED });
      });
  };

export const createSurvey: ThunkActionCreator<Promise<void>> =
  (survey: CreateSurvey, { name, type, url }: AnalyticsProjectFields & { url: string }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: CREATE_SURVEY_REQUEST });

    return axios
      .post(`${Config.API_SERVICE_URL}/api/surveys`, survey, getHeaders())
      .then((response) => {
        dispatch({
          type: CREATE_SURVEY,
          payload: response.data,
          meta: {
            analytics: {
              event: EventTypes.MANAGE_SURVEYS_SUBMIT,
              eventType: "Action",
              projectName: name,
              projectType: type,
              surveyLink: url,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({ type: CREATE_SURVEY_FAILED, payload: error.response.data });
      });
  };

export const deleteSurvey: ThunkActionCreator<Promise<void>> =
  (surveyId: number, { name, type, url }: AnalyticsProjectFields & { url: string }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: DELETE_SURVEY_REQUEST });

    return axios
      .delete(`${Config.API_SERVICE_URL}/api/surveys/${surveyId}`, getHeaders())
      .then(() => {
        dispatch({
          type: DELETE_SURVEY,
          payload: surveyId,
          meta: {
            analytics: {
              event: EventTypes.MANAGE_SURVEYS_DELETE,
              eventType: "Action",
              projectName: name,
              projectType: type,
              surveyLink: url,
            },
          },
        });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_SURVEY_FAILED });
      });
  };

// Actions
const actions = {
  [FETCH_MY_SURVEYS_REQUEST]: (state: SurveyState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MY_SURVEYS]: (state: SurveyState, action: AnyAction): SurveyState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_MY_SURVEYS_FAILED]: (state: SurveyState): SurveyState => ({
    ...state,
    loading: false,
    hasError: true,
  }),
  [CREATE_SURVEY_REQUEST]: (state: SurveyState) => ({
    ...state,
    loading: true,
    hasError: false,
  }),
  [CREATE_SURVEY]: (state: SurveyState, action: AnyAction): SurveyState => ({
    ...state,
    loading: false,
    hasError: false,
    content: [...(state.content || []), action.payload],
  }),
  [CREATE_SURVEY_FAILED]: (state: SurveyState, action: AnyAction): SurveyState => ({
    ...state,
    loading: false,
    hasError: true,
    errors: action.payload,
  }),
  [DELETE_SURVEY_REQUEST]: (state: SurveyState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_SURVEY]: (state: SurveyState, action: AnyAction): SurveyState => ({
    ...state,
    loading: false,
    hasError: false,
    content: (state.content || []).filter((s) => s.id !== action.payload),
  }),
  [DELETE_SURVEY_FAILED]: (state: SurveyState): SurveyState => ({
    ...state,
    loading: false,
    hasError: true,
  }),
};

export interface SurveyState {
  hasError?: boolean;
  errors?: string[];
  loading?: boolean;
  content?: Survey[];
}

const initialState: SurveyState = {
  loading: false,
  hasError: false,
  content: [],
};

export default handleActions<SurveyState, AnyAction>(actions, initialState);
