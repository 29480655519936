import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { Reason } from "helpers/actions-reasons";
import Constants from "helpers/constants";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ProjectType } from "interfaces/project";
import { ThunkActionCreator } from "interfaces/thunk";
import {
  EventTypes,
  AnalyticsProjectFields,
  StoreDetailCommentAddEvent,
  StoreDetailCommentDeleteEvent,
} from "state/analytics";

// Actions Types
export const POST_COMMENT_REQUEST = "POST_COMMENT_REQUEST";
export const POST_COMMENT = "POST_COMMENT";
export const POST_COMMENT_FAILED = "POST_COMMENT_FAILED";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

// Thunk Action Creator
export const deleteComment: ThunkActionCreator<Promise<void>> =
  (
    id: number,
    {
      name,
      type,
      storeName,
      comment,
      action_id,
    }: AnalyticsProjectFields & {
      storeName: string;
      action_id: number;
      comment?: string;
    }
  ) =>
  (dispatch: Dispatch) => {
    dispatch({ type: DELETE_COMMENT_REQUEST });

    return axios
      .delete(`${Config.API_SERVICE_URL}/api/comments/${id}`, getHeaders())
      .then((response) => {
        const payload: Omit<StoreDetailCommentDeleteEvent, "Comment Action"> = {
          event: EventTypes.STORE_DETAIL_COMMENT_DELETE,
          eventType: "Action",
          projectName: name,
          projectType: type as ProjectType,
          storeName: storeName,
          commentCharacterCount: comment ? comment.length : 0,
        };

        switch (action_id.toString()) {
          case Constants.ACTION_GENERAL_COMMENT: {
            dispatch({
              type: DELETE_COMMENT,
              payload: id,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_GENERAL_COMMENT_LABEL,
                },
              },
            });
            break;
          }
          case Constants.ACTION_ADD_PRODUCT: {
            dispatch({
              type: DELETE_COMMENT,
              payload: id,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_ADD_PRODUCT_LABEL,
                },
              },
            });

            break;
          }
          case Constants.ACTION_MODIFY_PRODUCT: {
            dispatch({
              type: DELETE_COMMENT,
              payload: id,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_MODIFY_PRODUCT_LABEL,
                },
              },
            });

            break;
          }
          case Constants.ACTION_REMOVE_PRODUCT: {
            dispatch({
              type: DELETE_COMMENT,
              payload: id,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_REMOVE_PRODUCT_LABEL,
                },
              },
            });

            break;
          }
          case Constants.ACTION_SWAP_PRODUCT: {
            dispatch({
              type: DELETE_COMMENT,
              payload: id,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_SWAP_PRODUCT_LABEL,
                },
              },
            });

            break;
          }
        }
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_COMMENT_FAILED });
      });
  };

export const postComment: ThunkActionCreator<Promise<void>> =
  (
    data: Comment,
    {
      name,
      type,
      storeName,
      product_name,
      product_sub_name,
      reason,
    }: AnalyticsProjectFields & {
      storeName: string;
      product_sub_name?: string;
      product_name?: string;
      reason?: string;
    }
  ) =>
  (dispatch: Dispatch, getState) => {
    dispatch({ type: POST_COMMENT_REQUEST });

    return axios
      .post(
        `${Config.API_SERVICE_URL}/api/comments`,
        data,
        getHeaders({ "Content-Type": "application/json" })
      )
      .then((response) => {
        const payload: Omit<StoreDetailCommentAddEvent, "commentAction"> = {
          event: EventTypes.STORE_DETAIL_COMMENT_ADD,
          eventType: "Action",
          projectName: name,
          projectType: type as ProjectType,
          storeName: storeName,
          commentCharacterCount: data.comment ? data.comment.length : 0,
          ...(reason && { commentReason: reason as Reason }),
        };

        switch (data.action_id.toString()) {
          case Constants.ACTION_GENERAL_COMMENT: {
            dispatch({
              type: POST_COMMENT,
              payload: response.data,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_GENERAL_COMMENT_LABEL,
                },
              },
            });
            break;
          }
          case Constants.ACTION_ADD_PRODUCT: {
            dispatch({
              type: POST_COMMENT,
              payload: response.data,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_ADD_PRODUCT_LABEL,
                  "commentProduct": product_name as string,
                },
              },
            });
            break;
          }
          case Constants.ACTION_MODIFY_PRODUCT: {
            dispatch({
              type: POST_COMMENT,
              payload: response.data,
              meta: {
                analytics: {
                  ...payload,
                  "commentAction": Constants.ACTION_MODIFY_PRODUCT_LABEL,
                  "commentProduct": product_name as string,
                },
              },
            });
            break;
          }
          case Constants.ACTION_REMOVE_PRODUCT: {
            dispatch({
              type: POST_COMMENT,
              payload: response.data,
              meta: {
                analytics: {
                  ...payload,
                  commentAction: Constants.ACTION_REMOVE_PRODUCT_LABEL,
                  commentProduct: product_name as string,
                },
              },
            });
            break;
          }
          case Constants.ACTION_SWAP_PRODUCT: {
            dispatch({
              type: POST_COMMENT,
              payload: response.data,
              meta: {
                analytics: {
                  ...payload,
                  commentAction: Constants.ACTION_SWAP_PRODUCT_LABEL,
                  commentProduct: product_name as string,
                  commentSwapProduct: product_sub_name as string,
                },
              },
            });
            break;
          }
        }
      })
      .catch((error) => {
        dispatch({ type: POST_COMMENT_FAILED });
        throw new Error("Post Comment Failed");
      });
  };

export interface UpdateCommentBody {
  feedback?: string;
  explanation?: string;
}
export const updateComment: ThunkActionCreator<Promise<void>> =
  (id: number, body: UpdateCommentBody) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_COMMENT_REQUEST });

    return axios
      .patch(`${Config.API_SERVICE_URL}/api/comments/${id}`, body, getHeaders())
      .then((_) => {
        dispatch({ type: UPDATE_COMMENT, payload: id });
      })
      .catch(() => {
        dispatch({ type: UPDATE_COMMENT_FAILED });
      });
  };

const actions = {
  [DELETE_COMMENT_REQUEST]: (state: CommentState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_COMMENT]: (state: CommentState, action: AnyAction): CommentState => ({
    ...state,
    loading: false,
    error: false,
    content: state.content || undefined,
  }),
  [DELETE_COMMENT_FAILED]: (state: CommentState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [POST_COMMENT_REQUEST]: (state: CommentState) => ({
    ...state,
    loading: true,
  }),
  [POST_COMMENT]: (state: CommentState, action: AnyAction): CommentState => ({
    ...state,
    loading: false,
    content: {
      ...action.payload,
    },
  }),
  [POST_COMMENT_FAILED]: (state: CommentState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [UPDATE_COMMENT_REQUEST]: (state: CommentState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_COMMENT]: (state: CommentState, action: AnyAction): CommentState => ({
    ...state,
    loading: false,
    error: false,
    content: state.content || undefined,
  }),
  [UPDATE_COMMENT_FAILED]: (state: CommentState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

export interface Comment {
  readonly action_id: number;
  readonly comment: string;
  readonly created_at: string | null;
  readonly explanation: string | null;
  readonly feedback: string | null;
  readonly id: number;
  readonly product_id: string | null;
  readonly product_sub_id: string | null;
  readonly project_id: number;
  readonly reason_id: number | null;
  readonly store_id: number;
  readonly updated_at: string | null;
  readonly user_id: number;
}

// tslint:disable no-empty-interface
export interface CommentState {
  error?: boolean;
  loading?: boolean;
  content?: Comment;
}

// Initial State
const initialState: CommentState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<CommentState, AnyAction>(actions, initialState);
