import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { State } from "state";

interface ProfileOwnProps extends RouteComponentProps {}
type ProfileProps = ProfileOwnProps & PropsFromRedux;

const Profile: React.FunctionComponent<ProfileProps> = ({ auth_user }) => (
  <div className="profile-container" data-qa="profile">
    <p data-qa="profile-name">
      Signed in as: <strong>{auth_user && auth_user.username}</strong>
    </p>
  </div>
);

const connecter = connect((state: State) => ({
  auth_user: state.auth_user.content,
}));

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(Profile));
