import React from "react";
import { connect, ConnectedProps } from "react-redux";

import helpSvg from "assets/img/help-white.svg";
import Table from "components/Table";
import { Body } from "components/Typography";
import { State } from "state";
import { FileUploadState, unsetError } from "state/create_file_upload";
import { fetchUsersByProjectId } from "state/users";

interface UserPreviewOwnProps {
  prefix: string;
  errorPrefixes?: string[];
  projectId: string;
  showError?: boolean;
}

type UserPreviewProps = UserPreviewOwnProps & PropsFromRedux;

class UserPreview extends React.Component<UserPreviewProps> {
  public componentDidMount() {
    const { projectId } = this.props;

    this.props.fetchUsersByProjectId(projectId);
  }

  public componentDidUpdate(prevProps: UserPreviewProps) {
    const { projectId } = this.props;

    // if a new file was being loading
    // and loaded without an error
    // then re-fetch
    // TODO: Is there a better way to do this?
    if (
      (prevProps.upload.loading && !this.props.upload.loading && !this.props.upload.hasError) ||
      (prevProps.errorUpload &&
        prevProps.errorUpload.loading &&
        this.props.errorUpload &&
        !this.props.errorUpload.loading &&
        !this.props.errorUpload.hasError)
    ) {
      this.props.fetchUsersByProjectId(projectId);
    }
  }

  public componentWillUnmount() {
    const { prefix, errorPrefixes } = this.props;

    this.props.unsetError(prefix);
    if (errorPrefixes) {
      errorPrefixes.forEach((errorPrefix) => this.props.unsetError(errorPrefix));
    }
  }

  public render() {
    const {
      upload: { hasError: defaultError, errors: defaultErrors = [] },
      errorUpload,
      users,
      showError = true,
    } = this.props;

    if (!users) {
      return null;
    }

    const hasError = defaultError || (errorUpload && errorUpload.hasError);
    const errors = defaultErrors.concat((errorUpload && errorUpload.errors) || []);
    return (
      <div>
        {hasError && (
          <div className="box-error">
            <img src={helpSvg} alt="Help Icon" />
            <p className="text">Error: File formatting. See above for details.</p>
          </div>
        )}
        <Table
          data={users as object[]}
          emptyState={"Upload a project file to see the mapping preview"}
          isScrollable={true}
          style={{ maxHeight: "615px" }}
          columns={[
            { field: "displayName", header: "Name", dataQa: "user_name" },
            { field: "company", header: "Company", dataQa: "user_company" },
            { field: "role", header: "Role", dataQa: "user_role" },
            { field: "email", header: "Email", dataQa: "user_email" },
          ]}
        />
        {showError && hasError && (
          <div className="card-error-bottom">
            <h5 className="error">Errors:</h5>
            <ul className="error-list">
              {errors.map((e: string) => (
                <li key={e}>
                  <Body type="3">{e}</Body>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const connecter = connect(
  (state: State, ownProps: UserPreviewOwnProps) => {
    const { prefix, errorPrefixes = [] } = ownProps;

    let errorUpload: FileUploadState = { hasError: false };
    errorPrefixes.some((errorPrefix) => {
      // @ts-ignore
      if (state[`${errorPrefix}_file_upload`].hasError) {
        // @ts-ignore
        errorUpload = state[`${errorPrefix}_file_upload`];
        return true;
      }
      return false;
    });

    return {
      errorUpload,
      upload: {
        // @ts-ignore
        ...state[`${prefix}_file_upload`],
        hasError: errorUpload.hasError,
      },
      users: state.users.content?.data,
    };
  },
  { fetchUsersByProjectId, unsetError }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(UserPreview);
