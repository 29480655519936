import downloadSvg from "assets/img/download.svg";
import downloadInactiveSvg from "assets/img/downloads-inactive.svg";
import cx from "classnames";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import CommentPopover from "components/CommentPopover";
import CSVDownload from "components/CSVDownload";
import Dropdown from "components/Dropdown";
import ErrorMessage from "components/ErrorMessage";
import Icon from "components/Icon";
import Page from "components/Page";
import Table from "components/Table";
import { Tooltip } from "components/Tooltip";
import Config from "config";
import { RouteProps } from "containers/App";
import FileUpload from "containers/FileUpload";
import ProjectFilter from "containers/ProjectFilter";
import { PermissionsContext } from "context";
import actionsReasons, { ActionID, ReasonID, reasons } from "helpers/actions-reasons";
import Constants from "helpers/constants";
import { filterItemsByAction, filterItemsByUser } from "helpers/filter-functions";
import { ProjectType } from "interfaces/project";
import { ReactSelectObject } from "interfaces/select";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { updateComment, UpdateCommentBody } from "state/comment";
import { fetchProjectComments } from "state/project_comments";
import { allFieldsEmpty, buildExportFilename } from "utils/helpers";

type ReviewFeedbackProps = PropsFromRedux & RouteProps;

interface ReviewFeedbackState {
  tableData: any[];
  selectedProjectName?: string;
  selectedProjectType?: string;
  errors?: any[];
  selectedStoreNumber?: string | number;
  selectedUsers?: ReactSelectObject[];
  selectedActions?: ReactSelectObject[];
  isOpen?: boolean;
  explanationMap: { [idx: number]: string };
  feedbackMap: { [idx: number]: string };
  selectedProjectId: string | number | null;
}

class ReviewFeedback extends React.Component<ReviewFeedbackProps, ReviewFeedbackState> {
  protected MAX_EXPLANATION_LENGTH = 500;

  protected CSV_HEADERS = [
    { label: "Comment ID", key: "commentID" },
    { label: "Project Name", key: "projectName" },
    { label: "Store Number", key: "storeNumber" },
    { label: "Store Name", key: "storeName" },
    { label: "Action", key: "action" },
    { label: "Reason", key: "reason" },
    { label: "Product Name", key: "productName" },
    { label: "Product UPC", key: "productUPC" },
    { label: "Swap Product Name", key: "swapProductName" },
    { label: "Swap Product UPC", key: "swapProductUPC" },
    { label: "Comment", key: "comment" },
    { label: "User", key: "user" },
    { label: "User Email Address", key: "email" },
    { label: "Employer", key: "employer" },
    { label: "Created Date", key: "createdDate" },
    { label: "Explanation", key: "explanation" },
    { label: "Decision", key: "decision" },
  ];

  protected FEEDBACK_OPTIONS = {
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
  };

  constructor(props: ReviewFeedbackProps) {
    super(props);

    this.state = {
      tableData: [],
      explanationMap: {},
      feedbackMap: {},
      selectedProjectId: null,
    };
  }

  public componentDidMount() {
    const { selectedProjectId } = this.state;

    if (selectedProjectId) {
      this.props.fetchProjectComments(selectedProjectId);
    }
  }

  public formatCSVData() {
    return this.state.tableData.map((row) => ({
      commentID: row.commentId,
      projectName: row.projectName,
      storeNumber: row.storeNumber,
      storeName: row.storeName,
      action: actionsReasons[row.actionId as ActionID].action,
      reason: reasons[row.reasonId as ReasonID],
      productName: row.productName,
      productUPC: row.productUPC,
      swapProductName: row.productSubName,
      swapProductUPC: row.subUPC,
      comment: row.comment,
      user: `${row.firstName} ${row.lastName}`,
      email: row.email,
      employer: row.employer,
      createdDate: moment.utc(row.created_at).format("YYYY-MM-DD HH:mm:ss z"),
      explanation: this.explanationAndDecisionIsDisabled(row.validationDeadline)
        ? "NOT EDITABLE"
        : this.state.explanationMap[row.commentId],
      decision: this.explanationAndDecisionIsDisabled(row.validationDeadline)
        ? "NOT EDITABLE"
        : this.state.feedbackMap[row.commentId],
    }));
  }

  public explanationAndDecisionIsDisabled(validationDeadline: string): boolean {
    const now = moment();
    return moment(validationDeadline).isAfter(now);
  }

  public updateComment(id: string, body: UpdateCommentBody) {
    return this.props
      .updateComment(id, body);
  }

  public reloadTableData(id: string, name: string, type: string) {
    return this.props.fetchProjectComments(id).then(() => {
      // initialize explanationMap and feedbackMap for this project
      const explanations = {};
      const feedback = {};
      const { project_comments = [] } = this.props;
      project_comments.forEach((comment) => {
        // @ts-ignore
        explanations[comment.commentId] = comment.explanation;
        // @ts-ignore
        feedback[comment.commentId] = comment.feedback;
      });

      this.setState({
        selectedProjectId: id,
        tableData: project_comments,
        feedbackMap: feedback,
        explanationMap: explanations,
        selectedProjectName: name,
        selectedProjectType: type,
        selectedStoreNumber: undefined,
        selectedUsers: undefined,
        selectedActions: undefined,
      });
    });
  }

  public filterTableData() {
    const { project_comments = [] } = this.props;
    this.setState({
      tableData: filterItemsByUser(
        this.state.selectedUsers || [],
        filterItemsByAction(
          this.state.selectedActions || [],
          // This is typed as a string or number, so we'll catch both cases here
          this.state.selectedStoreNumber === -1 ||
            this.state.selectedStoreNumber === "-1" ||
            !this.state.selectedStoreNumber
            ? project_comments
            : project_comments.filter(
                (comment) => comment.storeNumber === this.state.selectedStoreNumber
              )
        )
      ),
    });
  }

  public render() {
    const { project_comments = [], projectCommentsLoading } = this.props;

    const storeNumDropdownOptions = [
      { label: "All Stores", value: "-1", "data-qa": "all-comments" },
    ].concat(
      uniqBy(
        project_comments
          .map((comments) => ({
            label: comments.storeNumber,
            value: comments.storeNumber,
            "data-qa": comments.storeNumber,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        "value"
      )
    );

    const userDropdownOptions = uniqBy(
      project_comments
        .map((comments) => ({
          label: `${comments.firstName} ${comments.lastName}`,
          value: `${comments.firstName} ${comments.lastName}`,
          "data-qa": `${comments.firstName} ${comments.lastName}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      "value"
    );

    const actionDropdownOptions = uniqBy(
      project_comments
        .map((comments) => {
          const actionReason = actionsReasons[comments.actionId as unknown as ActionID];
          return {
            label: actionReason && actionReason.action,
            value: comments.actionId,
            "data-qa": actionReason && `${actionReason.action}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
      "value"
    );

    const data = this.formatCSVData();
    return (
      <PermissionsContext.Consumer>
        {({ accept_comment, reject_comment }: any) => (
          <Page>
            <header className="section-header">
              <h1 className="layout-header" data-qa="review-feedback-header">
                Review Feedback
              </h1>
              <ProjectFilter
                onChange={({ id, name, type }) => this.reloadTableData(id.toString(), name, type)}
                dataQa={"select-project"}
                exclude={Constants.FILE_SHARING}
                defaultOptionLabel={"Select Project"}
                isSearchable
              />
              <div className="section-header-button-group">
                <CSVDownload
                  className="csv-link"
                  data={data}
                  headers={this.CSV_HEADERS}
                  filename={buildExportFilename(
                    "csv",
                    `${
                      this.state.selectedProjectName
                        ? this.state.selectedProjectName.toLowerCase()
                        : ""
                    }-review-feedback`
                  )}
                  onClick={() => {
                    const { selectedProjectName, selectedProjectType } = this.state;

                    this.props.createAnalyticsEvent({
                      event: EventTypes.REPORT_EXPORT,
                      eventType: "Action",
                      projectName: selectedProjectName as string,
                      projectType: selectedProjectType as ProjectType,
                      pageName: "Review Feedback",
                      reportRowCount: data.length,
                    });
                  }}
                >
                  <button
                    disabled={allFieldsEmpty(this.state.tableData)}
                    className={cx("import-export-button", {
                      disabled: allFieldsEmpty(this.state.tableData),
                    })}
                    data-qa="review-feedback-export-button"
                  >
                    Export{" "}
                    <Icon
                      src={allFieldsEmpty(this.state.tableData) ? downloadInactiveSvg : downloadSvg}
                    />
                  </button>
                </CSVDownload>
                {accept_comment && reject_comment && (
                  <span className="upload-item">
                    <FileUpload
                      disabled={!accept_comment || !reject_comment}
                      prefix={"COMMENTS"}
                      url={`${Config.API_SERVICE_URL}/api/projects/comments`}
                      label={"Import"}
                      dataQa={"review-feedback-file-upload"}
                      basicImport={true}
                      afterUploadFunction={() => {
                        this.reloadTableData(
                          this.state.selectedProjectId
                            ? this.state.selectedProjectId.toString()
                            : "-1",
                          this.state.selectedProjectName || "",
                          this.state.selectedProjectType || ""
                        );

                        const {
                          comments_upload: { content },
                        } = this.props;

                        if (content && (content as any).totalRows && (content as any).updatedRows) {
                          this.props.createAnalyticsEvent({
                            event: EventTypes.REPORT_IMPORT,
                            eventType: "Action",
                            projectName: this.state.selectedProjectName as string,
                            projectType: this.state.selectedProjectType as ProjectType,
                            pageName: "Review Feedback",
                            reportRowCount: (content as any).totalRows,
                            reviewFeedbackUpdateCount: (content as any).updatedRows.length,
                          });

                          for (const { type, value, storeName } of (content as any).updatedRows) {
                            if (type === "decision") {
                              this.props.createAnalyticsEvent({
                                event: EventTypes.REVIEW_FEEDBACK_DECISION,
                                eventType: "Action",
                                projectName: this.state.selectedProjectName as string,
                                projectType: this.state.selectedProjectType as ProjectType,
                                storeName: storeName,
                                reviewFeedbackAction:
                                  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
                                reviewFeedbackDecisionUpdateMethod: "Import",
                              });
                            } else if (type === "explanation") {
                              this.props.createAnalyticsEvent({
                                event: EventTypes.REVIEW_FEEDBACK_EXPLANATION,
                                eventType: "Action",
                                projectName: this.state.selectedProjectName as string,
                                projectType: this.state.selectedProjectType as ProjectType,
                                storeName: storeName,
                                reviewFeedbackExplanationCharacterCount: value.length,
                                reviewFeedbackExplanationUpdateMethod: "Import",
                              });
                            }
                          }
                        }
                      }}
                    />
                  </span>
                )}
              </div>
            </header>
            <div hidden={!this.props.comments_upload.hasError}>
              <ErrorMessage errors={this.props.comments_upload.errors || []} />
            </div>
            <CardList>
              <Card>
                <div className="review-feedback-filter-container">
                  <p className="header">Filters</p>
                  <div className="review-feedback-store-dropdown-container">
                    <p>Store Number</p>
                    <Dropdown
                      dataQa="review-feedback-store-dropdown"
                      value={
                        storeNumDropdownOptions.find(
                          (opt) => opt.value === this.state.selectedStoreNumber
                        ) || storeNumDropdownOptions[0]
                      }
                      options={storeNumDropdownOptions}
                      placeholder="Select"
                      isDisabled={
                        !this.state.selectedProjectId || this.state.selectedProjectId === -1
                      }
                      containerStyles={{
                        marginLeft: "unset",
                        fontWeight: "normal",
                        marginBottom: "10px",
                      }}
                      onChange={(store) => {
                        this.setState(
                          {
                            selectedStoreNumber: store.value,
                          },
                          this.filterTableData
                        );
                      }}
                      isSearchable
                    />
                  </div>
                  <div className="review-feedback-multi-filter-dropdown-container">
                    <p>User</p>
                    <Dropdown
                      dataQa="review-feedback-user-dropdown"
                      options={userDropdownOptions}
                      placeholder="Select"
                      isDisabled={
                        !this.state.selectedProjectId || this.state.selectedProjectId === -1
                      }
                      containerStyles={{
                        marginLeft: "unset",
                        fontWeight: "normal",
                        marginBottom: "10px",
                        width: "300px",
                      }}
                      onChange={(selectedUsers: ReactSelectObject[]) => {
                        this.setState({ selectedUsers }, this.filterTableData);
                      }}
                      value={this.state.selectedUsers}
                      isMulti={true}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      isSearchable
                    />
                  </div>
                  <div className="review-feedback-multi-filter-dropdown-container">
                    <p>Action</p>
                    <Dropdown
                      dataQa="review-feedback-actions-dropdown"
                      options={actionDropdownOptions}
                      placeholder="Select"
                      isDisabled={
                        !this.state.selectedProjectId || this.state.selectedProjectId === -1
                      }
                      containerStyles={{
                        marginLeft: "unset",
                        fontWeight: "normal",
                        marginBottom: "10px",
                        width: "300px",
                      }}
                      onChange={(selectedActions: ReactSelectObject[]) => {
                        this.setState({ selectedActions }, this.filterTableData);
                      }}
                      value={this.state.selectedActions}
                      isMulti={true}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      isSearchable
                    />
                  </div>
                </div>
              </Card>
              <Card>
                <Table
                  loading={projectCommentsLoading}
                  data={projectCommentsLoading ? [] : this.state.tableData}
                  emptyState={
                    this.state.selectedProjectId && this.state.selectedProjectId > -1
                      ? "No feedback found for selected project"
                      : "Select a project to view feedback"
                  }
                  columns={[
                    {
                      field: "storeNumber",
                      header: "Store",
                      dataQa: "storeNumber",
                      maxWidth: 100,
                    },
                    {
                      field: "actionId",
                      header: "Action",
                      dataQa: "action",
                      formatter: (value: string) => actionsReasons[value as ActionID].action,
                      maxWidth: 100,
                    },
                    {
                      field: "reasonId",
                      header: "Reason",
                      dataQa: "reason",
                      formatter: (value: string) => reasons[value as ReasonID],
                      maxWidth: 100,
                    },
                    {
                      field: "productName",
                      header: "Product",
                      dataQa: "product",
                      formatter: (name: string, row: any) => {
                        if (name) {
                          return `${name} - ${row.productUPC}`;
                        }
                        return "";
                      },
                    },
                    {
                      field: "productSubName",
                      header: "Swap",
                      dataQa: "swap",
                      maxWidth: 150,
                      formatter: (name: string, row: any) => {
                        if (name) {
                          return `${name} - ${row.subUPC}`;
                        }
                        return "";
                      },
                    },
                    {
                      field: "comment",
                      header: "Comment",
                      dataQa: "comment",
                      sortable: false,
                    },
                    {
                      field: "firstName",
                      header: "User",
                      dataQa: "user",
                      formatter: (value: string, row: any) => `${value} ${row.lastName}`,
                      maxWidth: 100,
                    },
                    {
                      field: "explanation",
                      header: "Explanation",
                      dataQa: "explanation",
                      sortable: false,
                      show: accept_comment && reject_comment,
                      formatter: (value: string, row: any) => {
                        const feedback = this.state.feedbackMap[row.commentId];
                        const isAccepted = feedback === this.FEEDBACK_OPTIONS.ACCEPT;
                        const isRejected = feedback === this.FEEDBACK_OPTIONS.REJECT;

                        return (
                          <CommentPopover
                            initialValue={this.state.explanationMap[row.commentId] || ""}
                            onCancel={() => {
                              this.props.createAnalyticsEvent({
                                event: EventTypes.REVIEW_FEEDBACK_EXPLANATION,
                                eventType: "Action",
                                projectName: this.state.selectedProjectName as string,
                                projectType: this.state.selectedProjectType as ProjectType,
                                storeName: row.storeName,
                                reviewFeedbackExplanationAction: "Cancel",
                                reviewFeedbackDecisionStatus: isAccepted
                                  ? "Accepted"
                                  : isRejected
                                  ? "Rejected"
                                  : "N/A",
                                reviewFeedbackExplanationUpdateMethod: "Manual",
                              });
                            }}
                            onSave={(textValue) => {
                              this.setState(
                                {
                                  explanationMap: {
                                    ...this.state.explanationMap,
                                    [row.commentId]: textValue || "",
                                  },
                                },
                                this.formatCSVData
                              );
                              this.updateComment(row.commentId, {
                                explanation: textValue || "",
                              });

                              this.props.createAnalyticsEvent({
                                event: EventTypes.REVIEW_FEEDBACK_EXPLANATION,
                                eventType: "Action",
                                projectName: this.state.selectedProjectName as string,
                                projectType: this.state.selectedProjectType as ProjectType,
                                storeName: row.storeName,
                                reviewFeedbackExplanationAction: "Save",
                                reviewFeedbackExplanationCharacterCount: (textValue || "")
                                  .length,
                                reviewFeedbackDecisionStatus: isAccepted
                                  ? "Accepted"
                                  : isRejected
                                  ? "Rejected"
                                  : "N/A",
                                reviewFeedbackExplanationUpdateMethod: "Manual",
                              });
                            }}
                            maxLength={this.MAX_EXPLANATION_LENGTH}
                            errorMessage={`Explanation cannot exceed ${this.MAX_EXPLANATION_LENGTH} characters`}
                            disabled={this.explanationAndDecisionIsDisabled(row.validationDeadline)}
                          />
                        );
                      },
                    },
                    {
                      field: "feedback",
                      header: "Decision",
                      dataQa: "decision",
                      maxWidth: 300,
                      show: accept_comment && reject_comment,
                      formatter: (value: string, row: any) => {
                        const disabled = this.explanationAndDecisionIsDisabled(
                          row.validationDeadline
                        );
                        const commentId = row.commentId;
                        const feedback = this.state.feedbackMap[row.commentId];
                        const isAccepted = feedback === this.FEEDBACK_OPTIONS.ACCEPT;
                        const isRejected = feedback === this.FEEDBACK_OPTIONS.REJECT;
                        return (
                          <div className="accept-reject-button-group">
                            <Button
                              hasTooltip={disabled}
                              disabled={disabled}
                              className={isAccepted && !disabled ? "accepted" : ""}
                              accept={true}
                              secondary={true}
                              data-qa={`accept-${row.commentId}`}
                              onClick={() => {
                                this.updateComment(commentId, {
                                  feedback: this.FEEDBACK_OPTIONS.ACCEPT,
                                  explanation: this.state.explanationMap[commentId],
                                });
                                this.setState({
                                  feedbackMap: {
                                    ...this.state.feedbackMap,
                                    [row.commentId]: this.FEEDBACK_OPTIONS.ACCEPT,
                                  },
                                });

                                this.props.createAnalyticsEvent({
                                  event: EventTypes.REVIEW_FEEDBACK_DECISION,
                                  eventType: "Action",
                                  projectName: this.state.selectedProjectName as string,
                                  projectType: this.state.selectedProjectType as ProjectType,
                                  storeName: row.storeName,
                                  reviewFeedbackAction: "Accept",
                                  reviewFeedbackDecisionStatus: isAccepted
                                    ? "Accepted"
                                    : isRejected
                                    ? "Rejected"
                                    : "N/A",
                                  reviewFeedbackDecisionUpdateMethod: "Manual",
                                });
                              }}
                            >
                              <Tooltip message="STORE VALIDATION DEADLINE HAS NOT PASSED" />
                              {isAccepted ? "ACCEPTED" : "ACCEPT"}
                            </Button>
                            <Button
                              hasTooltip={disabled}
                              reject={true}
                              disabled={disabled}
                              className={isRejected && !disabled ? "rejected" : ""}
                              data-qa={`reject-${row.commentId}`}
                              onClick={() => {
                                this.updateComment(commentId, {
                                  feedback: this.FEEDBACK_OPTIONS.REJECT,
                                  explanation: this.state.explanationMap[commentId],
                                });
                                this.setState({
                                  feedbackMap: {
                                    ...this.state.feedbackMap,
                                    [row.commentId]: this.FEEDBACK_OPTIONS.REJECT,
                                  },
                                });

                                this.props.createAnalyticsEvent({
                                  event: EventTypes.REVIEW_FEEDBACK_DECISION,
                                  eventType: "Action",
                                  projectName: this.state.selectedProjectName as string,
                                  projectType: this.state.selectedProjectType as ProjectType,
                                  storeName: row.storeName,
                                  reviewFeedbackAction: "Reject",
                                  reviewFeedbackDecisionStatus: isAccepted
                                    ? "Accepted"
                                    : isRejected
                                    ? "Rejected"
                                    : "N/A",
                                  reviewFeedbackDecisionUpdateMethod: "Manual",
                                });
                              }}
                            >
                              <Tooltip message="STORE VALIDATION DEADLINE HAS NOT PASSED" />
                              {isRejected ? "REJECTED" : "REJECT"}
                            </Button>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </Card>
            </CardList>
          </Page>
        )}
      </PermissionsContext.Consumer>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    project_comments: state.project_comments.content,
    projectCommentsLoading: state.project_comments.loading,
    comments_upload: state.COMMENTS_file_upload,
    comment: state.comment.content,
  }),
  { fetchProjectComments, updateComment, createAnalyticsEvent }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ReviewFeedback);
