import React from "react";
import ReactLoading from "react-loading";

import loadingAnimationWhite from "assets/img/loading-animation-white.gif";
import loadingAnimation from "assets/img/loading-animation.gif";
import { isIe } from "utils/helpers";

interface LoadingProps {
  width?: number;
  height?: number;
  color?: string;
}

const Loading: React.FunctionComponent<LoadingProps> = ({
  width = 100,
  height = 100,
  color = "#3c6df1",
}) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      width,
      height,
      marginTop: -height / 2,
      marginLeft: -width / 2,
    }}
  >
    {!isIe && <ReactLoading type={"spin"} color={color} height={height} width={width} />}
    {isIe && (
      <img src={color === "#fff" ? loadingAnimationWhite : loadingAnimation} alt="Loading..." />
    )}
  </div>
);

export default Loading;
