import { getItem } from "helpers/storage";
import { STORAGE_KEY } from "state/auth";

export const getHeaders = (headers: any = {}) => {
  const token = getItem(STORAGE_KEY);

  const newHeaders = { ...headers };
  if (token) {
    newHeaders["Authorization"] = `Bearer ${token}`;
  }

  return { headers: newHeaders };
};
