import React from "react";

import Error403Svg from "assets/img/403.svg";
import ErrorPage from "components/ErrorPage";

const Forbidden: React.FunctionComponent<{}> = () => (
  <ErrorPage
    image={<img src={Error403Svg} alt="" />}
    message={"Sorry, you don't have permission to access the requested page."}
    data-qa="403-error"
  />
);

export default Forbidden;
