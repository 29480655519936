import axios from "axios";
import config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { handleActions } from "redux-actions";
import { CREATE_PROJECT_REQUEST } from "state/create_project";

// Reducer Interface
export interface FileUploadState {
  readonly data?: FormData;
  readonly loading?: boolean;
  readonly hasError?: boolean;
  readonly errors?: string[];
  readonly content?: { fileList: string[] } | "OK";
}

export interface FileCheckUploadState {
  readonly loading?: boolean;
  readonly hasError?: boolean;
  readonly errors?: string[];
  readonly content?: any[];
}

// Actions Types
const UPLOAD_FILE_CHECK = (prefix: string) => `${prefix}_UPLOAD_FILE_CHECK`;
const UPLOAD_FILE_REQUEST = (prefix: string) => `${prefix}_UPLOAD_FILE_REQUEST`;
const UPLOAD_FILE = (prefix: string) => `${prefix}_UPLOAD_FILE`;
const UPLOAD_FILE_CHECK_SUCCEEDED = (prefix: string) => `${prefix}_UPLOAD_FILE_CHECK_SUCCEEDED`;
const UPLOAD_FILE_CHECK_FAILED = (prefix: string) => `${prefix}_UPLOAD_FILE_CHECK_FAILED`;
const UPLOAD_FILE_FAILED = (prefix: string) => `${prefix}_UPLOAD_FILE_FAILED`;
const UNSET_ERROR = (prefix: string) => `${prefix}_UNSET_ERROR`;
const RESET_UPLOAD = (prefix: string) => `${prefix}_RESET_UPLOAD`;

// Thunk Action Creator

const checkExistingFiles: ThunkActionCreator<Promise<void>> =
  (prefix: string, projectId: number, fileStatus?: string) => (dispatch: Dispatch) => {
    dispatch({
      type: UPLOAD_FILE_CHECK(prefix),
    });

    const url = `${config.API_SERVICE_URL}/api/upload-attachments/get-file-list`;
    const data = { projectId, fileStatus };
    return axios
      .post(url, data, getHeaders())
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_CHECK_SUCCEEDED(prefix),
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_FILE_CHECK_FAILED(prefix),
          payload: [error],
        });
      });
  };

const uploadFile =
  (
    prefix: string,
    uploadUrl: string,
    options?: { offset?: number, timezone?: string }
  ): ThunkActionCreator<Promise<void>> =>
  ({ data }: FileUploadState) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: UPLOAD_FILE_REQUEST(prefix),
    });

    let url = uploadUrl;
    if (options && options.offset) {
      url = `${url}?offset=${options.offset}`;
      if (options.timezone) {
        url = `${url}&timezone=${options.timezone}`
      }
    }
    return axios
      .post(url, data, getHeaders())
      .then((response) => {
        dispatch({ type: UPLOAD_FILE(prefix), payload: response.data });
      })
      .catch((error) => {
        if (typeof error === "object" && error && error.response && error.response.data) {
          const { errors = [] } = error.response.data;
          if (errors.length === 0) {
            errors.push("Server error. Please try again later.");
          }
          dispatch({ type: UPLOAD_FILE_FAILED(prefix), payload: errors });
        } else if (error instanceof Error && /Network Error/.test(error.message)) {
          dispatch({
            type: UPLOAD_FILE_FAILED(prefix),
            payload: ["Request Timed Out"],
          });
        } else {
          dispatch({
            type: UPLOAD_FILE_FAILED(prefix),
            payload: ["Unknown Error while processing Request"],
          });
        }
      });
  };

const unsetError = (prefix: string) => (dispatch: Dispatch) => {
  dispatch({ type: UNSET_ERROR(prefix) });
};

const resetUpload = (prefix: string) => (dispatch: Dispatch) => {
  dispatch({ type: RESET_UPLOAD(prefix) });
};

const fileCheckFailed = (prefix: string, error: string) => (dispatch: Dispatch) => {
  dispatch({ type: UPLOAD_FILE_CHECK_FAILED(prefix), payload: [error] });
};

export { unsetError, fileCheckFailed, checkExistingFiles, uploadFile, resetUpload };

const createFileUploadReducer = (prefix: string) => {
  // Actions
  const actions = {
    [CREATE_PROJECT_REQUEST]: () => ({ ...initialState }),
    [RESET_UPLOAD(prefix)]: () => ({
      content: [],
      loading: false,
      hasError: false,
      errors: [],
    }),
    [UNSET_ERROR(prefix)]: () => ({ ...initialState }),
    [UPLOAD_FILE_REQUEST(prefix)]: () => ({ loading: true }),
    [UPLOAD_FILE_CHECK(prefix)]: () => ({ loading: true }),
    [UPLOAD_FILE_CHECK_SUCCEEDED(prefix)]: (state: FileUploadState, action: AnyAction) => ({
      content: action.payload,
      loading: false,
      hasError: false,
      errors: [],
    }),
    [UPLOAD_FILE_CHECK_FAILED(prefix)]: (state: FileUploadState, action: AnyAction) => ({
      loading: false,
      hasError: true,
      errors: action.payload,
    }),
    [UPLOAD_FILE_REQUEST(prefix)]: () => ({ loading: true }),
    [UPLOAD_FILE(prefix)]: (state: FileUploadState, action: AnyAction): FileUploadState => ({
      content: action.payload,
      loading: false,
      hasError: false,
      errors: [],
    }),
    [UPLOAD_FILE_FAILED(prefix)]: (state: FileUploadState, action: AnyAction) => ({
      loading: false,
      hasError: true,
      errors: action.payload,
    }),
  };

  // Initial State
  const initialState: FileUploadState = {
    loading: false,
    hasError: false,
    errors: [],
  };

  // Reducer
  return handleActions<FileUploadState, AnyAction>(actions, initialState);
};

export default createFileUploadReducer;
