import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT = "CREATE_PROJECT";
const CREATE_PROJECT_FAILED = "CREATE_PROJECT_FAILED";

// Thunk Action Creator
const createProject: ThunkActionCreator<Promise<void>> =
  (data: CreateProject) => (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_PROJECT_REQUEST,
    });

    return axios
      .post(
        `${Config.API_SERVICE_URL}/api/projects`,
        data,
        getHeaders({
          "Content-Type": "application/json",
        })
      )
      .then((response) => {
        dispatch({ type: CREATE_PROJECT, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: CREATE_PROJECT_FAILED });
      });
  };

export { createProject };

// Actions
const actions = {
  [CREATE_PROJECT_REQUEST]: (state: CreateProjectState) => ({
    ...state,
    loading: true,
  }),
  [CREATE_PROJECT]: (state: CreateProjectState, action: AnyAction): CreateProjectState => ({
    ...state,
    loading: false,
    content: { ...action.payload },
  }),
  [CREATE_PROJECT_FAILED]: (state: CreateProjectState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
interface CreateProject {
  readonly id?: number;
  readonly name: string;
}

export interface CreateProjectState {
  error?: boolean;
  loading?: boolean;
  content?: CreateProject;
}

// Initial State
const initialState: CreateProjectState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<CreateProjectState, AnyAction>(actions, initialState);
