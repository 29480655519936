import cx from "classnames";
import React from "react";
import Popover from "react-popover";

import deleteTrashCan from "assets/img/delete-trash-can.svg";
import Button from "components/Button";
import Icon from "components/Icon";
import { Omit } from "types/omit";

interface DeletePopoverProps extends Omit<Popover.PopoverProps, "body"> {
  buttonStyles?: React.CSSProperties;
  onConfirm: (id: number) => Promise<any>;
  id: number;
  index: number;
  message?: string;
  hidden?: boolean;
  isMobile?: boolean;
}

interface DeletePopoverState {
  popoverOpenId: number | null;
}

class DeletePopover extends React.Component<DeletePopoverProps, DeletePopoverState> {
  public state = { popoverOpenId: null };

  public togglePopoverOpenId(id: number) {
    const { popoverOpenId } = this.state;

    this.setState({
      popoverOpenId: popoverOpenId === null ? id : null,
    });
  }

  public render() {
    const {
      onConfirm,
      index,
      id,
      message = "Delete Entity",
      hidden = false,
      isMobile,
      buttonStyles = {},
    } = this.props;

    return (
      <Popover
        body={
          <div
            style={{
              display: "flex-inline",
            }}
          >
            <p>{message}?</p>
            <Button
              data-qa="confirm-delete-button"
              warning={true}
              onClick={() => onConfirm(id).then(() => this.togglePopoverOpenId(id))}
            >
              DELETE
            </Button>
            <Button
              data-qa="cancel-delete-button"
              secondary={true}
              onClick={() => this.togglePopoverOpenId(index)}
            >
              CANCEL
            </Button>
          </div>
        }
        isOpen={this.state.popoverOpenId === index}
        onOuterAction={() => this.togglePopoverOpenId(index)}
        preferPlace="left"
      >
        {!hidden && (
          <button
            style={{ ...buttonStyles, outline: "none" }}
            className={cx(isMobile ? "mobile-table-button delete" : "delete-comment-button")}
            data-qa="delete-button"
            onClick={(e) => this.togglePopoverOpenId(index)}
          >
            {isMobile ? "Delete" : ""}
            <Icon medium={true} src={deleteTrashCan} />
          </button>
        )}
      </Popover>
    );
  }
}

export default DeletePopover;
