import React from "react";
import { connect, ConnectedProps } from "react-redux";

import Dropdown from "components/Dropdown";
import { State } from "state";
import { fetchBasicInfoChains } from "state/chains";

export interface FilterOptions {
  id: number;
  name: string;
}

type ChainFilterProps = ChainFilterOwnProps & PropsFromRedux;

interface ChainFilterOwnProps {
  dataQa?: string;
  onChange: (options: FilterOptions) => any;
  isSearchable?: boolean;
}

interface ChainFilterState {
  currentId: number;
}

class ChainFilter extends React.Component<ChainFilterProps, ChainFilterState> {
  public state = { currentId: -999 };

  public componentDidMount() {
    this.props.fetchBasicInfoChains();
  }

  public render() {
    const { chains, onChange, isSearchable } = this.props;
    const { currentId } = this.state;

    if (!chains) {
      return null;
    }

    const sortedChains = chains.sort((a, b) => a.name.localeCompare(b.name));

    const dropdownOptions = [{ value: -1, label: "Select Chain" }].concat(
      sortedChains.map((chain: any) => {
        const chainName = chain.name;
        return {
          "data-qa": chainName,
          value: chain.id,
          label: chainName,
        };
      })
    );

    return (
      <Dropdown
        dataQa={this.props.dataQa}
        value={dropdownOptions.find((option) => option.value === currentId)}
        containerStyles={{
          marginLeft: "unset",
          background: "white",
          fontWeight: "normal",
        }}
        options={dropdownOptions}
        onChange={(selected: any) => {
          this.setState({ currentId: selected.value }, () => {
            if (onChange) {
              onChange({
                id: this.state.currentId,
                name: selected.label,
              });
            }
          });
        }}
        placeholder="Select Chain"
        isSearchable={isSearchable}
      />
    );
  }
}

const connecter = connect(
  (state: State) => ({
    chains: state.chains.content,
  }),
  { fetchBasicInfoChains }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ChainFilter);
