import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { setItem } from "helpers/storage";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { State } from "state";

// Actions Types
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER = "FETCH_USER";
const FETCH_USER_FAILED = "FETCH_USER_FAILED";

export class UserAuthenticationError extends Error {

  constructor() {
    super("There is an issue with the current user session");

    this.name = "UserAuthenticationError";
  }
}

// Action Creators
const hydrateUser: ThunkActionCreator<Promise<boolean>> =
  () => (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    if (state.auth.content && state.auth_user && !state.auth_user.content) {
      return dispatch(fetchUser());
    }
    return Promise.resolve(false);
  };

const fetchUser: ThunkActionCreator<Promise<boolean>> = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/users/me`, getHeaders())
    .then((response) => {
      dispatch({ type: FETCH_USER, payload: response.data });
      setItem("user", response.data);
    })
    .then(_ => true)
    .catch((error) => {
      dispatch({ type: FETCH_USER_FAILED });
      throw error
    });
};

export { fetchUser, hydrateUser };

// Actions
const actions = {
  [FETCH_USER_REQUEST]: (state: AuthUserState): AuthUserState => ({
    ...state,
    loading: true,
  }),
  [FETCH_USER]: (state: AuthUserState, action: AnyAction) => ({
    ...state,
    loading: false,
    content: { ...action.payload },
  }),
  [FETCH_USER_FAILED]: (state: AuthUserState): AuthUserState => ({
    loading: false,
    error: true,
  }),
};

// Reducer Interface
export interface AuthUser {
  readonly id: number;
  readonly role: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly username: string;
  readonly created_at: string;
  readonly user_level_permissions: string[];
  readonly company?: string;
  readonly employer?: string;
}

export interface AuthUserState {
  readonly error?: boolean;
  readonly loading?: boolean;
  readonly content?: AuthUser;
}

// Initial State
const initialState: AuthUserState = {};

// Reducer
export default handleActions<AuthUserState, AnyAction>(actions, initialState);
