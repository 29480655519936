import Constants from "./constants";

export type Season =
| Constants.SEASON_SPRING_LABEL
| Constants.SEASON_SUMMER_LABEL
| Constants.SEASON_FALL_LABEL
| Constants.SEASON_WINTER_LABEL
| Constants.SEASON_SPECIAL_PROJECT_LABEL;

export type SeasonID =
  | Constants.SEASON_SPRING
  | Constants.SEASON_SUMMER
  | Constants.SEASON_FALL
  | Constants.SEASON_WINTER
  | Constants.SEASON_SPECIAL_PROJECT;

export const seasons: { [key in SeasonID]: Season } = {
  [Constants.SEASON_SPRING]: Constants.SEASON_SPRING_LABEL,
  [Constants.SEASON_SUMMER]: Constants.SEASON_SUMMER_LABEL,
  [Constants.SEASON_FALL]: Constants.SEASON_FALL_LABEL,
  [Constants.SEASON_WINTER]: Constants.SEASON_WINTER_LABEL,
  [Constants.SEASON_SPECIAL_PROJECT]: Constants.SEASON_SPECIAL_PROJECT_LABEL,
};
