import moment from "moment";

import React from 'react';
import {Card, CardList} from 'components/Card'
import Base64Logo from "components/Base64Logo";
import Link from "components/Link";
import {StarToggle} from "components/StarToggle";
import Table from "components/Table";
import { Body } from "components/Typography";
import { getPillProps, Pill } from "components/Pill";

import { Project } from "interfaces/project";
import Constants from 'helpers/constants';

export interface MyWorkCardProps extends React.HTMLAttributes<{}> {
    project: Project,
    isStarred: boolean,
    isMobile: boolean,
    history: any,
    clickHandler: Function,
}

export const MyWorkCard: React.FunctionComponent<MyWorkCardProps> = ({
    project,
    isStarred,
    isMobile,
    history,
    clickHandler,
}) => {
    const projectDetailUrl = `/projects/${project.project_id}`;
    const containsStores = project.user_store_count;
    const containsMultipleStores = project.user_store_count && project.user_store_count > 1;
    const isFileSharing = project.project_type === Constants.FILE_SHARING;
    const pillProps = isMobile
        ? getPillProps(Constants.PILL_MOBILE_FILE_SHARING)
        : getPillProps(Constants.PILL_FILE_SHARING);

    return (
        <Card>
            <Card.Header>
                <Base64Logo data={project.chain && project.chain.logo} logo_filename={project.chain && project.chain.logo_filename} />
                {isFileSharing && <Pill style={{ marginLeft: 20, marginRight: -10 }} { ...pillProps }/>}
                <Link
                    className="h2"
                    to={projectDetailUrl}
                    data-qa={`list-link-to-project-${project.project_id}`}
                    style={isMobile ? { marginLeft: 20 } : { marginLeft: 20, marginRight: 30 }}
                >
                    {project.project_name}
                </Link>
                <time dateTime={project.est_complete_date}>
                    <span className="est-complete-date">
                    {project.est_complete_date && moment(project.est_complete_date).format("M/D/YYYY")}
                    </span>
                </time>
                <StarToggle
                  clickHandler={(starred: any) => clickHandler(starred)}
                  isActive={isStarred}
                />
            </Card.Header>
            <div
                className="my-work-table-controls"
                data-qa={`my-work-table-project-${project.project_id}`}
            >
                <Table
                    data={project.stores as object[]}
                    emptyState={"Upload a project file to see the mapping preview"}
                    onRowClick={(row: any) => {
                      history.push(`/projects/${project.project_id}/stores/${row.store_id}`);
                    }}
                    columns={[
                        {
                          field: "store_number",
                          header: "Store No",
                          dataQa: "store_number",
                          sortable: false,
                          minWidth: 80,
                        },
                        {
                          field: "store_name",
                          header: "Store Name",
                          dataQa: "store_name",
                          sortable: false,
                          minWidth: 150,
                        },
                        {
                          field: "store_address",
                          header: "Address",
                          dataQa: "address",
                          sortable: false,
                          show: !isMobile,
                        },
                        {
                          field: "store_status",
                          header: "Status",
                          show: !isMobile,
                          formatter: (value) => {
                            const pill = getPillProps(value, project.project_type);
                            return <Pill {...pill} />;
                          },
                          dataQa: "store_status",
                          sortable: false,
                        },
                        {
                          field: "store_deadline",
                          header: "Deadline Date",
                          show: !isFileSharing && !isMobile,
                          formatter: (value) => {
                            const deadline = moment(value);

                            if (!deadline.isValid()) {
                              return "--";
                            }

                            return deadline.format("M/D/YYYY");
                          },
                          dataQa: "validation_deadline",
                          sortable: false,
                        },
                        {
                          field: "store_deadline",
                          header: "Days Left",
                          show: !isFileSharing,
                          formatter: (value) => {
                            const diff = moment(value).diff(moment().format(), "days");

                            if (Number.isNaN(diff)) {
                              return "--";
                            }

                            return diff.toString();
                          },

                          dataQa: "days_left",
                          minWidth: 90,
                          maxWidth: 100,
                          textAlign: isMobile ? "center" : "",
                        },
                    ]}
                />
            </div>
            {
                containsStores &&
                <Card.Footer>
                    <Link className="link" to={projectDetailUrl}>
                        <Body type="4" className="showall-link" data-qa="showall-link">
                            {
                                containsMultipleStores
                                ? `Show All ${project.user_store_count} Stores`
                                : `Show ${project.user_store_count} Store`
                            }
                        </Body>
                    </Link>
                </Card.Footer>
            }
        </Card>
    )
}
