import cx from "classnames";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Body } from "components/Typography";
import { State } from "state";
import { UserRole } from "types/roles";
import * as Routes from "routes";
import Constants from "helpers/constants";
import Hamburger from "components/Hamburger";
import Link from "components/Link";
import remove from "lodash/remove";

type Subroute = RegExp | string;
interface Route {
  title: string;
  route: string;
  qa: string;
  landingPageFor?: string[];
  inactiveImage?: string;
  activeImage?: string;
  subroutes?: Subroute[];
  disabled?: boolean;
  mobileOnly?: boolean;
  showFeature?: boolean;
}

interface NavGroup {
  name: string;
  bottom?: boolean;
  routes: Route[];
}

export const groups: { [key in UserRole]: NavGroup[] } = {
  [Constants.AB_SYSTEM_ADMIN]: [
    {
      name: "",
      routes: (
        [
          Routes.ADMIN_ANNOUNCEMENTS,
          Routes.DOWNLOADS,
          Routes.DIR_DOWNLOADS,
          Routes.UPLOAD_ATTACHMENTS,
          Routes.MY_STORES,
          Routes.NEARBY_STORES,
          Routes.ADMIN_MY_SURVEYS,
          Routes.VIEW_ALL_ADMIN_DATA,
        ] as unknown as Route[]
      ).filter((r) => r), // remove routes suppressed by feature flag
    },
    {
      name: "Reports",
      routes: [
        Routes.ADMIN_MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_STATUS,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.AB_PROJECT_ADMIN]: [
    {
      name: "",
      routes: [
        Routes.ADMIN_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.UPLOAD_ATTACHMENTS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
        Routes.ADMIN_MY_SURVEYS,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [
        Routes.MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.MY_FEEDBACK,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_STATUS,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.AB_COLLABORATOR]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
        Routes.MY_SURVEYS,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [
        Routes.MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.MY_FEEDBACK,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_STATUS,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.AB_MERCHANDISER]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
        Routes.MY_SURVEYS,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [
        Routes.MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.MY_FEEDBACK,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_STATUS,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.AB_REGION_CAT_DIRECTOR]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
        Routes.ADMIN_MY_SURVEYS,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [
        Routes.MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.MY_FEEDBACK,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_STATUS,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.AB_WHOLESALER]: [
    {
      name: "",
      routes: (
        [
          Routes.MY_ANNOUNCEMENTS,
          Routes.DOWNLOADS,
          Routes.DIR_DOWNLOADS,
          Routes.MY_STORES,
          Routes.NEARBY_STORES,
          Routes.MY_SURVEYS,
        ] as unknown as Route[]
      ).filter((r) => r), // remove routes suppressed by feature flag
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [Routes.MY_WORK, Routes.STARRED, Routes.MY_FEEDBACK, Routes.RESET_STATUS],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.AB_VIEWER]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [
        Routes.MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.RETAILER]: [
    {
      name: "",
      routes: [
        Routes.ADMIN_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
        Routes.MY_SURVEYS,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [
        Routes.MY_WORK,
        Routes.STARRED,
        Routes.VALIDATION_COMPLETION,
        Routes.REVIEW_FEEDBACK,
        Routes.RESET_STATUS,
        Routes.RESET_VALIDATION,
      ],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.VIEWER]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [Routes.MY_WORK, Routes.STARRED, Routes.RESET_STATUS],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.COMPETITOR_CSM]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES,
        Routes.ADMIN_MY_SURVEYS,
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [Routes.MY_WORK, Routes.STARRED, Routes.MY_FEEDBACK],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
  [Constants.COMPETITOR_WHOLESALER]: [
    {
      name: "",
      routes: [
        Routes.MY_ANNOUNCEMENTS,
        Routes.DOWNLOADS,
        Routes.DIR_DOWNLOADS,
        Routes.MY_STORES,
        Routes.NEARBY_STORES
      ],
    },
    {
      name: String.fromCharCode(160), // &nbsp; to separate groups
      routes: [Routes.MY_WORK, Routes.STARRED, Routes.MY_FEEDBACK],
    },
    {
      name: "",
      bottom: true,
      routes: [Routes.SUPPORT, Routes.LOGOUT],
    },
  ],
};

type NavigationProps = PropsFromRedux;
type INavigationState = { activeRoute: string; toggled: boolean };
class Navigation extends React.Component<NavigationProps, INavigationState> {
  public state = { activeRoute: "/", toggled: false };

  public componentDidMount() {
    this.setState({ activeRoute: window.location.pathname });
  }

  public handleClick = (activeRoute: string) => {
    this.setState({ activeRoute });
  };

  public handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState((prevState) => ({ toggled: !prevState.toggled }));
  };

  public render() {
    const { activeRoute, toggled } = this.state;
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return (
      <nav className={cx("navigation", { toggled })} data-qa="navigation">
        <Hamburger toggled={toggled} onToggle={this.handleToggle} />
        <ul className="navigation-links">
          {groups[user.role as UserRole].map((group, idx) => {
            const { name, routes, bottom = false } = group;
            let allowedRoutes = [...routes];
            // Remove user-level-permission-gated routes
            if (
              !Array.isArray(user.user_level_permissions) ||
              (!user.user_level_permissions.includes("access_DIR") &&
                user.role !== Constants.AB_SYSTEM_ADMIN)
            ) {
              remove(allowedRoutes, (route) => route === Routes.DIR_DOWNLOADS);
            }

            return (
              <li
                key={`group-${idx}-${name}-${bottom}`}
                className={cx("navigation-group", { bottom })}
              >
                <h2 className="navigation-group-name h6">{name}</h2>
                <ul className="navigation-group-links">
                  {allowedRoutes.map(
                    ({
                      title,
                      route,
                      subroutes = [],
                      qa,
                      inactiveImage,
                      activeImage,
                      mobileOnly = false,
                      disabled,
                    }) => {
                      const active =
                        route === activeRoute ||
                        subroutes.some((sr) =>
                          sr instanceof RegExp ? sr.test(activeRoute) : sr === activeRoute
                        );

                      const navItemClass = cx("navigation-item", {
                        active,
                        "mobile-only": mobileOnly,
                      });

                      return (
                        <li key={title} className={navItemClass}>
                          <Body type="3" className="navigation-link-container">
                            <Link
                              className="navigation-link"
                              onClick={(e) => {
                                disabled ? e.preventDefault() : this.handleClick(route);
                              }}
                              to={route}
                              data-qa={qa}
                            >
                              <img
                                className="navigation-image"
                                src={active ? activeImage : inactiveImage}
                                alt=""
                              />
                              {title}
                            </Link>
                          </Body>
                        </li>
                      );
                    }
                  )}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

const connecter = connect((state: State) => ({
  user: state.auth_user.content,
}));

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(Navigation);
