import find from "lodash/find";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";

import { RouteProps } from "containers/App";
import loadingSvg from "assets/img/loading.svg";
import successSvg from "assets/img/success.svg";
import { Card, CardList } from "components/Card";
import Dropdown from "components/Dropdown";
import Page from "components/Page";
import FileUpload from "containers/FileUpload";
import ProjectFilter from "containers/ProjectFilter";
import config from "config";
import { Dispatch } from "interfaces/dispatch";
import { ReactSelectObject } from "interfaces/select";
import {
  Status,
  AnalyticsEvent,
  createAnalyticsEvent,
  EventTypes,
  FileType,
  FileStatus,
} from "state/analytics";
import { checkExistingFiles, fileCheckFailed, resetUpload } from "state/create_file_upload";
import { ProjectType } from "interfaces/project";
import { AuthUser } from "state/auth_user";

// interface UploadAttachmentsDispatchProps {
//   hasError?: boolean;
//   loading?: boolean;
//   content?: any;
//   errors?: any[];
//   createAnalyticsEvent: (event: AnalyticsEvent) => void;
//   checkExistingFiles: (prefix: string, projectId: number, fileStatus?: string) => Promise<void>;
//   fileCheckFailed: (prefix: string, error: string) => void;
//   resetUpload: (prefix: string) => void;
// }
type UploadAttachmentsLocalProps = {
  user?: AuthUser;
}

type UploadAttachmentsProps = PropsFromRedux & RouteProps & UploadAttachmentsLocalProps;

interface UploadAttachmentsState {
  currentId: number;
  currentType: string;
  currentName: string;
  fileType: ReactSelectObject;
  fileStatus: ReactSelectObject;
  lastFileTypeUploaded?: string;
  analytics?: {
    attachmentsCount: number;
    fileExtensionList: string[];
    overwrite: boolean;
    projectName: string;
    projectType: string;
    fileStatus: string;
    fileType: string;
  };
}

const acceptedStoreFileTypes = ".pdf, .psa";
const acceptedProjectFileTypes =
  ".pdf, .xlsx, .xlsm, .doc, .docx, .dotx, .docm, .ppt, .pps, .pptx, .pptm, .sldx, .sldm";

const fileTypeOptions = [
  {
    value: "-1",
    label: "Select Store or Project File",
    "data-qa": "select-type",
  },
  { label: "Store", value: "store", "data-qa": "store" },
  { label: "Project", value: "project", "data-qa": "project" },
];

const fileStatusOptions = [
  { value: "-1", label: "Select File Status", "data-qa": "select-status" },
  { label: "Original", value: "ORIGINAL", "data-qa": "original" },
  { label: "Final", value: "FINAL", "data-qa": "final" },
];


const defaultState: UploadAttachmentsState = {
  currentId: -1,
  currentType: "",
  currentName: "",
  fileType: fileTypeOptions[0],
  fileStatus: fileStatusOptions[0],
  lastFileTypeUploaded: "",
};

class UploadAttachments extends React.Component<UploadAttachmentsProps, UploadAttachmentsState> {
  public state = defaultState;

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  public componentWillUnmount() {
    this.props.resetUpload("ATTACHMENT");
  }

  public checkFileList = (
    prefix: string,
    projectId: number,
    fileStatus: string,
    uploadedFiles: FileList
  ): Promise<any> => {
    const { currentName, currentType } = this.state;
    const totalFileSize = Array.from(uploadedFiles).reduce((total, file) => total + file.size, 0);

    if (totalFileSize > parseInt(config.FILE_UPLOAD_SIZE_LIMIT)) {
      this.props.fileCheckFailed(
        prefix,
        `Please select ${config.FILE_UPLOAD_SIZE_LIMIT.slice(0, -6)}mb or less of total files.`
      );
      return new Promise(() => {
        return { continue: false };
      });
    }

    return this.props.checkExistingFiles(prefix, projectId, fileStatus).then(() => {
      const filesToBeOverwritten: string[] = [];
      const fileType = this.state.fileType.value;
      if (this.props.content && typeof this.props.content !== "string") {
        this.props.content.fileList.forEach((savedFile: any) => {
          const savedFileName = savedFile.split("/").splice(-1, 1)[0];
          const existingFile = find(uploadedFiles, (file) => {
            return file.name === savedFileName;
          });
          if (existingFile) {
            filesToBeOverwritten.push(existingFile.name);
          }
        });
      }

      this.setState({
        analytics: {
          attachmentsCount: uploadedFiles.length,
          fileExtensionList: Array.from(uploadedFiles).map(({ name }: File) =>
            name.slice((Math.max(0, name.lastIndexOf(".")) || Infinity) + 1)
          ),
          overwrite: filesToBeOverwritten.length > 0,
          projectName: currentName,
          projectType: currentType,
          fileStatus: fileStatus,
          fileType: this.state.fileType.label,
        },
      });

      if (filesToBeOverwritten.length > 0) {
        return this.confirmUpload(filesToBeOverwritten)
          .then((result) => {
            this.setState({
              ...defaultState,
              lastFileTypeUploaded: fileType,
            });
            return { continue: true };
          })
          .catch((result) => {
            this.setState({
              ...defaultState,
              lastFileTypeUploaded: fileType,
            });
            return { continue: false };
          });
      }
      this.setState({
        ...defaultState,
        lastFileTypeUploaded: fileType,
      });
      return { continue: true };
    });
  };

  public confirmUpload = (filesToBeOverwritten: any[]) => {
    return new Promise((resolve, reject) => {
      const numFiles = filesToBeOverwritten.length;
      if (
        window.confirm(`You are about to overwrite ${numFiles} file${numFiles > 1 ? "s" : ""}.`)
      ) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  };

  public renderUploadStatus() {
    if (this.props.loading) {
      return <img className="spin-right" src={loadingSvg} alt="Loading Icon" />;
    } else if (this.props.hasError) {
      return (
        <span className="download-error error">
          Upload Error{this.props.errors ? `: ${this.props.errors[0]}` : ""}
        </span>
      );
    } else if (this.props.content === "OK") {
      return (
        <React.Fragment>
          <div className="upload-success-message">
            <img src={successSvg} alt="Sucess Icon" /> <div>Successfully uploaded files. </div>
          </div>
          {this.state.lastFileTypeUploaded === "store" && (
            <div style={{ marginLeft: "2em" }}>
              Warning: new stores in the project will require the System Admin to update user to
              store mapping in order to show new stores in the project.
            </div>
          )}
        </React.Fragment>
      );
    }
    return;
  }

  public render() {
    const { currentId, fileStatus, fileType } = this.state;
    const isDisabled =
      currentId === -1 ||
      fileType.value === "-1" ||
      (fileType.value === "store" && fileStatus.value === "-1");

    return (
      <Page>
        <h1>Upload Attachments</h1>
        <CardList>
          <Card>
            <Card.Header>
              <div className="downloads-header">
                <div data-qa="select-project">
                  <ProjectFilter
                    onChange={({ id, type, name }) => {
                      this.setState({
                        currentId: id,
                        currentName: name,
                        currentType: type,
                      });
                    }}
                    value={this.state.currentId}
                    isSearchable
                  />
                </div>
                <div data-qa="select-file-type">
                  <Dropdown
                    containerStyles={{
                      width: 300,
                      height: 34,
                      minWidth: 230,
                      marginLeft: 10,
                    }}
                    placeholder="Select File Status"
                    isSearchable
                    options={fileTypeOptions}
                    onChange={(selectedFileType: ReactSelectObject) => {
                      this.setState({ fileType: selectedFileType });
                    }}
                    value={this.state.fileType}
                  />
                </div>
                {fileType && fileType.value === "store" && (
                  <div data-qa="select-file-status">
                    <Dropdown
                      containerStyles={{
                        width: 300,
                        height: 34,
                        minWidth: 230,
                        marginLeft: 10,
                      }}
                      placeholder="Select File Status"
                      options={fileStatusOptions}
                      isSearchable
                      onChange={(selectedFileStatus: ReactSelectObject) => {
                        this.setState({ fileStatus: selectedFileStatus });
                      }}
                      value={this.state.fileStatus}
                    />
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              {!this.props.loading && (
                <FileUpload
                  disabled={isDisabled}
                  basicImport={true}
                  prefix={"ATTACHMENT"}
                  beforeUploadFunction={this.checkFileList}
                  afterUploadFunction={() => {
                    const { hasError } = this.props;
                    const { analytics } = this.state;
                    if (analytics) {
                      if (analytics.fileType === FileType.PROJECT) {
                        this.props.createAnalyticsEvent({
                          event: EventTypes.UPLOAD_ATTACHMENTS,
                          eventType: "Action",
                          projectName: analytics.projectName,
                          projectType: analytics.projectType as ProjectType,
                          uploadAttachmentCount: analytics.attachmentsCount,
                          uploadAttachmentFileExtensionList: analytics.fileExtensionList,
                          uploadAttachmentStatus: hasError ? Status.FAILURE : Status.SUCCESS,
                          uploadAttachmentOverwrite: analytics.overwrite,
                          uploadAttachmentFileType: FileType.PROJECT,
                        });
                      } else {
                        this.props.createAnalyticsEvent({
                          event: EventTypes.UPLOAD_ATTACHMENTS,
                          eventType: "Action",
                          projectName: analytics.projectName,
                          projectType: analytics.projectType as ProjectType,
                          uploadAttachmentCount: analytics.attachmentsCount,
                          uploadAttachmentFileExtensionList: analytics.fileExtensionList,
                          uploadAttachmentStatus: hasError ? Status.FAILURE : Status.SUCCESS,
                          uploadAttachmentOverwrite: analytics.overwrite,
                          uploadAttachmentFileType: FileType.STORE,
                          uploadAttachmentFileStatus: analytics.fileStatus as FileStatus,
                        });
                      }
                    }
                  }}
                  url={`${config.API_SERVICE_URL}/api/upload-attachments`}
                  label={`Click here to upload files. ${config.FILE_UPLOAD_SIZE_LIMIT.slice(
                    0,
                    -6
                  )}mb total file size limit.`}
                  multiple={true}
                  dataQa={"file-sharing-project-file-upload"}
                  projectId={currentId}
                  fileStatus={
                    fileStatus && fileStatus.value !== "-1" ? fileStatus.label : undefined
                  }
                  accept={
                    fileStatus.value !== "-1" ? acceptedStoreFileTypes : acceptedProjectFileTypes
                  }
                  user={this.props.user}
                />
              )}

              {this.renderUploadStatus()}
            </Card.Body>
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    hasError: state.ATTACHMENT_file_upload.hasError,
    loading: state.ATTACHMENT_file_upload.loading,
    content: state.ATTACHMENT_file_upload.content,
    errors: state.ATTACHMENT_file_upload.errors,
  }),
  (dispatch: Dispatch) => ({
    createAnalyticsEvent: (event: AnalyticsEvent) => dispatch(createAnalyticsEvent(event)),
    checkExistingFiles: (prefix: string, projectId: number, fileStatus?: string) =>
      dispatch(checkExistingFiles(prefix, projectId, fileStatus)),
    fileCheckFailed: (prefix: string, error: string) => dispatch(fileCheckFailed(prefix, error)),
    resetUpload: (prefix: string) => dispatch(resetUpload(prefix)),
  })
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(UploadAttachments);
