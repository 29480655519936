import moment from "moment";

import Constants from "helpers/constants";

export const buildExportFilename = (type: string, name?: string) =>
  `${name || "download"}--${moment().format(
    Constants.DATE_FORMAT__EXPORT_FILE__YYYY_MM_DD__HH_mm
  )}.${type}`;

export const allFieldsEmpty = (fields: object) => {
  // @ts-ignore
  return Object.keys(fields).every((field) => fields[field] === "");
};

export const anyFieldsEmpty = (fields: object) => {
  // @ts-ignore
  return !Object.keys(fields).every((field) => fields[field] !== "");
};

export const navigateToUrl = (url: string) => {
  window.open(url.startsWith("http://") || url.startsWith("https://") ? url : `http://${url}`);
};

export const MOBILE_WINDOW_WIDTH = 560;
export const DEFAULT_WINDOW_WIDTH = 900;

type CSVHeader =
  | "Upload Project File"
  | "Upload New Stores"
  | "Upload New Users"
  | "User to Store Mapping"
  | "Upload New Chains"
  | "Default";

type ICSVHeaders = {
  [key in CSVHeader]: Array<{ label: string; key?: string }>;
};

export const getTemplateHeaders = (uploadLabel: string) => {
  const CSV_HEADERS: ICSVHeaders = {
    "Upload Project File": [
      { label: "Chain Name", key: "chainName" },
      { label: "Project Name", key: "projectName" },
      { label: "Project Type", key: "projectType" },
      { label: "Timezone", key: "timezone" },
      { label: "Project Deadline", key: "projectDeadline" },
      { label: "Season", key: "season" },
      { label: "Project Year", key: "year" },
    ],
    "Upload New Stores": [
      { label: "Chain Name" },
      { label: "Retail Store Number" },
      { label: "Store Name" },
      { label: "Address Line 1" },
      { label: "Address Line 2" },
      { label: "City" },
      { label: "State" },
      { label: "Country" },
      { label: "Zip Code" },
      { label: "Status" },
      { label: "Cluster Store Group" },
      { label: "Ad Zone" },
      { label: "ABInBev Wholesaler" },
      { label: "Miller Wholesaler" },
      { label: "Coors Wholesaler" },
      { label: "Third Party Wholesaler" },
      { label: "Catalyst ID" },
    ],
    "Upload New Users": [
      { label: "Phone" },
      { label: "User Role" },
      { label: "Email Address" },
      { label: "First Name" },
      { label: "Last Name" },
      { label: "Company Representing" },
      { label: "Title" },
      { label: "Employer" },
      { label: "Region" },
      { label: "Wholesaler Numbers" },
      { label: "IRIS Access" },
      { label: "IRIS Email" },
    ],
    "User to Store Mapping": [
      { label: "Chain Name" },
      { label: "Retail Store Number" },
      { label: "Email Address" },
    ],
    "Upload New Chains": [
      { label: "Chain Name" },
      { label: "Channel" },
      { label: "Key Contact 1" },
      { label: "Key Contact 2" },
      { label: "Key Contact 3" },
      { label: "Feedback Limit" },
      { label: "Catalyst ID" },
      { label: "Chain Parent"},
    ],
    Default: [{ label: "No template found." }],
  };

  // @ts-ignore
  return CSV_HEADERS[uploadLabel] || CSV_HEADERS["Default"];
};

export const isIe =
  !!navigator.userAgent &&
  (navigator.userAgent.toLowerCase().indexOf("msie") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("trident") > -1);
