import { Card, CardList } from "components/Card";
import Popover from "components/DeletePopover";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import Page from "components/Page";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import ProjectFilter from "containers/ProjectFilter";
import Constants from "helpers/constants";
import { CreateSurvey } from "interfaces/survey";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createSurvey, deleteSurvey, fetchMySurveys } from "state/surveys";
import { trackPageView, PAGEVIEW_EVENTS } from "state/userEvents";
import { anyFieldsEmpty, navigateToUrl } from "utils/helpers";

type ManageSurveysProps = PropsFromRedux & RouteProps;

interface ManageSurveysState {
  survey: CreateSurvey;
  popoverOpenId: number | null;
  meta: { project_name: string; project_type: string };
}

const defaultState = {
  survey: { projectId: -1, name: "", url: "" },
  popoverOpenId: null,
  meta: {
    project_name: "",
    project_type: "",
  },
};

class ManageSurveys extends React.Component<ManageSurveysProps, ManageSurveysState> {
  state = defaultState;

  public componentDidMount() {
    this.props.fetchMySurveys();
  }

  public togglePopoverOpenId(id: number) {
    this.setState({ popoverOpenId: this.state.popoverOpenId ? null : id });
  }

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      survey,
      meta: { project_name, project_type },
    } = this.state;

    this.props
      .createSurvey(survey, {
        name: project_name,
        type: project_type,
        url: survey.url,
      })
      .then((_) => {
        if (!this.props.hasErrors) {
          this.props.fetchMySurveys();
        }
      });

    this.setState(defaultState);
  };

  public handleDelete = (
    id: number,
    { name, type, url }: { name: string; type: string; url: string }
  ) => {
    return this.props.deleteSurvey(id, {
      name,
      type,
      url,
    });
  };

  public renderForm() {
    const { isMobile } = this.props;

    const submitDisabled =
      this.state.survey.projectId === -1 ||
      isEmpty(this.state.survey) ||
      anyFieldsEmpty(this.state.survey);

    return (
      <CardList>
        <Card>
          <Card.Body className="survey-form">
            <Form onSubmit={this.handleSubmit}>
              <div className="title-url-group">
                <Form.Input
                  type="text"
                  placeholder="Survey Title"
                  data-qa="survey-title-field"
                  className="survey-title"
                  value={this.state.survey.name}
                  onChange={(event) =>
                    this.setState({
                      survey: {
                        ...this.state.survey,
                        name: event.currentTarget.value,
                      },
                    })
                  }
                />
                <div data-qa="select-project">
                  <ProjectFilter
                    value={this.state.survey.projectId}
                    minWidth={isMobile ? 250 : 345}
                    minHeight={40}
                    noShadow={true}
                    onChange={({ id, name, type }) => {
                      this.setState({
                        survey: {
                          projectId: id,
                          name: this.state.survey.name,
                          url: this.state.survey.url,
                        },
                        meta: {
                          project_name: name,
                          project_type: type,
                        },
                      });
                    }}
                    isSearchable
                  />
                </div>
              </div>
              <Form.Input
                type="text"
                placeholder="Survey Link"
                data-qa="survey-url-field"
                className="survey-url"
                value={this.state.survey.url}
                onChange={(event) =>
                  this.setState({
                    survey: {
                      ...this.state.survey,
                      url: event.currentTarget.value,
                    },
                  })
                }
              />
              <Form.ButtonGroup>
                <Form.ConfirmButton
                  disabled={submitDisabled}
                  data-qa="submit-survey"
                  className="submit-survey-button"
                >
                  Submit Survey
                </Form.ConfirmButton>
              </Form.ButtonGroup>
            </Form>
          </Card.Body>
        </Card>
      </CardList>
    );
  }

  public render() {
    const { isMobile, surveys = [], user } = this.props;

    return (
      <Page>
        <h1 data-qa="my-survey-header">My Surveys</h1>
        {this.renderForm()}
        <div hidden={!this.props.hasErrors}>
          <ErrorMessage errors={this.props.errors || []} />
        </div>
        <Card className="surveys__table">
          <Table
            isMobile={isMobile}
            data={surveys}
            columns={[
              {
                field: "chain_name",
                header: "Chain",
                dataQa: "chain_name",
                maxWidth: 150,
              },
              {
                field: "name",
                header: "Survey Name",
                dataQa: "survey_name",
                minWidth: 150,
              },
              {
                field: "project_name",
                header: "Project",
                dataQa: "project_name",
                minWidth: 200,
              },
              {
                field: "firstName",
                header: "Created By",
                dataQa: "created_by",
                formatter: (value: string, row: any) => `${value} ${row.lastName}`,
                minWidth: 150,
              },
              {
                field: "survey_url",
                header: "Survey Link",
                dataQa: "survey_url",
                minWidth: 300,
                maxWidth: 400,
                formatter: (value: string, row: any) => {
                  return (
                    <div
                      className="survey-table-truncate-text"
                      onClick={() => {
                        console.log(row);
                        
                        this.props.trackPageView({
                          event_type: PAGEVIEW_EVENTS.SURVEYS_PAGEVIEW,
                          user_id: user?.id,
                          survey_id: row.id,
                          store_id: null,
                          project_id: row.project_id,
                          timestamp: Date.now(),
                        });
                        navigateToUrl(value);
                      }}
                    >
                      {value}
                    </div>
                  );
                },
              },
              {
                field: "",
                header: "",
                sortable: false,
                dataQa: "delete-filemap",
                formatter: (row: any, index: number) => {
                  return (
                    <Popover
                      message={"Delete this survey"}
                      id={row.id}
                      index={index}
                      onConfirm={(id) =>
                        this.handleDelete(id, {
                          name: `${row.chain_name} - ${row.project_name}`,
                          type: row.project_type,
                          url: row.survey_url,
                        })
                      }
                      isMobile={isMobile}
                    />
                  );
                },
              },
            ]}
            emptyState="No surveys found"
          />
        </Card>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    user: state.auth_user.content,
    surveys: state.surveys.content,
    hasErrors: state.surveys.hasError,
    errors: state.surveys.errors,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  { fetchMySurveys, createSurvey, deleteSurvey, trackPageView }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageSurveys);
