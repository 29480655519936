import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";

import Button from "components/Button";
import CopyCommentsIcon from "assets/img/copy-comments.svg";
import Icon from "components/Icon";
import Dialog, { DialogIcon } from "components/Dialog";
import CopyCommentsTable from "components/CopyCommentsTable";
import { postComment } from "state/comment";
import { Project } from "interfaces/project";
import { Store } from "interfaces/store";
import actionsReasons, { reasons, ActionID, ReasonID } from "helpers/actions-reasons";

interface ButtonWithCopyCommentsProps {
  comment: any;
  stores: any;
  feedback_limit: number;
  projectId: number | string;
  project: Project;
  store: Store;
  id?: string;
  getDialogProps?: object;
}

interface SelectedDataProps {
  isSelectionInclusive: boolean;
  selectedIds: any[];
  deselectedIds: any[];
}

//Only need to write default values here. Remaining props will be passed via restProps
const ButtonWithCopyComments: React.FC<ButtonWithCopyCommentsProps> = ({
  comment,
  stores,
  feedback_limit,
  projectId,
  project,
  store,
  getDialogProps,
  ...restProps
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<SelectedDataProps>({
    isSelectionInclusive: true,
    selectedIds: [],
    deselectedIds: []
  });
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [totalRecordCount, setTotalRecordCount] = React.useState<number>(0);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    setSelectedCount(0)
  }, [open])

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handlePostComment = async () => {
    let selectedStores = selectedData.selectedIds;
    if (!selectedData.isSelectionInclusive) {
      // If isInclusive is false, add the current store id to array so it will not be updated
      selectedStores = [store.store_id, ...selectedData.deselectedIds]
    }
    try {
      await dispatch(postComment(
        {
          user_id: comment.user_id,
          store_id: selectedStores,
          project_id: comment.project_id,
          action_id: comment.action_id,
          reason_id: comment.reason_id,
          product_id: comment.product_id,
          product_sub_id: comment.product_sub_id,
          comment: comment.comment,
          inclusive: selectedData.isSelectionInclusive
        },
        {
          name: `${stores[0].chain_name} - ${comment.project_name}`,
          type: project.project_type,
          storeName: comment.stores_name,
          product_sub_name: comment.product_sub_name,
          product_name: comment.product_name,
          reason: comment.reason_id,
        }
      ))
      // handle dialog success message
      setShowSuccessDialog(true);
      //Close Copy Comments Modal
      setOpen(false);

      //TODO CommentsUI will need to refetch comment table after a successful post
    } catch (err) {
      // handle dialog error message
      setShowErrorDialog(true);
      console.log(err);
    }
  };

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  //The delay demonstrates to the user that "Try Again" is being proccessed even if it instantly fails
  async function handleTryAgain() {
    //Closes current Error Dialog
    setShowErrorDialog(false);
    await sleep(200);
    //Will re-render Error DIalog if it fails again
    handlePostComment();
  }

  const generateCommentCard = () => {
    return (
      <div className="button-with-copy-comments__comment-card">
        <p className="button-with-copy-comments__comment-primary" data-qa="copy-comment">
          {comment.comment ? '"' + comment.comment + '"' : "N/A"}
        </p>
        <div className="button-with-copy-comments__container">
          <div>
            <span className="button-with-copy-comments__comment-secondary bold">Reason: </span>
            <span className="button-with-copy-comments__comment-secondary" data-qa="copy-comment-reason">
              {comment.reason_id ? reasons[comment.reason_id as ReasonID] : "N/A"}
            </span>
          </div>
          <div>
            <span className="button-with-copy-comments__comment-secondary bold">{comment.product_sub_name ? "Products:" : "Product:"}</span>
            <span className="button-with-copy-comments__comment-secondary" data-qa="copy-comment-product">
              {comment.product_name ? comment.product_name : "N/A"} {comment.product_sub_name && "→ " + comment.product_sub_name}
            </span>
          </div>
          <div>
            <span className="button-with-copy-comments__comment-secondary bold">Action: </span>
            <span className="button-with-copy-comments__comment-secondary" data-qa="copy-comment-action">
              {comment.action_id ? actionsReasons[comment.action_id as ActionID].action : "N/A"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const generateTableComponent = () => {
    return (
      <CopyCommentsTable projectId={projectId} feedback_limit={feedback_limit} setSelectedData={setSelectedData} store={store} setSelectedCount={setSelectedCount} setTotalRecordCount={setTotalRecordCount}/>
    );
  };

  return (
    <>
      <Button
        className="icon-button"
        onClick={handleOpenDialog}
        style={{ padding: 0 }}
        data-qa="copy-comments-button"
        {...restProps}
      >
        <Icon small src={CopyCommentsIcon} />
      </Button>
      <Dialog
        open={open}
        setOpen={setOpen}
        maxWidth="lg"
        dialogTitleText="Copy Comment"
        primaryContent={generateCommentCard}
        secondaryContent={generateTableComponent}
        primaryButtonContent="Submit Comments"
        getPrimaryButtonProps={{ disabled: selectedCount === 0 }}
        removeSecondaryButton
        buttonRowContent={`${selectedCount}/${totalRecordCount} selected`}
        onPrimaryAction={handlePostComment}
        getDialogActionsProps={{ style: { marginBottom: "6%", justifyContent: "flex-end" } }}
        scroll="paper"
        closeDialogAfterPrimaryAction={false}
        {...getDialogProps}
      />

      <Dialog
        open={showSuccessDialog}
        setOpen={setShowSuccessDialog}
        onPrimaryAction={() => {
          setShowSuccessDialog(false);
        }}
        icon={DialogIcon.SUCCESS}
        primaryButtonContent="Back To Store"
        primaryContent={() => <></>}
        secondaryContent={() => {
          return (
            <div className="items-center">
              <p>Your comment was successfully copied</p>
            </div>
          );
        }}
        removeSecondaryButton
      />

      <Dialog
        open={showErrorDialog}
        setOpen={setShowErrorDialog}
        onPrimaryAction={handleTryAgain}
        icon={DialogIcon.ERROR}
        primaryContent={() => (
          <div className="dialog__error-text">
            <p>Error</p>
          </div>
        )}
        secondaryContent={() => {
          return (
            <div className="items-center">
              <p className="remove-margins"> The action you requested could not be completed.</p>
              <p className="remove-margins">Please try to submit the action again.</p>
            </div>
          );
        }}
        primaryButtonContent="Try Again"
        removeSecondaryButton
      />
    </>
  );
};

export default ButtonWithCopyComments;
