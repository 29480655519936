// fix for second button in the page
import React, { useRef } from "react";

// @ts-ignore
import { CSVLink } from "react-csv";
import Button from "./Button";

interface CSVDownloadProps extends React.HTMLAttributes<{}> {
  data: any;
  filename: string;
  disabled: boolean;
  headers: Array<{ label: string; key: string }>;
}

const CSVDownload3: React.FunctionComponent<CSVDownloadProps> = (props) => {
  const ref = useRef();
  const handleOnClick = () => {
    if (props.data.length > 0) {
      // @ts-ignore
      ref.current.link.click();
    }
  };

  return (<>
    <Button disabled={props.disabled} className="button secondary" onClick={handleOnClick}>
      EXPORT TABLE
    </Button>
    <CSVLink
      uFEFF={false}
      target="_self" // needed for download in Safari
      className={props.className}
      data={props.data}
      filename={props.filename}
      headers={props.headers}
      ref={ref}
    >
    </CSVLink>
  </>
  );
};

export default CSVDownload3;
