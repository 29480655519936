import React from "react";

import { LocationDescriptorObject } from "history";
import { Link as InternalLink, LinkProps as InternalLinkProps } from "react-router-dom";

interface LinkProps extends InternalLinkProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const combineParts = ({ pathname, search, hash }: LocationDescriptorObject<any>): string =>
  `${pathname || ""}${search || ""}${hash || ""}`;

const Link: React.FunctionComponent<LinkProps> = ({ children, to, ...other }) => {
  let internal = false;
  if (typeof to === "string") {
    internal = /^\/(?!\/)/.test(to) || to === "#";
  } else if (typeof to === "object" && to.pathname) {
    internal = /^\/(?!\/)/.test(to.pathname) || to.pathname === "#";
  }

  // Use react-router/Link for internal links, and <a> for others
  if (internal) {
    return (
      <InternalLink to={to} {...other}>
        {children}
      </InternalLink>
    );
  }

  return (
    <a
      href={typeof to === "string" ? to : combineParts(to as LocationDescriptorObject<any>)}
      {...other}
    >
      {children}
    </a>
  );
};

export default Link;
