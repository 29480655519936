import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { Project } from "interfaces/project";
import { ThunkActionCreator } from "interfaces/thunk";
import { State } from "state";
import { CREATE_PROJECT } from "state/create_project";

// Actions Types
const FETCH_MYPROJECTS_REQUEST = "FETCH_MYPROJECTS_REQUEST";
const FETCH_MYPROJECTS = "FETCH_MYPROJECTS";
const FETCH_MYPROJECTS_FAILED = "FETCH_MYPROJECTS_FAILED";

const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
const FETCH_PROJECTS = "FETCH_PROJECTS";
const FETCH_PROJECTS_FAILED = "FETCH_PROJECTS_FAILED";

const FETCH_PUBLISHED_PROJECTS_REQUEST = "FETCH_PUBLISHED_PROJECTS_REQUEST";
const FETCH_PUBLISHED_PROJECTS = "FETCH_PUBLISHED_PROJECTS";
const FETCH_PUBLISHED_PROJECTS_FAILED = "FETCH_PUBLISHED_PROJECTS_FAILED";

export {
  FETCH_MYPROJECTS,
  FETCH_MYPROJECTS_FAILED,
  FETCH_MYPROJECTS_REQUEST,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS,
  FETCH_PROJECTS_FAILED,
};

// Thunk Action Creator
const fetchMyProjects: ThunkActionCreator = (forceReload = false) => (dispatch: Dispatch, getState: () => State) => {
  const myProjectsState = getState().my_projects;

  // bailout if we already have this data in redux
  if (
    !forceReload &&
    myProjectsState &&
    myProjectsState.content &&
    Array.isArray(myProjectsState.content) &&
    myProjectsState.content.length > 0
  ) {
    return Promise.resolve();
  }

  dispatch({ type: FETCH_MYPROJECTS_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/projects/me`, getHeaders())
    .then((response) => dispatch({ type: FETCH_MYPROJECTS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: FETCH_MYPROJECTS_FAILED }));
};

const fetchAllProjects: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PROJECTS_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/projects`, getHeaders())
    .then((response) => dispatch({ type: FETCH_PROJECTS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: FETCH_PROJECTS_FAILED }));
};

const fetchAllPublishedProjects: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PUBLISHED_PROJECTS_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/projects?published=true`, getHeaders())
    .then((response) => dispatch({ type: FETCH_PUBLISHED_PROJECTS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: FETCH_PUBLISHED_PROJECTS_FAILED }));
};

export { fetchAllProjects, fetchAllPublishedProjects, fetchMyProjects };

// Actions
const actions = {
  [FETCH_MYPROJECTS_REQUEST]: (state: ProjectsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MYPROJECTS]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_MYPROJECTS_FAILED]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [FETCH_PROJECTS_REQUEST]: (state: ProjectsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_PROJECTS]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_PROJECTS_FAILED]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [FETCH_PUBLISHED_PROJECTS_REQUEST]: (state: ProjectsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_PUBLISHED_PROJECTS]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_PUBLISHED_PROJECTS_FAILED]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [CREATE_PROJECT]: (state: ProjectsState, action: AnyAction): ProjectsState => ({
    ...state,
    content: [...(state.content || []), action.payload],
    loading: false,
  }),
};

export interface ProjectsState {
  error?: boolean;
  loading?: boolean;
  content?: Project[];
}

// Initial State
const initialState: ProjectsState = {
  loading: false,
  error: false,
  content: [],
};

// Reducer
export default handleActions<ProjectsState, AnyAction>(actions, initialState);
