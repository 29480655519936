import { MiddlewareAPI, Middleware, Dispatch } from "redux";

import Constants from "helpers/constants";
import { AnyAction } from "interfaces/action";
import {
  AnalyticsEventPostMiddleware,
  fireAnalyticsAction,
  ANALYTICS_EVENT,
  AppName,
} from "state/analytics";
import { isGeolocationEnabled } from "utils/location";

export const analyticsMiddleware: Middleware<Dispatch> =
  (store: MiddlewareAPI) => (next) => async (action: AnyAction) => {
    const dispatch = store.dispatch;
    const getState = store.getState;

    if (action.meta && action.meta.analytics) {
      // medium and large breakpoints are categorized as 'desktop'
      const { breakpoint } = getState();
      const analyticsBreakpoint =
        breakpoint.content === Constants.BREAKPOINT_MOBILE
          ? "mobile"
          : breakpoint.content === Constants.BREAKPOINT_SMALL
          ? "tablet"
          : "desktop";

      const geolocationEnabled = await isGeolocationEnabled();
      const analyticsPayload: AnalyticsEventPostMiddleware = {
        ...action.meta.analytics,
        breakpoint: analyticsBreakpoint,
        geolocationEnabled: geolocationEnabled,
        appName: AppName,
      };

      dispatch(fireAnalyticsAction(analyticsPayload));

      // remove analytics metadata from non-analytics action payloads
      if (action.type !== ANALYTICS_EVENT) {
        delete action.meta;
      }
    }

    return next(action);
  };
