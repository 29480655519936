import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { User } from "interfaces/user";

// Actions Types
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

// Thunk Action Creator
const deleteUser: ThunkActionCreator<Promise<void>> = (userId: number) => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });
  return axios
    .delete(`${Config.API_SERVICE_URL}/api/users/${userId}`, getHeaders())
    .then((_) => {
      dispatch({ type: DELETE_USER, payload: userId });
    })
    .catch((_) => {
      dispatch({ type: DELETE_USER_FAILED });
    });
};

export { deleteUser };

// Actions
const actions = {
  [DELETE_USER_REQUEST]: (state: UserState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_USER]: (state: UserState, action: AnyAction): UserState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [DELETE_USER_FAILED]: (state: UserState, action: AnyAction): UserState => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
export interface UserState {
  error?: boolean;
  loading?: boolean;
  content?: User;
}

// Initial State
const initialState: UserState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<UserState, AnyAction>(actions, initialState);
