import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

export const FETCH_VALIDATION_SUMMARY_DASHBOARD_REQUEST =
  "FETCH_VALIDATION_SUMMARY_DASHBOARD_REQUEST";
export const FETCH_VALIDATION_SUMMARY_DASHBOARD = "FETCH_VALIDATION_SUMMARY_DASHBOARD";
export const FETCH_VALIDATION_SUMMARY_DASHBOARD_FAILED =
  "FETCH_VALIDATION_SUMMARY_DASHBOARD_FAILED";

export const fetchValidationSummaryDashboard: ThunkActionCreator =
  (projectId: number | string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_VALIDATION_SUMMARY_DASHBOARD_REQUEST });

    return axios
      .get(`${Config.API_SERVICE_URL}/api/dashboards/validation-summary/${projectId}`, getHeaders())
      .then((response) => {
        dispatch({
          type: FETCH_VALIDATION_SUMMARY_DASHBOARD,
          payload: response.data,
        });
      })
      .catch((error: Error) => dispatch({ type: FETCH_VALIDATION_SUMMARY_DASHBOARD_FAILED }));
  };

export interface ValidationSummaryDashboardData {
  data?: number[];
  labels?: string[];
  total?: number;
  colors?: string[];
}

export interface ValidationSummaryDashboardState {
  error: boolean;
  loading: boolean;
  content: ValidationSummaryDashboardData;
}

const initialState = {
  error: false,
  loading: false,
  content: {},
};

// Actions
const actions = {
  [FETCH_VALIDATION_SUMMARY_DASHBOARD_REQUEST]: (state: ValidationSummaryDashboardState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_VALIDATION_SUMMARY_DASHBOARD]: (
    state: ValidationSummaryDashboardState,
    action: AnyAction
  ) => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_VALIDATION_SUMMARY_DASHBOARD_FAILED]: (state: ValidationSummaryDashboardState) => ({
    ...state,
    loading: false,
    error: true,
    content: {},
  }),
};

export default handleActions<ValidationSummaryDashboardState, AnyAction>(actions, initialState);
