import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ErrorProps extends RouteComponentProps {
  image: React.ReactElement;
  message: string;
}

const ErrorPage: React.FunctionComponent<ErrorProps> = ({
  image: Image,
  message,
  history,
  ...restProps
}) => {
  return (
    <div className="error-container" {...restProps}>
      {Image}
      <div className="error-message-container">{message}</div>
      <button
        className="error-home-button"
        onClick={() => {
          history.push("/");
        }}
      >
        Go Home
      </button>
    </div>
  );
};

export default withRouter(ErrorPage);
