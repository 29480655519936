import { handleActions } from "redux-actions";

import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import Constants from "helpers/constants";

// Action types
export const SCREEN_RESIZE = "SCREEN_RESIZE";

// Thunk Action Creator
export const getBreakpoint: ThunkActionCreator = () => (dispatch: Dispatch) => {
  // Descending order is needed to find the largest applicable breakpoint.
  const breakpoints = [
    {
      name: Constants.BREAKPOINT_LARGE,
      query: "screen and (min-width: 110em)",
    },
    {
      name: Constants.BREAKPOINT_MEDIUM,
      query: "screen and (min-width: 70em)",
    },
    {
      name: Constants.BREAKPOINT_SMALL,
      query: "screen and (min-width: 50em)",
    },
    {
      name: Constants.BREAKPOINT_MOBILE,
      query: "screen and (min-width: 35em)",
    },
  ];

  const matching = breakpoints.filter(({ query }) => window.matchMedia(query).matches);

  dispatch({
    type: SCREEN_RESIZE,
    payload: (matching[0] || breakpoints[breakpoints.length - 1]).name,
  });
};

// Actions
const actions = {
  [SCREEN_RESIZE]: (state: BreakpointState, action: AnyAction) => ({
    ...state,
    content: action.payload,
  }),
};

export interface BreakpointState {
  hasError?: boolean;
  loading?: boolean;
  content?:
    | Constants.BREAKPOINT_LARGE
    | Constants.BREAKPOINT_MEDIUM
    | Constants.BREAKPOINT_SMALL
    | Constants.BREAKPOINT_MOBILE;
}

const initialState: BreakpointState = {
  loading: false,
  hasError: false,
  content: undefined,
};

export default handleActions<BreakpointState, AnyAction>(actions, initialState);
