import downArrowSvg from "assets/img/arrow-down.svg";
import upArrowSvg from "assets/img/arrow-up.svg";
import emptyTableStateSvg from "assets/img/empty-table-state.svg";
import cx from "classnames";
import IndeterminateCheckbox from "components/IndeterminateCheckbox";
import Loading from "components/Loading";
import isEqual from "lodash/isEqual";
import kebabCase from "lodash/kebabCase";
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  CellPropGetter,
  CellProps,
  Column as OrigColumnType,
  ColumnInstance,
  FooterGroupPropGetter,
  FooterPropGetter,
  HeaderGroupPropGetter,
  HeaderPropGetter,
  Hooks,
  IdType,
  Meta,
  PluginHook,
  Renderer,
  Row,
  RowPropGetter,
  SortingRule,
  TableBodyPropGetter,
  TableCellProps,
  TableHeaderProps,
  TableInstance,
  TableOptions,
  TablePropGetter,
  TableProps,
  TableState,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table-latest";

// Patch the Column type definition, as it's missing Cell
type Column<D extends object> = OrigColumnType<D> & {
  Cell?: Renderer<CellProps<D>>;
};

interface CustomTableProps<D extends object = {}> {
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#instance-properties)
   */
  getTableProps?: TablePropGetter<D>;
  /**
   * A single event delegation handler for all supported events (listed below):
   * - click
   *
   * @example
   * handleEvent={(event) => {
   *  if (event.type === "click") {
   *    const clickedEl = (event.target as HTMLElement);
   *    // Do something with clickeEl
   *  }
   * }}
   */
  handleEvent?: (event: Event, tableInstance: TableInstance<D>) => void;
  /**
   * What to display with no data.
   *
   * A string value is displayed as a message in the default component.
   * A component value entirely overrides the default component.
   */
  renderEmpty?: string | (() => JSX.Element);
  /**
   * Applies CSS classes to enable a special mobile/responsive layout
   * where rows are stacks of columns with headers to the left.
   *
   * Styles are located in src/styles/modules/_data-tables.scss
   *
   * @example
   * // Row 1
   * | header 1 | col 1 |
   * | header 2 | col 2 |
   *
   * // Row 2
   * | header 1 | col 1 |
   * | header 2 | col 2 |
   */
  responsiveLayout?: boolean;
}

interface CustomHeaderProps<D extends object = {}> {
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#headergroup-properties)
   */
  getHeaderGroupProps?: HeaderGroupPropGetter<D>;
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#column-properties)
   */
  getHeaderProps?: HeaderPropGetter<D>;
}

interface CustomBodyProps<D extends object = {}> {
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#instance-properties)
   */
  getTableBodyProps?: TableBodyPropGetter<D>;
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#row-properties)
   */
  getRowProps?: RowPropGetter<D>;
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#cell-properties)
   */
  getCellProps?: CellPropGetter<D>;
}

interface CustomFooterProps<D extends object = {}> {
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#headergroup-properties)
   */
  getFooterGroupProps?: FooterGroupPropGetter<D>;
  /**
   * @see [React Table docs for useTable()](https://react-table.tanstack.com/docs/api/useTable#column-properties)
   */
  getFooterProps?: FooterPropGetter<D>;
}

interface RowSelectAction {
  type: "select" | "deselect" | "selectAll" | "deselectAll";
  rowId?: string;
}

/**
 * Top-level props passed through to subcomponents via React context
 */
interface PassthroughProps<D extends object>
  extends CustomTableProps<D>,
    CustomHeaderProps<D>,
    CustomBodyProps<D>,
    CustomFooterProps<D> {
  /**
   * Called when page navigation occurs. Useful to update state
   * of the parent component, e.g. fetch a new page of data
   * from the backend if client-side pagination is not used.
   *
   * @example
   * onPageChange={(page: number, size: number, sort: string) => {
   *   if (
   *     page !== this.state.page ||
   *     size !== this.state.pageSize ||
   *     sort !== this.state.sort
   *   ) {
   *     this.setState({
   *       page,
   *       pageSize: size,
   *       sort
   *     });
   *   }
   * }}
   */
  onPageChange?: (page: any, size: any, sort: any) => void;
  /**
   * Called when any row selection event occurs. Useful to update
   * state of the parent component, e.g. to prepare a list of ids
   * for selected items to POST to an API endpoint.
   *
   * @example
   * onRowSelect={({
   *   isInclusive,
   *   selectedRowIds,
   *   deselectedRowIds,
   *   action
   * }) => {
   *   switch (action.type) {
   *     case "selectAll":
   *       this.setState({ selectedIds: selectedRowIds });
   *       break;
   *     default:
   *       break;
   *   }
   */
  onRowSelect?: (rowSelectMeta: {
    /**
     * Indicates the mode of selection.
     *
     * When `false`, select-all is active and `deselectedRowIds` will
     * contain IDs for any rows deselected thereafter.
     *
     * When `true`, select-all is not active and `selectedIds` will
     * contain IDs for any rows explicitly selected in the UI.
     */
    isInclusive: boolean;
    selectedRowIds: IdType<D>[];
    deselectedRowIds: IdType<D>[];
    /**
     * A description of the row selection event.
     *
     * @example
     * {
     *   type: "select" // or "deselect", "selectAll", "deselectAll",
     *   rowId: 1 // present when type = "select" or "deselect"
     * }
     */
    action: RowSelectAction;
  }) => void;
  /**
   * By default toggling select-all will select all rows.
   *
   * Return `false` or `undefined` from this predicate function to
   * suppress selection for certain rows. Must return `true` to select.
   *
   * @example
   * // Prevent selection of subrows
   * selectAllRowFilter={(row) => {
   *   // Subrows have a depth > 0
   *   return row.depth === 0;
   * }}
   */
  selectAllRowFilter?: (row: Row<D>) => boolean;
  isLoading?: boolean;
  /**
   * Activate the pagination feature, based on React Table's
   * [usePagination() plugin](https://react-table.tanstack.com/docs/api/usePagination).
   *
   * By default, pagination is client-side. Server-side pagination
   * requires the following props:
   *
   * @example
   * <DataTable
   *  paginated // Activates pagination
   *  manualPagination // Activates server-side behavior
   *  sortable // Only necessary if columns are sortable
   *  pageCount={pageCount} // Required with manualPagination
   *  initialState={{ // Required with manualPagination
   *    pageSize, // From parent component state
   *    pageIndex,// From parent component state
   *    sortBy    // From parent component state
   *  }}
   *  onPageChange={() => {}} // React to page nav and sort
   *  />
   */
  paginated?: boolean;
  /**
   * Pass `true` when all internal table state should be reset.
   * For example, after a new search, the dataset displayed in
   * the table needs to be reset to the first page of paginated
   * results and the default sort.
   */
  reset?: boolean;
  /**
   * Activate the row selection feature, based on React Table's
   * [useRowSelect() plugin](https://react-table.tanstack.com/docs/api/useRowSelect).
   */
  rowSelectable?: boolean;
  sortable?: boolean;
  selectionIds?: any[];
}

/**
 * The full set of props for <DataTable>.
 */
export interface DataTableProps<D extends object = {}>
  extends TableOptions<D>,
    PassthroughProps<D> {
  initialState?: Partial<TableState<D> & { deselectedRowIds?: IdType<D>[] }>;
  render?: (renderMeta: {
    tableInstance: TableInstance<D>;
    passthroughProps: PassthroughProps<D>;
  }) => ReactNode;
}

/**
 * The React context object passed to compound component
 * subcomponents:
 * - `<DataTable.Table>`
 * - `<DataTable.Header>`
 * - `<DataTable.Body>`
 * - `<DataTable.Footer>`
 * - `<DataTable.PageControls>`
 */
interface DataTableContext<D extends object = {}> {
  tableInstance?: TableInstance<D>;
  passthroughProps?: PassthroughProps<D> & { columns: Column<D>[] };
}

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_SORT_BY = [{ id: "id", desc: true }];
const ROW_SELECT_INPUT_CSS_CLASS = "data-table__select-row";

const sortArrowImages = new Map<"up" | "down", string>([
  ["up", upArrowSvg],
  ["down", downArrowSvg],
]);

export const formatSortParam: <D>(sortingRules: SortingRule<D>[]) => string = (sortingRules) =>
  sortingRules.map((r) => `${r.id.toLowerCase()}.${r.desc ? "desc" : "asc"}`).join(",");

export const parseSortParam: <D>(sortParam: string | undefined) => SortingRule<D>[] = (
  sortParam
) => {
  if (!sortParam) return [];
  const sortRuleStrings = sortParam.split(",");
  const sortRules: SortingRule<any>[] = [];
  return sortRuleStrings.reduce((sortRules, sortRuleStr) => {
    const [field, direction] = sortRuleStr.trim().split(".");
    if (!field || !direction) {
      if (process.env.NODE_ENV !== "production") {
        console.warn("Expected a sort param that includes both field and order, like `id.desc`");
      }
    }
    return sortRules.concat({ id: field, desc: direction === "desc" });
  }, sortRules);
};

const selectedRowIdsToArray = <D extends object>(
  selectedRowIds: Record<IdType<D>, boolean>
): Array<IdType<D>> => {
  return Object.entries(selectedRowIds)
    .filter(([_k, v]) => v)
    .map(([k, _v]) => k);
};

const decorateColumns = <D extends object>(columns: Column<D>[]): Column<D>[] => {
  return columns.map((column) => {
    const origHeader = column.Header;
    if (typeof origHeader === "string") {
      column.Header = (_props) => {
        // TODO: Wrap Header rendering
        return `${origHeader}`;
      };
    }

    const origCell = column.Cell;
    if (typeof origCell === "string") {
      column.Cell = (_props: PropsWithChildren<TableInstance<D>>) => {
        // TODO: Wrap Cell rendering
        return `${origCell}`;
      };
    }

    const origFooter = column.Footer;
    if (typeof origFooter === "string") {
      column.Footer = (_props) => {
        // TODO: Wrap Footer rendering
        return `${origFooter}`;
      };
    }

    return column;
  });
};

const customRowSelect = <D extends object>(hooks: Hooks<D>) => {
  hooks.visibleColumns.push((columns: ColumnInstance<D>[]) => [
    {
      id: "select-row",
      dataQa: "select-row",
      disableSortBy: true,
      width: 40,
      Header: (headerProps) => {
        const { getToggleAllRowsSelectedProps, state } = headerProps;
        const reactTableProps = getToggleAllRowsSelectedProps();

        // For some reason react-table incorrectly calculates this select-all
        // checkbox as checked and not indeterminate after the following steps:
        //  1. toggle select-all
        //  2. navigate to a new page using backend/manual pagination
        //  3. deselect one or more rows
        //  4. navigate back or forward to another page
        // So, we need to explicitly control the indeterminate and checked input attributes
        const isIndeterminate = Boolean(
          !state.isRowSelectInclusive &&
            state.deselectedRowIds &&
            state.deselectedRowIds?.length > 0
        );
        const isChecked = Boolean(reactTableProps.checked) && !isIndeterminate;
        return (
          <IndeterminateCheckbox
            {...reactTableProps}
            checked={isChecked}
            indeterminate={isIndeterminate}
            className="data-table__select-all-rows"
          />
        );
      },
      Cell: ({ row, state }: CellProps<D>) => {
        const checkboxProps = row.getToggleRowSelectedProps();
        const originalRow = row.original as any;
        const disableCheckboxSelector = originalRow?.disableCheckboxSelector;
        checkboxProps.checked = state.selectedRowIds[row.id] === true;
        return <IndeterminateCheckbox {...checkboxProps} disabled={disableCheckboxSelector} className={ROW_SELECT_INPUT_CSS_CLASS} />;
      },
    },
    ...columns,
  ]);

  hooks.useInstance.push((instance: TableInstance<D>) => {
    instance.isAllRowsSelected = Boolean(instance.state.isAllRowsSelected);
  });

  hooks.prepareRow.push((row: Row<D>, meta: Meta<D>) => {
    row.subRows.forEach((subRow) => {
      subRow.isSubRow = true;
      subRow.parentRow = row;
    });
  });
};

const defaultGetRowId = <D extends object>(
  originalRow: D,
  relativeIndex: number,
  parent?: Row<D>
): string => {
  if ("id" in originalRow) {
    return String((originalRow as D & { id: string | number }).id);
  }
  return parent ? [parent.id, relativeIndex].join(".") : String(relativeIndex);
};

const defaultGetSubRows = <D extends object>(row: D & { subRows?: D[] }, _index: number): D[] => {
  return row && Array.isArray(row.subRows) ? row.subRows : [];
};

const TableContext = React.createContext<DataTableContext>({});

/**
 * Based on [React Table v7.x](https://react-table.tanstack.com/docs/).
 *
 * All options for the [useTable() React Table hook](https://react-table.tanstack.com/docs/api/useTable)
 * can be passed as props.
 *
 * Styles are located in `src/styles/modules/_data-tables.scss`
 *
 * Basic required props are [`data`](https://react-table.tanstack.com/docs/api/useTable#table-options)
 * and [`columns`](https://react-table.tanstack.com/docs/api/useTable#column-options).
 *
 * Pagination defaults:
 * - `pageSize = 100`.
 * - `sortBy = [{ id: "id", desc: true }]`
 *
 * Server-side pagination and sorting requires the `manualPagination` boolean prop,
 * among others (see example below).
 *
 * @example
 * <DataTable
 *  data={data} // Array of row objects
 *  columns={tableColumnsConfig} // Array of column config objects
 *  paginated // Activates pagination
 *  manualPagination // Activates server-side pagination+sorting
 *  sortable // Only necessary if columns are sortable
 *  pageCount={pageCount} // Required with manualPagination
 *  initialState={{ // Required with manualPagination
 *    pageSize,  // From parent component state
 *    pageIndex, // From parent component state
 *    sortBy,    // From parent component state
 *    hiddenColumns: tableColumnsConfig // Conditionally hide columns
 *      .filter((col: any) => col.show === false)
 *      .map(col => col.id || col.accessor) as any
 *  }}
 *  onPageChange={() => {}} // React to page nav and sort
 *  />
 *
 *
 */
const DataTable = <D extends object>(props: DataTableProps<D>) => {
  const {
    handleEvent,
    onPageChange,
    onRowSelect,
    render,
    selectAllRowFilter,
    getCellProps,
    getFooterGroupProps,
    getFooterProps,
    getHeaderGroupProps,
    getHeaderProps,
    getRowProps,
    getTableBodyProps,
    getTableProps,
    isLoading,
    paginated,
    renderEmpty,
    reset = false,
    responsiveLayout,
    rowSelectable,
    sortable,
    selectionIds,
    ..._useTableOptions
  } = props;

  const useTableOptions: TableOptions<D> = _useTableOptions;

  // Destructure for convenient use below...
  const {
    // ..and, assign defaults for useTable() options.
    getRowId = defaultGetRowId,
    getSubRows = defaultGetSubRows,
    initialState = {},
    manualPagination,
    data,
    columns,
    pageCount,
  } = useTableOptions;

  const passthroughProps = useMemo(
    () => ({
      handleEvent,
      onPageChange,
      columns,
      getCellProps,
      getFooterGroupProps,
      getFooterProps,
      getHeaderGroupProps,
      getHeaderProps,
      getRowProps,
      getTableBodyProps,
      getTableProps,
      isLoading,
      paginated,
      renderEmpty,
      responsiveLayout,
      rowSelectable,
      sortable,
      selectionIds,
    }),
    [
      handleEvent,
      onPageChange,
      columns,
      getCellProps,
      getFooterGroupProps,
      getFooterProps,
      getHeaderGroupProps,
      getHeaderProps,
      getRowProps,
      getTableBodyProps,
      getTableProps,
      isLoading,
      paginated,
      renderEmpty,
      responsiveLayout,
      rowSelectable,
      sortable,
      selectionIds,
    ]
  );

  const prevData = useRef<D[]>([]);
  const prevPageCount = useRef<number | undefined>();
  const prevSortParam = useRef<string | undefined>();
  const prevPageIndex = useRef<number | undefined>(0);
  const prevPageSize = useRef<number | undefined>();
  const prevSelectedRowIds = useRef<IdType<D>[]>([]);
  const prevDeselectedRowIds = useRef<IdType<D>[]>([]);
  const prevExclusiveSelectedRowIds = useRef<IdType<D>[]>([]);
  const prevIsRowSelectInclusive = useRef(true);
  const toggledRowSelection = useRef<{ id: string; value: boolean } | undefined>();

  const hasData = data.length > 0;

  // IMPORTANT! The data array is checked for change by reference, so the
  // parent component will need to ensure that array is memoized and that
  // the same array is passed on multiple render passes when the data rows
  // haven't fundamentally changed.
  const hasDataChanged = hasData && prevData.current.length > 0 && data !== prevData.current;

  useTableOptions.data = data;
  useTableOptions.getRowId = getRowId;
  useTableOptions.getSubRows = getSubRows;
  useTableOptions.initialState = initialState;

  // Set up default handling of some column config options
  useTableOptions.columns = useMemo(() => {
    return decorateColumns(columns);
  }, [columns]);

  // The final set of plugins is determined by boolean props
  // for features, checked below.
  const plugins: PluginHook<D>[] = [];

  if (isLoading) {
    useTableOptions.data = prevData.current;
    useTableOptions.initialState.pageIndex = prevPageIndex.current;
    useTableOptions.pageCount = prevPageCount.current;
  }

  if (sortable) {
    plugins.push(useSortBy);

    useTableOptions.disableSortRemove = true;

    if (!useTableOptions.initialState.sortBy) {
      useTableOptions.initialState.sortBy = DEFAULT_SORT_BY;
    }
  }

  if (paginated) {
    plugins.push(usePagination);

    if (initialState.pageSize === undefined) {
      useTableOptions.initialState.pageSize = DEFAULT_PAGE_SIZE;
    }

    if (initialState.pageIndex === undefined) {
      useTableOptions.initialState.pageIndex = 0;
    }
    if (sortable && manualPagination) {
      useTableOptions.manualSortBy = true;
    }
  }

  if (rowSelectable) {
    plugins.push(useRowSelect, customRowSelect);

    useTableOptions.autoResetSelectedRows = false;
  }

  useTableOptions.useControlledState = function useControlledState(state) {
    return useMemo<TableState>(
      () => {
        const newState = { ...state };

        if (reset) {
          newState.pageSize =
            initialState.pageSize !== undefined ? initialState.pageSize : DEFAULT_PAGE_SIZE;
          newState.pageIndex = initialState.pageIndex !== undefined ? initialState.pageIndex : 0;
          newState.sortBy =
            initialState.sortBy !== undefined ? initialState.sortBy : DEFAULT_SORT_BY;
          newState.selectedRowIds = {} as Record<IdType<D>, boolean>;
          prevSelectedRowIds.current = [];
        }

        return newState;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [state, reset, data, hasDataChanged, prevIsRowSelectInclusive.current]
    );
  };

  useTableOptions.stateReducer = (newState, action, prevState, currTableInstance) => {
    // Initialize custom state properties, if this is the first time stateReducer is run
    newState.deselectedRowIds = newState.deselectedRowIds ?? [];
    newState.isRowSelectInclusive = newState.isRowSelectInclusive ?? true;
    newState.exclusiveSelectedRowIds = newState.exclusiveSelectedRowIds ?? [];

    switch (action.type) {
      case "resetPage":
      case "toggleSortBy":
        newState.pageIndex = 0;
        if (reset) {
          newState.selectedRowIds = {} as Record<IdType<D>, boolean>;
          newState.deselectedRowIds = [];
          newState.exclusiveSelectedRowIds = [];
          newState.isRowSelectInclusive = true;
          newState.isAllRowsSelected = false;
        }
        break;

      case "toggleAllRowsSelected":
      case "toggleAllPageRowsSelected":
        if (manualPagination) {
          if (action.value) {
            if (action.type === "toggleAllRowsSelected") {
              newState.deselectedRowIds = [];
              let selectedRowIds = [];

              /*
                Context on the if-else statement:
                  This conditional branch handles the case when the "selectionIds"
                  prop is populated by the parent component. This allows for any number of
                  data-table rows to be selected, regardless of what the "prevState" object contains.
                  This functionality is useful in situations where the "toggleAllRowsSelected" action
                  should select rows that are not within the current data-table's visible page of rows.
                Relevant ticket number(s):
                  -- 74796
                  -- 42215
              */
              if (selectionIds && selectionIds.length) {
                selectedRowIds = selectionIds;
                newState.selectedRowIds = selectionIds.reduce((acc, rowId) => {
                  acc[rowId] = true;
                  return acc;
                }, {});
              } else {
                selectedRowIds = selectedRowIdsToArray<D>(prevState.selectedRowIds);
              }
              
              newState.exclusiveSelectedRowIds = [];

              for (const rowId of selectedRowIds) {
                const selectedRow = currTableInstance!.rowsById[rowId];
                if (selectedRow && selectAllRowFilter && !selectAllRowFilter(selectedRow)) {
                  newState.exclusiveSelectedRowIds.push(rowId);
                }
              }
              prevDeselectedRowIds.current = newState.deselectedRowIds;
            }

            for (const [rowId, row] of Object.entries(currTableInstance!.rowsById)) {
              if (
                newState.deselectedRowIds.indexOf(rowId) > -1 ||
                (!prevState.selectedRowIds[rowId] && selectAllRowFilter && !selectAllRowFilter(row))
              ) {
                delete newState.selectedRowIds[rowId];
              }
            }
          } else {
            newState.deselectedRowIds = [];
            newState.exclusiveSelectedRowIds = [];
            newState.selectedRowIds = {} as Record<IdType<D>, boolean>;
          }

          newState.isAllRowsSelected = action.value;
          newState.isRowSelectInclusive = !action.value;
        }
        break;

      case "toggleRowSelected":
        if (action.value) {
          newState.selectedRowIds = { ...prevState.selectedRowIds, [action.id]: true };

          if (!prevState.isRowSelectInclusive) {
            if (newState.deselectedRowIds!.indexOf(action.id) === -1) {
              newState.exclusiveSelectedRowIds = [...prevState.exclusiveSelectedRowIds!, action.id];
            }
            newState.deselectedRowIds = prevState.deselectedRowIds?.filter(
              (rowId) => rowId !== action.id
            );
          }

          toggledRowSelection.current = {
            id: action.id as string,
            value: true,
          };
        } else {
          delete newState.selectedRowIds[action.id];

          if (!prevState.isRowSelectInclusive) {
            if (newState.exclusiveSelectedRowIds.indexOf(action.id) === -1) {
              newState.deselectedRowIds = [...prevDeselectedRowIds.current, action.id];
            }
            newState.exclusiveSelectedRowIds = prevState.exclusiveSelectedRowIds?.filter(
              (rowId) => rowId !== action.id
            );
          }

          toggledRowSelection.current = {
            id: action.id as string,
            value: false,
          };
        }
        break;

      default:
        break;
    }
    return newState;
  };

  // Update the table instance with new options, e.g. data, pagination info
  const tableInstance = useTable(useTableOptions, ...plugins);
  const state = tableInstance.state;

  // When navigating to a new page using backend pagination, we need
  // to manually select rows in the new page if select-all was previously
  // activated.
  if (
    manualPagination &&
    !reset &&
    rowSelectable &&
    hasDataChanged &&
    !state.isRowSelectInclusive
  ) {
    tableInstance.toggleAllPageRowsSelected(true);
    prevData.current = data;
  }

  const currSortParam = formatSortParam(state.sortBy);

  const currSelectedRowIds = useMemo(() => {
    return state.selectedRowIds ? selectedRowIdsToArray<D>(state.selectedRowIds) : [];
  }, [state.selectedRowIds]);

  const currDeselectedRowIds = useMemo(() => {
    return state.deselectedRowIds || [];
  }, [state.deselectedRowIds]);

  const currExclusiveSelectedRowIds = useMemo(() => {
    return state.exclusiveSelectedRowIds || [];
  }, [state.exclusiveSelectedRowIds]);

  // BEGIN: Handle sorting and pagination state changes
  // ==================================================

  useEffect(() => {
    const isSortChange = currSortParam !== prevSortParam.current;
    const isPageChange =
      isSortChange ||
      state.pageIndex !== prevPageIndex.current ||
      state.pageSize !== prevPageSize.current;

    if (onPageChange && hasData && isPageChange) {
      // Changing sort resets the items of each page, so take the
      // user back to page 1 (pageIndex = 0)
      const pageIndex = isSortChange ? 0 : state.pageIndex;

      // Notify parent component using a callback prop
      onPageChange(pageIndex, state.pageSize, currSortParam);
    }
  }, [
    onPageChange,
    currSortParam,
    hasData,
    isLoading,
    prevPageIndex,
    prevPageSize,
    prevSortParam,
    state.pageIndex,
    state.pageSize,
  ]);

  // ================================================
  // END: Handle sorting and pagination state changes

  // BEGIN: Handle row selection state changes
  // =========================================

  useEffect(() => {
    if (
      !hasDataChanged &&
      onRowSelect &&
      (!isEqual(prevSelectedRowIds.current, currSelectedRowIds) ||
        !isEqual(prevDeselectedRowIds.current, currDeselectedRowIds))
    ) {
      let isInclusive: boolean = false;
      let selectedRowIds: IdType<D>[] = [];
      let deselectedRowIds: IdType<D>[] = [];
      let action: RowSelectAction | undefined = undefined;

      // Select all
      if (tableInstance.isAllRowsSelected && currDeselectedRowIds.length === 0) {
        isInclusive = false;
        selectedRowIds = currExclusiveSelectedRowIds;
        if (toggledRowSelection.current) {
          action = {
            type: toggledRowSelection.current?.value ? "select" : "deselect",
            rowId: toggledRowSelection.current?.id,
          };
        } else {
          action = {
            type: "selectAll",
          };
        }
      }
      // Deselect all
      else if (currSelectedRowIds.length === 0) {
        isInclusive = true;
        selectedRowIds = [];
        action = {
          type: "deselectAll",
        };
      }
      // Inclusive selection after first render or deselect all
      else if (prevIsRowSelectInclusive.current) {
        isInclusive = true;
        selectedRowIds = currSelectedRowIds;
        action = {
          type: toggledRowSelection.current?.value ? "select" : "deselect",
          rowId: toggledRowSelection.current?.id,
        };
      }
      // Exclusive selection after select all and deselecting at least one row
      else {
        isInclusive = false;
        deselectedRowIds = currDeselectedRowIds;
        selectedRowIds = currExclusiveSelectedRowIds;
        action = {
          type: toggledRowSelection.current?.value ? "select" : "deselect",
          rowId: toggledRowSelection.current?.id,
        };
      }

      // Notify parent component
      onRowSelect({ isInclusive, selectedRowIds, deselectedRowIds, action });

      prevIsRowSelectInclusive.current = isInclusive;
      prevSelectedRowIds.current = currSelectedRowIds;
      prevDeselectedRowIds.current = currDeselectedRowIds;
      prevExclusiveSelectedRowIds.current = currExclusiveSelectedRowIds;
      toggledRowSelection.current = undefined;
    }
  }, [
    onRowSelect,
    selectAllRowFilter,
    hasDataChanged,
    prevSelectedRowIds,
    currSelectedRowIds,
    prevDeselectedRowIds,
    currDeselectedRowIds,
    prevExclusiveSelectedRowIds,
    currExclusiveSelectedRowIds,
    tableInstance.isAllRowsSelected,
    prevIsRowSelectInclusive,
    toggledRowSelection,
  ]);
  // ======================================
  // END: Handle row selection state change

  // Important! This useEffect() callback must run as the very last
  // effect, so that other prior effects can rely on the values of
  // prev[Whatever] refs being that from the previous render.
  useEffect(() => {
    prevData.current = data;
    prevPageCount.current = pageCount;
    prevPageIndex.current = state.pageIndex;
    prevPageSize.current = state.pageSize;
    prevSortParam.current = currSortParam;
  }, [hasDataChanged, pageCount, state.pageIndex, state.pageSize, data, currSortParam]);

  const ContextProvider = TableContext.Provider as React.Provider<Partial<DataTableContext<D>>>;

  return (
    <ContextProvider
      value={{
        passthroughProps,
        tableInstance,
      }}
    >
      {render ? render({ passthroughProps, tableInstance }) : <DataTable.Table></DataTable.Table>}
    </ContextProvider>
  );
};

const defaultRenderEmpty = (message: string = "No data") => {
  return (
    <>
      <img src={emptyTableStateSvg} alt="" data-qa="empty-state-image"></img>
      <span className="data-table__empty-message" data-qa="empty-state-text">
        {message}
      </span>
    </>
  );
};

const Table: FunctionComponent<CustomTableProps> = (props) => {
  const tableContext = useContext(TableContext);
  const tableInstance = tableContext?.tableInstance;
  const passthroughProps = tableContext?.passthroughProps;

  const data = tableInstance?.data;
  const pageCount = tableInstance?.pageCount;
  const hasData = data && data.length > 0;
  const isLoading = passthroughProps?.isLoading;
  const paginate = passthroughProps?.paginated;
  const showFooter = passthroughProps?.columns.some((c) => c.Footer);
  const showPageControls = hasData && paginate && pageCount !== undefined && pageCount > 1;

  const responsiveLayout =
    typeof props.responsiveLayout !== "undefined"
      ? props.responsiveLayout
      : passthroughProps?.responsiveLayout;

  const getTableProps = tableInstance?.getTableProps;

  const customGetTableProps = props.getTableProps || passthroughProps?.getTableProps;

  const tableElRef = useRef<HTMLTableElement>(null);

  let handleEvent = props.handleEvent || passthroughProps?.handleEvent;
  let internalHandleEvent: EventListener | null = null;
  if (handleEvent && tableElRef.current !== null) {
    handleEvent = handleEvent.bind(tableElRef.current);
    internalHandleEvent = (event) => {
      if (handleEvent && tableInstance) {
        switch (event.type) {
          case "click":
            if (!(event.target as HTMLElement).classList.contains(ROW_SELECT_INPUT_CSS_CLASS)) {
              handleEvent(event, tableInstance);
            }
            break;

          default:
            break;
        }
      }
    };
  }

  useEffect(() => {
    let tableEl: HTMLTableElement;
    if (internalHandleEvent) {
      tableEl = tableElRef.current!;
      // TODO: Add listeners for other events the table should handle
      tableEl.addEventListener("click", internalHandleEvent);
    }
    return () => {
      if (internalHandleEvent) {
        tableEl.removeEventListener("click", internalHandleEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableElRef.current, internalHandleEvent]);

  // Merge mandatory classes with custom ones
  let tableProps: TableProps | undefined = undefined;
  if (getTableProps) {
    tableProps = getTableProps(customGetTableProps);
    tableProps = {
      ...tableProps,
      className: cx("data-table__table b3", tableProps.className),
    };
  }

  const wrapperProps = {
    className: cx("data-table", {
      "--responsive": responsiveLayout,
      "--empty": !hasData,
    }),
  };

  const renderEmpty = props.renderEmpty || passthroughProps?.renderEmpty;
  let emptyContent: string | JSX.Element;

  if (typeof renderEmpty === "function") {
    emptyContent = renderEmpty();
  } else {
    emptyContent = defaultRenderEmpty(renderEmpty);
  }

  return (
    <div ref={tableElRef} {...wrapperProps}>
      {tableProps && (
        <table {...tableProps}>
          <DataTable.Header></DataTable.Header>
          <DataTable.Body></DataTable.Body>
          {showFooter && <DataTable.Footer></DataTable.Footer>}
        </table>
      )}
      {showPageControls && <DataTable.PageControls></DataTable.PageControls>}
      {!hasData && <div className="data-table__empty">{!isLoading && emptyContent}</div>}
      {isLoading && (
        <div className="block-ui">
          <Loading></Loading>
        </div>
      )}
    </div>
  );
};

const Header: FunctionComponent<CustomHeaderProps> = (props) => {
  const tableContext = useContext(TableContext);
  const tableInstance = tableContext?.tableInstance;
  const passthroughProps = tableContext?.passthroughProps;

  const headerGroups = tableInstance?.headerGroups;

  const customGetHeaderGroupProps =
    props.getHeaderGroupProps || passthroughProps?.getHeaderGroupProps;
  const customGetHeaderProps = props.getHeaderProps || passthroughProps?.getHeaderProps;

  return (
    <>
      {headerGroups && (
        <thead className="data-table__header">
          {headerGroups.map((headerGroup) => {
            const getHeaderGroupProps = headerGroup.getHeaderGroupProps;
            let headerGroupProps = getHeaderGroupProps(customGetHeaderGroupProps);
            headerGroupProps = {
              ...headerGroupProps,
              // Merge mandatory CSS classes with custom ones
              className: cx("data-table__header-row", headerGroupProps.className),
            };

            return (
              <tr {...headerGroupProps}>
                {headerGroup.headers.map((column) => {
                  const getHeaderProps = column.getHeaderProps;
                  let headerProps: TableHeaderProps & { "data-qa"?: string } =
                    getHeaderProps(customGetHeaderProps);

                  const colWidth =
                    column.width === "auto"
                      ? column.width
                      : typeof column.width === "string"
                      ? column.width.endsWith("%")
                        ? column.width
                        : `${parseInt(column.width, 10)}px`
                      : typeof column.width === "number"
                      ? `${column.width}px`
                      : undefined;

                  let dataQa: string = (column as any).dataQa;
                  if (!dataQa && process.env.NODE_ENV === "development") {
                    dataQa = kebabCase(column.id);
                    console.warn(
                      `No dataQa attribute values was supplied for column ${column.id}. ` +
                        `Using kebab-case ID instead: "${dataQa}"`
                    );
                  }

                  headerProps = {
                    ...headerProps,
                    ...(column.canSort ? column.getSortByToggleProps() : undefined),
                    // Merge mandatory CSS classes with custom ones
                    className: cx("data-table__col-header h6", headerProps.className),
                    "data-qa": dataQa,
                  };

                  if (colWidth) {
                    headerProps = {
                      ...headerProps,
                      style: {
                        ...headerProps.style,
                        width: colWidth,
                      },
                    };
                  }

                  return (
                    <th
                      {...headerProps}
                      title={
                        column.canSort
                          ? `Click to sort ${
                              column.isSortedDesc || !column.isSorted ? "ascending" : "descending"
                            }`
                          : ""
                      }
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <img
                          className="data-table__col-sort-icon"
                          src={sortArrowImages.get(
                            column.isSortedDesc || !column.isSorted ? "down" : "up"
                          )}
                          alt=""
                        />
                      )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
      )}
    </>
  );
};

const Body: FunctionComponent<CustomBodyProps> = (props) => {
  const tableContext = useContext(TableContext);
  const tableInstance = tableContext?.tableInstance;
  const passthroughProps = tableContext?.passthroughProps;

  const getTableBodyProps = tableInstance?.getTableBodyProps;

  const customGetTableBodyProps = props.getTableBodyProps;
  const customGetRowProps = props.getRowProps || passthroughProps?.getRowProps;
  const customGetCellProps = props.getCellProps || passthroughProps?.getCellProps;

  const responsiveLayout = passthroughProps?.responsiveLayout;

  const { prepareRow } = tableInstance || {};

  const rows = passthroughProps?.paginated ? tableInstance?.page : tableInstance?.rows;

  let tbodyProps = getTableBodyProps!(customGetTableBodyProps);
  tbodyProps = {
    ...tbodyProps,
    className: cx("data-table__body"),
  };

  return (
    <>
      {tableContext && (
        <tbody {...tbodyProps}>
          {rows!.map((row) => {
            return [row].concat(row.subRows).map((row, index) => {
              prepareRow!(row);

              let trProps = row.getRowProps(customGetRowProps);
              trProps = {
                ...trProps,
                // Merge mandatory CSS classes with custom ones
                className: cx("data-table__row", trProps.className),
              };

              return (
                <tr {...trProps}>
                  {row.cells.map((cell) => {
                    const getCellProps = cell.getCellProps;
                    let tdProps: TableCellProps = getCellProps(customGetCellProps);

                    let dataQa: string = (cell.column as any).dataQa;

                    tdProps = {
                      ...tdProps,
                      // Merge mandatory CSS classes with custom ones
                      className: cx("data-table__cell", tdProps.className),
                    };
                    return (
                      <td {...tdProps}>
                        {responsiveLayout && (
                          <div className="data-table__col-header h6" hidden>
                            {cell.render("Header")}
                          </div>
                        )}
                        <div className="data-table__cell-content" data-qa={dataQa}>
                          {cell.render("Cell")}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            });
          })}
        </tbody>
      )}
    </>
  );
};

const Footer: FunctionComponent<CustomFooterProps> = (props) => {
  const tableContext = useContext(TableContext);

  const tableInstance = tableContext?.tableInstance;
  const passthroughProps = tableContext?.passthroughProps;

  const footerGroups = tableInstance?.footerGroups;

  const customGetFooterGroupProps =
    props.getFooterGroupProps || passthroughProps?.getFooterGroupProps;
  const customGetFooterProps = props.getFooterProps || passthroughProps?.getFooterProps;

  return (
    <>
      {footerGroups && (
        <tfoot>
          {footerGroups.map((footerGroup) => {
            const getFooterGroupProps = footerGroup.getFooterGroupProps;
            let footerGroupProps = getFooterGroupProps(customGetFooterGroupProps);
            footerGroupProps = {
              ...footerGroupProps,
              // Merge mandatory CSS classes with custom ones
              className: cx("data-table__footer-row", footerGroupProps.className),
            };

            return (
              <tr {...footerGroupProps}>
                {footerGroup.headers.map((column) => {
                  const getFooterProps = column.getFooterProps;
                  let footerProps = getFooterProps(customGetFooterProps);
                  footerProps = {
                    ...footerProps,
                    // Merge mandatory CSS classes with custom ones
                    className: cx("data-table__col-header h6", footerProps.className),
                  };
                  return <td {...footerProps}>{column.render("Footer")}</td>;
                })}
              </tr>
            );
          })}
        </tfoot>
      )}
    </>
  );
};

const PageControls: FunctionComponent<{}> = () => {
  const tableContext = useContext(TableContext);

  const tableInstance = tableContext?.tableInstance;
  const passthroughProps = tableContext?.passthroughProps;

  if (!passthroughProps?.paginated && process.env.NODE_ENV === "development") {
    console.warn(
      "The <DataTable.PageControls> component should only be " +
        "rendered in conjunction with <DataTable paginate={true}>."
    );
    return null;
  }
  if (!tableInstance) {
    return null;
  }

  const {
    pageCount,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  return (
    <div className="data-table__page-nav">
      <button
        disabled={!canPreviousPage}
        onClick={() => canPreviousPage && previousPage()}
        title={canPreviousPage ? "Previous page" : undefined}
        data-qa="table-pagination-prev"
      >
        Prev
      </button>
      <span className="data-table__page-nav-status" data-qa="table-pagination-status">
        Page {pageIndex + 1} of {pageCount!}
      </span>
      <button
        disabled={!canNextPage}
        onClick={() => canNextPage && nextPage()}
        title={canNextPage ? "Next page" : undefined}
        data-qa="table-pagination-next"
      >
        Next
      </button>
    </div>
  );
};

DataTable.Table = Table;
DataTable.Header = Header;
DataTable.Body = Body;
DataTable.Footer = Footer;
DataTable.PageControls = PageControls;

export default DataTable;
