import * as Msal from "msal";

import Config from "config";
import { getItem, setItem } from "helpers/storage";
import { ILoginService } from "interfaces/login-service";
import { STORAGE_KEY } from "state/auth";
import { isIe } from "utils/helpers";

const LOGIN_SCOPE = ["openid", "profile"];
class LoginService implements ILoginService {
  private client: Msal.UserAgentApplication;
  private clientResetPassword: Msal.UserAgentApplication;

  constructor() {
    // storeAuthStateInCookie needs to be true for IE to work properly:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/347
    const authorityBase = `https://${Config.AZURE_AD_TENANT_NAME}.b2clogin.com/tfp/${Config.AZURE_AD_TENANT}`;
    this.client = new Msal.UserAgentApplication({
      auth: {
        clientId: Config.AZURE_AD_CLIENTID,
        authority: `${authorityBase}/${Config.AZURE_AD_USERFLOW_LOGIN}`,
        validateAuthority: false,
        redirectUri: `${Config.SERVICE_URL}/signin`,
        postLogoutRedirectUri: `${Config.SERVICE_URL}/signin`,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIe ? true : false,
      },
    });
    this.clientResetPassword = new Msal.UserAgentApplication({
      auth: {
        clientId: Config.AZURE_AD_CLIENTID,
        authority: `${authorityBase}/${Config.AZURE_AD_USERFLOW_PASSWORD_RESET}`,
        validateAuthority: false,
        redirectUri: `${Config.SERVICE_URL}/signin`,
        postLogoutRedirectUri: `${Config.SERVICE_URL}/signin`,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIe ? true : false,
      },
    });
    this.client.handleRedirectCallback((error, authResponse: any) => {
      if (error) {
        const errorMessage = error.message || "";
        console.error("Authentication Error: ", error)

        if (errorMessage.includes("AADB2C90118")) {
          // Forgotten password flow
          this.clientResetPassword.loginRedirect({ scopes: LOGIN_SCOPE });
        } else if (errorMessage.includes("AADB2C90091")) {
          // General cancellation flow
          // this.client.loginRedirect({ scopes: LOGIN_SCOPE });
        }
        return;
      }

      if (isIe) {
        setItem(STORAGE_KEY, authResponse.idToken.rawIdToken, false);
        setItem("msal.client.info", authResponse.idToken.rawIdToken, false);
      }
    });
  }


  public login = async () => {
    this.client.loginRedirect({ scopes: LOGIN_SCOPE });
    return getItem(STORAGE_KEY);
  }

  public logout = (): Promise<void> =>
    new Promise((resolve, reject) => {
      try {
        this.client.logout();
        resolve();
      } catch (e) {
        reject(e);
      }
    });

  public resetPassword = (): Promise<string | any> =>
    new Promise((resolve, reject) => {
      try {
        this.clientResetPassword.loginRedirect({ scopes: LOGIN_SCOPE });
        resolve(getItem(STORAGE_KEY));
      } catch (e) {
        reject(e);
      }
    })

  public acquireTokenSilent = () =>
    this.client.acquireTokenSilent({ scopes: LOGIN_SCOPE }).then((auth) => {
      return auth.idToken.rawIdToken;
    });

  public acquireToken = (): Promise<string | any> =>
    new Promise((resolve, reject) => {
      try {
        this.client.acquireTokenRedirect({ scopes: LOGIN_SCOPE });
        resolve(getItem(STORAGE_KEY));
      } catch (e) {
        console.log(e);
        reject(e);
      }
    })
}

export default LoginService;
