import Announcement from "components/Announcement";
import { Card } from "components/Card";
import Popover from "components/DeletePopover";
import NewAnnouncementForm from "components/NewAnnouncementForm";
import Page from "components/Page";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import Constants from "helpers/constants";
import { Announcement as AnnouncementProps, CreateAnnouncement } from "interfaces/announcement";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnnouncement, deleteAnnouncement } from "state/announcement";
import { fetchMyAnnouncements } from "state/announcements";
import { trackPageView, PAGEVIEW_EVENTS } from "state/userEvents";

type ManageSurveysProps = PropsFromRedux & RouteProps;

interface ManageSurveysState {
  tableData: AnnouncementProps[];
  announcement: CreateAnnouncement;
  meta: { project_name: string; project_type: string };
  popoverOpenId: number | null;
}

const defaultState = {
  tableData: [],
  announcement: {
    project_id: -1,
    title: "",
    body: "",
  },
  meta: {
    project_name: "",
    project_type: "",
  },
  popoverOpenId: null,
};

class ManageAnnouncements extends React.Component<ManageSurveysProps, ManageSurveysState> {
  // update tableData when announcements props are changed
  public static getDerivedStateFromProps(props: ManageSurveysProps, state: ManageSurveysState) {
    if (props.announcements && props.announcements.length !== state.tableData.length) {
      const { announcements = [] } = props;

      return {
        tableData: announcements,
      };
    }

    return null;
  }

  public state = defaultState;
  public componentDidMount() {
    this.props.fetchMyAnnouncements().then(() => {
      const { announcements = [] } = this.props;

      this.setState({
        tableData: announcements,
      });

      this.props.trackPageView({
        event_type: PAGEVIEW_EVENTS.ANNOUNCEMENTS_PAGEVIEW,
        user_id: this.props.user?.id,
        store_id: null,
        project_id: null,
        timestamp: Date.now(),
      });
    });
  }

  public togglePopoverOpenId(id: number) {
    const { popoverOpenId } = this.state;

    this.setState({ popoverOpenId: popoverOpenId ? null : id });
  }

  public handleDelete = (id: number, { name, type }: { name: string; type: string }) => {
    return this.props.deleteAnnouncement(id, { name, type });
  };

  public render() {
    const { isMobile, isAdmin, currentUserId } = this.props;

    return (
      <Page>
        <h1>Announcements</h1>
        <NewAnnouncementForm {...this.props} />
        <Card className="announcements__table">
          <Card.Body>
            <Table
              columnStyles={{ borderTop: "none" }}
              isMobile={isMobile}
              showEmptyMobileHeader={false}
              showHeader={false}
              data={this.state.tableData}
              defaultSort={false}
              columns={[
                {
                  field: "",
                  header: "",
                  sortable: false,
                  dataQa: "announcement",
                  formatter: (row: any, index: number) => {
                    if (row.user.id !== currentUserId && !isAdmin) {
                      return <Announcement {...row} />;
                    }

                    // only allow users to delete Announcements they have created (unless they are the admin)
                    return (
                      <div className="announcement__delete-button-container">
                        <div className="announcement__delete-button">
                          <Popover
                            buttonStyles={{ height: 20, width: 20, padding: 0 }}
                            message={"Delete this announcement"}
                            id={row.id}
                            index={index}
                            onConfirm={(id) =>
                              this.handleDelete(id, {
                                name: `${row.chain.name} - ${row.project.name}`,
                                type: row.project.type,
                              })
                            }
                          />
                        </div>
                        <Announcement {...row} />
                      </div>
                    );
                  },
                },
              ]}
              emptyState="No announcements found"
            />
          </Card.Body>
        </Card>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    user: state.auth_user.content,
    announcements: state.announcements.content,
    hasErrors: state.surveys.hasError,
    errors: state.surveys.errors,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
    currentUserId: state.auth_user.content && state.auth_user.content.id,
    isAdmin: state.auth_user.content && state.auth_user.content.role === Constants.AB_SYSTEM_ADMIN,
  }),
  {
    fetchMyAnnouncements,
    createAnnouncement,
    deleteAnnouncement,
    trackPageView,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageAnnouncements);
