import downArrowSvg from "assets/img/arrow-down.svg";
import upArrowSvg from "assets/img/arrow-up.svg";
import emptyTableStateSvg from "assets/img/empty-table-state.svg";
import cx from "classnames";
import Loading from "components/Loading";
import MobileTable from "components/MobileTable";
import { Body } from "components/Typography";
import React from "react";
import ReactTable, {
  Column,
  ColumnRenderProps,
  FinalState,
  ReactTableDefaults,
  RowInfo,
  RowRenderProps,
  SortingRule,
} from "react-table";
import selectTable from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";

const SelectTable = selectTable(ReactTable);

export interface ColumnProps {
  show?: boolean;
  field: string;
  header: string;
  className?: string;
  formatter?: (value: string, row?: any, index?: number) => string | JSX.Element | null;
  dataQa?: string;
  sortable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  wrap?: boolean;
  textAlign?: string;
  Expander?: any;
}

interface TableProps {
  data: object[];
  columns: ColumnProps[];
  emptyState?: string;
  headerClassName?: string;
  onRowClick?: (e: React.MouseEvent<any>) => void;
  isMobile?: boolean;
  isScrollable?: boolean;
  style?: object;
  loading?: boolean;
  showPagination?: boolean;
  pageSize?: number;
  showEmptyMobileHeader?: boolean;
  showHeader?: boolean;
  columnStyles?: React.CSSProperties;
  defaultSort?: boolean;
  customSort?: SortingRule[];
  useSelect?: boolean;
  selectInput?: any;
  updateSelections?: (selection: any) => void;
  LoadingComponent?: Element;
}

interface ColumnState {
  id: string;
  desc: boolean;
  sortable: boolean;
}

interface TableState {
  columns: ColumnState[];
  selection?: any;
  selectAll?: any;
  expandedRows?: any;
}

const defaultColStyle = {
  padding: 16,
  marginTop: "-1px",
  borderTop: "1px solid #ebebeb",
  borderLeft: "none",
  borderRight: "none",
  fontSize: 14,
  fontFamily: "sans-serif",
  whiteSpace: "unset",
};

const DEFAULT_PAGE_SIZE = 100;

export default class Table extends React.Component<TableProps, TableState> {
  public constructor(props: TableProps) {
    super(props);
    this.state = {
      columns: props.columns.map((c) => ({
        id: c.field,
        desc: true,
        sortable: c.sortable !== false,
      })),
    };
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  public selectTable: any;

  public toggleSelection = (key: any, shift: any, row: any) => {
    // start off with the existing state
    let selection: any = this.state.selection ? { ...this.state.selection } : {};
    const keyIndex = selection[key];
    // check to see if the key exists
    if (keyIndex) {
      // it does exist so we will remove it using destructing
      delete selection[key];
    } else {
      // it does not exist so add it
      selection[key] = row;
    }
    if (this.state.selectAll) {
      this.setState({ selectAll: !this.state.selectAll });
    }
    // update the state
    this.setState({ selection });
    if (this.props.updateSelections) {
      this.props.updateSelections(selection);
    }
  };

  public toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection: any = {};
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item: any) => {
        if (item._original) {
          selection[item._original._id] = item._original;
        }
      });
    }
    this.setState({ selectAll, selection });
    if (this.props.updateSelections) {
      this.props.updateSelections(selection);
    }
  };

  public isSelected = (key: any) => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection ? this.state.selection[key] : false;
  };

  selectInput = (props: any) => {
    return (
      <input
        type={props.selectType || "checkbox"}
        className={"dir-checkbox"}
        checked={props.checked ? true : false}
        id={`checkbox-${props.id}`}
        onClick={(e) => {
          const { shiftKey } = e;
          e.stopPropagation();
          props.onClick(props.id, shiftKey, props.row);
        }}
        onChange={() => {}}
        value=""
      />
    );
  };

  selectAllInput = (props: any) => {
    return (
      <input
        type={props.selectType || "checkbox"}
        className={"dir-checkbox"}
        checked={this.state.selectAll}
        id={`select-all-checkbox`}
        onClick={(e) => {
          const { shiftKey } = e;
          e.stopPropagation();
          props.onClick(props.id, shiftKey, props.row);
        }}
        onChange={() => {}}
        value=""
      />
    );
  };

  public render() {
    const {
      data,
      columns,
      emptyState,
      headerClassName,
      isScrollable,
      onRowClick,
      loading,
      showPagination = this.props.data.length > 500,
      pageSize = DEFAULT_PAGE_SIZE,
      isMobile,
      showEmptyMobileHeader = true,
      defaultSort = true,
      customSort,
      columnStyles = {},
    } = this.props;

    if (isMobile) {
      if (data.length === 0) {
        return this.renderMobileEmptyState();
      } else {
        return data.map((datum, i) => (
          <MobileTable
            onRowClick={onRowClick}
            index={i}
            key={i}
            columns={columns}
            data={datum}
            isScrollable={isScrollable}
            showEmptyHeader={showEmptyMobileHeader}
          />
        ));
      }
    }
    const { toggleSelection, toggleAll, isSelected } = this;
    const extraProps = {
      isSelected,
      toggleAll,
      toggleSelection,
      ref: (r: any) => {
        this.selectTable = r;
      },
      LoadingComponent: () => (
        <div className={`-loading ${loading && `-active`}`}>
          <div className="-loading-inner">
            <Loading />
          </div>
        </div>
      ),
    };

    if (this.props.useSelect) {
      const defaultExpandedRows = data.map((element, index) => {
        return { index: true };
      });
      return (
        <SelectTable
          {...extraProps}
          selectType="checkbox"
          loading={loading}
          SelectInputComponent={this.selectInput}
          SelectAllInputComponent={this.selectAllInput}
          loadingText=""
          data={data}
          columns={columns.map((c) => this.transformColumn(c, columnStyles))}
          showPagination={showPagination}
          pageSize={showPagination ? pageSize : data.length}
          showPageJump={false}
          showPageSizeOptions={false}
          resizable={false}
          minRows={0}
          expanded={defaultExpandedRows}
          defaultSorted={
            (customSort && customSort) ||
            (defaultSort && [{ id: "field", desc: true }]) ||
            undefined
          }
          style={{
            border: 0,
            ...this.props.style,
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName,
            headerStyle: {
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 16,
              border: "1px solid #ebebeb",
              borderLeft: "none",
              borderRight: "none",
              borderTop: "none",
              background: "transparent",
              boxShadow: "none",
              fontSize: 14,
              fontWeight: "bold",
              fontFamily: "serif",
              outline: "none",
            },
            style: { ...defaultColStyle, ...columnStyles },
          }}
          ThComponent={({ toggleSort, className, children, onClick, column, ...rest }) => {
            const { showHeader = true } = this.props;
            if (column === "_selector") {
              return (
                <div
                  className={cx("rt-th", className) + ` -checkbox-header`}
                  role="columnheader"
                  title="Select All"
                  {...rest}
                >
                  {children}
                </div>
              );
            }

            const [[desc, sortable]] = this.state.columns
              .filter((c) => c.id === column)
              .map((c) => [c.desc, c.sortable]);

            if (!showHeader) {
              return null;
            }

            return (
              <div
                className={cx("rt-th", className)}
                onClick={(e) => {
                  e.persist();

                  return onClick(e, () => sortable && toggleSort && toggleSort(e));
                }}
                role="columnheader"
                tabIndex={-1}
                {...rest}
              >
                {children}
                {sortable && (
                  <span
                    style={{
                      marginLeft: 10,
                      display: "flex",
                    }}
                  >
                    {desc ? <img src={downArrowSvg} alt="" /> : <img src={upArrowSvg} alt="" />}
                  </span>
                )}
              </div>
            );
          }}
          getTheadProps={(state: FinalState, rowInfo: undefined) => {
            return {
              style: {
                boxShadow: "none",
              },
            };
          }}
          getTheadThProps={(
            state: FinalState,
            rowInfo: undefined,
            column: Column | undefined,
            instance: any
          ) => ({
            ...ReactTableDefaults.getTheadThProps,
            column: column && column.id,
            onClick: (e: React.SyntheticEvent<HTMLElement>, handleOriginal: () => any) => {
              this.setState(
                (prevState: TableState) => ({
                  columns: prevState.columns.map((c) => {
                    if (c.id === (column && column.id)) {
                      c.desc = !c.desc;
                    } else {
                      c.desc = true;
                    }

                    return c;
                  }),
                }),
                () => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              );
            },
          })}
          noDataText={emptyState || "No data found"}
          getNoDataProps={() => ({
            style: {
              position: "relative",
              transform: "none",
              width: 150,
              height: 150,
              marginTop: 30,
            },
          })}
          NoDataComponent={({ children, style }: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "16em",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      zIndex: 10000,
                      minHeight: "16em",
                    }}
                  >
                    <Loading />
                  </div>
                ) : (
                  <React.Fragment>
                    <img
                      style={style}
                      src={emptyTableStateSvg}
                      data-qa="empty-state-image"
                      alt=""
                    />
                    <Body
                      type="2"
                      style={{
                        marginTop: 12,
                        color: "#8d8d92",
                      }}
                      data-qa="empty-state-text"
                    >
                      {children}
                    </Body>
                  </React.Fragment>
                )}
              </div>
            );
          }}
          getTrProps={(
            state: any,
            rowInfo: RowInfo | undefined,
            column: undefined,
            instance: any
          ) => {
            let additionalClasses = "";
            if (rowInfo && rowInfo.row.version && rowInfo.row.version < 0) {
              additionalClasses = "previous-file";
            }
            return {
              style: {
                cursor: onRowClick ? "pointer" : "inherit",
              },
              onClick: (e: any) => onRowClick && onRowClick(rowInfo && rowInfo.row._original),
              className: `${additionalClasses} ${onRowClick && "table-row-clickable"}`,
            };
          }}
          getTdProps={() => {
            return { style: { overflow: "visible" } };
          }}
          getTbodyProps={() => {
            return {
              style: this.props.isScrollable ? { overflowY: "scroll" } : { overflow: "visible" },
            };
          }}
          getTableProps={() => {
            return {
              style: this.props.isScrollable ? { overflowY: "scroll" } : { overflow: "visible" },
            };
          }}
        />
      );
    }

    return (
      <ReactTable
        loading={loading}
        loadingText=""
        data={data}
        columns={columns.map((c) => this.transformColumn(c, columnStyles))}
        showPagination={showPagination}
        pageSize={showPagination ? pageSize : data.length}
        showPageJump={false}
        showPageSizeOptions={false}
        resizable={false}
        minRows={0}
        defaultSorted={
          (customSort && customSort) || (defaultSort && [{ id: "field", desc: true }]) || undefined
        }
        style={{
          border: 0,
          ...this.props.style,
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName,
          headerStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: 16,
            border: "1px solid #ebebeb",
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            background: "transparent",
            boxShadow: "none",
            fontSize: 14,
            fontWeight: "bold",
            fontFamily: "serif",
            outline: "none",
          },
          style: { ...defaultColStyle, ...columnStyles },
        }}
        ThComponent={({ toggleSort, className, children, onClick, column, ...rest }) => {
          const { showHeader = true } = this.props;

          const [[desc, sortable]] = this.state.columns
            .filter((c) => c.id === column)
            .map((c) => [c.desc, c.sortable]);

          if (!showHeader) {
            return null;
          }

          return (
            <div
              className={cx("rt-th", className)}
              onClick={(e) => {
                e.persist();

                return onClick(e, () => sortable && toggleSort && toggleSort(e));
              }}
              role="columnheader"
              tabIndex={-1}
              {...rest}
            >
              {children}
              {sortable && (
                <span
                  style={{
                    marginLeft: 10,
                    display: "flex",
                  }}
                >
                  {desc ? <img src={downArrowSvg} alt="" /> : <img src={upArrowSvg} alt="" />}
                </span>
              )}
            </div>
          );
        }}
        getTheadProps={(state: FinalState, rowInfo: undefined) => {
          return {
            style: {
              boxShadow: "none",
            },
          };
        }}
        getTheadThProps={(
          state: FinalState,
          rowInfo: undefined,
          column: Column | undefined,
          instance: any
        ) => ({
          ...ReactTableDefaults.getTheadThProps,
          column: column && column.id,
          onClick: (e: React.SyntheticEvent<HTMLElement>, handleOriginal: () => any) => {
            this.setState(
              (prevState: TableState) => ({
                columns: prevState.columns.map((c) => {
                  if (c.id === (column && column.id)) {
                    c.desc = !c.desc;
                  } else {
                    c.desc = true;
                  }

                  return c;
                }),
              }),
              () => {
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            );
          },
        })}
        noDataText={emptyState || "No data found"}
        getNoDataProps={() => ({
          style: {
            position: "relative",
            transform: "none",
            width: 150,
            height: 150,
            marginTop: 30,
          },
        })}
        NoDataComponent={({ children, style }: any) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "16em",
              }}
            >
              {loading ? (
                <div
                  style={{
                    zIndex: 10000,
                    minHeight: "16em",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <React.Fragment>
                  <img style={style} src={emptyTableStateSvg} data-qa="empty-state-image" alt="" />
                  <Body
                    type="2"
                    style={{
                      marginTop: 12,
                      color: "#8d8d92",
                    }}
                    data-qa="empty-state-text"
                  >
                    {children}
                  </Body>
                </React.Fragment>
              )}
            </div>
          );
        }}
        getTrProps={(
          state: any,
          rowInfo: RowInfo | undefined,
          column: undefined,
          instance: any
        ) => {
          return {
            style: {
              cursor: onRowClick ? "pointer" : "inherit",
            },
            onClick: (e: any) => onRowClick && onRowClick(rowInfo && rowInfo.row._original),
            className: onRowClick && "table-row-clickable",
          };
        }}
        getTdProps={() => {
          return { style: { overflow: "visible" } };
        }}
        getTbodyProps={() => {
          return {
            style: this.props.isScrollable ? { overflowY: "scroll" } : { overflow: "visible" },
          };
        }}
        getTableProps={() => {
          return {
            style: this.props.isScrollable ? { overflowY: "scroll" } : { overflow: "visible" },
          };
        }}
      />
    );
  }

  public renderMobileEmptyState() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ marginTop: 20 }}
          src={emptyTableStateSvg}
          data-qa="empty-state-image"
          alt=""
        />
        <Body
          type="2"
          style={{ marginTop: 12, paddingBottom: 20, color: "#8d8d92" }}
          data-qa="empty-state-text"
        >
          {this.props.emptyState}
        </Body>
      </div>
    );
  }

  public transformColumn = (
    { show = true, ...column }: ColumnProps,
    styles: React.CSSProperties = {}
  ) => ({
    Header: (props: ColumnRenderProps) => <h6 data-qa={column.dataQa}>{column.header}</h6>,
    accessor: column.field,
    id: column.field,
    show,
    style: column.textAlign
      ? {
          textAlign: column.textAlign,
          ...defaultColStyle,
          ...styles,
        }
      : { ...defaultColStyle, ...styles },
    // min-width can not be set to undefined otherwise it wonks up the table
    ...(column.minWidth ? { minWidth: column.minWidth } : {}),
    maxWidth: column.maxWidth,
    className: cx({ "no-wrap": column.wrap }),
    expander: column.field === "expander",
    Expander: column.Expander,
    Cell: (row: RowRenderProps) => {
      const classes = `body b3 ${column.className}`;
      return (
        <span className={classes} data-qa={column.dataQa}>
          {column.formatter
            ? column.formatter(row.value, row.original, row.index)
            : row.value
            ? row.value
            : ""}
        </span>
      );
    },
  });
}
