import cx from "classnames";
import React from "react";

export interface TooltipProps extends React.HTMLAttributes<{}> {
  message: string;
  subText?: string;
}

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  message,
  subText,
  className,
  ...props
}) => (
  <div className={cx("tooltip", className)} {...props} role="tooltip">
    {message}
    {subText && <p className="small b4">{subText}</p>}
  </div>
);
