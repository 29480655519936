import Dropdown from "components/Dropdown";
import Constants from "helpers/constants";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { fetchMyWholesalers, fetchWholesalers } from "state/wholesalers";

export interface FilterOptions {
  number: string;
  name: string;
}

interface WholesalersFilterOwnProps {
  name?: string;
  defaultOptionLabel?: string;
  dataQa?: string;
  placeholder?: string;
  onChange: (options: FilterOptions) => any;
  noShadow?: boolean;
  minWidth?: number;
  minHeight?: number;
  value?: string;
}

type WholesalersFilterProps = WholesalersFilterOwnProps & PropsFromRedux;

interface WholesalersFilterState {
  current: string;
}

const DROPDOWN_PLACEHOLDER = "Select Wholesaler";

const defaultState: WholesalersFilterState = { current: "" };

class WholesalerFilter extends React.Component<WholesalersFilterProps, WholesalersFilterState> {
  public state = { ...defaultState };

  public componentDidMount() {
    const { isAdmin } = this.props;

    return isAdmin ? this.props.fetchWholesalers() : this.props.fetchMyWholesalers();
  }

  public render() {
    const {
      wholesalers = [],
      onChange,
      defaultOptionLabel = DROPDOWN_PLACEHOLDER,
      placeholder = DROPDOWN_PLACEHOLDER,
      ...restProps
    } = this.props;

    const dropdownOptions = [{ value: "", label: defaultOptionLabel }].concat(
      wholesalers
        .map((wholesaler: any) => {
          const wholesalerName = wholesaler.name;
          const wholesalerNum = wholesaler.number
          return {
            "data-qa": wholesalerName + " - " + wholesalerNum,
            value: wholesaler.number,
            label: wholesalerName + " - " + wholesalerNum,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    );

    return (
      <Dropdown
        {...restProps}
        dataQa="wholesaler-dropdown"
        value={
          dropdownOptions.find((option) =>
            this.props.value !== undefined
              ? option.value === this.props.value
              : option.value === this.state.current
          ) || (defaultOptionLabel ? dropdownOptions[0] : null)
        }
        containerStyles={{
          marginLeft: "unset",
          background: "white",
          minWidth: this.props.minWidth ? this.props.minWidth : 290,
        }}
        controlStyles={{
          minHeight: this.props.minHeight ? this.props.minHeight : 35,
        }}
        options={dropdownOptions}
        onChange={(selected: any) => {
          this.setState({ current: selected.value }, () => {
            if (onChange) {
              onChange({
                number: this.state.current,
                name: selected.label,
              });
            }
          });
        }}
        placeholder={placeholder}
        isSearchable
      />
    );
  }
}

const connecter = connect(
  (state: State) => ({
    isAdmin:
      state.auth_user.content &&
      state.auth_user.content.role &&
      state.auth_user.content.role === Constants.AB_SYSTEM_ADMIN,
    wholesalers: state.wholesalers.content,
  }),
  { fetchMyWholesalers, fetchWholesalers }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(WholesalerFilter);
