import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Announcement, CreateAnnouncement } from "interfaces/announcement";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { EventTypes, AnalyticsProjectFields } from "state/analytics";

// Action types
const CREATE_ANNOUNCEMENT_REQUEST = "CREATE_ANNOUNCEMENT_REQUEST";
const CREATE_ANNOUNCEMENT = "CREATE_ANNOUNCEMENT";
const CREATE_ANNOUNCEMENT_FAILED = "CREATE_ANNOUNCEMENT_FAILED";

const DELETE_ANNOUNCEMENT_REQUEST = "DELETE_ANNOUNCEMENT_REQUEST";
const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
const DELETE_ANNOUNCEMENT_FAILED = "DELETE_ANNOUNCEMENT_FAILED";

export {
  CREATE_ANNOUNCEMENT_REQUEST,
  CREATE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT_FAILED,
  DELETE_ANNOUNCEMENT_REQUEST,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_FAILED,
};

export const createAnnouncement: ThunkActionCreator =
  (announcement: CreateAnnouncement, { name, type }: AnalyticsProjectFields) =>
  (dispatch: Dispatch) => {
    dispatch({ type: CREATE_ANNOUNCEMENT_REQUEST });

    return axios
      .post(`${Config.API_SERVICE_URL}/api/announcements`, announcement, getHeaders())
      .then((response) =>
        dispatch({
          type: CREATE_ANNOUNCEMENT,
          payload: response.data,
          meta: {
            analytics: {
              event: EventTypes.ANNOUNCEMENT_SUBMIT,
              eventType: "Action",
              projectName: name,
              projectType: type,
            },
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: CREATE_ANNOUNCEMENT_FAILED,
          payload: error.response.data,
        })
      );
  };

export const deleteAnnouncement: ThunkActionCreator<Promise<void>> =
  (announcementId: number, { name, type }: AnalyticsProjectFields) =>
  (dispatch: Dispatch) => {
    dispatch({ type: DELETE_ANNOUNCEMENT_REQUEST });

    return axios
      .delete(`${Config.API_SERVICE_URL}/api/announcements/${announcementId}`, getHeaders())
      .then(() => {
        dispatch({
          type: DELETE_ANNOUNCEMENT,
          payload: announcementId,
          meta: {
            analytics: {
              event: EventTypes.ANNOUNCEMENT_DELETE,
              eventType: "Action",
              projectName: name,
              projectType: type,
            },
          },
        });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_ANNOUNCEMENT_FAILED });
      });
  };

// Actions
const actions = {
  [CREATE_ANNOUNCEMENT_REQUEST]: (state: AnnouncementsState): AnnouncementsState => ({
    ...state,
    loading: true,
    hasError: false,
  }),
  [CREATE_ANNOUNCEMENT]: (state: AnnouncementsState, action: AnyAction): AnnouncementsState => ({
    ...state,
    loading: false,
    content: action.payload,
    hasError: false,
  }),
  [CREATE_ANNOUNCEMENT_FAILED]: (
    state: AnnouncementsState,
    action: AnyAction
  ): AnnouncementsState => ({
    ...state,
    loading: false,
    hasError: true,
    errors: action.payload,
    content: undefined,
  }),
  [DELETE_ANNOUNCEMENT_REQUEST]: (state: AnnouncementsState): AnnouncementsState => ({
    ...state,
    loading: true,
  }),
  [DELETE_ANNOUNCEMENT]: (state: AnnouncementsState, action: AnyAction): AnnouncementsState => ({
    ...state,
    loading: false,
    content: undefined,
    hasError: false,
  }),
  [DELETE_ANNOUNCEMENT_FAILED]: (
    state: AnnouncementsState,
    action: AnyAction
  ): AnnouncementsState => ({
    ...state,
    loading: false,
    hasError: true,
  }),
};

export interface AnnouncementsState {
  hasError?: boolean;
  errors?: string[];
  loading?: boolean;
  content?: Announcement;
}

const initialState: AnnouncementsState = {
  loading: false,
  hasError: false,
  errors: [],
  content: undefined,
};

export default handleActions<AnnouncementsState, AnyAction>(actions, initialState);
