import cx from "classnames";
import React from "react";

import Button, { ButtonProps } from "components/Button";

const FormButtonGroup: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
}) => <div className={cx(className, "form-button-group")}>{children}</div>;

const FormButton: React.FunctionComponent<ButtonProps> = ({
  className,
  children,
  ...restProps
}) => (
  <Button className={cx(className, "form-button")} {...restProps}>
    {children}
  </Button>
);

const ConfirmButton: React.FunctionComponent<React.ButtonHTMLAttributes<{}>> = (props) => (
  <FormButton type="submit" {...props} />
);

const CancelButton: React.FunctionComponent<React.ButtonHTMLAttributes<{}>> = (props) => (
  <FormButton secondary={true} {...props} />
);

const FormGroup: React.FunctionComponent<React.LabelHTMLAttributes<{}> & { span?: number }> = ({
  className,
  children,
  title,
  htmlFor,
  span,
  ...restProps
}) => (
  <label htmlFor={htmlFor} className={cx(className, "form-group-label")}>
    <h6 className="form-group-title">{title}</h6>
    <div className={cx("form-group", { [`form-group-span-${span}`]: span })} {...restProps}>
      {children}
    </div>
  </label>
);

const FormInput: React.FunctionComponent<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  ...restProps
}) => <input className={cx(className, "form-input")} {...restProps} />;

const FormTextarea: React.FunctionComponent<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  className,
  ...restProps
}) => <textarea className={cx(className, "form-input", "form-textarea")} {...restProps} />;

type FormProps = React.HTMLAttributes<HTMLFormElement>;
export default class Form extends React.Component<FormProps> {
  public static Input = FormInput;
  public static Textarea = FormTextarea;
  public static Group = FormGroup;
  public static ButtonGroup = FormButtonGroup;
  public static ConfirmButton = ConfirmButton;
  public static CancelButton = CancelButton;

  public render() {
    const { children, className, ...restProps } = this.props;

    return (
      <form className={cx("form", className)} {...restProps}>
        {children}
      </form>
    );
  }
}
