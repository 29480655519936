import debounce from "lodash/debounce";
import React from "react";

import Dropdown from "components/Dropdown";
import Highlighter from "helpers/highlighter";
import { ReactSelectObject } from "interfaces/select";

interface ProductDropdownProps {
  productsList?: ReactSelectObject[];
  isSearchable: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  containerStyles: object;
  noOptionsMessage: () => void;
  onChange?: (newValue: any, action: object) => void;
  product_id?: string | number;
  product_name?: string;
  dataQa?: string;
}

interface ProductDropdownState {
  query: string;
  showPlaceholder: boolean;
  productsSearchResults: any[];
  selectedProduct: string;
}

const defaultState = {
  query: "",
  showPlaceholder: true,
  productsSearchResults: [],
  selectedProduct: "",
};


export default class ProductDropdown extends React.Component<
  ProductDropdownProps,
  ProductDropdownState
> {
  constructor(props: any) {
    super(props);
    this.state = defaultState;
  }

  public handleOpen = () => {
    this.setState({ showPlaceholder: false });
  };

  public handleClose = () => {
    this.setState({ showPlaceholder: true });
  };

  public handleInputChange = (query: string, action: any) => {
    this.setState({
      // only search if more than 2 characters have been entered
      productsSearchResults: query.length > 2 ? this.searchProducts(query) : [],
    });
  };

  public searchProducts = (query: string) => {
    this.setState({ query });
    const lowercaseQuery = query.toLowerCase();
    return this.props.productsList
      ? this.props.productsList
          .filter((product: ReactSelectObject) => {
            return (
              product.label.toLowerCase().indexOf(lowercaseQuery) > -1 ||
              (product.upc && product.upc.toString().indexOf(lowercaseQuery) > -1)
            );
          })
          // only show (render) the first 25 results
          .slice(0, 25)
      : [];
  };

  public formatOption = (props: any, context: any) => {
    const trigger = context.context;
    let label = props.label;
    if (props.upc && trigger === "menu") {
      label = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span data-qa="product-option">
            {this.state.query ? Highlighter(this.state.query, label) : label}
          </span>
          <span>
            {this.state.query
              ? Highlighter(this.state.query, props.upc.toString())
              : props.upc.toString()}
          </span>
        </div>
      );
    } else if (this.state.query && trigger === "menu") {
      return Highlighter(this.state.query, label);
    }
    return label;
  };

  public render() {
    const { product_id, product_name, placeholder, ...props } = this.props;
    const hasProductId = product_id !== 'null';
    return (
      <Dropdown
        {...props}
        onInputChange={debounce((e: any, action: any) => this.handleInputChange(e, action), 100)}
        onMenuOpen={this.handleOpen}
        onMenuClose={this.handleClose}
        formatOptionLabel={this.formatOption}
        options={this.state.productsSearchResults || []}
        placeholder={this.state.showPlaceholder ? placeholder : ""}
        value={
          product_id && hasProductId && {
            label: product_name,
            value: product_id,
          }
        }
      />
    );
  }
}
