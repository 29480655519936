import React from "react";
import { connect } from "react-redux";

import Breadcrumb from "components/Breadcrumb";
import { State } from "state";
import { BreadcrumbState } from "state/breadcrumb";

interface BreadcrumbProps {
  breadcrumb: BreadcrumbState;
}

class NavigationBreadcrumb extends React.Component<BreadcrumbProps> {
  public render() {
    const {
      breadcrumb: { crumbs = [] },
    } = this.props;

    return <Breadcrumb crumbs={crumbs} />;
  }
}

const mapStateToProps = (state: State) => ({
  breadcrumb: state.breadcrumb,
});

export default connect(mapStateToProps)(NavigationBreadcrumb);
