import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Base64Logo from "components/Base64Logo";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import Page from "components/Page";
import { getPillProps, Pill } from "components/Pill";
import Table from "components/Table";
import Constants from "helpers/constants";
import { State } from "state";
import { createProject } from "state/create_project";
import { fetchAllProjects } from "state/my_projects";

type ProjectsProps = RouteComponentProps & PropsFromRedux;

class Projects extends React.Component<ProjectsProps> {
  public componentDidMount() {
    this.props.fetchAllProjects();
  }

  public handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { history } = this.props;

    this.props
      .createProject({ name: Constants.CREATE_PROJECT_DEFAULT_NAME })
      .then(() => {
        const { id } = this.props.create_project || {};

        if (id) {
          history.push(`/create-project/${id}`);
        }
      })
      .catch(() => {
        // handle error state here eventually
      });
  };

  public renderProjects() {
    const { projects } = this.props;

    if (!projects || (Array.isArray(projects) && projects.length === 0)) {
      return null;
    }

    return (
      <Table
        data={projects as object[]}
        emptyState={"No projects found"}
        onRowClick={(row: any) => {
          const { history } = this.props;
          history.push(`/create-project/${row.project_id}`);
        }}
        customSort={[{ id: "project_name", asc: true }]}
        columns={[
          {
            field: "chain",
            formatter: (value, row) => {
              return (
                <div>
                  <Base64Logo data={row.chain && row.chain.logo} logo_filename={row.chain && row.chain.logo_filename}/>
                  {row.chain && <span style={{ marginLeft: 10 }}>{row.chain.name}</span>}
                </div>
              );
            },
            header: "CHAIN",
            dataQa: "project_chain_name",
            className: "project-chain-name",
          },
          {
            field: "project_name",
            header: "PROJECT NAME",
            dataQa: "project_name",
          },
          {
            field: "project_type",
            header: "PROJECT TYPE",
            dataQa: "project_type",
          },
          {
            field: "published",
            header: "Status",
            dataQa: "project_status_pill",
            formatter: (value, row) => {
              const pill = getPillProps(
                row.published ? Constants.PUBLISHED : Constants.UNPUBLISHED
              );

              return <Pill {...pill} />;
            },
          },
          {
            field: "updated_at",
            header: "UPDATE DATE",
            formatter: (value) => moment(value).format("M/D/YYYY"),
            dataQa: "project_updatedate",
          },
        ]}
      />
    );
  }

  public render() {
    return (
      <Page>
        <div className="projects-header">
          <h1>View All Projects</h1>
          <Button onClick={this.handleClick} data-qa="create-project-button">
            Create New Project
          </Button>
        </div>
        <CardList>
          <Card>{this.renderProjects()}</Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    projects: state.my_projects.content,
    create_project: state.create_project.content,
  }),
  { fetchAllProjects, createProject }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(Projects));
