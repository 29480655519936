export const getItem = (key: string): string => {
  let item;
  try {
    item = localStorage.getItem(key);
  } catch (e) {
    // use default value
  }

  return item || "";
};

export const getItemJson = (key: string): object | undefined => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const setItem = (key: string, value: any, stringify: boolean = true) => {
  try {
    localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
  } catch (e) {
    // avoid errors in Safari private mode
  }
};

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // avoid errors in Safari private mode
  }
};
