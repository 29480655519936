import axios from "axios";
import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { PaginatedQuery, PaginatedResponse } from "interfaces/paginated-query";
import { ThunkActionCreator } from "interfaces/thunk";
import { User } from "interfaces/user";
import queryString from "query-string";
import { handleActions } from "redux-actions";
import { CREATE_PROJECT_REQUEST } from "state/create_project";
import { DELETE_USER } from "state/user";

// Actions Types
export const FETCH_USERS_BY_PROJECT_REQUEST = "FETCH_USERS_BY_PROJECT_REQUEST";
export const FETCH_USERS_BY_PROJECT = "FETCH_USERS_BY_PROJECT";
export const FETCH_USERS_BY_PROJECT_FAILED = "FETCH_USERS_BY_PROJECT_FAILED";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const FILTER_USERS_REQUEST = "FILTER_USERS_REQUEST";
export const FILTER_USERS = "FILTER_USERS";
export const FILTER_USERS_FAILED = "FILTER_USERS_FAILED";

// Thunk Action Creator
const fetchUsers: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  return axios
    .get(`${Config.API_SERVICE_URL}/api/users`, getHeaders())
    .then((response) => dispatch({ type: FETCH_USERS, payload: { data: response.data } }))
    .catch((error: Error) => dispatch({ type: FETCH_USERS_FAILED }));
};

const fetchUsersByProjectId: ThunkActionCreator =
  (projectId: number, queryOptions: PaginatedQuery = { size: 100000000, page: 0 }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: FETCH_USERS_BY_PROJECT_REQUEST });

    // TODO: Update default queryOptions once the API endpoint supports requesting all records
    const url = `${Config.API_SERVICE_URL}/api/projects/${projectId}/users?${queryString.stringify(
      queryOptions
    )}`;

    return axios
      .get(url, getHeaders())
      .then((response) => dispatch({ type: FETCH_USERS_BY_PROJECT, payload: response.data }))
      .catch((error: Error) => dispatch({ type: FETCH_USERS_BY_PROJECT_FAILED }));
  };

  const filterUsers: ThunkActionCreator = (queryParams?: { email: string }) => (dispatch: Dispatch) => {
    dispatch({ type: FILTER_USERS_REQUEST });
    let url = `${Config.API_SERVICE_URL}/api/users`;

    if (queryParams) {
      url += `?email=${queryParams.email}`;
    }
    return axios
      .get(url, getHeaders())
      .then((response) => dispatch({ type: FILTER_USERS, payload: { data: response.data } }))
      .catch((error: Error) => dispatch({ type: FILTER_USERS_FAILED }));
  };

// Actions
const actions = {
  [CREATE_PROJECT_REQUEST]: () => ({ ...initialState }),
  [DELETE_USER]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    content: {
      data: (state.content?.data || []).filter((u: User) => u.id !== action.payload),
    },
  }),
  [FETCH_USERS_BY_PROJECT_REQUEST]: (state: UsersState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_USERS_BY_PROJECT]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_USERS_BY_PROJECT_FAILED]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
  [FETCH_USERS_REQUEST]: (state: UsersState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_USERS]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_USERS_FAILED]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
  [FILTER_USERS_REQUEST]: (state: UsersState) => ({
    ...state,
    loading: true,
  }),
  [FILTER_USERS]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FILTER_USERS_FAILED]: (state: UsersState, action: AnyAction): UsersState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  })
};

// Reducer Interface
export interface UsersState {
  error?: boolean;
  loading?: boolean;
  content?: PaginatedResponse<User>;
}

// Initial State
const initialState: UsersState = {
  loading: false,
  error: false,
  content: { data: [] },
};

export { fetchUsers, fetchUsersByProjectId, filterUsers, initialState };

// Reducer
export default handleActions<UsersState, AnyAction>(actions, initialState);
