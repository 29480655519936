import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { MyFeedback } from "interfaces/feedback";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
export const FETCH_MY_FEEDBACK_REQUEST = "FETCH_MY_FEEDBACK_REQUEST";
export const FETCH_MY_FEEDBACK = "FETCH_MY_FEEDBACK";
export const FETCH_MY_FEEDBACK_FAILED = "FETCH_MY_FEEDBACK_FAILED";

// Thunk Action Creator
const fetchMyFeedback: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_MY_FEEDBACK_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/users/me/comments`, getHeaders())
    .then((response) => dispatch({ type: FETCH_MY_FEEDBACK, payload: response.data }))
    .catch(() => dispatch({ type: FETCH_MY_FEEDBACK_FAILED }));
};

export { fetchMyFeedback };

// Actions
const actions = {
  [FETCH_MY_FEEDBACK_REQUEST]: (state: MyFeedbackState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MY_FEEDBACK]: (state: MyFeedbackState, action: AnyAction): MyFeedbackState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_MY_FEEDBACK_FAILED]: (state: MyFeedbackState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Reducer Interface
export interface MyFeedbackState {
  content?: MyFeedback[];
  error?: boolean;
  loading?: boolean;
}

// Initial State
const initialState: MyFeedbackState = {
  loading: false,
  error: false,
  content: [],
};

// Reducer
export default handleActions<MyFeedbackState, AnyAction>(actions, initialState);
