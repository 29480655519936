import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { DELETE_COMMENT, Comment } from "state/comment";
export const FETCH_COMMENTS_REQUEST = "FETCH_COMMENTS_REQUEST";
export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_FAILED = "FETCH_COMMENTS_FAILED";

export interface FetchCommentOptions {
  projectId: number | string;
  storeId: number | string;
}

// Thunk Action Creator
export const fetchComments: ThunkActionCreator<Promise<void>> =
  (options: FetchCommentOptions) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_COMMENTS_REQUEST });

    return axios
      .get(
        `${Config.API_SERVICE_URL}/api/projects/${options.projectId}/stores/${options.storeId}/comments`,
        getHeaders()
      )
      .then((response) => {
        dispatch({ type: FETCH_COMMENTS, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_COMMENTS_FAILED });
      });
  };

export interface CommentsState {
  loading: boolean;
  error: boolean;
  content?: Comment[];
}

const actions = {
  [FETCH_COMMENTS_REQUEST]: (state: CommentsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_COMMENTS]: (state: CommentsState, action: AnyAction): CommentsState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_COMMENTS_FAILED]: (state: CommentsState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [DELETE_COMMENT]: (state: CommentsState, action: AnyAction): CommentsState => ({
    ...state,
    loading: false,
    content: (state.content || []).filter((c) => c.id !== action.payload),
  }),
};

// Initial State
const initialState: CommentsState = {
  loading: false,
  error: false,
  content: [],
};

// Reducer
export default handleActions<CommentsState, AnyAction>(actions, initialState);
