import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ProjectComments } from "interfaces/projectComment";
import { ThunkActionCreator } from "interfaces/thunk";

export const FETCH_PROJECT_COMMENTS_REQUEST = "FETCH_PROJECT_COMMENTS_REQUEST";
export const FETCH_PROJECT_COMMENTS = "FETCH_PROJECT_COMMENTS";
export const FETCH_PROJECT_COMMENTS_FAILED = "FETCH_PROJECT_COMMENTS_FAILED";
export const FETCH_MY_PROJECT_COMMENTS_REQUEST = "FETCH_MY_PROJECT_COMMENTS_REQUEST";
export const FETCH_MY_PROJECT_COMMENTS = "FETCH_MY_PROJECT_COMMENTS";
export const FETCH_MY_PROJECT_COMMENTS_FAILED = "FETCH_MY_PROJECT_COMMENTS_FAILED";

export const fetchProjectComments: ThunkActionCreator<Promise<void>> =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PROJECT_COMMENTS_REQUEST });
    return axios
      .get(`${Config.API_SERVICE_URL}/api/projects/${id}/comments`, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_PROJECT_COMMENTS, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: FETCH_PROJECT_COMMENTS_FAILED });
      });
  };

export const fetchMyProjectComments: ThunkActionCreator = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_MY_PROJECT_COMMENTS_REQUEST });
  return axios
    .get(`${Config.API_SERVICE_URL}/api/projects/${id}/comments/me`, getHeaders())
    .then((response) => dispatch({ type: FETCH_MY_PROJECT_COMMENTS, payload: response.data }))
    .catch(() => dispatch({ type: FETCH_MY_PROJECT_COMMENTS_FAILED }));
};

const actions = {
  [FETCH_PROJECT_COMMENTS_REQUEST]: (state: ProjectCommentsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_PROJECT_COMMENTS]: (state: ProjectCommentsState, action: AnyAction) => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_PROJECT_COMMENTS_FAILED]: (state: ProjectCommentsState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [FETCH_MY_PROJECT_COMMENTS_REQUEST]: (state: ProjectCommentsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MY_PROJECT_COMMENTS]: (state: ProjectCommentsState, action: AnyAction) => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_MY_PROJECT_COMMENTS_FAILED]: (state: ProjectCommentsState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
export interface ProjectCommentsState {
  content?: ProjectComments;
  error?: boolean;
  loading?: boolean;
}

// Initial State
const initialState: ProjectCommentsState = {
  loading: false,
  error: false,
  content: [],
};

export default handleActions<ProjectCommentsState, AnyAction>(actions, initialState);
