import React from "react";
import { connect, ConnectedProps } from "react-redux";

import DonutChart from "components/DonutChart";
import LegendColorIndicator from "components/LegendColorIndicator";
import Loading from "components/Loading";
import Constants from "helpers/constants";
import { Project } from "interfaces/project";
import { State } from "state";
import { fetchProjectStatusDashboard } from "state/project_status_dashboard";

interface ProjectStatusDashboardOwnProps {
  isMobile?: boolean;
  project: Project;
}

type ProjectStatusDashboardProps = ProjectStatusDashboardOwnProps & PropsFromRedux;

export const ALLOWED_ROLES = [
  Constants.AB_PROJECT_ADMIN,
  Constants.AB_COLLABORATOR,
  Constants.AB_MERCHANDISER,
  Constants.AB_REGION_CAT_DIRECTOR,
  Constants.RETAILER,
  Constants.AB_VIEWER,
];

class ProjectStatusDashboard extends React.Component<ProjectStatusDashboardProps> {
  public componentDidMount() {
    this.updateChartData();
  }

  public componentDidUpdate(prevProps: ProjectStatusDashboardProps) {
    const {
      project: { id },
    } = prevProps;

    if (id !== this.props.project.id) {
      this.updateChartData();
    }
  }

  public updateChartData() {
    if (this.props.project.id) {
      this.props.fetchProjectStatusDashboard(this.props.project.id);
    }
  }

  public render() {
    const { isMobile } = this.props;
    const { data, labels, colors, total } = this.props.projectstatusdashboard;

    if (!data) {
      return <Loading />;
    }

    return (
      <div className="chart-wrapper" data-qa="project-status-dashboard">
        <DonutChart
          isMobile={isMobile}
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor: colors,
              },
            ],
          }}
          total={total || 0}
        />
        <div className="legend">
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="red" />
              Feedback in Progress
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="blue" />
              Feedback Complete
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="yellow" />
              Feedback in Review
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="orange" />
              Awaiting Final Upload
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="green" />
              Final Upload Complete
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    projectstatusdashboard: state.projectstatusdashboard.content,
  }),
  { fetchProjectStatusDashboard }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ProjectStatusDashboard);
