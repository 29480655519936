import Chart from "chart.js";
// @ts-ignore
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { defaults, Doughnut } from "react-chartjs-2";

// Thanks to an issue with the type definition, TS thinks
// there is no .global object on defaults so we have to ignore these lines
// @ts-ignore
defaults.global.defaultFontColor = "#fff";
// @ts-ignore
defaults.global.defaultFontFamily = "Roboto";
// @ts-ignore
defaults.global.defaultFontSize = 11;
Chart.plugins.register(ChartDataLabels);

/**
 * Chart.js code to draw the total in the center
 * Drawn from code here:
 * https://stackoverflow.com/questions/20966817/how-to-add-text-inside-the-doughnut-chart-using-chart-js
 */
Chart.pluginService.register({
  beforeDraw(chart) {
    // @ts-ignore
    if (chart.config.options.elements.center) {
      // Get ctx from string
      // @ts-ignore
      const ctx = chart.chart.ctx;

      // Get options from the center object in options
      // @ts-ignore
      const centerConfig = chart.config.options.elements.center;
      const fontStyle = centerConfig.fontStyle || "Arial";
      const txt = centerConfig.text;
      const color = centerConfig.color || "#000";
      const sidePadding = centerConfig.sidePadding || 20;
      const sidePaddingCalculated =
        // @ts-ignore
        (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width;
      // @ts-ignore
      const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor((30 * widthRatio) / 2);
      // @ts-ignore
      const elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      const fontSizeToUse = Math.min(newFontSize, elementHeight);

      // Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      // Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  },
});

interface DonutChartProps {
  data: Chart.ChartData;
  total: number;
  isMobile?: boolean;
}

const DonutChart: React.FunctionComponent<DonutChartProps> = ({ total, data, isMobile }) => (
  <Doughnut
    legend={{ display: false }}
    width={100}
    height={55}
    options={{
      animation: { duration: isMobile ? 0 : 500 },
      cutoutPercentage: 35,
      responsive: true,
      elements: {
        arc: {
          borderWidth: 0,
        },
        // Again, since we added this options via the plugin up top,
        // ts doesn't know that this object has this property.
        // @ts-ignore
        center: {
          text: total,
          fontStyle: "Roboto",
          sidePadding: 15,
        },
      },
      plugins: {
        datalabels: {
          formatter(value: number) {
            const percentage = (value / total) * 100;
            // Round up numbers less than 1% for display
            if (percentage < 1) {
              return `${value} (${Math.ceil(percentage)}%)`;
            } else {
              return `${value} (${Math.floor(percentage)}%)`;
            }
          },
        },
      },
    }}
    data={data}
  />
);

export default DonutChart;
