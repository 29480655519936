import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { Project } from "interfaces/project";
import { ThunkActionCreator } from "interfaces/thunk";
import { CREATE_PROJECT_REQUEST } from "state/create_project";

// Actions Types
export const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
export const FETCH_PROJECT = "FETCH_PROJECT";
export const FETCH_PROJECT_FAILED = "FETCH_PROJECT_FAILED";
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED";
export const TOGGLE_PROJECT_STARRING_REQUEST = "TOGGLE_PROJECT_STARRING_REQUEST";
export const TOGGLE_PROJECT_STARRING = "TOGGLE_PROJECT_STARRING";
export const TOGGLE_PROJECT_STARRING_FAILED = "TOGGLE_PROJECT_STARRING_FAILED";

// Thunk Action Creator
const fetchProject: ThunkActionCreator<Promise<void>> = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PROJECT_REQUEST });
  return axios
    .get(`${Config.API_SERVICE_URL}/api/projects/${id}`, getHeaders())
    .then((response) => {
      dispatch({ type: FETCH_PROJECT, payload: response.data });
    })
    .catch((_) => {
      dispatch({ type: FETCH_PROJECT_FAILED });
    });
};

const updateProject: ThunkActionCreator<Promise<void>> =
  (
    id: string,
    fields: {
      expires_at?: string;
      est_complete_date?: string;
      published?: boolean;
      season_id?: string;
      year?: string;
      publish_at?: string;
    }
  ) =>
  (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_PROJECT_REQUEST });

    return axios
      .patch(
        `${Config.API_SERVICE_URL}/api/projects/${id}`,
        JSON.stringify(fields),
        getHeaders({ "Content-Type": "application/json" })
      )
      .then((response) => {
        dispatch({ type: UPDATE_PROJECT, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: UPDATE_PROJECT_FAILED });
      });
  };

const toggleProjectStarring: ThunkActionCreator<Promise<void>> = ( id: string, starred: boolean ) => (dispatch: Dispatch) => {
    dispatch({ type: TOGGLE_PROJECT_STARRING_REQUEST });

    return axios
      .post(
        `${Config.API_SERVICE_URL}/api/projects/starred`,
        JSON.stringify({id, starred}),
        getHeaders({ "Content-Type": "application/json" })
      )
      .then((response) => {
        dispatch({ type: TOGGLE_PROJECT_STARRING, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: TOGGLE_PROJECT_STARRING_FAILED });
      });
  };

const deleteProject: ThunkActionCreator<Promise<void>> = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_PROJECT_REQUEST });

  return axios
    .delete(`${Config.API_SERVICE_URL}/api/projects/${id}`, getHeaders())
    .then((_) => {
      dispatch({ type: DELETE_PROJECT });
    })
    .catch(() => {
      dispatch({ type: DELETE_PROJECT_FAILED });
    });
};

export { fetchProject, updateProject, toggleProjectStarring, deleteProject };

// Actions
const actions = {
  [CREATE_PROJECT_REQUEST]: () => ({ ...initialState }),
  [FETCH_PROJECT_REQUEST]: (state: ProjectState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_PROJECT]: (state: ProjectState, action: AnyAction): ProjectState => ({
    ...state,
    loading: false,
    content: { ...action.payload },
  }),
  [FETCH_PROJECT_FAILED]: (state: ProjectState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [UPDATE_PROJECT_REQUEST]: (state: ProjectState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_PROJECT]: (state: ProjectState, action: AnyAction): ProjectState => ({
    ...state,
    error: false,
    loading: false,
    content: { ...action.payload },
  }),
  [UPDATE_PROJECT_FAILED]: (state: ProjectState) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [DELETE_PROJECT_REQUEST]: (state: ProjectState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_PROJECT]: (state: ProjectState): ProjectState => ({
    ...state,
    error: false,
    loading: false,
    content: undefined,
  }),
  [DELETE_PROJECT_FAILED]: (state: ProjectState) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [TOGGLE_PROJECT_STARRING_REQUEST]: (state: ProjectState) => ({
    ...state,
    loading: true,
  }),
  [TOGGLE_PROJECT_STARRING]: (state: ProjectState, action: AnyAction) => ({
    ...state,
    error: false,
    loading: false,
    content: {
      ...action.payload,
      starred: action.payload.starred,
    },
  }),
  [TOGGLE_PROJECT_STARRING_FAILED]: (state: ProjectState) => ({
    ...state,
    loading: false,
    error: true,
  }),
};

// Reducer Interface
export interface ProjectState {
  content?: Project;
  error?: boolean;
  loading?: boolean;
}

// Initial State
const initialState: ProjectState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<ProjectState, AnyAction>(actions, initialState);
