import cx from "classnames";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import errorSvg from "assets/img/error-red.svg";
import loadingSvg from "assets/img/loading.svg";
import successSvg from "assets/img/success.svg";
import uploadBlueSvg from "assets/img/upload-blue.svg";
import uploadInactive from "assets/img/upload-inactive.svg";
import uploadSvg from "assets/img/upload.svg";
import CSVDownload from "components/CSVDownload";
import Dropzone from "components/Dropzone";
import Icon from "components/Icon";
import { Body } from "components/Typography";
import { Dispatch } from "interfaces/dispatch";
import { State } from "state";
import { FileUploadState, uploadFile } from "state/create_file_upload";
import { fetchProject } from "state/project";
import { buildExportFilename, getTemplateHeaders } from "utils/helpers";
import { AuthUser } from "state/auth_user";

interface FileUploadOwnProps {
  label: string;
  url: string;
  prefix: string;
  projectId?: string | number;
  fileStatus?: string;
  basicImport?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
  dataQa?: string;
  user?: AuthUser;
}

interface FileUploadDispatchProps {
  upload: FileUploadState;
  afterUploadFunction?: (arg?: any) => void | Promise<any>;
  beforeUploadFunction?: (...args: any[]) => Promise<any>;
}

interface FileUploadDispatchProps {
  fetchProject: (id: string | number) => void;
  uploadFile: (
    prefix: string,
    uploadUrl: string,
    options?: { offset?: number }
  ) => (data: FileUploadState) => Promise<void>;
}

interface FileUploadInternalState {
  uploadedFiles: string[];
  active: boolean;
  success: boolean;
}

type FileUploadProps = FileUploadDispatchProps & FileUploadOwnProps & FileUploadDispatchProps;

// tslint:disable no-console
class FileUpload extends React.Component<FileUploadProps, FileUploadInternalState> {
  public state = {
    uploadedFiles: [] as string[],
    active: false,
    success: false,
  };
  public timer: number = -1;

  public componentDidUpdate(prevProps: FileUploadProps) {
    const {
      upload: { loading: prevLoading },
    } = prevProps;

    const {
      upload: { content, loading, hasError },
    } = this.props;

    if (prevLoading && !loading && !hasError && content) {
      this.setState({ success: true }, () => {
        this.timer = window.setTimeout(
          () => this.setState({ success: false, active: false }),
          5000
        );
      });
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.timer);
  }

  public handleUpload(
    prefix: string,
    url: string,
    data: FormData,
    options?: { offset?: number },
    fileStatus?: string,
    projectId?: string | number,
    userId?: number
  ) {
    if (projectId) {
      data.append("projectId", `${projectId}`);
    }

    if (fileStatus) {
      data.append("fileStatus", `${fileStatus}`);
    }

    if (userId) {
      data.append("userId", `${userId}`)
    }

    this.props
      .uploadFile(
        prefix,
        url,
        options
      )({ data })
      .then(() => {
        if (this.props.afterUploadFunction) {
          this.props.afterUploadFunction();
        }
      })
      .then(() => {
        if (projectId) {
          this.props.fetchProject(projectId);
        }
      });
  }

  public onDrop = (files: File[]) => {
    const { prefix, url, projectId, fileStatus } = this.props;
    const userId = this.props.user?.id
    const data = new FormData();

    if (files) {
      for (let file of files) {
        if (file) {
          this.setState({
            uploadedFiles: [file.name, ...this.state.uploadedFiles],
          });
          if (this.props.multiple) {
            data.append("files", file);
          } else {
            data.append("file", file);
          }
        }
      }
    }

    const offset = moment().utcOffset();
    const timezone = moment.tz.guess();
    const options = { offset, timezone };

    if (this.props.beforeUploadFunction) {
      this.props.beforeUploadFunction(prefix, projectId, fileStatus, files, userId).then((result) => {
        if (result) {
          this.handleUpload(prefix, url, data, options, fileStatus, projectId, userId);
        }
      });
    } else {
      this.handleUpload(prefix, url, data, options, fileStatus, projectId, userId);
    }
  };

  public onBasicUpload(files: FileList | null) {
    const { prefix, url, projectId, fileStatus = "" } = this.props;
    const userId = this.props.user?.id
    const data = new FormData();

    if (files) {
      for (const file of files) {
        if (file) {
          this.setState({
            uploadedFiles: [file.name, ...this.state.uploadedFiles],
          });
          if (this.props.multiple) {
            data.append("files", file);
          } else {
            data.append("file", file);
          }
        }
      }
    }

    const offset = moment().utcOffset();
    const timezone = moment.tz.guess();
    const options = { offset, timezone };

    if (this.props.beforeUploadFunction) {
      this.props.beforeUploadFunction(prefix, projectId, fileStatus, files, userId).then((result) => {
        if (result.continue) {
          this.handleUpload(prefix, url, data, options, fileStatus, projectId, userId);
        }
      });
    } else {
      this.handleUpload(prefix, url, data, options, fileStatus, projectId, userId);
    }
  }

  public renderLoading = () => {
    const { uploadedFiles } = this.state;

    return (
      <>
        <Body type="2">{uploadedFiles[0]}</Body>
        <img className="spin-right" src={loadingSvg} alt="Loading Icon" />
      </>
    );
  };

  public renderError = () => {
    const { uploadedFiles } = this.state;

    return (
      <>
        <p>{uploadedFiles[0]}</p>
        <img src={errorSvg} alt="Error Icon" />
      </>
    );
  };

  public renderSuccess = () => {
    const { uploadedFiles } = this.state;

    return (
      <>
        <Body type="2">{uploadedFiles[0]}</Body>
        <img src={successSvg} alt="Sucess Icon" />
      </>
    );
  };

  public renderNormal = () => (
    <>
      <Body type="2">
        Drag &amp; Drop or <span className="blue">Browse</span>
      </Body>
      <img src={uploadSvg} alt="Upload Icon" />
    </>
  );

  public renderBasicImport() {
    const { dataQa, disabled, multiple, accept } = this.props;

    return (
      <div>
        <input
          id="basicImport"
          name="basicImport"
          type="file"
          disabled={disabled}
          multiple={multiple}
          onChange={(e) => this.onBasicUpload(e.target.files)}
          onClick={(event: React.MouseEvent<HTMLInputElement>) => {
            // unset target of file input to allow upload file with same name multiple times
            if (event && event.target) {
              (event.target as HTMLInputElement).value = "";
            }
          }}
          style={{ display: "none" }}
          accept={accept}
          data-qa={dataQa}
        />
        <label className={cx("basic-import-button", { disabled })} htmlFor="basicImport">
          {this.props.label}
          <Icon medium={true} src={disabled ? uploadInactive : uploadBlueSvg} />
        </label>
      </div>
    );
  }

  public renderDropzone() {
    const {
      dataQa,
      label,
      upload: { hasError, loading },
      disabled,
    } = this.props;

    const { active, success } = this.state;

    const secondHeaders = [
      {},
      {
        chainName: "Store Number",
        projectName: "File Name",
        projectType: "File Type",
        timezone: "Status",
        projectDeadline: "Store Deadline",
        season: "---",
        year: "---"
      },
    ];

    return (
      <div className="file-upload-container">
        <CSVDownload
          data={label === "Upload Project File" ? secondHeaders : []}
          headers={getTemplateHeaders(label)}
          filename={buildExportFilename("csv", `${label}-template`)}
        >
          <h2 className="h6 file-upload-header">{label}</h2>
        </CSVDownload>
        <Dropzone
          disabled={disabled}
          className={cx("file-upload-body", { active })}
          onDrop={this.onDrop}
          data-qa={dataQa}
          onDragEnter={() => this.setState({ active: true })}
          onDragLeave={() => this.setState({ active: false })}
        >
          {loading && this.renderLoading()}
          {hasError && this.renderError()}
          {!hasError && !loading && !success && this.renderNormal()}
          {success && this.renderSuccess()}
        </Dropzone>
      </div>
    );
  }

  public render() {
    if (this.props.basicImport) {
      return this.renderBasicImport();
    } else {
      return this.renderDropzone();
    }
  }
}

const mapStateToProps = (state: State, ownProps: FileUploadOwnProps) => {
  const { prefix } = ownProps;

  return {
    // @ts-ignore
    upload: state[`${prefix}_file_upload`],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProject: (id: string | number) => dispatch(fetchProject(id)),
  uploadFile:
    (prefix: string, uploadUrl: string, options?: { offset?: number, timezone?: string }) => (data: FileUploadState) =>
      dispatch(uploadFile(prefix, uploadUrl, options)(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
