import { combineReducers } from "redux";

import createAnalyticsReducer, { AnalyticsState } from "state/analytics";
import AnnouncementsReducer, { AnnouncementsState } from "state/announcements";
import AuthReducer, { AuthState } from "state/auth";
import AuthUserReducer, { AuthUserState } from "state/auth_user";
import BatchDownloadReducer, { BatchDownloadState } from "state/batch_download";
import BreadcrumbReducer, { BreadcrumbState } from "state/breadcrumb";
import BreakpointReducer, { BreakpointState } from "state/breakpoint";
import ChainReducer, { ChainState } from "state/chain";
import ChainsReducer, { ChainsState } from "state/chains";
import CommentReducer, { CommentState } from "state/comment";
import CommentsReducer, { CommentsState } from "state/comments";
import CoverageReportsReducer, { CoverageReportsState } from "state/coverage_reports";
import createFileUploadReducer, { FileUploadState } from "state/create_file_upload";
import CreateProjectReducer, { CreateProjectState } from "state/create_project";
import FeedbackReviewDashboardReducer, {
  FeedbackReviewDashboardState,
} from "state/feedback_review_dashboard";
import FilesReducer, { FilesState } from "state/files";
import DIRFilesReducer, { DIRFilesState } from "state/dir-files";
import FontsReducer, { FontsState } from "state/fonts";
import LocationReducer, { LocationState } from "state/location";
import MyFeedbackReducer, { MyFeedbackState } from "state/my_feedback";
import MyProjectsReducer, { ProjectsState } from "state/my_projects";
import NotificationsReducer, { NotificationsState } from "state/notifications";
import PermissionsReducer, { PermissionsState } from "state/permissions";
import ProductsReducer, { ProductState } from "state/products";
import ProjectReducer, { ProjectState } from "state/project";
import ProjectCommentsReducer, { ProjectCommentsState } from "state/project_comments";
import ProjectStatusDashboardReducer, {
  ProjectDashboardState,
} from "state/project_status_dashboard";
import ProjectValidationReducer, { ProjectValidationState } from "state/project_validation";
import RecentProjectsReducer, {RecentProjectsState} from "state/recentProjects"
import RecentStoresReducer, {RecentStoresState} from "state/recentStores"
import ResetStatusDashboardReducer, {
  ResetStatusDashboardState,
} from "state/reset_status_dashboard";
import ResetnotesReducer, { ResetNotesState } from "state/resetnotes";
import StarredProjectsReducer, {StarredProjectsState} from "state/starredProjects"
import StarredStoresReducer, {StarredStoresState} from "state/starredStores"
import StoreReducer, { StoreState } from "state/store";
import Store2ProjectReducer, { Store2ProjectState } from "state/store2project";
import StoresReducer, { StoresState } from "state/stores";
import SupportFilesReducer, { SupportFilesState } from "state/support_files";
import SurveysReducer, { SurveyState } from "state/surveys";
import UserReducer, { UserState } from "state/user";
import UsersToStoresReducer, { Users2StoresState } from "state/user2stores";
import UsersReducer, { UsersState } from "state/users";
import UserEventsReducer, {UserEventsState} from "state/userEvents"
import ValidateReducer, { ValidateStoreState } from "state/validate_store";
import ValidationSummaryDashboardReducer, {
  ValidationSummaryDashboardState,
} from "state/validation_summary_dashboard";
import WholesalersReducer, { WholesalersState } from "state/wholesalers";
import SearchBarReducer, { SearchBarState } from "state/search";
import configReducer,{ConfigState} from "state/config";

// Global App State
export interface State {
  readonly analytics: AnalyticsState;
  readonly announcements: AnnouncementsState;
  readonly auth: AuthState;
  readonly auth_user: AuthUserState;
  readonly breadcrumb: BreadcrumbState;
  readonly breakpoint: BreakpointState;
  readonly create_project: CreateProjectState;
  readonly STORE2POG_file_upload: FileUploadState;
  readonly USERS_file_upload: FileUploadState;
  readonly STORES_file_upload: FileUploadState;
  readonly USER2STORE_file_upload: FileUploadState;
  readonly CHAINS_file_upload: FileUploadState;
  readonly COMMENTS_file_upload: FileUploadState;
  readonly coverage_reports : CoverageReportsState;
  readonly STORERESET_file_upload: FileUploadState;
  readonly ATTACHMENT_file_upload: FileUploadState;
  readonly UPLOAD_FILE_CHECK_file_upload: FileUploadState;
  readonly chain: ChainState;
  readonly chains: ChainsState;
  readonly files: FilesState;
  readonly dir_files: DIRFilesState;
  readonly fonts: FontsState;
  readonly notifications: NotificationsState;
  readonly products: ProductState;
  readonly project: ProjectState;
  readonly my_projects: ProjectsState;
  readonly my_feedback: MyFeedbackState;
  readonly store: StoreState;
  readonly stores: StoresState;
  readonly user: UserState;
  readonly users: UsersState;
  readonly userEvents: UserEventsState;
  readonly comments: CommentsState;
  readonly comment: CommentState;
  readonly permissions: PermissionsState;
  readonly validate_store: ValidateStoreState;
  readonly users_to_stores: Users2StoresState;
  readonly batch_download: BatchDownloadState;
  readonly project_comments: ProjectCommentsState;
  readonly project_validation: ProjectValidationState;
  readonly resetnotes: ResetNotesState;
  readonly surveys: SurveyState;
  readonly support_files: SupportFilesState;
  readonly store2Projects: Store2ProjectState;
  readonly projectstatusdashboard: ProjectDashboardState;
  readonly feedbackreviewdashboard: FeedbackReviewDashboardState;
  readonly resetstatusdashboard: ResetStatusDashboardState;
  readonly validationsummarydashboard: ValidationSummaryDashboardState;
  readonly location: LocationState;
  readonly wholesalers: WholesalersState;
  readonly starredProjects: StarredStoresState;
  readonly starredStores: StarredProjectsState;
  readonly recentProjects: RecentProjectsState;
  readonly recentStores: RecentStoresState;
  readonly searchBar: SearchBarState;
  readonly config: ConfigState;
}

// Root Reducer
export default combineReducers<Partial<State>>({
  analytics: createAnalyticsReducer(),
  announcements: AnnouncementsReducer,
  auth: AuthReducer,
  auth_user: AuthUserReducer,
  breadcrumb: BreadcrumbReducer,
  breakpoint: BreakpointReducer,
  create_project: CreateProjectReducer,
  STORE2POG_file_upload: createFileUploadReducer("STORE2POG"),
  STORES_file_upload: createFileUploadReducer("STORES"),
  USERS_file_upload: createFileUploadReducer("USERS"),
  USER2STORE_file_upload: createFileUploadReducer("USER2STORE"),
  CHAINS_file_upload: createFileUploadReducer("CHAINS"),
  COMMENTS_file_upload: createFileUploadReducer("COMMENTS"),
  coverage_reports: CoverageReportsReducer,
  STORERESET_file_upload: createFileUploadReducer("STORERESET"),
  ATTACHMENT_file_upload: createFileUploadReducer("ATTACHMENT"),
  UPLOAD_FILE_CHECK_file_upload: createFileUploadReducer("UPLOAD_FILE_CHECK"),
  chain: ChainReducer,
  chains: ChainsReducer,
  files: FilesReducer,
  dir_files: DIRFilesReducer,
  fonts: FontsReducer,
  products: ProductsReducer,
  project: ProjectReducer,
  my_projects: MyProjectsReducer,
  my_feedback: MyFeedbackReducer,
  notifications: NotificationsReducer,
  store: StoreReducer,
  stores: StoresReducer,
  user: UserReducer,
  users: UsersReducer,
  userEvents: UserEventsReducer,
  comments: CommentsReducer,
  comment: CommentReducer,
  permissions: PermissionsReducer,
  validate_store: ValidateReducer,
  users_to_stores: UsersToStoresReducer,
  batch_download: BatchDownloadReducer,
  project_comments: ProjectCommentsReducer,
  project_validation: ProjectValidationReducer,
  resetnotes: ResetnotesReducer,
  support_files: SupportFilesReducer,
  surveys: SurveysReducer,
  store2Projects: Store2ProjectReducer,
  projectstatusdashboard: ProjectStatusDashboardReducer,
  feedbackreviewdashboard: FeedbackReviewDashboardReducer,
  resetstatusdashboard: ResetStatusDashboardReducer,
  validationsummarydashboard: ValidationSummaryDashboardReducer,
  location: LocationReducer,
  wholesalers: WholesalersReducer,
  starredProjects: StarredProjectsReducer,
  starredStores: StarredStoresReducer,
  recentProjects: RecentProjectsReducer,
  recentStores: RecentStoresReducer,
  searchBar: SearchBarReducer,
  config: configReducer

});
