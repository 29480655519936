import startCase from "lodash/startCase";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Body } from "components/Typography";
import { State } from "state";
import { resetUpload } from "state/create_file_upload";

interface ManageDataPreviewOwnProps {
  prefix: string;
  label?: string;
}

type ManageDataPreviewProps = ManageDataPreviewOwnProps & PropsFromRedux;

class ManageDataPreview extends React.Component<ManageDataPreviewProps> {
  public componentWillUnmount() {
    this.props.resetUpload(this.props.prefix);
  }
  public render() {
    const {
      prefix,
      label,
      upload: { hasError, errors = [], content = [] },
    } = this.props;
    const text = label || startCase(prefix.toLowerCase()).replace(/s$/i, "(s)");
    let itemsCreated = 0;
    let itemsUpdated = 0;

    return (
      <>
        {content.length > 0 && (
          <div className="card-bottom">
            {content.forEach((c: any) => {
              c.action === "UPDATE" ? itemsUpdated++ : itemsCreated++;
            })}
            <ul className="changes-list">
              Changes
              <li key="updated" data-qa="changes-updated">
                {itemsUpdated} {text} Updated
              </li>
              <li key="created" data-qa="changes-created">
                {itemsCreated} New {text} Created
              </li>
            </ul>
          </div>
        )}
        {hasError && (
          <div className="card-bottom">
            <h5 className="error">Errors:</h5>
            <ul className="error-list">
              {errors.map((e: string) => (
                <li key={e}>
                  <Body type="3">{e}</Body>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  }
}

const connecter = connect(
  (state: State, ownProps: ManageDataPreviewOwnProps) => {
    const { prefix } = ownProps;

    return {
      // @ts-ignore
      upload: state[`${prefix}_file_upload`],
    };
  },
  { resetUpload }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageDataPreview);
