import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as xlsx from 'xlsx';
import moment from 'moment'
import Button from "components/Button";
import { Body } from "components/Typography";
import Loading from "components/Loading";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import {
  fetchCoverageReports,
  resetCoverageReports,
} from "state/coverage_reports";

class ManageAllData extends React.Component<RouteComponentProps & PropsFromRedux> {
  private isLoading = false;

  public componentDidUpdate(prevProps: RouteComponentProps & PropsFromRedux) {
    if (
      !prevProps.coverage_reports &&
      this.props.coverage_reports
    ) {
      const now = new Date();
      const timestamp = moment(now).format('YYYY-mm-dd_HH-MM-SS')
      const fileName = `${timestamp}_AdminDashboardLiveData.xlsx`
      xlsx.writeFile(this.props.coverage_reports as any, fileName);
      this.isLoading = false;
      this.props.resetCoverageReports();
      
    }
  }
  
  public render() {
    return (
      <section className="manage-all-data-container">
        <h1 className="header">Manage Master Data</h1>
        <ul className="manage-all-data-grid">
          <li className="manage-all-data-grid-item">
            <div>
              <h3>Chain</h3>
              <Body type="4">Manage Chain data records</Body>
            </div>
            <Button
              secondary={true}
              data-qa="chain-button"
              onClick={() => {
                const { history } = this.props;

                history.push("/manage-chains");
              }}
            >
              Edit
            </Button>
          </li>
          <li className="manage-all-data-grid-item">
            <div>
              <h3>Store</h3>
              <Body type="4">Manage Store data records</Body>
            </div>
            <Button
              secondary={true}
              data-qa="store-button"
              onClick={() => {
                const { history } = this.props;

                history.push("/manage-stores");
              }}
            >
              Edit
            </Button>
          </li>
          <li className="manage-all-data-grid-item">
            <div>
              <h3>Files</h3>
              <Body type="4">Manage File mapping records</Body>
            </div>
            <Button
              secondary={true}
              data-qa="files-button"
              onClick={() => {
                const { history } = this.props;

                history.push("/manage-files");
              }}
            >
              Edit
            </Button>
          </li>
          <li className="manage-all-data-grid-item">
            <div>
              <h3>User</h3>
              <Body type="4">Manage User data records</Body>
            </div>
            <Button
              secondary={true}
              data-qa="user-button"
              onClick={() => {
                const { history } = this.props;

                history.push("/manage-users");
              }}
            >
              Edit
            </Button>
          </li>
          <li className="manage-all-data-grid-item">
            <div>
              <h3>User Chain-Store Mapping</h3>
              <Body type="4">Manage User Chain-Store data records</Body>
            </div>
            <Button
              secondary={true}
              data-qa="user-mapping-button"
              onClick={() => {
                const { history } = this.props;
                history.push("/manage-mapping-data");
              }}
            >
              Edit
            </Button>
          </li>
        </ul>
        <Button
          data-qa="export-live-data"
          style={{ marginTop: "20px" }}
          disabled={this.isLoading}
          onClick={() => {
            this.isLoading = true;
            this.props.fetchCoverageReports();
          }}
        >
          Export Live Data
        </Button>
        {this.isLoading && (
        <div className="block-ui">
          <Loading></Loading>
        </div>
      )}
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    coverage_reports: state.coverage_reports.content,
    loading: state.coverage_reports.loading,
  }),
  {
    fetchCoverageReports,
    resetCoverageReports,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(ManageAllData));
