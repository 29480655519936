import { ReactSelectObject } from "interfaces/select";

export const filterItemsByUser = (selectedUsers: ReactSelectObject[], items: any[]) => {
  const filteredItems: any[] = [];
  if (selectedUsers.length > 0) {
    for (const user of selectedUsers) {
      for (const item of items) {
        if (
          (item.firstName &&
            item.lastName &&
            `${item.firstName} ${item.lastName}`.toUpperCase() === user.value.toUpperCase()) ||
          (item.userName && item.userName.toUpperCase() === user.value.toUpperCase())
        ) {
          filteredItems.push(item);
        }
      }
    }
    return filteredItems;
  } else {
    return items;
  }
};

export const filterItemsByAction = (selectedActions: ReactSelectObject[], items: any[]) => {
  const filteredItems: any[] = [];
  if (selectedActions.length > 0) {
    for (const action of selectedActions) {
      for (const item of items) {
        if (item.actionId === action.value) {
          filteredItems.push(item);
        }
      }
    }
    return filteredItems;
  } else {
    return items;
  }
};
