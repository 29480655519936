import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { File } from "interfaces/file";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
const FETCH_SUPPORT_FILES_REQUEST = "FETCH_SUPPORT_FILES_REQUEST";
const FETCH_SUPPORT_FILES = "FETCH_SUPPORT_FILES";
const FETCH_SUPPORT_FILES_FAILED = "FETCH_SUPPORT_FILES_FAILED";

export { FETCH_SUPPORT_FILES_REQUEST, FETCH_SUPPORT_FILES, FETCH_SUPPORT_FILES_FAILED };

export interface FetchStoreFilesOptions {
  projectId: number | string;
  storeId: number | string;
}

const fetchSupportFiles: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_SUPPORT_FILES_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/support-files/`, getHeaders())
    .then((response) =>
      dispatch({
        type: FETCH_SUPPORT_FILES,
        payload: response.data,
      })
    )
    .catch((error: Error) => dispatch({ type: FETCH_SUPPORT_FILES_FAILED }));
};

export { fetchSupportFiles };

// Actions
const actions = {
  [FETCH_SUPPORT_FILES_REQUEST]: (state: SupportFilesState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_SUPPORT_FILES]: (state: SupportFilesState, action: AnyAction): SupportFilesState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_SUPPORT_FILES_FAILED]: (
    state: SupportFilesState,
    action: AnyAction
  ): SupportFilesState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Reducer Interface
export interface SupportFilesState {
  error?: boolean;
  loading?: boolean;
  content?: File[];
}

// Initial State
const initialState: SupportFilesState = {
  loading: false,
  error: false,
  content: [],
};

// Reducer
export default handleActions<SupportFilesState, AnyAction>(actions, initialState);
