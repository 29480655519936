import { handleActions } from "redux-actions";

import Config, { hasFeature } from "config";
import { Reason } from "helpers/actions-reasons";
import Constants from "helpers/constants";
import { AnyAction } from "interfaces/action";
import { ProjectType } from "interfaces/project";
import {datadogRum} from "@datadog/browser-rum";

export const ANALYTICS_EVENT = "ANALYTICS_EVENT";
export const ANALYTICS = "ANALYTICS";
export const AppName = "BB360";

export type IncrementProperties =
  | "Announcements Count"
  | "Accepted Review Feedback Count"
  | "Rejected Review Feedback Count"
  | "Imported Review Feedback Count"
  | "Validation Completion Count"
  | "Take Survey Count";

export enum Status {
  SUCCESS = "Success",
  FAILURE = "Failure",
  CANCEL = "Cancel",
}

export enum FileStatus {
  ORIGINAL = "Original",
  FINAL = "Final",
}

export enum FileType {
  PROJECT = "Project",
  STORE = "Store",
}

export enum DIRFileStatus {
  CURRENT = "Current",
  PREVIOUS = "Previous",
}

export type PageName =
  // Landing page
  | "Root" // Users are always redirected from here
  | "Home Page"
  // Admin Pages
  | "Manage Projects"
  | "Create Project"
  | "Manage Chains"
  | "Manage Stores"
  | "Manage Users"
  | "Manage Mapping Data"
  | "Manage All Data"
  | "Manage Files"
  // Admin + CSM Pages
  | "Manage Surveys"
  | "Manage Announcements"
  // CSM Pages
  | "Upload Attachments"
  // User Pages
  | "My Projects"
  | "Store Detail"
  | "Project Detail"
  | "Downloads"
  | "IRIS Downloads"
  | "My Stores"
  | "My Feedback"
  | "Validation Completion"
  | "Review Feedback"
  | "Store Reset Status"
  | "Reset Feedback"
  | "My Surveys"
  | "Sign In"
  | "Sign Out"
  | "Starred Page"
  | "Support"
  | "My Announcements"
  | "Nearby Stores"
  | "About Us"
  | "Error";

export enum EventTypes {
  SIGN_IN_START = "Sign In Start",
  RESET_PASSWORD = "Reset Password",
  SIGN_IN_END = "Sign In End",
  SIGN_OUT = "Sign Out",
  PAGE_VIEW = "Pageview",
  ANNOUNCEMENT_SUBMIT = "Announcement Submit",
  ANNOUNCEMENT_DELETE = "Announcement Delete",
  DOWNLOADS_SEARCH = "Downloads Search",
  DOWNLOAD_FILES = "Download Files",
  DIR_DOWNLOADS_SEARCH = "IRIS Downloads Search",
  DIR_DOWNLOAD_FILES = "IRIS Download Files",
  DIR_DOWNLOAD_IRIS_SURVEY = "IRIS Survey",
  DIR_DOWNLOAD_IRIS_PSA = "IRIS PSA Downloads",
  UPLOAD_ATTACHMENTS = "Upload Attachments",
  MY_STORES_SEARCH = "My Stores Search",
  NEARBY_STORES_SEARCH = "Nearby Stores Search",
  MANAGE_SURVEYS_SUBMIT = "Manage Surveys Submit",
  MANAGE_SURVEYS_DELETE = "Manage Surveys Delete",
  TAKE_SURVEY = "Take Survey",
  SUPPORT_FILE_DOWNLOAD = "Support File Download",
  SUPPORT_KEY_CONTACTS = "Support Key Contacts",
  STORE_DETAIL_VALIDATION_COMPLETE = "Store Detail Validation Complete",
  STORE_DETAIL_MODIFY_FEEDBACK = "Store Detail Modify Feedback",
  STORE_DETAIL_COMMENT_ADD = "Store Detail Comment Add",
  STORE_DETAIL_COMMENT_DELETE = "Store Detail Comment Delete",
  STORE_DETAIL_ATTACHMENTS = "Store Detail Attachments",
  STORE_DETAIL_RESET_NOTE_ADD = "Store Detail Reset Note Add",
  SET_TARGET_RESET_DATE = "Set Target Reset Date",
  SET_ACTUAL_RESET_DATE = "Set Actual Reset Date",
  REPORT_EXPORT = "Report Export",
  REPORT_IMPORT = "Report Import",
  REVIEW_FEEDBACK_DECISION = "Review Feedback Decision",
  REVIEW_FEEDBACK_EXPLANATION = " Review Feedback Explanation",
  CREATE_NEW_PROJECT_UPLOAD = "Create New Project Upload",
  CREATE_NEW_PROJECT = "Create New Project",
  PAGEVIEW_ERROR = "Pageview Error",
}

export type AnalyticsProjectFields = {
  type: Constants.VALIDATION | Constants.FILE_SHARING;
  name: string;
};

export type AnalyticsProjectAndStoreFields = AnalyticsProjectFields & {
  storeName: string;
};

// properties common to all authed requests
type UserSuperProperties = {
  distinctId: string;
  brewBox360Role: string;
  company?: string;
  employer?: string;
};

// properties common to all requests
// handled by middleware in src/state/middleware/analytics.ts
type SuperProperties = {
  breakpoint?: "mobile" | "tablet" | "desktop";
  geolocationEnabled?: boolean;
  appName?: typeof AppName;
};

type UserProperties = UserSuperProperties & {
  name?: string;
  email: string;
  last_login: Date;
  created: Date;
  brewBox360UserId: string;
};

type StoreName = {
  storeName: string;
};

type ProjectFields = {
  projectName: string;
  projectType: ProjectType;
};

type StoreNameAndProjectFields = StoreName & ProjectFields;

export type SearchFields =
  | "Store Number"
  | "Store Name"
  | "Address"
  | "City"
  | "State"
  | "Zip Code"
  | "AB InBev Wholesaler"
  | "Miller Wholesaler"
  | "Coors Wholesaler"
  | "3rd Party Wholesaler"
  | "AdZone"
  | "Cluster/Store Group"
  | "Status";

type WholesalerFields = {
  wholesalerName: string;
  wholesalerNumber: string;
};

export type DIRSearchFields = Exclude<
  SearchFields,
  | "AB InBev Wholesaler"
  | "Miller Wholesaler"
  | "Coors Wholesaler"
  | "3rd Party Wholesaler"
  | "AdZone"
  | "Cluster/Store Group"
>;

type FileTypeFields = "File Type - PSA" | "File Type - PDF";

type AttachmentFields =
  | "Attachment Type - All"
  | "Attachment Type - Store"
  | "Attachment Type - Project";

export type DownloadSearchFields = SearchFields | FileTypeFields | AttachmentFields;

export type DIRDownloadSearchFields = DIRSearchFields;

type SearchResults = {
  searchResults: number;
};

type SurveyEvent = ProjectFields & {
  surveyLink: string;
};

type StoreDetailEvent = StoreNameAndProjectFields;
type StoreDetailFeedback = {
  storeDetailCommentCount: number;
};

type StoreDetailCommentLength = {
  commentCharacterCount: number;
};

type ResetDateEvent = StoreNameAndProjectFields & {
  pageName: Extract<PageName, "Store Detail" | "Store Reset Status">;
};

type Action = {
  eventType: "Action";
}

type PageView = {
  eventType: "Pageview";
}

// authentication events
type SignInStartEvent = Action & {
  event: EventTypes.SIGN_IN_START;
};

type SignOutEvent = Action & {
  event: EventTypes.SIGN_OUT;
};

type ResetPasswordEvent = Action & {
  event: EventTypes.RESET_PASSWORD;
  resetPasswordStatus: Status.SUCCESS;
};

type ResetPasswordCancelledEvent = Action & {
  event: EventTypes.RESET_PASSWORD;
  resetPasswordStatus: Status.CANCEL;
};

type ResetPasswordFailedEvent = Action & {
  event: EventTypes.RESET_PASSWORD;
  resetPasswordStatus: Status.FAILURE;
};

export type LogInEvent = Action & {
  event: EventTypes.SIGN_IN_END;
  loginStatus: Status.SUCCESS;
} & UserProperties;

type LogInCancelledEvent = Action & {
  event: EventTypes.SIGN_IN_END;
  loginStatus: Status.CANCEL;
};

type LogInFailedEvent = Action & {
  event: EventTypes.SIGN_IN_END;
  loginStatus: Status.FAILURE;
};

type ReportRowCount = {
  reportRowCount: number;
};

// pageview events
type PageviewEvent = PageView & {
  event: EventTypes.PAGE_VIEW;
  pageUrl: string;
} & (
  | ({ pageName: "Store Detail" } & ProjectFields & StoreName)
  | ({ pageName: "Project Detail" } & ProjectFields)
  | {
      pageName: Exclude<PageName, "Project Detail" | "Store Detail">;
    }
);

// other events
type AnnouncementSubmitEvent = Action & {
  event: EventTypes.ANNOUNCEMENT_SUBMIT;
} & ProjectFields;

type AnnouncementDeleteEvent = Action & {
  event: EventTypes.ANNOUNCEMENT_DELETE;
} & ProjectFields;

type DownloadsSearchEvent = Action & {
  event: EventTypes.DOWNLOADS_SEARCH;
} & (
  | (ProjectFields &
      SearchResults & {
        searchFieldsList: DownloadSearchFields[];
        searchAction: "Search";
      })
  | {
      searchAction: "Clear";
    }
);

type DownloadFilesEvent = Action & {
  event: EventTypes.DOWNLOAD_FILES;
} & ProjectFields &
  (
    | {
        downloadFileCount: 1;
        downloadFileName: string;
        downloadFileType: FileType;
        downloadFileStatus: FileStatus;
      }
    | {
        downloadFileCount: number;
      }
  );

type DIRDownloadsSearchEvent = Action & {
  event: EventTypes.DIR_DOWNLOADS_SEARCH;
} & (
  | (WholesalerFields &
      SearchResults & {
        searchFieldsList: DIRSearchFields[];
        searchAction: "Search";
      })
  | {
      searchAction: "Clear";
    }
);

type DIRDownloadFilesEvent = Action & {
  event: EventTypes.DIR_DOWNLOAD_FILES;
} & WholesalerFields &
  (
    | {
        downloadFileCount: 1;
        downloadFileName: string;
        downloadFileStatus: DIRFileStatus;
      }
    | {
        downloadFileCount: number;
      }
    | {
        downloadFileCount: number;
        goSpotCheckSurveyLink: string;
      }
  );

  type DIRDownloadSurveyEvent = Action & {
    event: EventTypes.DIR_DOWNLOAD_IRIS_SURVEY;
  } & WholesalerFields &
    (
      | {
          downloadFileCount: number;
          goSpotCheckSurveyLink: string;
        }
    );

    type DIRDownloadPsaEvent = Action & {
      event: EventTypes.DIR_DOWNLOAD_IRIS_PSA;
    } & WholesalerFields &
      (
        | {
            downloadFileCount: 1;
            downloadFileName: string;
            downloadFileStatus: DIRFileStatus;
          }
      );

type UploadAttachmentsEvent = Action & {
  event: EventTypes.UPLOAD_ATTACHMENTS;
  uploadAttachmentCount: number;
  uploadAttachmentFileExtensionList: string[];
  uploadAttachmentStatus: Status.SUCCESS | Status.FAILURE;
  uploadAttachmentOverwrite: boolean;
} & ProjectFields &
  (
    | {
        uploadAttachmentFileType: FileType.STORE;
        uploadAttachmentFileStatus: FileStatus;
      }
    | { uploadAttachmentFileType: FileType.PROJECT }
  );

type MyStoresSearchEvent = Action & {
  event: EventTypes.MY_STORES_SEARCH;
} & (
  | (ProjectFields &
      SearchResults & {
        searchFieldsList: SearchFields[];
        searchAction: "Search";
      })
  | (SearchResults & {
      searchFieldsList: SearchFields[];
      searchAction: "Search";
    })
  | {
      searchAction: "Clear";
    }
);

type NearbyStoresSearchEvent = Action & {
  event: EventTypes.NEARBY_STORES_SEARCH;
  nearbyStoresSearchStatus: Status;
  nearbyStoresSearchQuery: string;
} & SearchResults;

type ManageSurveysSubmitEvent = Action & {
  event: EventTypes.MANAGE_SURVEYS_SUBMIT;
} & SurveyEvent;

type ManageSurveysDeleteEvent = Action & {
  event: EventTypes.MANAGE_SURVEYS_DELETE;
} & SurveyEvent;

type TakeSurveyEvent = Action & {
  event: EventTypes.TAKE_SURVEY;
} & (
  | ({
      pageName: Extract<PageName, "My Surveys">;
    } & SurveyEvent)
  | ({ pageName: Extract<PageName, "Store Detail"> } & Omit<SurveyEvent, "surveyLink">)
);

type SupportFileDownloadEvent = Action & {
  event: EventTypes.SUPPORT_FILE_DOWNLOAD;
  supportFileName: string;
};

type SupportKeyContactsEvent = Action & {
  event: EventTypes.SUPPORT_KEY_CONTACTS;
  chainName: string;
};

type StoreDetailValidationCompleteEvent = Action & {
  event: EventTypes.STORE_DETAIL_VALIDATION_COMPLETE;
} & StoreDetailEvent &
  StoreDetailFeedback;

type StoreDetailModifyFeedbackEvent = Action & {
  event: EventTypes.STORE_DETAIL_MODIFY_FEEDBACK;
} & StoreDetailEvent &
  StoreDetailFeedback;

type CommentReason = {
  commentReason?: Reason;
};

type CommentProduct = {
  commentProduct: string;
};

export type StoreDetailCommentAddEvent = Action & {
  event: EventTypes.STORE_DETAIL_COMMENT_ADD;
} & StoreDetailEvent &
  StoreDetailCommentLength &
  (
    | {
        commentAction: Constants.ACTION_GENERAL_COMMENT_LABEL;
      }
    | ({
        commentAction: Constants.ACTION_ADD_PRODUCT_LABEL;
      } & CommentProduct &
        CommentReason)
    | ({
        commentAction: Constants.ACTION_MODIFY_PRODUCT_LABEL;
      } & CommentProduct &
        CommentReason)
    | ({
        commentAction: Constants.ACTION_REMOVE_PRODUCT_LABEL;
      } & CommentProduct &
        CommentReason)
    | ({
        commentAction: Constants.ACTION_SWAP_PRODUCT_LABEL;
        commentSwapProduct: string;
      } & CommentProduct)
  );

export type StoreDetailCommentDeleteEvent = Action & {
  event: EventTypes.STORE_DETAIL_COMMENT_DELETE;
} & StoreDetailEvent &
  StoreDetailCommentLength;

type StoreDetailAttachmentsEvent = Action & {
  event: EventTypes.STORE_DETAIL_ATTACHMENTS;
  storeDetailsAttachmentAction: "View" | "Download";
  attachmentFileName: string;
  attachmentFileStatus: FileStatus;
} & StoreDetailEvent;

type StoreDetailResetNoteAddEvent = Action & {
  event: EventTypes.STORE_DETAIL_RESET_NOTE_ADD;
  storeDetailResetImplementation: "N/A" | "Yes" | "No";
  noteCharacterCount: number;
} & StoreDetailEvent;

type SetTargetResetDateEvent = Action & {
  event: EventTypes.SET_TARGET_RESET_DATE;
} & ResetDateEvent;

type SetActualResetDateEvent = Action & {
  event: EventTypes.SET_ACTUAL_RESET_DATE;
} & ResetDateEvent;

type ReportExportEvent = Action & {
  event: EventTypes.REPORT_EXPORT;
  pageName: Extract<
    PageName,
    | "Validation Completion"
    | "My Feedback"
    | "Review Feedback"
    | "Store Reset Status"
    | "Reset Feedback"
  >;
} & ProjectFields &
  ReportRowCount;

type ReportImportEvent = Action & {
  event: EventTypes.REPORT_IMPORT;
} & ProjectFields &
  ReportRowCount &
  (
    | { pageName: Extract<PageName, "Store Reset Status"> }
    | {
        pageName: Extract<PageName, "Review Feedback">;
        reviewFeedbackUpdateCount: number;
      }
  );

type ReviewFeedbackDecisionEvent = Action & {
  event: EventTypes.REVIEW_FEEDBACK_DECISION;
  reviewFeedbackAction: "Accept" | "Reject";
} & StoreNameAndProjectFields &
  (
    | {
        reviewFeedbackDecisionUpdateMethod: "Import";
      }
    | {
        reviewFeedbackDecisionStatus: "N/A" | "Accepted" | "Rejected";
        reviewFeedbackDecisionUpdateMethod: "Manual";
      }
  );

type ReviewFeedbackExplanationEvent = Action & {
  event: EventTypes.REVIEW_FEEDBACK_EXPLANATION;
} & StoreNameAndProjectFields &
  (
    | {
        reviewFeedbackExplanationAction: "Save";
        reviewFeedbackExplanationCharacterCount: number;
        reviewFeedbackDecisionStatus: "N/A" | "Accepted" | "Rejected";
        reviewFeedbackExplanationUpdateMethod: "Manual";
      }
    | {
        reviewFeedbackExplanationAction: "Cancel";
        reviewFeedbackDecisionStatus: "N/A" | "Accepted" | "Rejected";
        reviewFeedbackExplanationUpdateMethod: "Manual";
      }
    | {
        reviewFeedbackExplanationCharacterCount: number;
        reviewFeedbackExplanationUpdateMethod: "Import";
      }
  );

type CreateNewProjectUploadEvent = Action & {
  event: EventTypes.CREATE_NEW_PROJECT_UPLOAD;
  fileUploadType: "Project File" | "New Stores" | "New Users" | "User to Store Mapping";
  fileUploadStatus: Status.SUCCESS | Status.FAILURE;
} & Partial<ProjectFields>;

type CreateNewProjectEvent = Action & {
  event: EventTypes.CREATE_NEW_PROJECT;
  createNewProjectAction: "Edit" | "Save" | "Delete" | "Publish" | "Unpublish";
} & Partial<ProjectFields>;

type PageviewErrorEvent = PageView & {
  event: EventTypes.PAGEVIEW_ERROR;
  pageUrl: string;
  pageviewErrorType: Constants.ERROR_403 | Constants.ERROR_404 | "Error Page";
};

export type AnalyticsEvent =
  | SignInStartEvent
  | ResetPasswordEvent
  | ResetPasswordCancelledEvent
  | ResetPasswordFailedEvent
  | SignOutEvent
  | LogInEvent
  | LogInCancelledEvent
  | LogInFailedEvent
  | PageviewEvent
  | AnnouncementSubmitEvent
  | AnnouncementDeleteEvent
  | DownloadsSearchEvent
  | DownloadFilesEvent
  | DIRDownloadsSearchEvent
  | DIRDownloadFilesEvent
  | DIRDownloadSurveyEvent
  | DIRDownloadPsaEvent
  | UploadAttachmentsEvent
  | MyStoresSearchEvent
  | NearbyStoresSearchEvent
  | ManageSurveysSubmitEvent
  | ManageSurveysDeleteEvent
  | TakeSurveyEvent
  | SupportFileDownloadEvent
  | SupportKeyContactsEvent
  | StoreDetailValidationCompleteEvent
  | StoreDetailModifyFeedbackEvent
  | StoreDetailCommentAddEvent
  | StoreDetailCommentDeleteEvent
  | StoreDetailAttachmentsEvent
  | StoreDetailResetNoteAddEvent
  | SetTargetResetDateEvent
  | SetActualResetDateEvent
  | ReportExportEvent
  | ReportImportEvent
  | ReviewFeedbackDecisionEvent
  | ReviewFeedbackExplanationEvent
  | CreateNewProjectUploadEvent
  | CreateNewProjectEvent
  | PageviewErrorEvent;

// analytics middleware adds SuperProperties
export type AnalyticsEventPostMiddleware = AnalyticsEvent & SuperProperties;

// action creators
export const fireAnalyticsAction = (payload: AnalyticsEventPostMiddleware) => ({
  type: ANALYTICS,
  payload,
});

export const createAnalyticsEvent = (payload: AnalyticsEvent) => ({
  type: ANALYTICS_EVENT,
  meta: {
    analytics: payload,
  },
});

export interface AnalyticsState {
  content?: AnalyticsEvent;
}

const initialState: AnalyticsState = {
  content: undefined,
};

const createAnalyticsReducer = () => {
  return handleActions<AnalyticsState, AnalyticsEvent>(
    {
      ANALYTICS: (_: AnalyticsState, action: AnyAction) => {
        const payload: AnalyticsEventPostMiddleware = { ...action.payload };
        if (hasFeature(Config.FEATURES, "analytics")) {
          if (payload.event !== EventTypes.SIGN_IN_END) {
            delete payload.breakpoint;
            delete payload.geolocationEnabled;
            delete payload.appName;
          }
          switch (payload.event) {
            case EventTypes.SIGN_IN_START:
            case EventTypes.SIGN_OUT: {
              const { event, ...authProperties } = payload;
              // track Sign In, Sign out event
              datadogRum.addAction(payload.event, authProperties);
              break;
            }
            case EventTypes.RESET_PASSWORD: {
              const { event, ...resetPasswordProperties } = payload;
              datadogRum.startView("Auth")
              // track Reset Password event
              datadogRum.addAction(payload.event, resetPasswordProperties);
              break;
            }
            case EventTypes.SIGN_IN_END: {
              datadogRum.startView("Auth")
              // handle cancelled and failed login
              if (
                payload.loginStatus === Status.CANCEL ||
                payload.loginStatus === Status.FAILURE
              ) {
                datadogRum.clearUser();
                datadogRum.addAction(EventTypes.SIGN_IN_END, {
                  eventType: payload.eventType,
                  loginStatus: payload.loginStatus,
                });
                break;
              }

              // handle successful login
              const {
                distinctId,
                brewBox360Role: Role,
                company = "N/A",
                email = "N/A",
                last_login,
                created,
                employer,
                name = "N/A",
                brewBox360UserId: id,
                geolocationEnabled: GeolocationEnabled,
              } = payload;

              datadogRum.setUser({
                id: id,
                name: name,
                email: email,
                role: Role,
                company: company,
                employer: employer,
                lastLogin: last_login,
                userCreated: created,
                geoLocationEnabled: GeolocationEnabled,
              })

              // track Log In event
              datadogRum.addAction(payload.event, {
                eventType: payload.eventType,
                loginStatus: payload.loginStatus,
              });
              break;
            }
            case EventTypes.PAGE_VIEW:
            case EventTypes.PAGEVIEW_ERROR: {
              const { event, ...pageviewProperties } = payload;

              // track Pageview, Pageview Error event
              datadogRum.addAction(payload.event, pageviewProperties);
              break;
            }
            case EventTypes.ANNOUNCEMENT_SUBMIT: {
              const { event, ...announcementProperties } = payload;

              // track Announcement Submit event
              datadogRum.addAction(event, announcementProperties);

              // increment Announcements property
              // Mixpanel.increment("Announcements Count");
              break;
            }
            case EventTypes.ANNOUNCEMENT_DELETE: {
              const { event, ...announcementProperties } = payload;

              // track Announcement Delete event
              datadogRum.addAction(event, announcementProperties);
              break;
            }
            case EventTypes.DOWNLOAD_FILES: {
              const { event, ...downloadFilesProperties } = payload;

              // track Download Files event
              datadogRum.addAction(event, downloadFilesProperties);
              break;
            }
            case EventTypes.DIR_DOWNLOAD_FILES: {
              const { event, ...downloadFilesProperties } = payload;

              // track Download Files event
              datadogRum.addAction(event, downloadFilesProperties);
              break;
            }
            case EventTypes.DIR_DOWNLOAD_IRIS_SURVEY: {
              const { event, ...downloadFilesProperties } = payload;

              // track Download Iris Survey event
              datadogRum.addAction(event, downloadFilesProperties);
              break;
            }
            case EventTypes.DIR_DOWNLOAD_IRIS_PSA: {
              const { event, ...downloadFilesProperties } = payload;

              // track Download Iris Psa event
              datadogRum.addAction(event, downloadFilesProperties);
              break;
            }
            case EventTypes.UPLOAD_ATTACHMENTS: {
              const { event, ...uploadAttachmentsProperties } = payload;

              // track Upload Attachments event
              datadogRum.addAction(event, uploadAttachmentsProperties);
              break;
            }
            case EventTypes.DOWNLOADS_SEARCH: {
              const { event, ...downloadsSearchProperties } = payload;

              // track Downloads Search event
              datadogRum.addAction(event, downloadsSearchProperties);
              break;
            }
            case EventTypes.DIR_DOWNLOADS_SEARCH: {
              const { event, ...downloadsSearchProperties } = payload;

              // track Downloads Search event
              datadogRum.addAction(event, downloadsSearchProperties);
              break;
            }
            case EventTypes.MY_STORES_SEARCH: {
              const { event, ...myStoresSearchProperties } = payload;

              // track My Stores Search event
              datadogRum.addAction(event, myStoresSearchProperties);
              break;
            }
            case EventTypes.MANAGE_SURVEYS_SUBMIT:
            case EventTypes.MANAGE_SURVEYS_DELETE: {
              const { event, ...surveyProperties } = payload;

              // track Manage Survey Submit, Manage Survey Delete event
              datadogRum.addAction(event, surveyProperties);
              break;
            }
            case EventTypes.TAKE_SURVEY: {
              const { event, ...surveyProperties } = payload;

              // track usage of Take Survey button on My Surveys page
              // (routes users to an actual survey)
              if (payload.pageName === "My Surveys") {
                // Mixpanel.increment("Take Survey Count");
              }

              // track Take Survey event
              datadogRum.addAction(event, surveyProperties);
              break;
            }
            case EventTypes.SUPPORT_FILE_DOWNLOAD:
            case EventTypes.SUPPORT_KEY_CONTACTS: {
              const { event, ...supportProperties } = payload;

              // track Support File Download and Support Key Contacts event
              datadogRum.addAction(event, supportProperties);
              break;
            }
            case EventTypes.STORE_DETAIL_VALIDATION_COMPLETE: {
              const { event, ...validationProperties } = payload;

              // track count of Validation Completion button usage
              // Mixpanel.increment("Validation Completion Count");

              // track Store Detail Validation Complete event
              datadogRum.addAction(event, validationProperties);
              break;
            }
            case EventTypes.STORE_DETAIL_MODIFY_FEEDBACK:
            case EventTypes.STORE_DETAIL_COMMENT_ADD:
            case EventTypes.STORE_DETAIL_COMMENT_DELETE: {
              const { event, ...validationProperties } = payload;

              // track Store Detail Modify Feedback event
              // track Store Detail Comment Add event
              // track Store Detail Comment Delete event
              datadogRum.addAction(event, validationProperties);
              break;
            }
            case EventTypes.NEARBY_STORES_SEARCH: {
              const { event, ...nearbyStoresProperties } = payload;

              // track Nearby Stores Search event
              datadogRum.addAction(event, nearbyStoresProperties);
              break;
            }
            case EventTypes.SET_ACTUAL_RESET_DATE:
            case EventTypes.SET_TARGET_RESET_DATE: {
              const { event, ...resetDateProperties } = payload;

              // track Set Actual Reset Date event
              // track Set Target Reset Date event
              datadogRum.addAction(event, resetDateProperties);
              break;
            }
            case EventTypes.STORE_DETAIL_ATTACHMENTS: {
              const { event, ...attachmentProperties } = payload;

              // track Store Detail Attachments event
              datadogRum.addAction(event, attachmentProperties);
              break;
            }
            case EventTypes.STORE_DETAIL_RESET_NOTE_ADD: {
              const { event, ...resetNoteProperties } = payload;

              // track Store Detail Reset Note Add event
              datadogRum.addAction(event, resetNoteProperties);
              break;
            }
            case EventTypes.REPORT_EXPORT: {
              const { event, ...reportProperties } = payload;

              // track Report Export event
              datadogRum.addAction(event, reportProperties);
              break;
            }
            case EventTypes.REPORT_IMPORT: {
              const { event, ...reportProperties } = payload;

              // track count of imports on Review Feedback
              if (payload.pageName === "Review Feedback") {
                // Mixpanel.increment("Imported Review Feedback Count");
              }

              // track Report Import event
              datadogRum.addAction(event, reportProperties);
              break;
            }
            case EventTypes.CREATE_NEW_PROJECT_UPLOAD:
            case EventTypes.CREATE_NEW_PROJECT: {
              const { event, ...createProjectProperties } = payload;

              // track Create New Project Upload event
              // track Create New Project event
              datadogRum.addAction(event, createProjectProperties);
              break;
            }
            case EventTypes.REVIEW_FEEDBACK_DECISION: {
              const { event, ...reviewFeedbackProperties } = payload;

              // track feedback decision count by accept/reject
              if (payload["reviewFeedbackAction"] === "Accept") {
                // Mixpanel.increment("Accepted Review Feedback Count");
              } else {
                // Mixpanel.increment("Rejected Review Feedback Count");
              }

              // track Review Feedback Decision event
              datadogRum.addAction(event, reviewFeedbackProperties);
              break;
            }
            case EventTypes.REVIEW_FEEDBACK_EXPLANATION: {
              const { event, ...reviewFeedbackProperties } = payload;

              // track Review Feedback Explanation event
              datadogRum.addAction(event, reviewFeedbackProperties);
              break;
            }
          }
        }

        // return original payload so we can track which event was actually fired
        return { content: action.payload };
      },
    },
    initialState
  );
};

export default createAnalyticsReducer;
