import partition from "lodash/partition";
import { parse } from "query-string";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import stable from "stable";

import {MyWorkCard} from "components/MyWorkCard";
import emptyTableStateSvg from "assets/img/empty-table-state.svg";
import { CardList } from "components/Card";
import Dropdown from "components/Dropdown";
import Loading from "components/Loading";
import Page from "components/Page";
import { Body } from "components/Typography";
import Constants from "helpers/constants";
import { Project } from "interfaces/project";
import { State } from "state";
import { fetchMyProjects } from "state/my_projects";
import { toggleProjectStarring } from "state/project";
import { toggleStarredProject } from "state/starredProjects";
import { byChainName, byEstCompleteDate, byNumberOfStores } from "utils/sorters";

const sortOptions = [
  {
    value: "est_complete_date:asc",
    label: "Completion Date",
  },
  {
    value: "store_number:desc",
    label: "Largest Project",
  },
  {
    value: "store_number:asc",
    label: "Smallest Project",
  },
  {
    value: "chain_name:asc",
    label: "Chain A to Z",
  },
  {
    value: "chain_name:desc",
    label: "Chain Z to A",
  },
];

interface MyWorkOwnProps extends RouteComponentProps<{ id: string }> {}
type MyWorkProps = MyWorkOwnProps & PropsFromRedux;

class MyWork extends React.Component<MyWorkProps> {
  public componentDidMount() {
    this.props.fetchMyProjects();
  }

  public renderMyProjects(project: Project) {
    const { isMobile, history, starredProjects, user } = this.props;
    const isStarred = starredProjects.findIndex(p => p.project_id === project.project_id) > -1
    return (
      project &&
      <MyWorkCard
        isStarred={isStarred}
        isMobile={isMobile}
        history={history}
        project={project}
        clickHandler={async (starred: any) => {
          await this.props.toggleStarredProject({
            user_id: user?.id,
            project_id: project.project_id,
            project_name: project.project_name,
            project_type: project.project_type,
            announcement_count: 5,
            survey_count: 5,
            starred,
          });
        }}
      /> 
    );
  }

  public sortProjects(sortParam: string): Project[] {
    const { projects = [] } = this.props;
    const [sort, order] = sortParam.split(":");
    const direction = order === "asc" ? 1 : -1;

    if (sort === "est_complete_date") {
      // this gets funky because we want to drag the
      // undefined stuff to the bottom
      const [withDate, withoutDate] = partition(projects, (project) => project.est_complete_date);

      return [
        ...stable(withDate, (a, b) => direction * byEstCompleteDate(a as any, b as any)),
        ...withoutDate,
      ];
    } else if (sort === "store_number") {
      return stable(projects, (a, b) => direction * byNumberOfStores(a, b));
    } else if (sort === "chain_name") {
      return stable(projects, (a, b) => direction * byChainName(a, b));
    }

    return projects;
  }

  public renderEmptyState() {
    return (
      <div className="empty-state">
        <img src={emptyTableStateSvg} data-qa="empty-state-image" alt="empty-state-No-Work" />
        <Body className="empty-state-body" type="2" data-qa="empty-state-text">
          No work found
        </Body>
      </div>
    );
  }

  public render() {
    const { history, location, projectsLoading, isMobile } = this.props;

    const sortParam = (parse(location.search).sort as any) || sortOptions[0].value;
    const projects = this.sortProjects(sortParam);

    const renderedProjects = projects.map((project: Project) => this.renderMyProjects(project));
    const hasProjects = renderedProjects.length > 0;

    return (
      <Page className="mywork-container">
        <header className="section-header">
          <h1 className="layout-header" data-qa="mywork-header">
            My Work
          </h1>
          <Dropdown
            value={sortOptions.find((option) => option.value === sortParam)}
            options={sortOptions}
            isSearchable
            classNamePrefix="work-sorter"
            containerStyles={
              isMobile
                ? {
                    marginLeft: "auto",
                    fontSize: 11,
                    alignSelf: "flex-end",
                    backgroundColor: "transparent",
                    width: 140,
                  }
                : { marginLeft: "unset" }
            }
            noShadow={isMobile}
            backgroundFill={!isMobile}
            controlStyles={isMobile ? { border: "none" } : {}}
            onChange={(selected) => {
              if (selected && !Array.isArray(selected)) {
                history.push({
                  search: `?sort=${selected.value}`,
                });
              }
            }}
          />
        </header>
        {!hasProjects && !projectsLoading && this.renderEmptyState()}
        {projectsLoading && <Loading/>}
        {hasProjects && <CardList>{renderedProjects}</CardList>}
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    projects: state.my_projects.content,
    projectsLoading: state.my_projects.loading,
    starredProjects: state.starredProjects.content,
    auth_user: state.auth_user.content,
    user: state.auth_user.content,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  { fetchMyProjects, toggleProjectStarring, toggleStarredProject }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(MyWork));
