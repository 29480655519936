import cx from "classnames";
import React from "react";

import arrowDownSvg from "assets/img/arrow-down.svg";
import arrowUpSvg from "assets/img/arrow-up.svg";

interface CollapsableProps extends React.HTMLAttributes<{}> {
  defaultCollapsed?: boolean;
  header: JSX.Element;
}

interface CollapsableState {
  collapsed: boolean;
}

export default class Collapsable extends React.Component<CollapsableProps, CollapsableState> {
  public constructor(props: CollapsableProps) {
    super(props);

    this.state = { collapsed: !!props.defaultCollapsed };
  }

  public handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState((prevState: CollapsableState) => ({
      collapsed: !prevState.collapsed,
    }));

    e.preventDefault();
  };

  public render() {
    const { collapsed } = this.state;
    const { header: Header, children } = this.props;

    return (
      <section
        id="store-details-collapse-container"
        role="presentation"
        className="collapse-container"
      >
        {/* inject the button to collapse the section */}
        <button
          id="store-details-collapse-button"
          key="collapse-button"
          className="collapse-button"
          data-qa="collapse-button"
          onClick={this.handleClick}
          aria-controls="store-details-collapse-container"
          aria-expanded={collapsed}
        >
          {React.cloneElement(Header, { className: "collapse-header" }, [
            ...React.Children.toArray(Header.props.children),
            <img
              key=""
              style={{ height: 18, width: 18 }}
              src={!collapsed ? arrowUpSvg : arrowDownSvg}
              alt=""
            />,
          ])}
        </button>

        <div
          role="region"
          aria-hidden={collapsed}
          aria-labelledby="store-details-collapse-container"
          className={cx("collapse-body", { collapsed })}
          data-qa="collapse-body"
        >
          {children}
        </div>
      </section>
    );
  }
}
