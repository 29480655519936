import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { unregister } from "registerServiceWorker";

// load environment
import "config";

// load styles
import "index.scss";

import App from "containers/App";
import { init } from "state/init";
import configureStore from "store/configureStore";
import {initializeDataDog} from "./datadogConfiguration";

// remove service worker
unregister();

const store = configureStore();
store.dispatch<any>(init());
initializeDataDog();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);
