import React from "react";

interface PDFProps extends React.HTMLAttributes<{}> {
  file: string;
}

const PDF: React.FunctionComponent<PDFProps> = ({ file }) => (
  <div className="attachments-pdf-container">
    <iframe title="Attachment" className="attachments-pdf" src={file} />
  </div>
);

export default PDF;
