import cx from "classnames";
import React from "react";

import fileSharing from "assets/img/file-sharing.svg";
import Icon from "components/Icon";
import Constants from "helpers/constants";

type UserPill =
  | Constants.NEEDS_VALIDATION
  | Constants.VALIDATION_PENDING
  | Constants.VALIDATION_COMPLETE
  | Constants.FEEDBACK_UNDER_REVIEW
  | Constants.NEEDS_FEEDBACK_REVIEW
  | Constants.AWAITING_FINAL_UPLOAD
  | Constants.FINAL_UPLOAD_COMPLETE
  | Constants.RESET_NOT_SCHEDULED
  | Constants.RESET_SCHEDULED
  | Constants.RESET_COMPLETE;

type AdminPill =
  | Constants.PUBLISHED
  | Constants.UNPUBLISHED
  | Constants.PILL_FILE_SHARING
  | Constants.PILL_MOBILE_FILE_SHARING;

type ProjectType = Constants.FILE_SHARING | Constants.VALIDATION;

export type FilePill = Constants.ORIGINAL | Constants.FINAL;

export type PillType = UserPill | AdminPill | FilePill;

type PillColor = "blue" | "green" | "yellow" | "red" | "orange" | "gray" | "dark-gray";

export function getPillProps(
  pillType: string,
  projectType: ProjectType = Constants.VALIDATION
): PillProps {
  switch (pillType) {
    case Constants.NEEDS_VALIDATION:
    case Constants.NEEDS_FEEDBACK_REVIEW:
      return { pillType, pillColor: "blue" };
    case Constants.VALIDATION_PENDING:
    case Constants.FEEDBACK_UNDER_REVIEW:
    case Constants.RESET_NOT_SCHEDULED:
      return { pillType, pillColor: "yellow" };
    case Constants.FINAL_UPLOAD_COMPLETE:
    case Constants.VALIDATION_COMPLETE:
    case Constants.FINAL:
    case Constants.RESET_COMPLETE:
    case Constants.PUBLISHED:
      return { pillType, pillColor: "green" };
    case Constants.AWAITING_FINAL_UPLOAD:
    case Constants.UNPUBLISHED:
    case Constants.ORIGINAL:
    case Constants.RESET_SCHEDULED:
      return { pillType, pillColor: "orange" };
    case Constants.PILL_FILE_SHARING:
      return { pillType, pillColor: "dark-gray" };
    case Constants.PILL_MOBILE_FILE_SHARING:
      return { pillType, pillColor: "dark-gray", icon: fileSharing };
    default:
      return projectType === Constants.VALIDATION
        ? { pillType: Constants.NEEDS_VALIDATION, pillColor: "blue" }
        : { pillType: Constants.RESET_NOT_SCHEDULED, pillColor: "yellow" };
  }
}

export interface PillProps extends React.HTMLAttributes<{}> {
  pillType: PillType | number | string;
  pillColor: PillColor;
  icon?: any;
}

export const Pill: React.FunctionComponent<PillProps> = ({
  pillType,
  pillColor,
  icon,
  className,
  ...props
}) => (
  <div
    className={cx("pill", icon ? "icon-pill" : "", `pill-color-${pillColor}`, className)}
    {...props}
  >
    {icon ? <Icon small={true} src={icon} /> : <h6 className="pill-text">{pillType}</h6>}
  </div>
);
