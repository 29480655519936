import React from "react";

import { Body } from "components/Typography";

interface ErrorMessageProps {
  errors: string[];
}

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ errors }) => (
  <div className="card-error-bottom">
    <h5 className="error">Error(s):</h5>
    <ul className="error-list">
      {errors.map((e) => (
        <li key={e}>
          <Body type="3">{e}</Body>
        </li>
      ))}
    </ul>
  </div>
);

export default ErrorMessage;
