import cx from "classnames";
import React from "react";

interface BodyProps extends React.HTMLAttributes<{}> {
  type: "1" | "2" | "3" | "4";
}

export const Body: React.FunctionComponent<BodyProps> = ({
  type,
  className,
  children,
  ...rest
}) => (
  <p className={cx("body", `b${type}`, className)} {...rest}>
    {children}
  </p>
);
