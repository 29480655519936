import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Action Types
export const FETCH_RECENT_STORES_REQUEST = "FETCH_RECENT_STORES_REQUEST";
export const FETCH_RECENT_STORES_SUCCESS = "FETCH_RECENT_STORES_SUCCESS";
export const FETCH_RECENT_STORES_FAILURE = "FETCH_RECENT_STORES_FAILURE";

// Thunk Action Creators
export const fetchRecentStores: ThunkActionCreator<Promise<void>> = (userId: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_RECENT_STORES_REQUEST });
    const url = `${Config.API_SERVICE_URL}/api/recent-stores?userId=${userId}&eventType=STORE_DETAIL_PAGEVIEW`;
    return axios
        .get(url, getHeaders())
        .then(response => {
            dispatch({ type: FETCH_RECENT_STORES_SUCCESS, payload: response.data });
        })
        .catch( error => {
            dispatch({ type: FETCH_RECENT_STORES_FAILURE, payload: error });
        });
};

// Actions
const actions = {
    [FETCH_RECENT_STORES_REQUEST]: (state: RecentStoresState) => ({
        ...state,
        loading: true,
    }),
    [FETCH_RECENT_STORES_SUCCESS]: (state: RecentStoresState, action: AnyAction) => {
        return {
            ...state,
            content: action.payload,
            loading: false,
            error: false,
        };
    },
    [FETCH_RECENT_STORES_FAILURE]: (state: RecentStoresState) => ({
        ...state,
        loading: false,
        error: true,
    }),
};

// Reducer Interface
// TODO: Add type to content
export interface RecentStoresState {
    content: any[];
    error?: boolean;
    loading?: boolean;
}

// Initial State
const initialState: RecentStoresState = {
    loading: false,
    error: false,
    content: [],
};

// Reducer
export default handleActions<RecentStoresState, AnyAction>(actions, initialState);