import downloadSvg from "assets/img/download.svg";
import downloadInactiveSvg from "assets/img/downloads-inactive.svg";
import cx from "classnames";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import DatePickerPopover from "components/DatePickerPopover";
import Dropdown from "components/Dropdown";
import ErrorMessage from "components/ErrorMessage";
import Icon from "components/Icon";
import Page from "components/Page";
import Table from "components/Table";
import Config from "config";
import { RouteProps } from "containers/App";
import FileUpload from "containers/FileUpload";
import ProjectFilter, { FilterOptions } from "containers/ProjectFilter";
import { PermissionsContext } from "context";
import Constants from "helpers/constants";
import { ProjectType } from "interfaces/project";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { fetchMyStore2Projects, updateStore2Project, fetchStore2Projects } from "state/store2project";
import { buildExportFilename } from "utils/helpers";

type StoreResetStatusProps = PropsFromRedux & RouteProps;
interface StoreResetStatusState {
  tableData: any[];
  selectedProjectId?: string | number;
  selectedStoreNumber?: string | number;
  selectedProjectName: string;
  selectedProjectType: string;
}

class StoreResetStatus extends React.Component<StoreResetStatusProps, StoreResetStatusState> {
  protected CSV_HEADERS = [
    { label: "Project ID", key: "project_id" },
    { label: "Store Number", key: "storeNumber" },
    { label: "Address", key: "address" },
    { label: "Target Reset Date", key: "target_reset_date" },
    { label: "Target Reset Time", key: "target_reset_time" },
    { label: "Actual Reset Date", key: "actual_reset_date" },
    { label: "Actual Reset Time", key: "actual_reset_time" },
  ];
  constructor(props: any) {
    super(props);
    this.state = {
      tableData: [],
      selectedProjectName: "",
      selectedProjectType: "",
    };
  }

  public reloadTableData(id: string) {
    const user:any =  this.props.user;
    if(user.role === Constants.AB_SYSTEM_ADMIN){
      return   this.props.fetchStore2Projects(id).then(() => {
        this.filterTableData();
        this.setState({
          selectedProjectId: id,
        });
      });
    }
    else{
      return this.props.fetchMyStore2Projects(id).then(() => {
        this.filterTableData();
        this.setState({
          selectedProjectId: id,
        });
      });
    }
    
  }

  public projectIsSelected() {
    return this.state.selectedProjectId && this.state.selectedProjectId > -1;
  }

  public getDataToExport() {
    // Clone to avoid mutating tableData
    const data = cloneDeep(this.state.tableData);
    return data.map((row) => {
      if (row.target_reset_date) {
        const targetResetMoment = moment(row.target_reset_date);
        row.target_reset_date = targetResetMoment.local().format("MM/D/YYYY");
        row.target_reset_time = targetResetMoment.local().format("hh:mm a");
      }

      if (row.actual_reset_date) {
        const actualResetMoment = moment(row.actual_reset_date);
        row.actual_reset_date = actualResetMoment.local().format("MM/D/YYYY");
        row.actual_reset_time = actualResetMoment.local().format("hh:mm a");
      }
      return row;
    });
  }

  public filterTableData() {
    const { store2Projects = [] } = this.props;

    if (this.state.selectedStoreNumber && this.state.selectedStoreNumber.toString() !== "-1") {
      this.setState({
        tableData: store2Projects.filter(
          (row) => row.storeNumber === this.state.selectedStoreNumber
        ),
      });
    } else {
      this.setState({ tableData: store2Projects });
    }
  }

  public render() {
    const { store2Projects = [], loadingProjects } = this.props;

    const storeNumDropdownOptions = [
      { label: "All Stores", value: "-1", "data-qa": "all-comments" },
    ].concat(
      store2Projects
        .map((s2p) => ({
          label: s2p.storeNumber,
          value: s2p.storeNumber,
          "data-qa": s2p.storeNumber,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );

    const data = this.getDataToExport();
    return (
      <PermissionsContext.Consumer>
        {({ set_reset }: any) => (
          <Page>
            <header className="section-header">
              <h1 className="layout-header" data-qa="store-reset-status-header">
                Store Reset Status
              </h1>
              <ProjectFilter
                onChange={({ id, name, type }: FilterOptions) => {
                  this.reloadTableData(id.toString());
                  this.setState({
                    selectedProjectName: name,
                    selectedProjectType: type,
                  });
                }}
                dataQa={"select-project"}
                defaultOptionLabel={"Select Project"}
                isSearchable
              />
              <div className="section-header-button-group">
                <CSVDownload
                  className="store-reset-status-export-link"
                  data={data}
                  headers={this.CSV_HEADERS}
                  filename={buildExportFilename("csv", "store-reset-status")}
                  onClick={() => {
                    const { selectedProjectName, selectedProjectType } = this.state;

                    this.props.createAnalyticsEvent({
                      event: EventTypes.REPORT_EXPORT,
                      eventType: "Action",
                      projectName: selectedProjectName as string,
                      projectType: selectedProjectType as ProjectType,
                      pageName: "Store Reset Status",
                      reportRowCount: data.length,
                    });
                  }}
                >
                  <button
                    data-qa="store-reset-status-export-button"
                    className={cx("import-export-button", {
                      disabled: !this.projectIsSelected(),
                    })}
                    disabled={!this.projectIsSelected()}
                  >
                    Export
                    <Icon src={this.projectIsSelected() ? downloadSvg : downloadInactiveSvg} />
                  </button>
                </CSVDownload>
                {set_reset && (
                  <FileUpload
                    disabled={!set_reset}
                    prefix={"STORERESET"}
                    url={`${Config.API_SERVICE_URL}/api/store2projects`}
                    data-qa={"store-reset-status-import-button"}
                    label={"Import"}
                    dataQa={"store-resetstatus-file-upload"}
                    basicImport={true}
                    afterUploadFunction={() => {
                      this.reloadTableData(
                        this.state.selectedProjectId
                          ? this.state.selectedProjectId.toString()
                          : "-1"
                      );

                      const {
                        store_reset_upload: { content },
                      } = this.props;

                      if (content && (content as any).totalRows) {
                        this.props.createAnalyticsEvent({
                          event: EventTypes.REPORT_IMPORT,
                          eventType: "Action",
                          projectName: this.state.selectedProjectName as string,
                          projectType: this.state.selectedProjectType as ProjectType,
                          pageName: "Store Reset Status",
                          reportRowCount: (content as any).totalRows,
                        });
                      }
                    }}
                  />
                )}
              </div>
            </header>
            <div hidden={!this.props.store_reset_upload.hasError}>
              <ErrorMessage errors={this.props.store_reset_upload.errors || []} />
            </div>
            <CardList>
              <Card>
                <div className="review-feedback-filter-container">
                  <p className="header">Filters</p>
                  <div className="review-feedback-store-dropdown-container">
                    <p>Store Number</p>
                    <Dropdown
                      dataQa="reset-status-store-dropdown"
                      value={
                        storeNumDropdownOptions.find(
                          (opt) => opt.value === this.state.selectedStoreNumber
                        ) || storeNumDropdownOptions[0]
                      }
                      options={storeNumDropdownOptions}
                      placeholder="Select"
                      isDisabled={
                        !this.state.selectedProjectId || this.state.selectedProjectId === -1
                      }
                      containerStyles={{
                        marginLeft: "unset",
                        fontWeight: "normal",
                        marginBottom: "10px",
                      }}
                      onChange={(store) => {
                        this.setState(
                          {
                            selectedStoreNumber: store.value,
                          },
                          this.filterTableData
                        );
                      }}
                      isSearchable
                    />
                  </div>
                </div>
              </Card>
              <Card>
                <Table
                  loading={loadingProjects}
                  data={loadingProjects ? [] : this.state.tableData}
                  columns={[
                    {
                      field: "storeNumber",
                      header: "Store No.",
                      dataQa: "storeNumber",
                      formatter: (value: string, row: any) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            <span>{value}</span>
                          </div>
                        );
                      },
                    },
                    {
                      field: "address",
                      header: "Address",
                      dataQa: "address",
                      formatter: (value: string, row: any) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            <span>{value}</span>
                          </div>
                        );
                      },
                    },
                    {
                      field: "target_reset_date",
                      header: "Target Reset Date",
                      dataQa: "targetResetDate",
                      sortable: false,
                      formatter: (value: string, row: any) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                            }}
                          >
                            <span style={{ paddingTop: "10px" }} data-qa="target-date-display">
                              {value
                                ? moment.utc(value).local().format("MM/D/YYYY h:mm a")
                                : "Please select date"}
                            </span>
                            <DatePickerPopover
                              disabled={!set_reset}
                              defaultValue={value}
                              onSave={(date: moment.Moment) => {
                                const { selectedProjectType, selectedProjectName } = this.state;
                                const { storeName } = row;

                                this.props.createAnalyticsEvent({
                                  event: EventTypes.SET_TARGET_RESET_DATE,
                                  eventType: "Action",
                                  projectName: selectedProjectName,
                                  projectType: selectedProjectType as ProjectType,
                                  storeName: storeName,
                                  pageName: "Store Reset Status",
                                });

                                this.props
                                  .updateStore2Project(row.id, {
                                    targetResetDate: moment
                                      .utc(date)
                                      .format(Constants.DATE_FORMAT__YYYY_MM_DD__HH_mm_ss),
                                  })
                                  .then(() => {
                                    if (this.state.selectedProjectId) {
                                      this.reloadTableData(this.state.selectedProjectId.toString());
                                    }
                                  });
                              }}
                            />
                          </div>
                        );
                      },
                    },
                    {
                      field: "actual_reset_date",
                      header: "Actual Reset Date",
                      dataQa: "actualResetDate",
                      sortable: false,
                      formatter: (value: string, row: any) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                            }}
                          >
                            <span data-qa="actual-date-display">
                              {value
                                ? moment.utc(value).local().format("MM/D/YYYY h:mm a")
                                : "Please select date"}
                            </span>
                            <DatePickerPopover
                              disabled={!set_reset}
                              defaultValue={value}
                              onSave={(date: moment.Moment) => {
                                const { selectedProjectName, selectedProjectType } = this.state;
                                const { storeName } = row;

                                this.props.createAnalyticsEvent({
                                  event: EventTypes.SET_ACTUAL_RESET_DATE,
                                  eventType: "Action",
                                  projectName: selectedProjectName,
                                  projectType: selectedProjectType as ProjectType,
                                  storeName: storeName,
                                  pageName: "Store Reset Status",
                                });

                                this.props
                                  .updateStore2Project(row.id, {
                                    actualResetDate: moment
                                      .utc(date)
                                      .format(Constants.DATE_FORMAT__YYYY_MM_DD__HH_mm_ss),
                                  })
                                  .then(() => {
                                    if (this.state.selectedProjectId) {
                                      this.reloadTableData(this.state.selectedProjectId.toString());
                                    }
                                  });
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                  emptyState={
                    this.projectIsSelected()
                      ? "No notes found"
                      : "Please select a project to view notes"
                  }
                />
              </Card>
            </CardList>
          </Page>
        )}
      </PermissionsContext.Consumer>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    store2Projects: state.store2Projects.content,
    loadingProjects: state.store2Projects.loading,
    store_reset_upload: state.STORERESET_file_upload,
    user: state.auth_user.content,

  }),
  { fetchMyStore2Projects, updateStore2Project, createAnalyticsEvent, fetchStore2Projects }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(StoreResetStatus);
