import cx from "classnames";
import React from "react";

export interface ButtonProps extends React.ButtonHTMLAttributes<{}> {
  secondary?: boolean;
  warning?: boolean;
  accept?: boolean;
  reject?: boolean;
  hasTooltip?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  secondary,
  warning,
  accept,
  reject,
  children,
  onClick,
  className,
  type = "button",
  disabled = false,
  hasTooltip = false,
  ...restProps
}) => {
  const buttonClass = cx(
    "button",
    { secondary, accept, reject, disabled, warning, "has-tooltip": hasTooltip },
    className
  );
  return (
    <button
      className={buttonClass}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
