import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Action Types
export const ANNOUNCEMENTS_PAGEVIEW_EVENT_REQUEST = "ANNOUNCEMENTS_PAGEVIEW_EVENT_REQUEST";
export const ANNOUNCEMENTS_PAGEVIEW_EVENT_SUCCESS = "ANNOUNCEMENTS_PAGEVIEW_EVENT_SUCCESS";
export const ANNOUNCEMENTS_PAGEVIEW_EVENT_FAILURE = "ANNOUNCEMENTS_PAGEVIEW_EVENT_FAILURE";
export const SURVEYS_PAGEVIEW_EVENT_REQUEST = "SURVEYS_PAGEVIEW_EVENT_REQUEST";
export const SURVEYS_PAGEVIEW_EVENT_SUCCESS = "SURVEYS_PAGEVIEW_EVENT_SUCCESS";
export const SURVEYS_PAGEVIEW_EVENT_FAILURE = "SURVEYS_PAGEVIEW_EVENT_FAILURE";
export const PROJECT_DETAIL_PAGEVIEW_EVENT_REQUEST = "PROJECT_DETAIL_PAGEVIEW_EVENT_REQUEST";
export const PROJECT_DETAIL_PAGEVIEW_EVENT_SUCCESS = "PROJECT_DETAIL_PAGEVIEW_EVENT_SUCCESS";
export const PROJECT_DETAIL_PAGEVIEW_EVENT_FAILURE = "PROJECT_DETAIL_PAGEVIEW_EVENT_FAILURE";
export const STORE_DETAIL_PAGEVIEW_EVENT_REQUEST = "STORE_DETAIL_PAGEVIEW_EVENT_REQUEST";
export const STORE_DETAIL_PAGEVIEW_EVENT_SUCCESS = "STORE_DETAIL_PAGEVIEW_EVENT_SUCCESS";
export const STORE_DETAIL_PAGEVIEW_EVENT_FAILURE = "STORE_DETAIL_PAGEVIEW_EVENT_FAILURE";

export const PAGEVIEW_EVENTS = {
    ANNOUNCEMENTS_PAGEVIEW: "ANNOUNCEMENTS_PAGEVIEW",
    SURVEYS_PAGEVIEW: "SURVEYS_PAGEVIEW",
    PROJECT_DETAIL_PAGEVIEW: "PROJECT_DETAIL_PAGEVIEW",
    STORE_DETAIL_PAGEVIEW: "STORE_DETAIL_PAGEVIEW",
};

export interface PageViewEvent {
    event_type: string,
    user_id: string,
    store_id: string,
    project_id: string,
    timestamp: string, // Date.now() for now, // TODO: Use as an optimistic "tell"
    // use "updated_at" --> new Date("2021-12-15T14:02:01.333Z").getTime()
}

// Thunk Action Creators
export const trackPageView: ThunkActionCreator<Promise<void>> = (payload: PageViewEvent) => (dispatch: Dispatch) => {
    let initialAction: string;
    let nextActionOnSuccess: string;
    let nextActionOnFailure: string;
    let url;

    switch (payload.event_type) {
        case PAGEVIEW_EVENTS.ANNOUNCEMENTS_PAGEVIEW:
            initialAction = ANNOUNCEMENTS_PAGEVIEW_EVENT_REQUEST
            nextActionOnSuccess = ANNOUNCEMENTS_PAGEVIEW_EVENT_SUCCESS
            nextActionOnFailure = ANNOUNCEMENTS_PAGEVIEW_EVENT_FAILURE
            url = `${Config.API_SERVICE_URL}/api/user-events`
            break;
        case PAGEVIEW_EVENTS.SURVEYS_PAGEVIEW:
            initialAction = SURVEYS_PAGEVIEW_EVENT_REQUEST
            nextActionOnSuccess = SURVEYS_PAGEVIEW_EVENT_SUCCESS
            nextActionOnFailure = SURVEYS_PAGEVIEW_EVENT_FAILURE
            url = `${Config.API_SERVICE_URL}/api/survey-clicks`
            break;
        case PAGEVIEW_EVENTS.PROJECT_DETAIL_PAGEVIEW:
            initialAction = PROJECT_DETAIL_PAGEVIEW_EVENT_REQUEST
            nextActionOnSuccess = PROJECT_DETAIL_PAGEVIEW_EVENT_SUCCESS
            nextActionOnFailure = PROJECT_DETAIL_PAGEVIEW_EVENT_FAILURE
            url = `${Config.API_SERVICE_URL}/api/user-events`
            break;
        case PAGEVIEW_EVENTS.STORE_DETAIL_PAGEVIEW:
            initialAction = STORE_DETAIL_PAGEVIEW_EVENT_REQUEST
            nextActionOnSuccess = STORE_DETAIL_PAGEVIEW_EVENT_SUCCESS
            nextActionOnFailure = STORE_DETAIL_PAGEVIEW_EVENT_FAILURE
            url = `${Config.API_SERVICE_URL}/api/user-events`
            break;
        default:
            throw new Error("Unsupported event");
    }

    dispatch({ type: initialAction, payload });

    return axios
        .post(url, payload, getHeaders())
        .then(response => {
            dispatch({ type: nextActionOnSuccess, payload: response.data });
        })
        .catch( error => {
            dispatch({ type: nextActionOnFailure, payload: error });
        });
};

// Actions
const actions = {
    [ANNOUNCEMENTS_PAGEVIEW_EVENT_REQUEST]: (state: UserEventsState, action: AnyAction) => {
        const payload = action.payload;
        const eventIndex = state.content.findIndex(event => event.event_type === payload.event_type);
        let content;
        
        if (eventIndex >= 0) {
            content = state.content.map(event => {
                return (event.event_type === payload.event_type)
                    ? payload
                    : event;
            })
        } else {
            content = [...state.content, payload];
        }
        
        return {
            ...state,
            content,
            loading: true,
        };
    },
    [ANNOUNCEMENTS_PAGEVIEW_EVENT_SUCCESS]: (state: UserEventsState, action: AnyAction) => {
        return {
            ...state,
            loading: false,
            error: false,
        }
    },
    [ANNOUNCEMENTS_PAGEVIEW_EVENT_FAILURE]: (state: UserEventsState) => {
        return {
            ...state,
            loading: false,
            error: true,
        };
    },
    [SURVEYS_PAGEVIEW_EVENT_REQUEST]: (state: UserEventsState, action: AnyAction) => {
        const payload = action.payload;
        const eventIndex = state.content.findIndex(event => event.event_type === payload.event_type);
        let content;

        if (eventIndex >= 0) {
            content = state.content.map(event => {
                return (event.event_type === payload.event_type)
                    ? payload
                    : event;
            })
        } else {
            content = [...state.content, payload];
        }
        
        return {
            ...state,
            content,
            loading: true,
        };
    },
    [SURVEYS_PAGEVIEW_EVENT_SUCCESS]: (state: UserEventsState, action: AnyAction) => {    
        return {
            ...state,
            loading: false,
            error: false,
        }
    },
    [SURVEYS_PAGEVIEW_EVENT_FAILURE]: (state: UserEventsState) => {
        return {
            ...state,
            loading: false,
            error: true,
        };
    },
    [PROJECT_DETAIL_PAGEVIEW_EVENT_REQUEST]: (state: UserEventsState, action: AnyAction) => {
        const payload = action.payload;
        let events = state.content
            .filter(event => event.event_type === payload.event_type)
            .sort((a,b) => b.timestamp - a.timestamp);
        const eventIndex = events.findIndex(event => event.project_id === payload.project_id);
        const filteredEvents = state.content
        .filter(event => event.event_type !== payload.event_type);

        if (eventIndex < 0) {
            events = [payload, ...events];
        } else {
            events = events.map(event => event.project_id === payload.project_id ? payload : event);
        }

        if (events.length > 5) {
            events.pop();
        }

        console.log([...filteredEvents, ...events]);

        return {
            ...state,
            content: [...filteredEvents, ...events],
            loading: true,
        };
    },
    [PROJECT_DETAIL_PAGEVIEW_EVENT_SUCCESS]: (state: UserEventsState, action: AnyAction) => {
        return {
            ...state,
            loading: false,
            error: false,
        }
    },
    [PROJECT_DETAIL_PAGEVIEW_EVENT_FAILURE]: (state: UserEventsState) => {
        return {
            ...state,
            loading: false,
            error: true,
        };
    },
    [STORE_DETAIL_PAGEVIEW_EVENT_REQUEST]: (state: UserEventsState, action: AnyAction) => {
        const payload = action.payload;
        let events = state.content
            .filter(event => event.event_type === payload.event_type)
            .sort((a,b) => b.timestamp - a.timestamp);
        const eventIndex = events.findIndex(event => event.store_id === payload.store_id);
        const filteredEvents = state.content
        .filter(event => event.event_type !== payload.event_type);

        if (eventIndex < 0) {
            events = [payload, ...events];
        } else {
            events = events.map(event => event.store_id === payload.store_id ? payload : event);
        }

        // if (events.length > 5) {
        //     events.pop();
        // }

        console.log([...filteredEvents, ...events]);

        return {
            ...state,
            content: [...filteredEvents, ...events],
            loading: true,
        };
    },
    [STORE_DETAIL_PAGEVIEW_EVENT_SUCCESS]: (state: UserEventsState, action: AnyAction) => {
        return {
            ...state,
            loading: false,
            error: false,
        }
    },
    [STORE_DETAIL_PAGEVIEW_EVENT_FAILURE]: (state: UserEventsState) => {
        return {
            ...state,
            loading: false,
            error: true,
        };
    },
};

// Reducer Interface
// TODO: Add type to content
export interface UserEventsState {
    content: any[];
    error?: boolean;
    loading?: boolean;
}

// Initial State
const initialState: UserEventsState = {
    loading: false,
    error: false,
    content: [],
};

// Reducer
export default handleActions<UserEventsState, AnyAction>(actions, initialState);