import cx from "classnames";
import React from "react";

type AllowedColors = "red" | "blue" | "yellow" | "orange" | "green" | "gray";

interface LegendColorIndicatorProps {
  color: AllowedColors;
}

const LegendColorIndicator: React.FunctionComponent<LegendColorIndicatorProps> = ({ color }) => (
  <span className={cx("legend-dot", color)} />
);

export default LegendColorIndicator;
