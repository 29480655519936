import downloadSvg from "assets/img/download.svg";
import downloadInactiveSvg from "assets/img/downloads-inactive.svg";
import cx from "classnames";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import Dropdown from "components/Dropdown";
import Icon from "components/Icon";
import Page from "components/Page";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import ProjectFilter, { FilterOptions } from "containers/ProjectFilter";
import actionsReasons, { ActionID, ReasonID, reasons } from "helpers/actions-reasons";
import Constants from "helpers/constants";
import { ProjectType } from "interfaces/project";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnalyticsEvent, EventTypes } from "state/analytics";
import { fetchMyFeedback } from "state/my_feedback";
import { allFieldsEmpty, buildExportFilename } from "utils/helpers";

type MyFeedbackProps = PropsFromRedux & RouteProps;
interface MyFeedbackState {
  tableData: any[];
  selectedProjectId?: number;
  selectedStoreNumber?: string | null;
  selectedProjectName?: string;
  selectedProjectType?: string;
}

class MyFeedback extends React.Component<MyFeedbackProps, MyFeedbackState> {
  protected CSV_HEADERS = [
    { label: "Store Number", key: "store_number" },
    { label: "Product", key: "product_name" },
    { label: "UPC", key: "product_upc" },
    { label: "Action", key: "action" },
    { label: "Reason", key: "reason" },
    { label: "Swap", key: "product_sub_name" },
    { label: "Comment", key: "comment" },
  ];

  constructor(props: any) {
    super(props);
    this.state = { tableData: [] };
  }

  public componentDidMount() {
    this.props.fetchMyFeedback();
  }

  public getFeedbackToExport() {
    const { feedback = [] } = this.props;

    return feedback
      .filter((fb) => fb.project_id === this.state.selectedProjectId)
      .map((fb) => {
        // Add actual actions and reasons instead of their ids.
        return {
          ...fb,
          action: fb.action_id && actionsReasons[fb.action_id as ActionID].action,
          reason: fb.reason_id && reasons[fb.reason_id as ReasonID],
        };
      });
  }

  public updateTableData(option: number, name: string, type: string) {
    const { feedback = [] } = this.props;

    this.setState({
      selectedProjectId: option,
      selectedStoreNumber: null,
      selectedProjectName: name,
      selectedProjectType: type,
      tableData: feedback.filter((fb) => fb.project_id === option),
    });
  }

  public render() {
    const { feedback = [] } = this.props;

    const storeNumDropdownOptions = [
      { label: "All Stores", value: "-1", "data-qa": "all-comments" },
    ].concat(
      uniqBy(
        feedback
          .filter((fb) => fb.project_id === this.state.selectedProjectId)
          .map((fb) => ({
            label: fb.store_number,
            value: fb.store_number,
            "data-qa": fb.store_number,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        "value"
      )
    );

    const data = this.getFeedbackToExport();
    return (
      <Page>
        <header className="section-header">
          <h1 className="layout-header" data-qa="my-feedback-header">
            My Feedback
          </h1>
          <ProjectFilter
            dataQa={"my-feedback-project-dropdown"}
            exclude={Constants.FILE_SHARING}
            onChange={({ id, name, type }: FilterOptions) => this.updateTableData(id, name, type)}
            isSearchable
          />
          <div className="section-header-button-group">
            <CSVDownload
              className="csv-link"
              data={data}
              headers={this.CSV_HEADERS}
              filename={buildExportFilename("csv", "feedback")}
              onClick={() => {
                const { selectedProjectName, selectedProjectType } = this.state;

                this.props.createAnalyticsEvent({
                  event: EventTypes.REPORT_EXPORT,
                  eventType: "Action",
                  projectName: selectedProjectName as string,
                  projectType: selectedProjectType as ProjectType,
                  pageName: "My Feedback",
                  reportRowCount: data.length,
                });
              }}
            >
              <button
                disabled={allFieldsEmpty(this.state.tableData)}
                className={cx("export-feedback-button import-export-button", {
                  disabled: allFieldsEmpty(this.state.tableData),
                })}
                data-qa="export-feedback-btn"
              >
                Export{" "}
                <Icon
                  src={allFieldsEmpty(this.state.tableData) ? downloadInactiveSvg : downloadSvg}
                />
              </button>
            </CSVDownload>
          </div>
        </header>
        <CardList>
          <Card>
            <div className="my-feedback-filter-container">
              <p className="header">Filters</p>
              <div className="my-feedback-store-dropdown-container">
                <p>Store Number</p>
                <Dropdown
                  dataQa="my-feedback-store-dropdown"
                  value={
                    storeNumDropdownOptions.find(
                      (opt) => opt.value === this.state.selectedStoreNumber
                    ) || storeNumDropdownOptions[0]
                  }
                  options={storeNumDropdownOptions}
                  placeholder="Select"
                  isDisabled={!this.state.selectedProjectId}
                  isSearchable
                  containerStyles={{
                    marginLeft: "unset",
                    fontWeight: "normal",
                    marginBottom: "10px",
                  }}
                  onChange={(option) => {
                    this.setState({
                      selectedStoreNumber: option.value,
                      tableData:
                        option.value === "-1"
                          ? feedback.filter(
                              (comment) => comment.project_id === this.state.selectedProjectId
                            )
                          : feedback.filter(
                              (comment) =>
                                comment.store_number === option.value &&
                                comment.project_id === this.state.selectedProjectId
                            ),
                    });
                  }}
                />
              </div>
            </div>
          </Card>
          <Card>
            <Table
              data={this.state.tableData}
              style={{ marginTop: "unset" }}
              emptyState={
                this.state.selectedProjectId && this.state.selectedProjectId !== -1
                  ? "No feedback found for selected project"
                  : "Select a project to view feedback"
              }
              columns={[
                {
                  field: "store_number",
                  header: "Store No.",
                  dataQa: "storeNumber",
                },
                {
                  field: "action_id",
                  header: "Action",
                  dataQa: "action",
                  formatter: (value: string) => actionsReasons[value as ActionID].action,
                },
                {
                  field: "reason_id",
                  header: "Reason",
                  dataQa: "reason",
                  formatter: (value: string) => reasons[value as ReasonID],
                },
                {
                  field: "product_name",
                  header: "Product",
                  dataQa: "product",
                },
                {
                  field: "product_upc",
                  header: "UPC",
                  dataQa: "upc",
                },
                {
                  field: "product_sub_name",
                  header: "Swap",
                  dataQa: "productSubName",
                },
                {
                  field: "comment",
                  header: "Comment",
                  dataQa: "comment",
                },
              ]}
            />
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    feedback: state.my_feedback.content,
    auth_user: state.auth_user.content,
  }),
  { fetchMyFeedback, createAnalyticsEvent }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(MyFeedback);
