import axios from "axios";
import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { File } from "interfaces/file";
import { FetchAllStoresOrFilesQueryOptions } from "interfaces/filterQuery";
import { PaginatedQuery, PaginatedResponse } from "interfaces/paginated-query";
import { ThunkActionCreator } from "interfaces/thunk";
import queryString from "query-string";
import { handleActions } from "redux-actions";

// Actions Types
const FETCH_FILES_BY_STORE_PROJECT_REQUEST = "FETCH_FILES_BY_STORE_PROJECT_REQUEST";
const FETCH_FILES_BY_STORE_PROJECT = "FETCH_FILES_BY_STORE_PROJECT";
const FETCH_FILES_BY_STORE_PROJECT_FAILED = "FETCH_FILES_BY_STORE_PROJECT_FAILED";
const FETCH_FILES_BY_PROJECT_REQUEST = "FETCH_FILES_BY_PROJECT_REQUEST";
const FETCH_FILES_BY_PROJECT = "FETCH_FILES_BY_PROJECT";
const FETCH_FILES_BY_PROJECT_FAILED = "FETCH_FILES_BY_PROJECT_FAILED";

const INIT_ALLFILES = "INIT_ALLFILES";
const FETCH_ALLFILES_REQUEST = "FETCH_ALLFILES_REQUEST";
const FETCH_ALLFILES = "FETCH_ALLFILES";
const FETCH_ALLFILES_FAILED = "FETCH_ALLFILES_FAILED";

const DELETE_FILEMAPS_REQUEST = "DELETE_FILEMAPS_REQUEST";
const DELETE_FILEMAPS = "DELETE_FILEMAPS";
const DELETE_FILEMAPS_FAILED = "DELETE_FILEMAPS_FAILED";

export {
  FETCH_FILES_BY_STORE_PROJECT_REQUEST,
  FETCH_FILES_BY_STORE_PROJECT,
  FETCH_FILES_BY_STORE_PROJECT_FAILED,
  FETCH_FILES_BY_PROJECT_REQUEST,
  FETCH_FILES_BY_PROJECT,
  FETCH_FILES_BY_PROJECT_FAILED,
  FETCH_ALLFILES,
  FETCH_ALLFILES_REQUEST,
  FETCH_ALLFILES_FAILED,
  DELETE_FILEMAPS,
  DELETE_FILEMAPS_FAILED,
  DELETE_FILEMAPS_REQUEST,
};

export interface FetchStoreFilesOptions {
  projectId: number | string;
  storeId: number | string;
}

// Thunk Action Creator
//  this fetches all store level files by project
const fetchFilesByProjectStoreId: ThunkActionCreator<Promise<void>> =
  (options: FetchStoreFilesOptions) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FILES_BY_STORE_PROJECT_REQUEST });

    return axios
      .get(
        `${Config.API_SERVICE_URL}/api/projects/${options.projectId}/stores/${options.storeId}/files`,
        getHeaders()
      )
      .then((response) => {
        dispatch({
          type: FETCH_FILES_BY_STORE_PROJECT,
          payload: response.data,
        });
      })
      .catch((_) => {
        dispatch({ type: FETCH_FILES_BY_STORE_PROJECT_FAILED });
      });
  };

//  this fetches all project level files by project
const fetchFilesByProjectId: ThunkActionCreator =
  (projectId: number | string, queryOptions: PaginatedQuery = { size: 100000000, page: 0 }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FILES_BY_PROJECT_REQUEST });

    // TODO: Update default queryOptions once the API endpoint supports requesting all records
    const url = `${Config.API_SERVICE_URL}/api/projects/${projectId}/files?${queryString.stringify(
      queryOptions
    )}`;

    return axios
      .get(url, getHeaders())
      .then((response) =>
        dispatch({
          type: FETCH_FILES_BY_PROJECT,
          payload: response.data,
        })
      )
      .catch((error: Error) => dispatch({ type: FETCH_FILES_BY_PROJECT_FAILED }));
  };

const initAllFiles: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({
    type: INIT_ALLFILES,
  });
};

const fetchAllFiles: ThunkActionCreator<Promise<void>> =
  (projectId: number | string, queryOptions?: FetchAllStoresOrFilesQueryOptions) =>
  (dispatch: Dispatch) => {
    queryOptions = queryOptions ?? {};

    // TODO: Update default queryOptions once the API endpoint supports requesting all records
    queryOptions = {
      size: 100000000,
      page: 0,
      ...queryOptions,
    };

    dispatch({ type: FETCH_ALLFILES_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/projects/${projectId}/allfiles?${queryString.stringify(
      queryOptions
    )}`;

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({
          type: FETCH_ALLFILES,
          payload: response.data,
        });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_ALLFILES_FAILED });
      });
  };

const deleteFileMappings: ThunkActionCreator<Promise<void>> =
  (projectId: number | string, maptype: string) => (dispatch: Dispatch) => {
    dispatch({ type: DELETE_FILEMAPS_REQUEST });

    return axios
      .delete(
        `${Config.API_SERVICE_URL}/api/projects/${projectId}/allfiles?maptype=${maptype}`,
        getHeaders()
      )
      .then((response) => {
        dispatch({
          type: DELETE_FILEMAPS,
          payload: response.data,
        });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_FILEMAPS_FAILED });
      });
  };

// Actions
const actions = {
  [FETCH_FILES_BY_STORE_PROJECT_REQUEST]: (state: FilesState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_FILES_BY_STORE_PROJECT]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    content: {
      data: action.payload,
    },
  }),
  [FETCH_FILES_BY_STORE_PROJECT_FAILED]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
  [FETCH_FILES_BY_PROJECT_REQUEST]: (state: FilesState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_FILES_BY_PROJECT]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_FILES_BY_PROJECT_FAILED]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),

  [INIT_ALLFILES]: (state: FilesState) => ({
    ...state,
    loading: false,
  }),

  [FETCH_ALLFILES_REQUEST]: (state: FilesState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_ALLFILES]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_ALLFILES_FAILED]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),

  [DELETE_FILEMAPS_REQUEST]: (state: FilesState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_FILEMAPS]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [DELETE_FILEMAPS_FAILED]: (state: FilesState, action: AnyAction): FilesState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
};

// Reducer Interface
export interface FilesState {
  error?: boolean;
  loading?: boolean;
  content?: PaginatedResponse<File>;
}

// Initial State
const initialState: FilesState = {
  loading: false,
  error: false,
  content: { data: [] },
};

export {
  deleteFileMappings,
  fetchAllFiles,
  fetchFilesByProjectId,
  fetchFilesByProjectStoreId,
  initAllFiles,
  initialState,
};

// Reducer
export default handleActions<FilesState, AnyAction>(actions, initialState);
