import React from "react";
import Select, { components } from "react-select";

import downArrowSvg from "assets/img/arrow-down.svg";

interface DropdownProps {
  value: any;
  name?: string;
  options: any[];
  noOptionsMessage?: any;
  className?: string;
  classNamePrefix?: string;
  image?: string;
  placeholder?: string;
  containerStyles?: object;
  controlStyles?: object;
  onChange?: (newValue: any, action: object) => void;
  onInputChange?: (newValue: any, action: object) => void;
  isDisabled?: boolean;
  isSearchable?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  formatOptionLabel?: any;
  dataQa?: string;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  blurInputOnSelect?: boolean;
  noShadow?: boolean;
  backgroundFill?: boolean;
  placeholderStyles?: object;

}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  backgroundFill,
  noShadow,
  containerStyles,
  controlStyles,
  dataQa,
  image = downArrowSvg,
  isSearchable = false,
  placeholderStyles,
  ...restProps
}) => (
  <Select
    blurInputOnSelect={true}
    styles={{
      multiValueLabel: (styles) => ({ ...styles, textOverflow: "ellipsis" }),
      container: (base, state) => ({
        ...base,
        zIndex: state.selectProps.menuIsOpen ? 2 : "inherit",
        marginLeft: "auto",
        fontSize: 11,
        fontWeight: "normal",
        fontFamily: "Arial",
        color: "#8D8D92",
        minWidth: 200,
        alignSelf: "flex-start",
        textTransform: "capitalize",
        backgroundColor: backgroundFill ? "#ffffff" : state.isFocused ? "#ffffff" : "transparent",
        boxShadow: noShadow ? "none" : "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
        ...containerStyles,
      }),
      placeholder: (defaultStyles) => ({
        ...defaultStyles,
        ...placeholderStyles,
      }),
      indicatorSeparator: () => ({ display: "none" }),
      control: (base, state) => {
        return {
          ...base,
          border: noShadow
            ? "1px solid " + (state.isFocused ? "#4570e9" : "#8D8D92")
            : "1px solid " + (state.isFocused ? "#4570e9" : "#FFFFFF"),
          boxShadow: "none",
          borderRadius: 0,
          backgroundColor: "transparent",
          "&:hover": { borderColor: "none" },
          ...controlStyles,
        };
      },
      menu: (base, state) => ({
        ...base,
        margin: 0,
        borderRadius: 0,
        boxShadow: "0 5px 10px 0 rgba(150, 150, 150, 0.4)",
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#3C6DF1" : state.isFocused ? "#BADAF4" : "transparent",
        color: state.isSelected ? "#FFFFFF" : "#4A4A4A",
      }),
      singleValue: (base, state) => {
        return {
          ...base,
          color: state.getValue()[0].value === -1 ? "#8D8D92" : "#4A4A4A",
        };
      },
      valueContainer: (base, state) => ({
        ...base,
        flexWrap: state.selectProps.menuIsOpen ? "wrap" : "nowrap",
      }),
    }}
    components={{
      MultiValueRemove: (multiValueProps) =>
        components.MultiValueRemove && (
          <span
            data-qa={(multiValueProps as any).data["data-qa"] + "-remove"}
            style={{ display: "flex" }}
          >
            <components.MultiValueRemove {...multiValueProps} />
          </span>
        ),
      DropdownIndicator: (dropdownProps) =>
        components.DropdownIndicator && (
          <span data-qa={dataQa}>
            <components.DropdownIndicator {...dropdownProps}>
              <img src={image} alt="" />
            </components.DropdownIndicator>
          </span>
        ),
      Option: (optionProps) => (
        <span data-qa={(optionProps as any).data["data-qa"]}>
          <components.Option {...optionProps} />
        </span>
      ),
      Placeholder: (placeholderProps) => (
        <div>
          <components.Placeholder {...placeholderProps} />
        </div>
      ),
    }}
    isSearchable={isSearchable}
    {...restProps}
    data-qa={dataQa}
  />
);

export default Dropdown;
