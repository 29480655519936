import Button from "components/Button";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import DeletePopover from "components/DeletePopover";
import Table from "components/Table";
import Form from "components/Form";
import Config from "config";
import { RouteProps } from "containers/App";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import FileUpload from "containers/FileUpload";
import ManageDataPreview from "containers/ManageDataPreview";
import { UserCSV } from "interfaces/user";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { deleteUser } from "state/user";
import { fetchUsers, filterUsers } from "state/users";
import { buildExportFilename } from "utils/helpers";
import moment from "moment";
import Constants from "helpers/constants";

type ManageUsersProps = PropsFromRedux & RouteProps;
interface ManageUsersState {
  email: string | null;
}
class ManageUsers extends React.Component<ManageUsersProps, ManageUsersState> {
  protected CSV_HEADERS = [
    { label: "Phone", key: "phone" },
    { label: "User Role", key: "role" },
    { label: "Email Address", key: "email" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Company Representing", key: "company" },
    { label: "Title", key: "title" },
    { label: "Employer", key: "employer" },
    { label: "Region", key: "region" },
    { label: "Wholesaler Numbers", key: "wholesalernumbers" },
    { label: "IRIS Access", key: "accessDIR" },
    { label: "IRIS Email", key: "getDIRemail" },
    { label: "Date Added", key: "createdAt" },
  ];

  state = {
    email: "",
  };

  public componentDidMount() {
    this.props.fetchUsers();
  }

  public handleDelete(id: number) {
    return this.props.deleteUser(id);
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  public handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    // reset form and inputs
    this.setState({ email: "" });
    this.props.fetchUsers();
  };

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email } = this.state;
    this.props.filterUsers({email: email});
  };

  public renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group title="Search">
          <Form.Input
            type="text"
            placeholder="Email"
            data-qa="store-email-field"
            value={this.state.email}
            name="email"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.ButtonGroup>
          <Form.CancelButton onClick={this.handleClear} data-qa="clear-button">
            Clear
          </Form.CancelButton>
          <Form.ConfirmButton data-qa="search-button">Search</Form.ConfirmButton>
        </Form.ButtonGroup>
      </Form>
    );
  }

  public render() {
    const { currentUser, users = [] } = this.props;

    return (
      <section className="manage-data-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: "/manage-all-data",
                crumb: "VIEW ALL DATA",
                dataQa: "view-all-data-link",
              },
            ],
          }}
        />
        <div className="manage-data-body">
          <h1 className="header" data-qa="admin-user-header">
            Manage User Data
          </h1>
          <ul className="file-upload-list">
            <li className="file-upload-item">
              <FileUpload
                prefix={"USERS"}
                url={`${Config.API_SERVICE_URL}/api/users`}
                label={"Upload New Users"}
                dataQa={"manage-users-file-upload"}
                afterUploadFunction={this.props.fetchUsers}
              />
            </li>
            <CSVDownload
              data={this.props.users ? this.props.users.map(userToUserCSV) : []}
              headers={this.CSV_HEADERS}
              filename={buildExportFilename("csv", "users")}
            >
              <Button data-qa="export-all-data-btn">EXPORT ALL DATA</Button>
            </CSVDownload>
          </ul>
          <CardList>
            <Card>
              <ManageDataPreview prefix={"USERS"} />
            </Card>
          </CardList>
          <CardList>
            <Card>{this.renderForm()}</Card>
            <Card>
              <Table
                data={users}
                emptyState={"No users found"}
                columns={[
                  {
                    field: "role",
                    header: "USER ROLE",
                    dataQa: "user-role",
                  },
                  {
                    field: "email",
                    header: "EMAIL ADDRESS",
                    dataQa: "email-address",
                  },
                  {
                    field: "firstname",
                    header: "FIRST NAME",
                    dataQa: "first-name",
                  },
                  {
                    field: "lastname",
                    header: "LAST NAME",
                    dataQa: "last-name",
                  },
                  {
                    field: "company",
                    header: "COMPANY REPRESENTING",
                    dataQa: "company-representing",
                  },
                  {
                    field: "createdAt",
                    header: "DATE ADDED",
                    dataQa: "date-added",
                    formatter: (value) => {
                      return moment(value, Constants.DATE_FORMAT__YYYY_MM_DD).format("M/D/YYYY");
                    },
                  },
                  {
                    field: "id",
                    header: "",
                    sortable: false,
                    dataQa: "delete-user",
                    formatter: (id, row, index) => {
                      // don't allow the user to delete themselves
                      if (id.toString() === (currentUser && currentUser.id.toString())) {
                        return null;
                      }

                      return (
                        <DeletePopover
                          message={"Delete this user"}
                          onConfirm={(_) => this.handleDelete(row.id)}
                          id={row.id}
                          index={index as number}
                        />
                      );
                    },
                  },
                ]}
              />
            </Card>
          </CardList>
        </div>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    currentUser: state.auth_user.content,
    users: state.users.content?.data,
  }),
  { fetchUsers, deleteUser, filterUsers }
);

const userToUserCSV = (user: any): UserCSV => {
  let accessDIR = 0;
  let getDIRemail = 0;
  if (user.user_level_permissions) {
    for (const perm of user.user_level_permissions) {
      if (perm === "access_DIR") {
        accessDIR = 1;
      } else if (perm === "get_DIR_email") {
        getDIRemail = 1;
      }
    }
  }

  const wholesalernumbers = user.wholesaler_numbers ? user.wholesaler_numbers.join("/") : null;
  const csvUser = {
    accessDIR,
    getDIRemail,
    wholesalernumbers,
    ...user,
  };
  delete csvUser.user_level_permissions;
  delete csvUser.wholesaler_numbers;
  return csvUser;
};

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageUsers);
