import React from "react";

import { Permission } from "state/permissions";

const defaultPermissions: Permission = {
  view_pog: false,
  comment_pog: false,
  certify_pog: false,
  viewmy_comment: false,
  viewall_comment: false,
  accept_comment: false,
  reject_comment: false,
  set_reset: false,
  comment_reset: false,
  validate_reset: false,
  take_survey: false,
};

const PermissionsContext = React.createContext(defaultPermissions);

export { PermissionsContext };
