import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { hydrateAuth } from "state/auth";
import { hydrateUser, UserAuthenticationError } from "state/auth_user";
import { fetchConfig } from "state/config";
import { hydratePermissions } from "state/permissions";
import { SeverityLevel, ToastService } from "../services/toastService";

const init: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch(hydrateAuth())
    .then(() => dispatch(hydrateUser()))
    .then(() => dispatch(hydratePermissions()))
    .then(() => dispatch(fetchConfig()))
    .catch((error: any) => {
      console.error(error)
      ToastService.getInstance().publishAuthErrorToast(new UserAuthenticationError(), SeverityLevel.ERROR);
    });
};



export { init };

