import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
const UPDATE_NOTIFICATIONS_REQUEST = "UPDATE_NOTIFICATIONS_REQUEST";
const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
const UPDATE_NOTIFICATIONS_FAILED = "UPDATE_NOTIFICATIONS_FAILED";

export { UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATIONS_FAILED, UPDATE_NOTIFICATIONS_REQUEST };

// Thunk Action Creator
const updateNotifications: ThunkActionCreator = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_NOTIFICATIONS_REQUEST });

  return axios
    .post(
      `${Config.API_SERVICE_URL}/api/projects/${id}/notifications`,
      getHeaders({
        "Content-Type": "application/json",
      })
    )
    .then((response) => dispatch({ type: UPDATE_NOTIFICATIONS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: UPDATE_NOTIFICATIONS_FAILED }));
};

export { updateNotifications };

// Actions
const actions = {
  [UPDATE_NOTIFICATIONS_REQUEST]: (state: NotificationsState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_NOTIFICATIONS]: (state: NotificationsState, action: AnyAction): NotificationsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [UPDATE_NOTIFICATIONS_FAILED]: (state: NotificationsState): NotificationsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Reducer Interface
export interface Notifification {
  readonly project_id?: number;
  readonly store_id?: string;
  readonly user_id: string;
}

export interface NotificationsState {
  error?: boolean;
  loading?: boolean;
  content?: Notifification[];
}

// Initial State
const initialState: NotificationsState = {
  loading: false,
  error: false,
  content: [],
};

// reducer
export default handleActions<NotificationsState, AnyAction>(actions, initialState);
