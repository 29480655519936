export const isGeolocationEnabled = () => {
  return new Promise<boolean>((resolve) => {
    navigator.geolocation.watchPosition(
      function () {
        resolve(true);
      },
      function () {
        resolve(false);
      }
    );
  });
};
