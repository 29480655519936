import {toast, ToastItem, ToastOptions} from "react-toastify";

export enum SeverityLevel {
  WARN, ERROR, INFO
}
export class ToastService {

  private static instance: ToastService;

  private currentToasts: Set<string> = new Set<string>();

  private getToast = (severityLevel: SeverityLevel) => {
    switch (severityLevel) {
      case SeverityLevel.WARN:
        return toast.warning;
      case SeverityLevel.ERROR:
        return toast.error;
      case SeverityLevel.INFO:
        return toast.info;
    }
  }

  private constructor() {
    toast.onChange((payload: ToastItem<any>) => {
      let identifier: string = "";
      if (payload.data) {
        if (payload.data.identifier) {
          identifier = payload.data.identifier
        } else if (payload.data.url) {
          identifier =  new URL(payload.data.url).pathname
        } else {
          identifier = payload.data.name
        }
      }
      switch (payload.status) {
        case "added":
          break;
        case "updated":
          break;
        case "removed":
          this.currentToasts.delete(identifier)
          break;
      }
    });
  }

  static getInstance(): ToastService {
    if (!this.instance) {
      this.instance = new ToastService();
    }

    return this.instance;
  }

  publishRequestErrorToast(error: any, severity: SeverityLevel) {
    try {
      const path = new URL(error.request.responseURL).pathname
      const toastAlreadyPublished = this.currentToasts.has(path)
      if (!toastAlreadyPublished) {
        this.currentToasts.add(path)

        if (error.response?.data.identifier) {
          if (error.response?.data.trace) {
            this.getToast(severity)(
              <div className={severity === SeverityLevel.ERROR ? "toastify-error" : "toastify-warn"}>
                <div className={"error-title"}>{error.response.statusText}</ div>
                <div className={"title"}>{path}</ div>
                <div className={"identifier"}> Identifier: {error.response.data.identifier || ""} </div>
                <div className={"message"}><b>Error Message:</b> <br/>{error.response?.data.message}</ div>
              </div>, {
                data: {url: error.request.responseURL}
              }
            )
          } else {
            this.getToast(severity)(
              <div className={"toastify-error"}>
                <div className={severity === SeverityLevel.ERROR ? "toastify-error" : "toastify-warn"}>{error.response?.statusText || ""}</ div>
                <div className={"title"}>{path}</ div>
                <div className={"identifier"}> Identifier: {error.response.data.identifier} </div>
              </div>, {
                data: {url: error.request.responseURL}
              }
            )
          }
        } else {
          this.getToast(severity)(
            <div className={"toastify-error"}>
              <div className={severity === SeverityLevel.ERROR ? "toastify-error" : "toastify-warn"}>{error.response?.statusText || ""}</ div>
              <div className={"title"}>{path}</ div>
            </div>, {
              data: {url: error.request.responseURL}
            }
          )
        }

      }
    } catch (error) {
      console.log("Error publishing toast")
    }
  }

  publishAuthErrorToast(error: Error, severity: SeverityLevel) {
    try {
      const toastAlreadyPublished = this.currentToasts.has(error.message)
      if (!toastAlreadyPublished) {
        this.currentToasts.add(error.message)
            this.getToast(severity)(
              <div className={severity === SeverityLevel.ERROR ? "toastify-error" : "toastify-warn"}>
                <div className={"error-title"}>{error.name}</ div>
                <div className={"message"}><b>Error Message:</b> <br/>{error.message}</ div>
                <div className={"message"}><b>Click the link below to reset session</b> <br/><a className={"logoutLink"} href={"/logout"}>reset</a></ div>
              </div>, {
                autoClose: false,
                closeButton: false
              })
      }
    } catch (error) {
      console.log("Error publishing toast")
    }
  }

  publishToast(toast: {identifier: string, title: string, message: string}, severity: SeverityLevel, options: ToastOptions = {}) {
    try {
      const toastAlreadyPublished = this.currentToasts.has(toast.identifier)
      if (!toastAlreadyPublished) {
        this.currentToasts.add(toast.identifier)
        this.getToast(severity)(
          <div className={severity === SeverityLevel.ERROR ? "toastify-error" : "toastify-warn"}>
            <div className={"error-title"}>{toast.title}</ div>
            <div className={"message"}><b>Message:</b> <br/>{toast.message}</ div>
          </div>, options)
      }
    } catch (error) {
      console.log("Error publishing toast")
    }
  }
}
