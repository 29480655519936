import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Announcement } from "interfaces/announcement";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { CREATE_ANNOUNCEMENT, DELETE_ANNOUNCEMENT } from "state/announcement";

// Action types
const FETCH_ANNOUNCEMENTS_REQUEST = "FETCH_ANNOUNCEMENTS_REQUEST";
const FETCH_ANNOUNCEMENTS = "FETCH_ANNOUNCEMENTS";
const FETCH_ANNOUNCEMENTS_FAILED = "FETCH_ANNOUNCEMENTS_FAILED";

const FETCH_MY_ANNOUNCEMENTS_REQUEST = "FETCH_MY_ANNOUNCEMENTS_REQUEST";
const FETCH_MY_ANNOUNCEMENTS = "FETCH_MY_ANNOUNCEMENTS";
const FETCH_MY_ANNOUNCEMENTS_FAILED = "FETCH_MY_ANNOUNCEMENTS_FAILED";

export {
  FETCH_ANNOUNCEMENTS_REQUEST,
  FETCH_ANNOUNCEMENTS,
  FETCH_ANNOUNCEMENTS_FAILED,
  FETCH_MY_ANNOUNCEMENTS_REQUEST,
  FETCH_MY_ANNOUNCEMENTS,
  FETCH_MY_ANNOUNCEMENTS_FAILED,
};

// Thunk Action Creator
export const fetchMyAnnouncements: ThunkActionCreator<Promise<void>> =
  (projectid?: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MY_ANNOUNCEMENTS_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/announcements/me`;

    if (projectid) {
      url = url.concat(`?projectId=${projectid}`);
    }

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_MY_ANNOUNCEMENTS, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_MY_ANNOUNCEMENTS_FAILED });
      });
  };

// Actions
const actions = {
  [FETCH_MY_ANNOUNCEMENTS_REQUEST]: (state: AnnouncementsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MY_ANNOUNCEMENTS]: (state: AnnouncementsState, action: AnyAction): AnnouncementsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_MY_ANNOUNCEMENTS_FAILED]: (state: AnnouncementsState): AnnouncementsState => ({
    ...state,
    loading: false,
    hasError: true,
  }),
  [CREATE_ANNOUNCEMENT]: (state: AnnouncementsState, action: AnyAction): AnnouncementsState => ({
    ...state,
    loading: false,
    content: [...action.payload, ...(state.content || [])],
    hasError: false,
  }),
  [DELETE_ANNOUNCEMENT]: (state: AnnouncementsState, action: AnyAction): AnnouncementsState => ({
    ...state,
    loading: false,
    content: (state.content || []).filter((c) => c.id !== action.payload),
    hasError: false,
  }),
};

export interface AnnouncementsState {
  hasError?: boolean;
  errors?: string[];
  loading?: boolean;
  content?: Announcement[];
}

const initialState: AnnouncementsState = {
  loading: false,
  hasError: false,
  errors: [],
  content: [],
};

export default handleActions<AnnouncementsState, AnyAction>(actions, initialState);
