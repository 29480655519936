// workaround for CSVLink not working with async loaded data :(
// https://github.com/react-csv/react-csv/issues/350

import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";

// @ts-ignore
import { CSVLink } from "react-csv";

interface CSVDownloadProps extends React.HTMLAttributes<{}> {
  data(): Promise<any[]>;
  filename: string;
  headers: Array<{ label: string; key: string }>;
}

const CSVDownload2: React.FunctionComponent<CSVDownloadProps> = (props) => {
  const [csvData, setCSVData] = useState([] as any[]);
  const ref = useRef();

  const { data, filename, headers } = props;

  const loadCsv = () => {
    data()
      .then((newData) => {
        setCSVData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (csvData.length > 0) {
      // @ts-ignore
      ref.current.link.click();
    }
  }, [csvData]);

  return (
    <>
      <Button className="button secondary" onClick={loadCsv}>
        EXPORT TABLE
      </Button>
      <CSVLink data={csvData} ref={ref} filename={filename} headers= {headers} />
    </>
  );
}
export default CSVDownload2;
