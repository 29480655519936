import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { Store2Project } from "interfaces/store2project";
import { ThunkActionCreator } from "interfaces/thunk";
import axios, { AxiosResponse } from 'axios';
// Actions Types
export const FETCH_STORE_2_PROJECTS_REQUEST = "FETCH_STORE_2_PROJECTS_REQUEST";
export const FETCH_STORE_2_PROJECTS = "FETCH_STORE_2_PROJECTS";
export const FETCH_STORE_2_PROJECTS_FAILED = "FETCH_STORE_2_PROJECTS_FAILED";

export const FETCH_MY_STORE_2_PROJECTS_REQUEST = "FETCH_MY_STORE_2_PROJECTS_REQUEST";
export const FETCH_MY_STORE_2_PROJECTS = "FETCH_MY_STORE_2_PROJECTS";
export const FETCH_MY_STORE_2_PROJECTS_FAILED = "FETCH_MY_STORE_2_PROJECTS_FAILED";

export const UPDATE_STORE_2_PROJECT_REQUEST = "UPDATE_STORE_2_PROJECT_REQUEST";
export const UPDATE_STORE_2_PROJECT = "UPDATE_STORE_2_PROJECT";
export const UPDATE_STORE_2_PROJECT_FAILED = "UPDATE_STORE_2_PROJECT_FAILED";



export const fetchStore2Projects: ThunkActionCreator<Promise<void>> =
(projectId?: string | number, storeId?: string | number) => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_STORE_2_PROJECTS_REQUEST });
  let url = `${Config.API_SERVICE_URL}/api/store2projects`;

  if (projectId && !storeId) {
    url = `${url}?projectId=${projectId}`;
  } else if (projectId && storeId) {
    url = `${url}?projectId=${projectId}&storeId=${storeId}`;
  }
  return axios
    .get(url, getHeaders())
    .then((response) => {
      dispatch({ type: FETCH_STORE_2_PROJECTS, payload: response.data });
    })
    .catch((error: Error) => {
      dispatch({ type: FETCH_STORE_2_PROJECTS_FAILED });
    });
};
 
  

export const fetchMyStore2Projects: ThunkActionCreator<Promise<void>> =
  (projectId?: string | number, storeId?: string | number) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MY_STORE_2_PROJECTS_REQUEST });
    let url = `${Config.API_SERVICE_URL}/api/store2projects/me`;

    if (projectId && !storeId) {
      url = `${url}?projectId=${projectId}`;
    } else if (projectId && storeId) {
      url = `${url}?projectId=${projectId}&storeId=${storeId}`;
    }
    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_MY_STORE_2_PROJECTS, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_MY_STORE_2_PROJECTS_FAILED });
      });
  };

export interface UpdateStore2ProjectBody {
  targetResetDate?: string;
  actualResetDate?: string;
}

export const updateStore2Project: ThunkActionCreator<Promise<void>> =
  (id: string | number, body: UpdateStore2ProjectBody) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_STORE_2_PROJECT_REQUEST });

    return axios
      .patch(`${Config.API_SERVICE_URL}/api/store2projects/${id}`, body, getHeaders())
      .then((response) => {
        dispatch({ type: UPDATE_STORE_2_PROJECT, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: UPDATE_STORE_2_PROJECT_FAILED });
      });
  };

const actions = {
  [FETCH_STORE_2_PROJECTS_REQUEST]: (state: Store2ProjectState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_STORE_2_PROJECTS]: (state: Store2ProjectState, action: AnyAction): Store2ProjectState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_STORE_2_PROJECTS_FAILED]: (state: Store2ProjectState): Store2ProjectState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [FETCH_MY_STORE_2_PROJECTS_REQUEST]: (state: Store2ProjectState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MY_STORE_2_PROJECTS]: (
    state: Store2ProjectState,
    action: AnyAction
  ): Store2ProjectState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_MY_STORE_2_PROJECTS_FAILED]: (state: Store2ProjectState): Store2ProjectState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [UPDATE_STORE_2_PROJECT_REQUEST]: (state: Store2ProjectState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_STORE_2_PROJECT]: (state: Store2ProjectState, action: AnyAction): Store2ProjectState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [UPDATE_STORE_2_PROJECT_FAILED]: (state: Store2ProjectState): Store2ProjectState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Reducer Interface
export interface Store2ProjectState {
  error?: boolean;
  loading?: boolean;
  content?: Store2Project[];
}

// Initial State
const initialState: Store2ProjectState = {
  loading: false,
  error: false,
  content: [],
};

export default handleActions<Store2ProjectState, AnyAction>(actions, initialState);
