import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
const FETCH_COVERAGE_REPORTS_REQUEST = "FETCH_COVERAGE_REPORTS_REQUEST";
const FETCH_COVERAGE_REPORTS = "FETCH_COVERAGE_REPORTS";
const FETCH_COVERAGE_REPORTS_FAILED = "FETCH_COVERAGE_REPORTS_FAILED";
const RESET_COVERAGE_REPORTS = "RESET_COVERAGE_REPORTS";

export {
    FETCH_COVERAGE_REPORTS_REQUEST,
    FETCH_COVERAGE_REPORTS,
    FETCH_COVERAGE_REPORTS_FAILED,
    RESET_COVERAGE_REPORTS,
};

export const fetchCoverageReports : ThunkActionCreator<Promise<void>> =
    () => (dispatch: Dispatch) => {
        dispatch({ type: FETCH_COVERAGE_REPORTS_REQUEST });
        
        return axios
        .get(`${Config.API_SERVICE_URL}/api/coverage-report-download`, getHeaders())
        .then((response) => {
            dispatch({ type: FETCH_COVERAGE_REPORTS, payload: response.data });
        })
        .catch((error: Error) => {
            dispatch({ type: FETCH_COVERAGE_REPORTS_FAILED });
        });
    };

export const resetCoverageReports = () => (dispatch: Dispatch) => {
    dispatch({ type: RESET_COVERAGE_REPORTS });
}

// Actions
const actions = {
    [FETCH_COVERAGE_REPORTS_REQUEST]: (state: CoverageReportsState) => ({
        ...state,
        loading: true,
    }),
    [FETCH_COVERAGE_REPORTS]: (state: CoverageReportsState, action: AnyAction) => ({
        ...state,
        content: action.payload,
        loading: false,
    }),
    [FETCH_COVERAGE_REPORTS_FAILED]: (state: CoverageReportsState) => ({
        ...state,
        content: undefined,
        error: true,
        loading: false,
    }),
    [RESET_COVERAGE_REPORTS]: (state: CoverageReportsState) => ({
        ...state,
        content: undefined,
        loading: false,
        error: false,
    })
};

// Reducer Interface
export interface CoverageReports {
    readonly SheetNames: string[];
    readonly Sheets: {
        string : {
            string: { string : any } | any
        }
    };
}
  
export interface CoverageReportsState {
    error?: boolean;
    loading?: boolean;
    content?: CoverageReports;
}
  
// Initial State
const initialState: CoverageReportsState = {
    loading: false,
    error: false,
    content: undefined,
};
  
// reducer
export default handleActions<CoverageReportsState, AnyAction>(actions, initialState);