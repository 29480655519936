import cx from "classnames";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Dropdown from "components/Dropdown";
import transform from "lodash/transform";
import { ReactSelectObject } from "interfaces/select";

import Link from "components/Link";
import { Body } from "components/Typography";
import Constants from "helpers/constants";
import { State } from "state";
import { fetchProject, updateProject } from "state/project";
import { seasons, Season } from "helpers/seasons";

interface ProjectInfoState {
  ltext: string;
  expires_at?: string;
  est_complete_date?: string;
  season?: string;
  year?: string;
  chain_parent?: string;
  publish_at?: string;
}

type ProjectInfoProps = RouteComponentProps<{ id: string }> & {
  onEditOrSaveClick(action: "Edit" | "Save"): void;
} & PropsFromRedux;

class ProjectInfo extends React.Component<ProjectInfoProps, ProjectInfoState> {
  public saveEdit: HTMLAnchorElement | null = null;
  public state: ProjectInfoState = {
    ltext: "Edit",
    expires_at: "",
    est_complete_date: "",
    season: "",
    year: "",
    chain_parent: "",
    publish_at: "",
  };

  public getSeasons = (): ReactSelectObject[] => {
    return transform(
      seasons,
      (result: any[], value: Season, key) => {
        result.push({
          label: value,
          value: value,
          id: key
        });
        return result;
      },
      []
    );
  };

  public _seasons = this.getSeasons();

  public componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.props.fetchProject(id).then(() => {
      if (this.props.project) {
        const { est_complete_date, expires_at, project_season, project_year, chain, publish_at } = this.props.project;
        this.setState({
          est_complete_date,
          expires_at: moment(expires_at).format(Constants.DATE_FORMAT__YYYY_MM_DD),
          publish_at: publish_at ? moment.utc(publish_at).format(Constants.DATE_FORMAT__YYYY_MM_DD__HH_mm_ss): "",
          season: project_season,
          year: project_year,
          chain_parent: chain?.chain_parent
        });
      }
    });
  }

  public componentDidUpdate(prevprops: ProjectInfoProps) {
    const { project } = this.props;
    const { project: prevProject } = prevprops;

    if (project && prevProject) {
      if (
        project.expires_at !== prevProject.expires_at ||
        project.publish_at !== prevProject.publish_at ||
        project.est_complete_date !== prevProject.est_complete_date ||
        project.project_season !== prevProject.project_season ||
        project.project_year !== prevProject.project_year ||
        project?.chain?.chain_parent !== prevProject?.chain?.chain_parent
      ) {
        this.setState({
          expires_at: project.expires_at,
          publish_at: project.publish_at,
          est_complete_date: project.est_complete_date,
          season: project.project_season,
          year: project.project_year,
          chain_parent: project?.chain?.chain_parent
        });
      }
    }
  }

  public handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const {
      match: {
        params: { id },
      },
      project,
    } = this.props;
    const { ltext, expires_at, est_complete_date, season, year, publish_at } = this.state;

    if (ltext === "Save") {
      if (
        project &&
        (project.expires_at !== expires_at ||
          project.publish_at !== publish_at ||
          project.est_complete_date !== est_complete_date ||
          project.project_season !== season ||
          project.project_year !== year)
      ) {
        const seasonID = this._seasons.find((element)=> element.value === season)?.id;
        this.props.updateProject(id, {
          expires_at,
          est_complete_date,
          season_id: seasonID,
          year: year,
          publish_at,
        })
      }
      this.setState({ ltext: "Edit" });
      this.props.onEditOrSaveClick("Save");
    } else if (ltext === "Edit") {
      this.setState({ ltext: "Save" });
      this.props.onEditOrSaveClick("Edit");
    }

    if (this.saveEdit) {
      this.saveEdit.blur();
    }
  };

  public handleChange = (event: any, name: string) => {
    if (name === "expire-date") {
      this.setState({
        expires_at: moment.utc(event._d).format(Constants.DATE_FORMAT__YYYY_MM_DD),
      });
    } else if (name === "est-complete-date") {
      this.setState({
        est_complete_date: moment.utc(event._d).format(Constants.DATE_FORMAT__YYYY_MM_DD),
      });
    } else if (name === "season") {
      this.setState({
        season: event.value,
      });
    } else if (name === "year") {
      this.setState({
        year: event.currentTarget.value,
      });
    } else if (name === "publish-date"){
      this.setState({
        publish_at: moment.utc(event._d).format(Constants.DATE_FORMAT__YYYY_MM_DD__HH_mm_ss),
      });
    }
  };

  public render() {
    const { project } = this.props;
    const { ltext, expires_at, est_complete_date, season, year, chain_parent, publish_at } = this.state;
    const today = moment();
    const disabled = ltext === "Edit" || project?.published;

    if (!project) {
      return null;
    }

    return (
      <div className="project-info-container">
        <div className="project-info-edit-save">
          <Body type="1">Project Info</Body>
          <Link
            to="#"
            data-qa="prjtinfo-edit-save-link"
            className="project-info-edit-save-link"
            onClick={this.handleClick}
            innerRef={(node: HTMLAnchorElement | null) => (this.saveEdit = node)}
          >
            {this.state.ltext}
          </Link>
        </div>
        <ul className="project-info-list">
          <li className="project-info-list-item" data-qa="prjtinfo-project-name-label">
            <label className="project-info-label h6">PROJECT NAME</label>
            <input
              disabled={true}
              type="text"
              name="project-name"
              className={cx("project-info-input b3", { disabled: true })}
              value={project.name}
              data-qa="prjtinfo-project-name"
            />
          </li>
          <li className="project-info-list-item" data-qa="prjtinfo-expire-date-label">
            <label className="project-info-label h6">EXPIRATION DATE</label>
            <DatePicker
              disabled={ltext === "Edit"}
              selected={expires_at ? moment.utc(expires_at) : undefined}
              onChange={(e) => e && this.handleChange(e, "expire-date")}
              className={cx("project-info-input prjtinfo-expire-date b3", {
                disabled: ltext === "Edit",
              })}
              placeholderText={"mm/dd/yyyy"}
              minDate={today}
              name="expire-date"
              dateFormat="MM/DD/YYYY"
            />
          </li>
          <li className="project-info-list-item" data-qa="prjtinfo-est-complete-date-label">
            <label className="project-info-label h6">ESTIMATED COMPLETION DATE</label>
            <DatePicker
              disabled={ltext === "Edit"}
              selected={est_complete_date ? moment.utc(est_complete_date) : undefined}
              onChange={(e) => e && this.handleChange(e, "est-complete-date")}
              className={cx("project-info-input prjtinfo-est-complete-date b3", {
                disabled: ltext === "Edit",
              })}
              placeholderText={"mm/dd/yyyy"}
              minDate={today}
              name="est-complete-date"
              dateFormat="MM/DD/YYYY"
            />
          </li>
          <li className="project-info-list-item" data-qa="prjtinfo-chain-name-label">
            <label className="project-info-label h6">CHAIN</label>
            <input
              disabled={true}
              type="text"
              name="chain-name"
              className={cx("project-info-input b3", {
                disabled: true,
              })}
              value={(project && project.chain && project.chain.name) || ""}
              data-qa="prjtinfo-chain-name"
            />
          </li>
          <li className="project-info-list-item" data-qa="prjtinfo-store-ct-label">
            <label className="project-info-label h6">NUMBER OF STORES</label>
            <input
              disabled={true}
              type="text"
              name="store-ct"
              className={cx("project-info-input b3", {
                disabled: true,
              })}
              value={project.user_store_count || 0}
              data-qa="prjtinfo-store-ct"
            />
          </li>
        </ul>
        <ul className="project-info-list project-info-list-final-row">
          <li className="project-info-list-item" data-qa="prjtinfo-season">
            <label className="project-info-label h6">Season</label>
            <Dropdown
            className="project-info-dropdown"
            backgroundFill
            isDisabled={ltext === "Edit"}
            dataQa="prjtinfo-season-dropdown"
            options={this._seasons}
            value={season &&
              {
                label: season,
                value: season,
              }
            }
            containerStyles={{
              boxShadow: "noShadow"
            }}
            onChange={(e) => e && this.handleChange(e, "season")}
            placeholder={"Select a season"}
            isSearchable
          />
          </li>
          <li className="project-info-list-item" data-qa="prjtinfo-year">
            <label className="project-info-label h6">Year</label>
            <input
              disabled={ltext === "Edit"}
              type="text"
              name="store-ct"
              className={cx("project-info-input b3", {
                disabled: ltext === "Edit",
              })}
              value={year || ""}
              placeholder="yyyy"
              onChange={(e) => e && this.handleChange(e, "year")}
              data-qa="prjtinfo-year-input"
            />
          </li>
          <li className="project-info-list-item" data-qa="prjtinfo-chain-parent">
            <label className="project-info-label h6">Chain Parent</label>
            <input
              disabled={true}
              type="text"
              name="chain-parent"
              className={cx("project-info-input b3", {
                disabled: ltext === "Edit",
              })}
              value={chain_parent || "" }
              placeholder="----"
              data-qa="prjtinfo-chain-parent-input"
            />
          </li>

          <li className="project-info-list-item" data-qa="prjtinfo-publish-date-label">
            <label className="project-info-label h6">PUBLISH DATE</label>
            <DatePicker
              disabled={disabled}
              selected={publish_at ? moment.utc(publish_at) : undefined}
              onChange={(e) => e && this.handleChange(e, "publish-date")}
              showTimeSelect
              className={cx("project-info-input prjtinfo-publish-date b3", {
                disabled: disabled,
              })}
              placeholderText={"mm/dd/yyyy HH:mm"}
              name="publish-date"
              dateFormat="MM/DD/YYYY HH:mm"
            />
          </li>
          {
          //TODO Ideally, I would like to find a better solution to synchronizing columns with the other form fields
          }
          <li className="project-info-list-item" data-qa="prjtinfo-spacer"/>
        </ul>

      </div>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    project: state.project.content,
  }),
  { fetchProject, updateProject }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(ProjectInfo));
