import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ProjectValidation } from "interfaces/project-validation";
import { ThunkActionCreator } from "interfaces/thunk";

export const FETCH_PROJECT_VALIDATION_REQUEST = "FETCH_PROJECT_VALIDATION_REQUEST";
export const FETCH_PROJECT_VALIDATION = "FETCH_PROJECT_VALIDATION";
export const FETCH_PROJECT_VALIDATION_FAILED = "FETCH_PROJECT_VALIDATION_FAILED";

export const fetchProjectValidation: ThunkActionCreator<Promise<void>> =
  (id: number) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PROJECT_VALIDATION_REQUEST });
    return axios
      .get(`${Config.API_SERVICE_URL}/api/projects/${id}/validation`, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_PROJECT_VALIDATION, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: FETCH_PROJECT_VALIDATION_FAILED });
      });
  };

const actions = {
  [FETCH_PROJECT_VALIDATION_REQUEST]: (state: ProjectValidationState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_PROJECT_VALIDATION]: (state: ProjectValidationState, action: AnyAction) => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_PROJECT_VALIDATION_FAILED]: (state: ProjectValidationState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
export interface ProjectValidationState {
  content?: ProjectValidation;
  error?: boolean;
  loading?: boolean;
}

// Initial State
const initialState: ProjectValidationState = {
  loading: false,
  error: false,
  content: [],
};

export default handleActions<ProjectValidationState, AnyAction>(actions, initialState);
