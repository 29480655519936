import isEqual from "lodash/isEqual";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { BreadcrumbState, setBreadcrumb, unsetBreadcrumb } from "state/breadcrumb";

interface CreateBreadcrumbOwnProps {
  crumbs: BreadcrumbState;
}

type CreateBreadcrumbProps = CreateBreadcrumbOwnProps & PropsFromRedux;

class CreateBreadcrumb extends React.Component<CreateBreadcrumbProps> {
  public componentDidMount() {
    const { crumbs } = this.props.crumbs;

    this.props.setBreadcrumb({
      crumbs,
    });
  }

  public componentDidUpdate({ crumbs: prevCrumbs }: CreateBreadcrumbProps) {
    const { crumbs } = this.props.crumbs;

    if (!isEqual(prevCrumbs, crumbs)) {
      this.props.setBreadcrumb({
        crumbs,
      });
    }
  }

  public componentWillUnmount() {
    this.props.unsetBreadcrumb();
  }

  public render() {
    return null;
  }
}

const connecter = connect(null, { setBreadcrumb, unsetBreadcrumb });

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(CreateBreadcrumb);
