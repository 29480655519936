import { Card, CardList } from "components/Card";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import { RouteProps } from "containers/App";
import ProjectFilter, { FilterOptions } from "containers/ProjectFilter";
import Constants from "helpers/constants";
import { Announcement as AnnouncementProps, CreateAnnouncement } from "interfaces/announcement";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { createAnnouncement, deleteAnnouncement } from "state/announcement";
import { anyFieldsEmpty } from "utils/helpers";

type ManageSurveysProps = PropsFromRedux & RouteProps;

interface ManageSurveysState {
  tableData: AnnouncementProps[];
  announcement: CreateAnnouncement;
  meta: { project_name: string; project_type: string };
  popoverOpenId: number | null;
}

const defaultState = {
  tableData: [],
  announcement: {
    project_id: -1,
    title: "",
    body: "",
  },
  meta: {
    project_name: "",
    project_type: "",
  },
  popoverOpenId: null,
};

class NewAnnouncementForm extends React.Component<ManageSurveysProps, ManageSurveysState> {

  public state = defaultState;

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {
      announcement,
      meta: { project_name, project_type },
    } = this.state;

    this.props.createAnnouncement(announcement, {
      name: project_name,
      type: project_type,
    });
    this.setState({ announcement: defaultState.announcement });
  };

  public handleDelete = (id: number, { name, type }: { name: string; type: string }) => {
    return this.props.deleteAnnouncement(id, { name, type });
  };

  public renderForm() {
    const { announcement } = this.state;

    const submitDisabled = announcement.project_id === -1 || anyFieldsEmpty(announcement);

    return (
      <CardList>
        <Card className="create-announcement__form-container">
          <Form onSubmit={this.handleSubmit}>
            {/* htmlFor needed to be able to click on Dropdown within a label*/}
            <Form.Group htmlFor="select-project">
              <Form.Input
                type="text"
                placeholder="Announcement Title"
                data-qa="announcement-title-field"
                value={announcement.title}
                onChange={(event) =>
                  this.setState({
                    announcement: {
                      ...announcement,
                      title: event.currentTarget.value,
                    },
                  })
                }
              />
              {/* name needed for htmlFor */}
              <div data-qa="select-project">
                <ProjectFilter
                  name="select-project"
                  value={announcement.project_id}
                  isSearchable
                  minHeight={40}
                  noShadow={true}
                  onChange={({ id, name, type }: FilterOptions) => {
                    this.setState({
                      announcement: {
                        ...announcement,
                        project_id: id,
                      },
                      meta: {
                        project_name: name,
                        project_type: type,
                      },
                    });
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group span={2}>
              <Form.Textarea
                placeholder="Announcement Body"
                data-qa="announcement-body-field"
                value={announcement.body}
                onChange={(event) =>
                  this.setState({
                    announcement: {
                      ...announcement,
                      body: event.currentTarget.value,
                    },
                  })
                }
              />
            </Form.Group>
            <Form.ButtonGroup>
              <Form.ConfirmButton
                data-qa="submit-announcement"
                disabled={submitDisabled}
                style={{ marginLeft: "auto" }}
              >
                Submit Announcement
              </Form.ConfirmButton>
            </Form.ButtonGroup>
          </Form>
        </Card>
      </CardList>
    );
  }

  public render() {

    return (
      <>
        {this.renderForm()}
        <div hidden={!this.props.hasErrors}>
          <ErrorMessage errors={this.props.errors || []} />
        </div>
      </>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    user: state.auth_user.content,
    hasErrors: state.surveys.hasError,
    errors: state.surveys.errors,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
    currentUserId: state.auth_user.content && state.auth_user.content.id,
    isAdmin: state.auth_user.content && state.auth_user.content.role === Constants.AB_SYSTEM_ADMIN,
  }),
  {
    createAnnouncement,
    deleteAnnouncement,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(NewAnnouncementForm);
