import React, { useState, useMemo } from "react";
// import {debounce} from "lodash"
import starSvg from "assets/img/star-gray.svg";
import highlightedStarSvg from "assets/img/star-yellow.svg";
import Icon from "components/Icon";

export interface StarToggleProps extends React.HTMLAttributes<{}> {
  includePadding?: boolean,
  isActive?: boolean,
  clickHandler?: (...args: any) => any
}

export const StarToggle: React.FunctionComponent<StarToggleProps> = ({
  isActive,
  includePadding,
  clickHandler = () => {},
}) => {
  // const interval = 1500;
  // const [isHighlighted, setHighlight] = useState(isActive);
  // const debouncedAction = useMemo(
  //   () => debounce(clickHandler, interval),
  //   [],
  // );

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // debouncedAction(!isHighlighted);
    // setHighlight(!isHighlighted);
    // clickHandler(!isHighlighted);
    clickHandler(!isActive);
  }

  return (
    <div
    className="download-button"
    data-qa="download-support-file-button"
    style={includePadding ? {}: { padding : 0 }}
    onClick={handleClick}
  >
    <Icon src={isActive? highlightedStarSvg : starSvg} />
  </div>
  );
}
