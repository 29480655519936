import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ReactSelectObject } from "interfaces/select";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";

// Thunk Action Creator
const fetchProducts: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PRODUCTS_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/products`, getHeaders())
    .then((response) =>
      dispatch({
        type: FETCH_PRODUCTS,
        payload: response.data.map((product: any) => {
          return {
            label: product.ProductName,
            value: product.UPC,
            upc: product.UPC.toString(),
          };
        }),
      })
    )
    .catch(() => dispatch({ type: FETCH_PRODUCTS_FAILED }));
};

export { fetchProducts };

// Actions
const actions = {
  [FETCH_PRODUCTS_REQUEST]: (state: ProductState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_PRODUCTS]: (state: ProductState, action: AnyAction): ProductState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_PRODUCTS_FAILED]: (state: ProductState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Reducer Interface
export interface ProductState {
  content?: ReactSelectObject[];
  error?: boolean;
  loading?: boolean;
}

// Initial State
const initialState: ProductState = {
  loading: false,
  error: false,
  content: [],
};

// Reducer
export default handleActions<ProductState, AnyAction>(actions, initialState);
