import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { Location, UserLocation } from "interfaces/location";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
export const UPDATE_USER_LOCATION_REQUEST = "UPDATE_USER_LOCATION_REQUEST";
export const UPDATE_USER_LOCATION = "UPDATE_USER_LOCATION";
export const UPDATE_USER_LOCATION_FAILED = "UPDATE_USER_LOCATION_FAILED";

// Thunk Action Creator
const getUserZipCode: ThunkActionCreator<Promise<void>> =
  (currentCoords: UserLocation) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_USER_LOCATION_REQUEST });
    return axios
      .get(`${Config.API_SERVICE_URL}/api/locations/zip-codes`, {
        params: {
          latitude: currentCoords.latitude,
          longitude: currentCoords.longitude,
        },
      })
      .then((response) => {
        if (response.status === 204) {
          throw dispatch({ type: UPDATE_USER_LOCATION_FAILED });
        } else {
          dispatch({ type: UPDATE_USER_LOCATION, payload: response.data });
        }
      })
      .catch(() => {
        dispatch({
          type: UPDATE_USER_LOCATION_FAILED,
        });
      });
  };

const getLatLongFromZip: ThunkActionCreator<Promise<any>> =
  (zip: string) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_USER_LOCATION_REQUEST });
    return axios
      .get(`${Config.API_SERVICE_URL}/api/locations/lat-long-from-zip`, {
        params: {
          zip,
        },
      })
      .then((response) => {
        if (response.status === 204) {
          throw dispatch({ type: UPDATE_USER_LOCATION_FAILED });
        } else {
          return dispatch({
            type: UPDATE_USER_LOCATION,
            payload: response.data,
          });
        }
      })
      .catch(() =>
        dispatch({
          type: UPDATE_USER_LOCATION_FAILED,
        })
      );
  };

export { getUserZipCode, getLatLongFromZip };

// Actions
const actions = {
  [UPDATE_USER_LOCATION_REQUEST]: (state: LocationState): LocationState => ({
    ...state,
    loading: true,
    error: false,
  }),
  [UPDATE_USER_LOCATION]: (state: LocationState, action: AnyAction): LocationState => ({
    ...state,
    loading: false,
    error: false,
    content: action.payload,
  }),
  [UPDATE_USER_LOCATION_FAILED]: (state: LocationState, action: AnyAction): LocationState => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
export interface LocationState {
  error?: boolean;
  loading?: boolean;
  content?: Location;
}

// Initial State
const initialState: LocationState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<LocationState, AnyAction>(actions, initialState);
