import React from "react";

import dropShadowLogoSvg from "assets/img/drop-shadow-logo.svg";
import logoSvg from "assets/img/logo.svg";
import Link from "components/Link";

interface LogoProps extends React.ImgHTMLAttributes<{}> {
  dropShadow?: boolean;
}

const Logo: React.FunctionComponent<LogoProps> = ({ dropShadow = false, ...restProps }) => (
  <img src={dropShadow ? dropShadowLogoSvg : logoSvg} alt="Brewbox360 logo" {...restProps} />
);

export const LogoLink = () => (
  <Link to="/">
    <Logo />
  </Link>
);

export default Logo;
