import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { Wholesaler } from "interfaces/wholesaler";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
export const FETCH_WHOLESALERS_REQUEST = "FETCH_WHOLESALERS_REQUEST";
export const FETCH_WHOLESALERS = "FETCH_WHOLESALERS";
export const FETCH_WHOLESALERS_FAILED = "FETCH_WHOLESALERS_FAILED";

export const DELETE_WHOLESALER_REQUEST = "DELETE_WHOLESALER_REQUEST";
export const DELETE_WHOLESALER = "DELETE_WHOLESALER";
export const DELETE_WHOLESALER_FAILED = "DELETE_WHOLESALER_FAILED";

const fetchWholesalers: ThunkActionCreator<Promise<void>> =
  (mine = false) =>
  (dispatch: Dispatch) => {
    dispatch({ type: FETCH_WHOLESALERS_REQUEST });
    return axios
      .get(`${Config.API_SERVICE_URL}/api/wholesalers/me`, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_WHOLESALERS, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: FETCH_WHOLESALERS_FAILED });
      });
  };

const fetchMyWholesalers = fetchWholesalers.bind(null, true);

const deleteWholesalerById: ThunkActionCreator<Promise<void>> = (id) => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_WHOLESALER_REQUEST });
  return axios
    .delete(`${Config.API_SERVICE_URL}/api/wholesaler/${id}`, getHeaders())
    .then((_) => {
      dispatch({ type: DELETE_WHOLESALER });
    })
    .catch((error: Error) => {
      dispatch({ type: DELETE_WHOLESALER_FAILED });
    });
};

export { fetchWholesalers, fetchMyWholesalers, deleteWholesalerById };

// Actions
const actions = {
  [FETCH_WHOLESALERS_REQUEST]: (state: WholesalersState): WholesalersState => ({
    ...state,
    loading: true,
  }),
  [FETCH_WHOLESALERS]: (state: WholesalersState, action: AnyAction): WholesalersState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_WHOLESALERS_FAILED]: (state: WholesalersState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [DELETE_WHOLESALER_REQUEST]: (state: WholesalersState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_WHOLESALER]: (state: WholesalersState) => ({
    ...state,
    loading: false,
    content: [],
  }),
  [DELETE_WHOLESALER_FAILED]: (state: WholesalersState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

// Reducer Interface
export interface WholesalersState {
  error?: boolean;
  loading?: boolean;
  content?: Wholesaler[];
}

// Initial State
const initialState: WholesalersState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<WholesalersState, AnyAction>(actions, initialState);
