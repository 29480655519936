import { handleActions } from "redux-actions";

import FontFaceObserver from "fontfaceobserver";
// @ts-ignore
import Cookies from "universal-cookie";

import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

const cookies = new Cookies();

// Actions Types
export const LOAD_FONTS_REQUEST = "LOAD_FONTS_REQUEST";
export const LOAD_FONTS = "LOAD_FONTS";
export const LOAD_FONTS_FAILED = "LOAD_FONTS_FAILED";

function asyncLoadFonts() {
  return Promise.all([new FontFaceObserver("Roboto").load()]);
}

// Thunk Action Creator
const getFonts: ThunkActionCreator = () => (dispatch: Dispatch) => {
  if (cookies.get("fonts-loaded") === "true") {
    return Promise.resolve(dispatch({ type: LOAD_FONTS }));
  }

  dispatch({ type: LOAD_FONTS_REQUEST });
  return asyncLoadFonts()
    .then(() => {
      cookies.set("fonts-loaded", "true", {
        path: "/",
        secure: true,
        httpOnly: true,
      });
      dispatch({ type: LOAD_FONTS });
    })
    .catch(() => {
      dispatch({ type: LOAD_FONTS_FAILED });
    });
};

export { getFonts };

// Actions
const actions = {
  [LOAD_FONTS_REQUEST]: (state: FontsState) => ({
    ...state,
    loading: true,
  }),
  [LOAD_FONTS]: (state: FontsState): FontsState => ({
    ...state,
    loading: false,
    loaded: true,
  }),
  [LOAD_FONTS_FAILED]: (state: FontsState = initialState): FontsState => ({
    ...state,
    loading: false,
    error: true,
  }),
};

// Reducer Interface
export interface FontsState {
  loading: boolean;
  error: boolean;
  loaded: boolean;
}

// Initial State
const initialState: FontsState = {
  loading: false,
  error: false,
  loaded: false,
};

// Reducer
export default handleActions<FontsState, AnyAction>(actions, initialState);
