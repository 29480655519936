interface CompleteDateItem {
  est_complete_date: string;
}

export const undefinedToBottom = (a?: any, b?: any) => {
  if (a === undefined && b === undefined) {
    return 0;
  }

  // Undefined floats down to the bottom
  if (a === undefined) {
    return 1;
  }

  if (b === undefined) {
    return -1;
  }

  return 0;
};

export const byEstCompleteDate = (itemA: CompleteDateItem, itemB: CompleteDateItem) => {
  return new Date(itemA.est_complete_date).getTime() - new Date(itemB.est_complete_date).getTime();
};

interface StoresItem {
  user_store_count?: number;
}

export const byNumberOfStores = (itemA: StoresItem, itemB: StoresItem) => {
  const numberOfStoresA = itemA.user_store_count ? itemA.user_store_count : 0;
  const numberOfStoresB = itemB.user_store_count ? itemB.user_store_count : 0;

  return numberOfStoresA - numberOfStoresB;
};

const byAlpha = (stringA?: string, stringB?: string) => {
  const a = (stringA || "").toLocaleUpperCase();
  const b = (stringB || "").toLocaleUpperCase();

  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

interface ProjectNameItem {
  project_name?: string;
}

export const byProjectName = (itemA: ProjectNameItem, itemB: ProjectNameItem) =>
  byAlpha(itemA.project_name, itemB.project_name);

interface ChainNameItem {
  chain?: { name?: string };
}

export const byChainName = (itemA: ChainNameItem, itemB: ChainNameItem) =>
  byAlpha(itemA.chain && itemA.chain.name, itemB.chain && itemB.chain.name);
