import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Chain } from "interfaces/chain";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Actions Types
const UPDATE_CHAIN_REQUEST = "UPDATE_CHAIN_REQUEST";
const UPDATE_CHAIN = "UPDATE_CHAIN";
const UPDATE_CHAIN_FAILED = "UPDATE_CHAIN_FAILED";
const DELETE_CHAIN_REQUEST = "DELETE_CHAIN_REQUEST";
const DELETE_CHAIN = "DELETE_CHAIN";
const DELETE_CHAIN_FAILED = "DELETE_CHAIN_FAILED";

export { UPDATE_CHAIN, UPDATE_CHAIN_FAILED, UPDATE_CHAIN_REQUEST, DELETE_CHAIN_REQUEST, DELETE_CHAIN, DELETE_CHAIN_FAILED };

// Thunk Action Creator
const updateChain: ThunkActionCreator<Promise<void>> =
  (id: number, data: FormData) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_CHAIN_REQUEST });

    return axios
      .patch(`${Config.API_SERVICE_URL}/api/chains/${id}`, data, getHeaders())
      .then((response) => {
        dispatch({ type: UPDATE_CHAIN, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: UPDATE_CHAIN_FAILED });
      });
  };

  const deleteChain: ThunkActionCreator<Promise<void>> = (id: string) => (dispatch: Dispatch) => {
    dispatch({ type: DELETE_CHAIN_REQUEST });
    return axios
      .delete(`${Config.API_SERVICE_URL}/api/chains/${id}`, getHeaders())
      .then((response) => {
        dispatch({ type: DELETE_CHAIN, payload: response.data  });
      })
      .catch((error: Error) => {
        dispatch({ type: DELETE_CHAIN_FAILED });
      });
  };

export { updateChain, deleteChain };

// Actions
const actions = {
  [UPDATE_CHAIN_REQUEST]: (state: ChainState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_CHAIN]: (state: ChainState, action: AnyAction): ChainState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [UPDATE_CHAIN_FAILED]: (state: ChainState): ChainState => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
  [DELETE_CHAIN_REQUEST]: (state: ChainState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_CHAIN]: (state: ChainState, action: AnyAction): ChainState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [DELETE_CHAIN_FAILED]: (state: ChainState): ChainState => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

// Reducer Interface
export interface ChainState {
  error?: boolean;
  loading?: boolean;
  content?: Chain;
}

// Initial State
const initialState: ChainState = {
  loading: false,
  error: false,
  content: undefined,
};

// reducer
export default handleActions<ChainState, AnyAction>(actions, initialState);
