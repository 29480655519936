import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

// Action Types
export const FETCH_STARRED_STORES_REQUEST = "FETCH_STARRED_STORES_REQUEST";
export const FETCH_STARRED_STORES_SUCCESS = "FETCH_STARRED_STORES_SUCCESS";
export const FETCH_STARRED_STORES_FAILURE = "FETCH_STARRED_STORES_FAILURE";
export const STORE_STAR_TOGGLE_REQUEST = "STORE_STAR_TOGGLE_REQUEST";
export const STORE_STAR_TOGGLE_SUCCESS = "STORE_STAR_TOGGLE_SUCCESS";
export const STORE_STAR_TOGGLE_FAILURE = "STORE_STAR_TOGGLE_FAILURE";

// Thunk Action Creators
export const fetchStarredStores: ThunkActionCreator<Promise<void>> = (userId: string, page?: string, pageCount?: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_STARRED_STORES_REQUEST });
    let url = `${Config.API_SERVICE_URL}/api/starred-stores?userId=${userId}`;
    
    if (page) url += `&page=${page}`;
    if (pageCount) url += `&pageCount=${pageCount}`;

    return axios
        .get(url, getHeaders())
        .then(response => {
            dispatch({ type: FETCH_STARRED_STORES_SUCCESS, payload: response.data });
        })
        .catch( error => {
            dispatch({ type: FETCH_STARRED_STORES_FAILURE, payload: error });
        });
};
export const toggleStarredStore: ThunkActionCreator<Promise<void>> = (store: any) => (dispatch: Dispatch) => {
    dispatch({ type: STORE_STAR_TOGGLE_REQUEST, payload: store });
    
    return axios
        .post(
            `${Config.API_SERVICE_URL}/api/starred-stores`,
            store,
            getHeaders()
        )
        .then(response => {
            dispatch({ type: STORE_STAR_TOGGLE_SUCCESS, payload: response.data });
        })
        .catch( error => {
            dispatch({ type: STORE_STAR_TOGGLE_FAILURE, payload: error });
        });
};

// Actions
const actions = {
    [FETCH_STARRED_STORES_REQUEST]: (state: StarredStoresState) => ({
        ...state,
        loading: true,
    }),
    [FETCH_STARRED_STORES_SUCCESS]: (state: StarredStoresState, action: AnyAction) => {
        return {
            ...state,
            content: action.payload,
            loading: false,
            error: false,
        };
    },
    [FETCH_STARRED_STORES_FAILURE]: (state: StarredStoresState) => ({
        ...state,
        loading: false,
        error: true,
    }),
    [STORE_STAR_TOGGLE_REQUEST]: (state: StarredStoresState, action: AnyAction) => {
        const store = action.payload;
        const starredStoreExists = state.content.findIndex(s => (s.store_id == store.store_id && s.project_id == store.project_id)) > -1;
        let content = starredStoreExists
            ? state.content.map(s => (s.store_id == store.store_id && s.project_id == store.project_id) ? {...s, ...store} : s)
            : [ ...state.content, store ]
        content = content.filter(s => s.starred);
        
        return {
            ...state,
            content,
            loading: true,
        }
    },
    [STORE_STAR_TOGGLE_SUCCESS]: (state: StarredStoresState, action: AnyAction) => {
        return {
            ...state,
            loading: false,
            error: false,
        }
    },
    [STORE_STAR_TOGGLE_FAILURE]: (state: StarredStoresState) => ({
        ...state,
        loading: false,
        error: true,
    }),
};

// Reducer Interface
// TODO: Add type to content
export interface StarredStoresState {
    content: any[];
    error?: boolean;
    loading?: boolean;
}

// Initial State
const initialState: StarredStoresState = {
    loading: false,
    error: false,
    content: [],
};

// Reducer
export default handleActions<StarredStoresState, AnyAction>(actions, initialState);