import cx from "classnames";
import History from "history";
import debounce from "lodash/debounce";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { State } from "state";
import { createAnalyticsEvent, PageName, EventTypes } from "state/analytics";
import { isAuthorized } from "state/auth";
import { getBreakpoint } from "state/breakpoint";
import { getFonts } from "state/fonts";

interface PageContainerOwnProps extends RouteComponentProps {}

type PageContainerProps = PageContainerOwnProps & PropsFromRedux;
export class PageContainer extends React.Component<PageContainerProps> {
  private debouncedResize: () => any;

  public constructor(props: PageContainerProps) {
    super(props);
    this.debouncedResize = debounce(this.props.getBreakpoint, 250);
  }

  public componentDidMount() {
    const { location } = this.props;

    this.props.getFonts();
    this.props.getBreakpoint();

    // Checking /api/login to see if auth token is valid, else redirect to /signin page.
    // Do not run this on /signin, or it will cause an infinite loop
    if (
      location.pathname !== "/signin" &&
      // window.location.pathname check is required for testcafe
      window.location.pathname !== "/signin"
    ) {
      this.props.isAuthorized();
    }

    this.debouncedResize = debounce(this.props.getBreakpoint, 250);
    window.addEventListener("resize", this.debouncedResize);

    // register page that user landed on
    this.onRouteChanged();
  }

  componentDidUpdate(prevProps: PageContainerProps) {
    if (this.props.location !== prevProps.location) {
      // register location changes
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const {
      pathname,
      state: { pageName },
    } = this.props.location as History.Location<{ pageName: PageName }>;

    switch (pageName) {
      // TODO send 'Store Name' and 'Project Name' instead of 'N/A'
      case "Project Detail":
      case "Store Detail":
      case "Error":
        // Store Detail, Project detail, and Error are special cases and are handled in Store.tsx, Project.tsx, and ErrorBoundary.tsx respectively
        break;
      default:
        this.props.createAnalyticsEvent({
          event: EventTypes.PAGE_VIEW,
          eventType: "Pageview",
          pageName: pageName,
          pageUrl: pathname,
        });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedResize);
  }

  public render() {
    const { children, fonts } = this.props;

    const classes = cx({
      "fonts-loaded": fonts.loaded,
    });

    return <div className={classes}>{children}</div>;
  }
}

const connecter = connect(
  (state: State) => ({
    fonts: state.fonts,
  }),
  {
    createAnalyticsEvent,
    getFonts,
    getBreakpoint,
    isAuthorized,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(PageContainer));
