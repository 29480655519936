import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

export const FETCH_RESET_STATUS_DASHBOARD_REQUEST = "FETCH_RESET_STATUS_DASHBOARD_REQUEST";
export const FETCH_RESET_STATUS_DASHBOARD = "FETCH_RESET_STATUS_DASHBOARD";
export const FETCH_RESET_STATUS_DASHBOARD_FAILED = "FETCH_RESET_STATUS_DASHBOARD_FAILED";

export const fetchResetStatusDashboard: ThunkActionCreator =
  (projectId: number | string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_RESET_STATUS_DASHBOARD_REQUEST });

    return axios
      .get(`${Config.API_SERVICE_URL}/api/dashboards/reset-status/${projectId}`, getHeaders())
      .then((response) => {
        dispatch({
          type: FETCH_RESET_STATUS_DASHBOARD,
          payload: response.data,
        });
      })
      .catch((error: Error) => dispatch({ type: FETCH_RESET_STATUS_DASHBOARD_FAILED }));
  };

export interface ResetStatusDashboardData {
  data?: number[];
  labels?: string[];
  total?: number;
  colors?: string[];
}

export interface ResetStatusDashboardState {
  error: boolean;
  loading: boolean;
  content: ResetStatusDashboardData;
}

const initialState = {
  error: false,
  loading: false,
  content: {},
};

// Actions
const actions = {
  [FETCH_RESET_STATUS_DASHBOARD_REQUEST]: (state: ResetStatusDashboardState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_RESET_STATUS_DASHBOARD]: (state: ResetStatusDashboardState, action: AnyAction) => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_RESET_STATUS_DASHBOARD_FAILED]: (state: ResetStatusDashboardState) => ({
    ...state,
    loading: false,
    error: true,
    content: {},
  }),
};

export default handleActions<ResetStatusDashboardState, AnyAction>(actions, initialState);
