import axios, {AxiosError} from "axios";
import {applyMiddleware, compose, createStore, Store} from "redux";
import thunk from "redux-thunk";

import {getHeaders} from "helpers/headers";
import {getItem, getItemJson} from "helpers/storage";
import {AnyAction} from "interfaces/action";
import rootReducer, {State} from "state";
import {AuthState, hydrateAuth} from "state/auth";
import {AuthUser, AuthUserState} from "state/auth_user";
import {analyticsMiddleware} from "state/middleware/analytics";
import {Permission, PermissionsState} from "state/permissions";
import {SeverityLevel, ToastService} from "../services/toastService";


const defaultState: {
  auth: AuthState;
  auth_user: AuthUserState;
  permissions: PermissionsState;
} = {
  permissions: {
    content: getItemJson("permissions") as Permission,
    loading: false,
    error: false,
  },
  auth_user: {
    content: getItemJson("user") as AuthUser,
    loading: false,
    error: false,
  },
  auth: {
    content: getItem("msal.idtoken"),
    loading: false,
    hasError: false,
    error: undefined,
  },
};

const configureStore = (): Store<State> => {
  // TODO: remove dev tools in prod
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore<Partial<State>, AnyAction, any, any>(
    rootReducer,
    defaultState,
    composeEnhancers(applyMiddleware(thunk, analyticsMiddleware))
  );

  const { dispatch } = store;

  axios.interceptors.request.use(
    async (config: any) => {
      await dispatch(hydrateAuth());
      const { headers } = getHeaders();
      config.headers = { ...config.headers, ...headers };
      return config;
    },
    (error: any) => Promise.reject(error)
  );

  const toast = ToastService.getInstance();
  axios.interceptors.response.use((r) => r, (error: AxiosError<any>) => {
    if (error.response && error.response.status >= 500) {
      toast.publishRequestErrorToast(error, SeverityLevel.ERROR)
    } else {
      if (!isAuthFlowError(error)) {
        if (error.response && error.response.status === 401) {
          if (store.getState().auth.content) {
            toast.publishToast({
              identifier: "expiredUserSession",
              title: "Accessed Denied",
              message: "The user has been logged out, please refresh the page"
            }, SeverityLevel.ERROR, {closeButton: false, autoClose: false})
          }
        } else {
          toast.publishRequestErrorToast(error, SeverityLevel.WARN)
        }
      }
    }
    throw error
  })


  return store;
};

const isAuthFlowError = (error: AxiosError<any>): boolean => {
 return error.response?.request.responseURL.includes("/api/users/me")
}

export default configureStore;
