import React from "react";

import Error404Svg from "assets/img/404.svg";
import ErrorPage from "components/ErrorPage";

const NoMatch: React.FunctionComponent<{}> = () => (
  <ErrorPage
    image={<img src={Error404Svg} alt="" />}
    message={
      "Oops! Something went wrong. This page no longer exists or the URL was entered incorrectly."
    }
    data-qa="404-error"
  />
);

export default NoMatch;
