import React from "react";

// @ts-ignore
import { CSVLink } from "react-csv";

interface CSVDownloadProps extends React.HTMLAttributes<{}> {
  data: any;
  filename: string;
  headers: Array<{ label: string; key: string }>;
  onClick?: () => void;
}

const CSVDownload: React.FunctionComponent<CSVDownloadProps> = (props) => {
  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <CSVLink
      uFEFF={false}
      target="_self" // needed for download in Safari
      className={props.className}
      data={props.data}
      filename={props.filename}
      headers={props.headers}
      onClick={handleOnClick}
    >
      {props.children}
    </CSVLink>
  );
};

export default CSVDownload;
