import cx from "classnames";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Attachments from "components/Attachments";
import Base64Logo from "components/Base64Logo";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import Collapsable from "components/Collapsable";
import CommentsUI from "containers/CommentsUI";
import { getPillProps, Pill, PillType } from "components/Pill";
import ResetNotesUI from "components/ResetNotesUI";
import { Body } from "components/Typography";
import {StarToggle} from "components/StarToggle";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import { PermissionsContext } from "context";
import Constants from "helpers/constants";
import { File } from "interfaces/file";
import { ProjectType } from "interfaces/project";
import { Store2Project } from "interfaces/store2project";
import { State } from "state";
import { createAnalyticsEvent, EventTypes, FileStatus } from "state/analytics";
import { fetchFilesByProjectStoreId } from "state/files";
import { createResetNote, fetchResetnotes } from "state/resetnotes";
import { fetchStore, updateStore, toggleStoreStarring } from "state/store";
import { fetchStore2Projects, updateStore2Project } from "state/store2project";
import { fetchMySurveys } from "state/surveys";
import { updateValidation } from "state/validate_store";
import { toggleStarredStore } from "state/starredStores";
import { trackPageView, PAGEVIEW_EVENTS } from "state/userEvents";
import { isIe } from "utils/helpers";

type StoreProps = RouteComponentProps<{ storeId: string; projectId: string }> & PropsFromRedux;

class Store extends React.Component<StoreProps> {
  private displaySurveys: boolean = false;

  public componentDidMount() {
    const {
      match: {
        params: { storeId, projectId },
      },
    } = this.props;

    return this.props.fetchStore({ projectId, storeId }).then(() => {
      const {
        store,
        user,
        location: { pathname },
      } = this.props;


      if (store && store.chain_name && store.project_type) {
        this.props.createAnalyticsEvent({
          event: EventTypes.PAGE_VIEW,
          eventType: "Pageview",
          pageName: "Store Detail",
          pageUrl: pathname,
          storeName: `${store.store_name}`,
          projectName: `${store.chain_name} - ${store.project_name}`,
          projectType: store.project_type,
        });
      }

      if (store) {
        this.props.trackPageView({
          event_type: PAGEVIEW_EVENTS.STORE_DETAIL_PAGEVIEW,
          user_id: user?.id,
          store_id: store.store_id,
          project_id: store.project_id,
          timestamp: Date.now(),
        });
      }

      this.props.fetchMySurveys(projectId).then(() => {
        if (this.props.surveys && this.props.surveys.length > 0) {
          this.displaySurveys = true;
        }
      });
      this.props.fetchFilesByProjectStoreId({ storeId, projectId }).then(() => {
        if (isIe && document.getElementsByClassName("full-width").length > 0) {
          // tslint:disable no-console
          const cardItem = document.getElementsByClassName("project-store-details-body")[0] as any;

          cardItem.style.width = "90%";
          setTimeout(() => (cardItem.style.width = "100%"), 1);
        }
      });

      if (this.resetNoteStateActive()) {
        this.props.fetchResetnotes({ projectId, storeId });
        this.props.fetchStore2Projects(projectId, storeId);
      }
    });
  }
  onFocus(arg0: string, onFocus: any) {
    throw new Error("Method not implemented.");
  }

  public handleValidateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const source = e.currentTarget;
    const {
      match: {
        params: { storeId, projectId },
      },
      store,
      comments,
    } = this.props;

    if (store && comments) {
      if (source.name === "validation-complete") {
        this.props.createAnalyticsEvent({
          event: EventTypes.STORE_DETAIL_VALIDATION_COMPLETE,
          eventType: "Action",
          projectName: `${store.chain_name} - ${store.project_name}`,
          projectType: store.project_type as ProjectType,
          storeName: store.store_name,
          storeDetailCommentCount: comments.length,
        });
      } else if (source.name === "modify-feedback") {
        this.props.createAnalyticsEvent({
          event: EventTypes.STORE_DETAIL_MODIFY_FEEDBACK,
          eventType: "Action",
          projectName: `${store.chain_name} - ${store.project_name}`,
          projectType: store.project_type as ProjectType,
          storeName: store.store_name,
          storeDetailCommentCount: comments.length,
        });
      } else {
        // should not get here
        // TODO throw error ?
      }
    }

    this.props
      .updateValidation(storeId, projectId)
      .then(() => this.props.fetchStore({ projectId, storeId }));
  };

  public renderModifyButton() {
    return (
      <Button
        name="modify-feedback"
        className="validation-button"
        type="submit"
        onClick={this.handleValidateClick}
        data-qa="modify-feedback-button"
      >
        Modify Feedback
      </Button>
    );
  }

  public handleCreateResetNote = (
    e: React.FormEvent,
    {
      resetNote,
      implementation,
    }: {
      resetNote: string;
      implementation: string;
    }
  ) => {
    e.preventDefault();

    const {
      match: {
        params: { storeId, projectId },
      },
      store,
    } = this.props;

    if (store) {
      const { chain_name, project_name, project_type, store_name } = store;
      this.props
        .createResetNote(
          {
            storeId,
            projectId,
            resetnote: resetNote,
            implementation,
          },
          {
            name: `${chain_name} - ${project_name}`,
            type: project_type,
            storeName: store_name,
          }
        )
        .then(() => {
          this.props.fetchResetnotes({ storeId, projectId });
        });
    }

    return true;
  };

  public goToSurveys = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const { store } = this.props;

    if (store) {
      this.props.createAnalyticsEvent({
        event: EventTypes.TAKE_SURVEY,
        eventType: "Action",
        pageName: "Store Detail",
        projectName: `${store.chain_name} - ${store.project_name}`,
        projectType: store.project_type as ProjectType,
      });
    }
    const { history } = this.props;
    const { user } = this.props;
    let route = "/surveys/me";

    if (
      user &&
      [
        Constants.AB_SYSTEM_ADMIN,
        Constants.AB_PROJECT_ADMIN,
        Constants.AB_REGION_CAT_DIRECTOR,
        Constants.COMPETITOR_CSM,
      ].some((ar) => ar === user.role)
    ) {
      route = "/surveys";
    }

    history.push(route);
  };

  public handleDateChange = (date: moment.Moment, type: "target" | "actual") => {
    const store2Project = this.props.store2Projects ? this.props.store2Projects[0] : undefined;
    const { store } = this.props;
    if (store && store2Project) {
      const { project_type, project_name, store_name, chain_name } = store;

      if (type === "target") {
        this.props.updateStore2Project(store2Project.id, {
          targetResetDate: moment.utc(date).format(Constants.DATE_FORMAT__YYYY_MM_DD__HH_mm_ss),
        });

        this.props.createAnalyticsEvent({
          event: EventTypes.SET_TARGET_RESET_DATE,
          eventType: "Action",
          projectName: `${chain_name} - ${project_name}`,
          projectType: project_type as ProjectType,
          storeName: store_name,
          pageName: "Store Detail",
        });
      } else if (type === "actual") {
        this.props.updateStore2Project(store2Project.id, {
          actualResetDate: moment.utc(date).format(Constants.DATE_FORMAT__YYYY_MM_DD__HH_mm_ss),
        });

        this.props.createAnalyticsEvent({
          event: EventTypes.SET_ACTUAL_RESET_DATE,
          eventType: "Action",
          projectName: `${chain_name} - ${project_name}`,
          projectType: project_type as ProjectType,
          storeName: store_name,
          pageName: "Store Detail",
        });
      }
    }
  };

  public resetNoteStateActive() {
    const { store } = this.props;

    return (
      store &&
      (!store.store_status ||
        (store.store_status &&
          [
            Constants.RESET_NOT_SCHEDULED,
            Constants.RESET_SCHEDULED,
            Constants.RESET_COMPLETE,
          ].indexOf(store.store_status.toUpperCase()) >= 0))
    );
  }

  public getResetStatus(store2project: Store2Project): PillType {
    if (store2project.actual_reset_date) {
      return Constants.RESET_COMPLETE;
    }

    if (store2project.target_reset_date) {
      return Constants.RESET_SCHEDULED;
    }

    return Constants.RESET_NOT_SCHEDULED;
  }

  public handleAttachmentAnalytics = (action: "View" | "Download", { name, status }: File) => {
    const { store } = this.props;
    if (store) {
      this.props.createAnalyticsEvent({
        event: EventTypes.STORE_DETAIL_ATTACHMENTS,
        eventType: "Action",
        projectName: `${store.chain_name} - ${store.project_name}`,
        projectType: store.project_type as ProjectType,
        storeName: store.store_name,
        storeDetailsAttachmentAction: action,
        attachmentFileName: name,
        attachmentFileStatus: status as FileStatus,
      });
    }
  };

  public render() {
    const {
      store,
      storeError,
      starredStores,
      files,
      match: {
        params: { projectId, storeId },
      },
      isMobile,
      resetNotes = [],
      user,
    } = this.props;

    if (!store && storeError) {
      throw new Error(Constants.ERROR_404);
    }

    if (!store || !files) {
      return null;
    }

    const {
      project_name,
      project_type,
      chain_name,
      chain_logo,
      chain_logo_filename,
      validation_deadline,
      store_status,
    } = store as any;

    const store2project = this.props.store2Projects ? this.props.store2Projects[0] : undefined;

    const isStarred = starredStores.findIndex(s => s.store_id == store.store_id && s.project_id == store.project_id) > -1

    const isFileSharing = project_type === Constants.FILE_SHARING;

    const isAfterValidationDeadline = moment(validation_deadline).isSameOrBefore(moment());

    const pillType =
      isFileSharing && store2project ? this.getResetStatus(store2project) : store_status;

    const pill = getPillProps(pillType, project_type);

    return (
      <PermissionsContext.Consumer>
        {({ certify_pog, view_pog, comment_pog, comment_reset }: any) => (
          <section className="project-store-details-container">
            <CreateBreadcrumb
              crumbs={{
                crumbs: [
                  {
                    to: "/projects/me",
                    crumb: "My Work",
                    dataQa: "breadcrumb-mywork",
                  },
                  {
                    to: `/projects/${projectId}`,
                    crumb: project_name || "N/A",
                    dataQa: "breadcrumb-project-detail",
                  },
                  {
                    to: "#",
                    crumb: `Store ${store.retail_store_num}`,
                    dataQa: "breadcrumb-store-detail",
                  },
                ],
              }}
            />
            <div className="project-store-details-header">
              <h1 className="header-text">My Work</h1>
              <Pill data-qa="project-store-details-header-pill" {...pill} />
            </div>
            <CardList
              className={cx("project-store-details-body", {
                "full-width":
                  !comment_pog ||
                  (!isFileSharing && comment_pog) ||
                  (this.resetNoteStateActive() && !comment_reset),
              })}
            >
              <div>
                <Card>
                  <Collapsable
                    header={
                      <Card.Header>
                        <h3 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          Store Details
                          <StarToggle
                            clickHandler={async (starred: any) => {
                              await this.props.toggleStarredStore({
                                user_id: user?.id,
                                store_id: store.store_id,
                                project_id: store.project_id,
                                store_name: store.store_name,
                                chain_name: store.chain_name,
                                project_name: store.project_name,
                                project_type: store.project_type,
                                starred,
                              });
                            }}
                            isActive={isStarred}
                          />
                        </h3>
                      </Card.Header>
                    }
                  >
                    <Card.Body className="store-details-container">
                      <label>
                        <h6>Chain</h6>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Base64Logo data={chain_logo} logo_filename={chain_logo_filename}/>
                          <Body type="1" style={{ marginLeft: 11 }}>
                            {chain_name}
                          </Body>
                        </div>
                      </label>
                      <label>
                        <h6>Store No</h6>
                        <Body type="1" data-qa="store-number">
                          {store.retail_store_num}
                        </Body>
                      </label>
                      <label>
                        <h6>Store Name</h6>
                        <Body type="1" data-qa="store-name">
                          {store.store_name}
                        </Body>
                      </label>
                      <label>
                        <h6>Address</h6>
                        <Body type="1" data-qa="address">
                          {store.store_address}
                        </Body>
                      </label>
                      <label>
                        <h6>Validation Deadline</h6>
                        <Body type="1" data-qa="validation-deadline">
                          {moment(store.validation_deadline).format("MMM DD, YYYY")}
                        </Body>
                      </label>
                      <label>
                        <h6>AB-INBEV WHOLESALER</h6>
                        <Body type="1" data-qa="ab-inbev-wholesaler">
                          {store.abinbev_wholesaler}
                        </Body>
                      </label>
                      <label>
                        <h6>COORS WHOLESALER</h6>
                        <Body type="1" data-qa="coors-wholesaler">
                          {store.coors_wholesaler}
                        </Body>
                      </label>
                      <label>
                        <h6>MILLER WHOLESALER</h6>
                        <Body type="1" data-qa="miller-wholesaler">
                          {store.miller_wholesaler}
                        </Body>
                      </label>
                      <label>
                        <h6>3RD PARTY WHOLESALER</h6>
                        <Body type="1" data-qa="third-party-wholesaler">
                          {store.third_party_wholesaler}
                        </Body>
                      </label>
                      <label>
                        <h6>AD ZONE</h6>
                        <Body type="1" data-qa="adzone">
                          {store.ad_zone}
                        </Body>
                      </label>
                      <label>
                        <h6>CLUSTER/STORE GROUP</h6>
                        <Body type="1" data-qa="group">
                          {store.cluster_store_group}
                        </Body>
                      </label>
                      <label>
                        <h6>STORE NOTES</h6>
                        <Body type="1" />
                      </label>
                    </Card.Body>
                  </Collapsable>
                </Card>
                {view_pog && (
                  <Attachments
                    attachments={files}
                    onDownloadOrView={this.handleAttachmentAnalytics}
                  />
                )}
              </div>
              {((comment_reset && this.resetNoteStateActive()) || comment_pog || certify_pog) && (
                <Card>
                  {comment_reset && this.resetNoteStateActive() ? (
                    <ResetNotesUI
                      onSubmit={this.handleCreateResetNote}
                      onTakeSurveys={this.goToSurveys}
                      resetnotes={resetNotes}
                      displaySurveys={this.displaySurveys}
                      store2project={store2project || []}
                      onDateChange={this.handleDateChange}
                      isMobile={isMobile}
                    />
                  ) : (
                    !isFileSharing &&
                    comment_pog && <CommentsUI storeId={storeId} projectId={projectId} />
                  )}
                </Card>
              )}
            </CardList>
            {!isFileSharing && certify_pog && (
              <div className="project-store-details-validation-container">
                <div className="project-store-details-validation-sticky">
                  <Button
                    name="validation-complete"
                    className="validation-button"
                    disabled={
                      isAfterValidationDeadline || store.store_status !== Constants.NEEDS_VALIDATION
                    }
                    type="submit"
                    onClick={this.handleValidateClick}
                    data-qa="complete-validation-button"
                  >
                    VALIDATION COMPLETE
                  </Button>
                  {!isAfterValidationDeadline &&
                    store.store_status !== Constants.NEEDS_VALIDATION &&
                    this.renderModifyButton()}
                </div>
              </div>
            )}
          </section>
        )}
      </PermissionsContext.Consumer>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    auth_user: state.auth_user.content,
    comments: state.comments.content,
    files: state.files.content?.data,
    store: state.store.content,
    storeError: state.store.error,
    starredStores: state.starredStores.content,
    products: state.products.content,
    resetNotes: state.resetnotes.content,
    surveys: state.surveys.content,
    store2Projects: state.store2Projects.content,
    user: state.auth_user.content,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  {
    createAnalyticsEvent,
    fetchStore,
    updateStore,
    toggleStoreStarring,
    updateValidation,
    fetchFilesByProjectStoreId,
    fetchResetnotes,
    createResetNote,
    fetchMySurveys,
    fetchStore2Projects,
    updateStore2Project,
    toggleStarredStore,
    trackPageView,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(Store));
