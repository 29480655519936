import moment from "moment";
import React from "react";

import { Body } from "components/Typography";
import { Announcement as AnnouncementProps } from "interfaces/announcement";

const Announcement: React.FunctionComponent<AnnouncementProps> = ({
  title,
  body,
  dateCreated,
  project,
  chain,
  user,
}) => (
  <section className="announcement__container">
    <h3 className="announcement__header" data-qa="announcement-title">
      {title}
    </h3>
    <h6 data-qa="announcement-project">{`${chain.name} - ${project.name}`}</h6>
    <div className="announcement__subheader-container">
      <Body type="4" className="announcement__date" data-qa="announcement-created-date">
        {moment(dateCreated).format("M/DD/YYYY")}
      </Body>
      <Body
        type="4"
        className="announcement__createdby"
        data-qa="announcement-created-by"
      >{`By ${user.firstName} ${user.lastName}`}</Body>
    </div>
    <Body type="3" className="announcement__body" data-qa="announcement-body">
      {body}
    </Body>
  </section>
);

export default Announcement;
