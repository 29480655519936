import GoogleMapReact, { Coords } from "google-map-react";
import React from "react";
import * as ReactDOM from "react-dom";
import { RouteComponentProps, withRouter } from "react-router";

// import Config from "config";
import { Store } from "interfaces/store";
import { ConnectedProps, connect } from "react-redux";
import { State } from "state";

interface NearbyStoresGoogleMapProps extends RouteComponentProps {
  currentZip: string;
  stores: Store[];
  position: Coords;
}

interface LocationLatLng {
  lat: () => number;
  lng: () => number;
}

interface NearbyStoresGoogleMapState {
  googleApiKey: string;
  mapKey: any;
}

class NearbyStoresGoogleMap extends React.Component<
  NearbyStoresGoogleMapProps & PropsFromRedux,
  NearbyStoresGoogleMapState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      googleApiKey: "",
      mapKey: "",
    };
  }

  public formatStoreAddress = (address: string): { firstLine: string; secondLine: string } => {
    // This assumes that the address has a street address and
    // ends in 'city, state, zip'
    const splitAddress = address.split(",");
    // Street address, including suite if it exists
    const firstLine = splitAddress.slice(0, -3).join(",");
    // City, State, Zip
    const secondLine = splitAddress.slice(-3).join(",");

    return { firstLine, secondLine };
  };

  public StoreInfo: React.FunctionComponent<{ store: Store }> = ({ store }) => {
    const { history } = this.props;
    const storeUrl = `/projects/${store.project_id}/stores/${store.id}`;
    return (
      <div className="body-link" onClick={() => history.push(storeUrl)}>
        {store.name} <br />
        {this.formatStoreAddress(store.store_address).firstLine}
        <br />
        {this.formatStoreAddress(store.store_address).secondLine}
      </div>
    );
  };

  public getMarker(location: LocationLatLng, map: any, maps: any) {
    return new maps.Marker({
      position: {
        lat: location.lat(),
        lng: location.lng(),
      },
      map,
    });
  }

  public addInfowindowClickListener(
    map: any,
    maps: any,
    marker: any,
    infowindow: any,
    store: Store
  ) {
    maps.event.addListener(
      marker,
      "click",
      ((markerItem: object) => {
        return () => {
          // Clear the Infowindow when clicking on a new Marker.
          const resetInfowindowContent = new Promise<void>((resolve) => {
            infowindow.setContent('<div id="info-window-wrapper"></div>');
            infowindow.open(map, markerItem);
            resolve();
          });
          // Wait for #info-window-wrapper to be added to the DOM before attempting to update it.
          resetInfowindowContent.then(() => {
            ReactDOM.render(
              <this.StoreInfo store={store} />,
              document.getElementById("info-window-wrapper")
            );
          });
        };
      })(marker)
    );
  }

  public handleApiLoaded(map: any, maps: any) {
    const geocoder = new maps.Geocoder();
    const infowindow = new maps.InfoWindow();
    const bounds = new maps.LatLngBounds();

    // Loop through the address of every store and get the lat/long so we can set the Marker
    this.props.stores.forEach((store) => {
      geocoder.geocode({ address: store.store_address }, (results: any, status: any) => {
        if (status === "OK") {
          const marker = this.getMarker(results[0].geometry.location, map, maps);

          // Add the location of the Marker so we know where to set the Map zoom level.
          bounds.extend(marker.getPosition());

          // Add listener at the Map level so we only show one Infowindow at a time.
          return this.addInfowindowClickListener(map, maps, marker, infowindow, store);
        } else {
          return false;
        }
      });
    });
  }

  componentDidMount() {
    const { config } = this.props;
    const apiKey = config.data?.reactAppGoogleApiKey || "";
    if (apiKey) {
      this.setState({
        googleApiKey: apiKey,
        mapKey: new Date().getTime(),
      });
    }
  }

  componentDidUpdate(prevProps: NearbyStoresGoogleMapProps & PropsFromRedux) {
    if (prevProps.config.data !== this.props.config.data) {
      const newApiKey = this.props.config.data?.reactAppGoogleApiKey || "";
      if (newApiKey && newApiKey !== this.state.googleApiKey) {
        this.setState({
          googleApiKey: newApiKey,
          mapKey: new Date().getTime(),
        });
      }
    }
  }

  public render() {

    const { googleApiKey } = this.state;
    if (!googleApiKey) {
      return <></>;
    } else {
      return (
        <div className="nearby-stores-map-container" data-qa="nearby-stores-map">
          <GoogleMapReact
            key={this.state.mapKey}
            bootstrapURLKeys={{
              key: googleApiKey,
              language: "en",
            }}
            defaultCenter={this.props.position}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
          />
        </div>
      );
    }
  }
}
const connector = connect((state: State) => ({
  config: state.config,
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(NearbyStoresGoogleMap));
