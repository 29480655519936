import cx from "classnames";
import moment from "moment";
import React from "react";

import Button from "components/Button";
import { Card } from "components/Card";
import DatePickerPopover from "components/DatePickerPopover";
import Dropdown from "components/Dropdown";
import Table from "components/Table";
import Constants from "helpers/constants";
import { ResetNote } from "interfaces/reset-notes";
import { anyFieldsEmpty } from "utils/helpers";

interface ResetNotesUIProps {
  isMobile: boolean;
  onSubmit: (e: React.FormEvent, data: { resetNote: string; implementation: string }) => void;
  onTakeSurveys: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDateChange: (date: moment.Moment, type: "actual" | "target") => void;
  resetnotes: ResetNote[];
  displaySurveys: boolean;
  store2project: any;
}

interface ResetNotesUState {
  resetNote: string;
  implementation: string;
  noteOverLength?: boolean;
  targetResetDate: moment.Moment;
  actualResetDate: moment.Moment;
}

const implementationOptions = [
  { label: "N/A", value: "N/A", "data-qa": "N/A" },
  { label: "Yes", value: "Yes", "data-qa": "Yes" },
  { label: "No", value: "No", "data-qa": "No" },
];

const defaultState = {
  resetNote: "",
  implementation: "N/A",
  targetResetDate: moment(),
  actualResetDate: moment(),
  noteOverLength: false,
};

class ResetNotesUI extends React.Component<ResetNotesUIProps, ResetNotesUState> {
  private MAX_NOTE_LENGTH = 500;
  state = { ...defaultState };

  public handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.currentTarget.id === "add_resetnote") {
      const value = event.currentTarget.value;
      this.setState({
        resetNote: value,
        noteOverLength: value.length > this.MAX_NOTE_LENGTH,
      });
    }
  };

  public handleTakeSurveys = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.props.onTakeSurveys(e);
  };

  public handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { resetNote, implementation } = this.state;

    this.props.onSubmit(e, {
      resetNote,
      implementation,
    });
    this.setState({ ...defaultState });
  };

  public handleDateChange = (date: moment.Moment, type: "actual" | "target") => {
    if (type === "actual") {
      this.setState({ actualResetDate: date });
    } else if (type === "target") {
      this.setState({ targetResetDate: date });
    }
    this.props.onDateChange(date, type);
  };

  public handleTimeChange = (date: moment.Moment, type: string) => {
    const time = date.format("h:mm a");
    let newDateTime;
    if (type === "actual") {
      newDateTime = moment(
        `${this.state.actualResetDate.format("MM/D/YYYY")} ${time}`,
        "MM/DD/YYYY h:mm a"
      );
      this.setState({ actualResetDate: date });
      return newDateTime && this.props.onDateChange(newDateTime, "actual");
    } else if (type === "target") {
      newDateTime = moment(
        `${this.state.targetResetDate.format("MM/D/YYYY")} ${time}`,
        "MM/DD/YYYY h:mm a"
      );
      this.setState({ targetResetDate: date });
      return newDateTime && this.props.onDateChange(newDateTime, "target");
    }
  };

  public render() {
    const { resetNote } = this.state;
    const { isMobile } = this.props;

    const submitDisabled = anyFieldsEmpty({ note: this.state.resetNote });
    return (
      <Card>
        <Card.Header>
          <h3 style={{ marginRight: 20 }} data-qa="reset-notes-header">
            Add Reset Notes
          </h3>
        </Card.Header>
        <Card.Body>
          <div className="date-form-group">
            <div className="date-label-group" data-qa="target-reset-date">
              <h6>Target Reset Date</h6>
              <DatePickerPopover
                fieldValue={this.props.store2project.target_reset_date}
                field={true}
                defaultValue={""}
                dateFormat={isMobile ? "MM/D/YYYY" : "MM/D/YYYY h:mm a"}
                isMobile={isMobile}
                onSave={(date: moment.Moment) => {
                  this.handleDateChange(date, "target");
                }}
              />
            </div>
            {isMobile && (
              <div className="date-label-group" data-qa="target-reset-date">
                <h6>Target Reset Time</h6>
                <DatePickerPopover
                  fieldValue={this.props.store2project.target_reset_date}
                  field={true}
                  defaultValue={""}
                  isMobile={isMobile}
                  timeOnly={true}
                  onSave={(date: moment.Moment) => {
                    this.handleTimeChange(date, "target");
                  }}
                />
              </div>
            )}
            <div className="date-label-group" data-qa="actual-reset-date">
              <h6>Actual Reset Date</h6>
              <DatePickerPopover
                field={true}
                fieldValue={this.props.store2project.actual_reset_date}
                defaultValue={""}
                dateFormat={isMobile ? "MM/D/YYYY" : "MM/D/YYYY h:mm a"}
                isMobile={isMobile}
                onSave={(date: moment.Moment) => {
                  this.handleDateChange(date, "actual");
                }}
              />
            </div>
            {isMobile && (
              <div className="date-label-group" data-qa="actual-reset-date">
                <h6>Actual Reset Time</h6>
                <DatePickerPopover
                  field={true}
                  fieldValue={this.props.store2project.actual_reset_date}
                  defaultValue={""}
                  isMobile={isMobile}
                  timeOnly={true}
                  onSave={(date: moment.Moment) => {
                    this.handleTimeChange(date, "actual");
                  }}
                />
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Body className="note-form">
          <div className="note-form-input-group">
            <div className="implementation-dropdown">
              <h6>Implementation</h6>
              <Dropdown
                dataQa="implementation-dropdown"
                noShadow={true}
                backgroundFill={true}
                options={implementationOptions}
                value={{ label: this.state.implementation }}
                placeholder={"Implementation"}
                onChange={(value) => {
                  this.setState({ implementation: value.label });
                }}
                isSearchable
              />
            </div>
            <textarea
              className="note-text"
              id="add_resetnote"
              data-qa="note-text"
              placeholder="Type note here"
              onChange={this.handleTextChange}
              value={resetNote}
            />
          </div>
          <div hidden={!this.props.displaySurveys}>
            <Button
              style={{ float: "left" }}
              secondary={true}
              onClick={this.handleTakeSurveys}
              data-qa="take-surveys-button"
            >
              Take Survey
            </Button>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="submit-group">
              <span
                data-qa="note-char-count"
                className={cx("comment-char-count", {
                  warn: this.state.noteOverLength,
                })}
              >
                {this.state.resetNote.length} / {this.MAX_NOTE_LENGTH} characters
              </span>
              <Button
                type="submit"
                data-qa="add-resetnote-button"
                disabled={this.state.noteOverLength || submitDisabled}
              >
                ADD NOTE
              </Button>
            </div>
          </form>
        </Card.Body>
        <Table
          isMobile={isMobile}
          data={this.props.resetnotes || []}
          columns={[
            {
              field: "implementation",
              header: "Implementation",
              dataQa: "implementation",
            },
            {
              field: "resetnote",
              header: "Reset Note",
              dataQa: "reset_note",
              minWidth: 300,
            },
            {
              field: "created_at",
              header: "Note Date",
              formatter: (value) => {
                return moment(value, Constants.DATE_FORMAT__YYYY_MM_DD).format("M/D/YYYY");
              },
              dataQa: "note_date",
            },
          ]}
          emptyState="No notes found"
        />
      </Card>
    );
  }
}

export default ResetNotesUI;
