enum Constants {
  ACTION_GENERAL_COMMENT = `1`,
  ACTION_ADD_PRODUCT = `2`,
  ACTION_MODIFY_PRODUCT = `3`,
  ACTION_REMOVE_PRODUCT = `4`,
  ACTION_SWAP_PRODUCT = `5`,

  ACTION_GENERAL_COMMENT_LABEL = `General Comment`,
  ACTION_ADD_PRODUCT_LABEL = `Add Product`,
  ACTION_MODIFY_PRODUCT_LABEL = `Modify Product`,
  ACTION_REMOVE_PRODUCT_LABEL = `Remove Product`,
  ACTION_SWAP_PRODUCT_LABEL = `Swap Product`,

  REASON_APPROVED_CORE_NEW_ITEM_LABEL = `Approved Core/New item`,
  REASON_CAPACITY_LABEL = `Capacity`,
  REASON_DAYS_OF_SUPPLY_LABEL = `Days of Supply`,
  REASON_FLOW_LABEL = `Flow`,
  REASON_HURDLE_CRITERIA_LABEL = `Hurdle Criteria`,
  REASON_ITEM_NOT_APPROVED_LABEL = `Item Not Approved`,
  REASON_MARKET_PERFORMANCE_LABEL = `Market Performance`,
  REASON_OTHER_LABEL = `Other`,
  REASON_PLACEMENT_ORIENTATION_LABEL = `Placement / Orientation`,
  REASON_PRODUCT_UNAVAILABLE_LABEL = `Product Unavailable`,

  REASON_APPROVED_CORE_NEW_ITEM = `1`,
  REASON_CAPACITY = `2`,
  REASON_DAYS_OF_SUPPLY = `3`,
  REASON_FLOW = `4`,
  REASON_HURDLE_CRITERIA = `5`,
  REASON_ITEM_NOT_APPROVED = `6`,
  REASON_MARKET_PERFORMANCE = `7`,
  REASON_OTHER = `8`,
  REASON_PLACEMENT_ORIENTATION = `9`,
  REASON_PRODUCT_UNAVAILABLE = `10`,

  ATTACHMENTS_PDF_LABEL = `PDF File Extension`,
  ATTACHMENTS_PSA_LABEL = `PSA File Extension`,

  COMMENT_FORM_PRODUCT_DROPDOWN_PLACEHOLDER = `Products (Type 3 characters min.)`,
  COMMENT_FORM_SUB_PRODUCT_DROPDOWN_PLACEHOLDER = `With Product (Type 3 characters min.)`,

  CREATE_PROJECT_DEFAULT_NAME = `Untitled`,

  // Roles
  AB_SYSTEM_ADMIN = `AB System Admin`,
  AB_PROJECT_ADMIN = `AB Project Admin`,
  AB_COLLABORATOR = `AB Collaborator`,
  RETAILER = `Retailer`,
  AB_VIEWER = `AB Viewer`,
  AB_WHOLESALER = `AB Wholesaler`,
  VIEWER = `Viewer`,
  COMPETITOR_WHOLESALER = `Competitor Wholesaler`,
  COMPETITOR_CSM = `Competitor CSM`,
  AB_REGION_CAT_DIRECTOR = `AB Region Category Director`,
  AB_MERCHANDISER = `AB Merchandiser`,

  // Validation Statuses
  NEEDS_VALIDATION = `NEEDS VALIDATION`,
  VALIDATION_PENDING = `VALIDATION PENDING`,
  VALIDATION_COMPLETE = `VALIDATION COMPLETE`,
  AWAITING_FINAL_UPLOAD = `AWAITING FINAL UPLOAD`,
  FINAL_UPLOAD_COMPLETE = `FINAL UPLOAD COMPLETE`,
  FEEDBACK_UNDER_REVIEW = `FEEDBACK UNDER REVIEW`,
  NEEDS_FEEDBACK_REVIEW = `NEEDS FEEDBACK REVIEW`,

  // Reset Statuses
  RESET_NOT_SCHEDULED = `RESET NOT SCHEDULED`,
  RESET_SCHEDULED = `RESET SCHEDULED`,
  RESET_COMPLETE = `RESET COMPLETE`,

  // Project Types
  FILE_SHARING = `File Sharing`,
  VALIDATION = `Validation`,

  // File Types
  ORIGINAL = `ORIGINAL`,
  FINAL = `FINAL`,

  // Pill Types
  PILL_FILE_SHARING = `FILE SHARING`,
  PILL_MOBILE_FILE_SHARING = `MOBILE FILE SHARING`,

  // Project Status
  PUBLISHED = `PUBLISHED`,
  UNPUBLISHED = `UNPUBLISHED`,

  // Downloads
  DOWNLOAD_FILES_SUCCESS_MESSAGE = `Your files will be emailed soon.`,
  DOWNLOAD_FILES_ERROR_MESSAGE = `Something went wrong. Please try again.`,
  DOWNLOAD_FILES_DOWNLOAD_FAILED_MESSAGE = `Download failed. Please refresh your page before trying again.`,

  BREAKPOINT_LARGE = `large`,
  BREAKPOINT_MEDIUM = `medium`,
  BREAKPOINT_SMALL = `small`,
  BREAKPOINT_MOBILE = `mobile`,

  DATE_FORMAT__YYYY_MM_DD = `YYYY-MM-DD`,
  DATE_FORMAT__YYYY_MM_DD__HH_mm_ss = `YYYY-MM-DD HH:mm:ss`,
  DATE_FORMAT__EXPORT_FILE__YYYY_MM_DD__HH_mm = `YYYY-MM-DD--HHmm`,

  ERROR_403 = "403",
  ERROR_404 = "404",

  // Season Information
  SEASON_SPRING = `1`,
  SEASON_SUMMER = `2`,
  SEASON_FALL = `3`,
  SEASON_WINTER = `4`,
  SEASON_SPECIAL_PROJECT = `5`,

  SEASON_SPRING_LABEL = `Spring`,
  SEASON_SUMMER_LABEL = `Summer`,
  SEASON_FALL_LABEL = `Fall`,
  SEASON_WINTER_LABEL = `Winter`,
  SEASON_SPECIAL_PROJECT_LABEL = `Special Project`,

  IRIS_DOWNLOADS_QUERY = "iris.downloads.query",
  MY_STORES_QUERY = "my.stores.query"
}

export default Constants;
