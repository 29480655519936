import axios from "axios";
import {handleActions} from "redux-actions";

import Config from "config";
import {getHeaders} from "helpers/headers";
import {AnyAction} from "interfaces/action";
import {Dispatch} from "interfaces/dispatch";
import {ThunkActionCreator} from "interfaces/thunk";

// Actions Types
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_RESET = "SEARCH_RESET";
export const SEARCH_COMPLETE = "SEARCH_COMPLETE";
export const SEARCH_FAILED = "SEARCH_FAILED";

interface QueryParams {
  query: string;
  serachFor: string;
}

// Thunk Action Creator
export const getSearch: ThunkActionCreator<Promise<void>> = (queryParams: QueryParams) =>
  async (dispatch: Dispatch) => {
    dispatch({type: SEARCH_REQUEST});

    try {
      const response = await axios
        .get(`${Config.API_SERVICE_URL}/api/search?query=${queryParams.query}&searchFor=${queryParams.serachFor}`, getHeaders());
      dispatch({
        type: SEARCH_COMPLETE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({type: SEARCH_FAILED});
    }
  };

export const resetSearch: ThunkActionCreator<Promise<void>> = (query: string) =>
  async (dispatch: Dispatch) => {
    dispatch({type: SEARCH_RESET});
  };

const actions = {
  [SEARCH_REQUEST]: (state: SearchBarState) => ({
    ...state,
    loading: true,
  }),
  [SEARCH_RESET]: (state: SearchBarState) => ({
    ...defaultState
  }),
  [SEARCH_COMPLETE]: (state: SearchBarState, action: AnyAction): SearchBarState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [SEARCH_FAILED]: (state: SearchBarState) => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
};

export enum SearchResultSource {
  PROJECT = "PROJECT",
  STORE = "STORE",
}

export interface SearchResultType {
  source: SearchResultSource,
  chainId: number,
  chainName: string,
  projectId: number,
  projectName: string,
  storeName?: string,
  storeId?: string,
}

// tslint:disable no-empty-interface
export interface SearchBarState {
  query: string,
  loading: boolean,
  content: SearchResultType[],
  error: boolean
}

const defaultState: SearchBarState = {
  query: "",
  content: [],
  loading: false,
  error: false
}

// Reducer
export default handleActions<SearchBarState, AnyAction>(actions, defaultState);
