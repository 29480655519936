import cx from "classnames";
import React from "react";

interface IconProps extends React.ImgHTMLAttributes<{}> {
  small?: boolean;
  medium?: boolean;
  mobile?: boolean;
}

const Icon: React.FunctionComponent<IconProps> = ({
  src,
  className,
  small = false,
  medium = false,
  mobile = false,
}) => <img className={cx("icon", className, { small, medium, mobile })} src={src} alt="" />;

export default Icon;
