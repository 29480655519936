import axios, { AxiosResponse } from "axios";
import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { DirFile, DirFilesQueryParams } from "interfaces/dir-file";
import { Dispatch } from "interfaces/dispatch";
import { PaginatedResponse } from "interfaces/paginated-query";
import { ThunkActionCreator } from "interfaces/thunk";
import { handleActions } from "redux-actions";
import queryString from "query-string";
import {isArray} from "lodash";

// For right now, only a current and previous version is needed.
// If that number increases, increase this.
export type DirFileNumVersions = 2;

// Actions Types
const INIT_DIR_FILES = "INIT_DIR_FILES";
const CLEAR_DIR_FILES = "CLEAR_DIR_FILES";
const FETCH_DIR_FILES_REQUEST = "FETCH_DIR_FILES_REQUEST";
const FETCH_DIR_FILES = "FETCH_DIR_FILES";
const FETCH_DIR_FILES_FAILED = "FETCH_DIR_FILES_FAILED";

export { INIT_DIR_FILES, FETCH_DIR_FILES_REQUEST, FETCH_DIR_FILES, FETCH_DIR_FILES_FAILED };

export interface FetchStoreFilesOptions {
  projectId: number | string;
  storeId: number | string;
}

const initDIRFiles: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({
    type: INIT_DIR_FILES,
  });
};

const clearDIRFiles: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_DIR_FILES,
  });
};

export const addQueryParamsToUrl = (url: string, queryParams: DirFilesQueryParams = {}) => {
  if (queryParams && Object.keys(queryParams).length > 0) {
    const queryString = Object.keys(queryParams)
      .map(
        (key) => {
          // @ts-ignore
          return encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])
        }
      )
      .join("&");
    url = `${url}?${queryString}`;
  }
  return url;
}

const fetchDIRFilesCount = (queryParams: DirFilesQueryParams = {}): Promise<AxiosResponse<any>> => {
  queryParams = { ...queryParams, countOnly: true };

  return axios.get(addQueryParamsToUrl(`${Config.API_SERVICE_URL}/api/iris-files`, queryParams), getHeaders());
};

const fetchDIRFiles: ThunkActionCreator<Promise<void>> =
  (queryParams: DirFilesQueryParams = {}) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_DIR_FILES_REQUEST });

    return axios
      .get(addQueryParamsToUrl(`${Config.API_SERVICE_URL}/api/iris-files`, queryParams), getHeaders())
      .then((response) => {
        dispatch({
          type: FETCH_DIR_FILES,
          payload: response.data,
        });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_DIR_FILES_FAILED });
      });
  };

// Actions
const actions = {
  [INIT_DIR_FILES]: (state: DIRFilesState) => ({
    ...state,
    loading: false,
  }),
  [CLEAR_DIR_FILES]: (state: DIRFilesState) => ({
    ...state,
    loading: false,
    error: false,
    content: { data: [] },
  }),
  [FETCH_DIR_FILES_REQUEST]: (state: DIRFilesState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_DIR_FILES]: (state: DIRFilesState, action: AnyAction): DIRFilesState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_DIR_FILES_FAILED]: (state: DIRFilesState, action: AnyAction): DIRFilesState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
};

// Reducer Interface
export interface DIRFilesState {
  error?: boolean;
  loading?: boolean;
  content?: PaginatedResponse<DirFile<DirFileNumVersions>> | null;
}

// Initial State
const initialState: DIRFilesState = {
  loading: false,
  error: false,
  content: { data: [] },
};

export { initDIRFiles, fetchDIRFiles, fetchDIRFilesCount, clearDIRFiles, initialState };

// Reducer
export default handleActions<DIRFilesState, AnyAction>(actions, initialState);
