import cx from "classnames";
import React, {useState, useEffect} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from "react-router-dom";

import { State } from "state";
import emptyTableStateSvg from "assets/img/empty-table-state.svg";
import { RouteProps } from 'containers/App';
import Loading from "components/Loading";
import { getPillProps, Pill } from "components/Pill";
import { fetchStarredProjects } from "state/starredProjects";
import { fetchStarredStores } from "state/starredStores";

interface BubbleProps extends React.HTMLAttributes<{}> {
    color: string,
    text: string,
}
const Bubble: React.FunctionComponent<BubbleProps> = ({
    color,
    text,
    ...props
}) => {
    return (
        <div className={cx("pill",`pill-color-${color}`)} {...props}>
            <h6 className="pill-text">{text}</h6>
        </div>
    )
}

interface StoreCardProps extends React.HTMLAttributes<{}> {
    data: any,
    history: any,
}
const StoreCard: React.FunctionComponent<StoreCardProps> = ({
    data,
    history,
}) => {
    const pillProps = getPillProps(data.store_status);
    const onClick = () => history.push(`/projects/${data.project_id}/stores/${data.store_id}`);
    return (
        <div className="starred-card" onClick={onClick}>
            <div>
                <Pill {...pillProps}/>
            </div>
            <div className="starred-store-card__title-text truncated">
                {data.store_name}
            </div>
            <div className="starred-store-card__chain-text truncated">
                {data.chain_name}
            </div>
            <div className="starred-store-card__project-text truncated">
                {data.project_name}
            </div>
        </div>
    );
}

interface ProjectCardProps extends React.HTMLAttributes<{}> {
    data: any,
    history: any,
}
const ProjectCard: React.FunctionComponent<ProjectCardProps> = ({
    data,
    history,
}) => {
    const onClick = () => history.push(`/projects/${data.project_id}`)
    return (
        <div className="starred-card starred-project-card" onClick={onClick}>
            <div className="starred-project-card__title-text truncated">
                {data.project_name}
            </div>
            <div className="starred-project-card__type-text1 truncated">
                {data.project_type}
            </div>
            <div className="starred-project-card__type-text2 truncated">
                {data.chain_name}
            </div>
            <div className="starred-project-card__counter-text">
                <Bubble color="blue" text={data.announcement_count} style={{minWidth: 0, width: 30}}/>
                <span>Announcements</span>
            </div>
            <div className="starred-project-card__counter-text">
                <Bubble color="blue" text={data.survey_count} style={{minWidth: 0, width: 30}}/>
                <span>Surveys</span>
            </div>
        </div>
    )
}

interface CardListProps extends React.HTMLAttributes<{}> {
    type: string, //"Store" | "Project"
    data: any,
    history: any,
}
const CardList: React.FunctionComponent<CardListProps> = ({
    type,
    data,
    history,
}) => {
    const cards = data.map((d: any) => type === "Store" ? <StoreCard data={d} history={history}/> : <ProjectCard data={d} history={history}/>);

    if (cards.length === 0) {
        return (
            <div className="empty-card-list">
                <img
                  src={emptyTableStateSvg}
                  data-qa="empty-state-image"
                  alt=""
                />
                <div style={{ marginTop: 12, color: "#8d8d92" }}>
                   {`No ${type.toLowerCase()}s found `}
                </div>
            </div>
        )
    }

    return (
        <div className="starred-card-list">
            {cards}
        </div>
    )
}

interface PaginationProps extends React.HTMLAttributes<{}> {
    activePage: any,
    setActivePage: any,
    totalPages: any,
}
const Pagination: React.FunctionComponent<PaginationProps> = ({
    activePage,
    setActivePage,
    totalPages,
}) => {
    return (
        <div style={{display: 'flex', justifyContent: 'flex-end', gap: 6}}>
            {
                new Array(totalPages).fill(null).map((c:any, i: any) => (
                    <button
                        key={i}
                        style={{
                            listStyleType: 'none',
                            borderRadius: '5px',
                            height: 32,
                            width: 64,
                            backgroundColor: (i + 1) === activePage ? '#D0021B' : '#EBEBEB',
                            color: (i + 1) === activePage ? 'white' : '#4A4A4A',
                            border:'none',
                            boxSizing: 'border-box',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            setActivePage(i + 1);
                        }}
                    >
                        {i + 1}
                    </button>
                ))
            }
        </div>
    )
}

interface ToggleViewProps extends React.HTMLAttributes<{}> {
    title: string,
    elements: any,
    history: any,
}
const ToggleView: React.FunctionComponent<ToggleViewProps> = ({
    title,
    elements,
    history,
}) => {
    const [type, setType] = useState("Store");
    const data = type === "Store" ? elements.stores : elements.projects;
    const [activePage, setActivePage] = useState(1)
    const rowsPerPage = 20
    const count = data.length
    const totalPages = Math.max(
        Math.ceil(count / rowsPerPage),
        1,
    );
    const calculatedRows = data.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)

    return (
        <div style={{ backgroundColor: 'white', width: '97.5%', maxWidth: 1360, minHeight: 350, padding: 20, display: 'flex', flexDirection: 'column', gap: 10, }} >
            <div>
                <span style={{ fontSize: 28, color: '#4A4A4A' }}>
                    {title}
                </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, }} >
                <button onClick={() => setType("Store")} className="card-row__button-toggle" aria-checked={type === "Store"}>
                    Stores
                </button>
                <button onClick={() => setType("Project")} className="card-row__button-toggle" aria-checked={type === "Project"}>
                    Projects
                </button>
            </div>
            <CardList type={type} data={calculatedRows} history={history}/>
            <Pagination
                activePage={activePage}
                setActivePage={setActivePage}
                totalPages={totalPages}
            />
        </div>
    )
}

type StarredPageProps = RouteProps & PropsFromRedux & RouteComponentProps;
interface StarredPageState {}
class StarredPage extends React.Component<StarredPageProps, StarredPageState> {
    componentDidMount() {
        const {
            user,
            fetchStarredStores,
            fetchStarredProjects,
        } = this.props;
        return Promise.all([
            fetchStarredStores(user?.id, 1, 500),
            fetchStarredProjects(user?.id, 1, 500),
        ])
    }

    public render() {
        const {
            starredStores,
            starredProjects,
            isLoading,
            history,
        } = this.props;


        if (isLoading) {
            return <Loading/>
        }
        
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 40,
                }}
            >
                <ToggleView
                    title="Starred Pages"
                    elements={{ projects: starredProjects, stores: starredStores }}
                    history={history}
                />
            </div>
        )
    }
}

const connector = connect(
    (state: State) => ({
        user: state.auth_user.content,
        isLoading: (
            state.starredStores.loading || 
            state.starredProjects.loading
        ),
        starredStores: state.starredStores.content,
        starredProjects: state.starredProjects.content,
    }),
    {
        fetchStarredProjects,
        fetchStarredStores,
    }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(StarredPage));