import React from "react";

const Highlighter = (searchTerm: string, value: string) => {
  const parts = value.split(new RegExp(`(${searchTerm})`, "gi"));

  if (parts.length > 1) {
    return (
      <div>
        {parts.map((p: string, idx: number) => {
          if (p.toLowerCase() === searchTerm.toLowerCase()) {
            return <b key={idx}>{p}</b>;
          }
          return p;
        })}
      </div>
    );
  }
  return value;
};

export default Highlighter;
