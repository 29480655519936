import downloadSvg from "assets/img/download.svg";
import { Card, CardList } from "components/Card";
import Icon from "components/Icon";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import ChainFilter from "containers/ChainFilter";
import Constants from "helpers/constants";
import { File } from "interfaces/file";
import { User } from "interfaces/user";
import find from "lodash/find";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { State } from "state";
import { EventTypes, createAnalyticsEvent } from "state/analytics";
import { fetchMyChains } from "state/chains";
import { fetchSupportFiles } from "state/support_files";
import { fetchUsers } from "state/users";

type SupportProps = PropsFromRedux & RouteProps;

interface SupportState {
  userTableData: User[];
}

class Support extends React.Component<SupportProps, SupportState> {
  state = { userTableData: [] };

  public async componentDidMount() {
    await this.props.fetchUsers();
    await this.props.fetchMyChains();
    await this.props.fetchSupportFiles();
  }

  public updateTableData(option: number) {
    const { chains = [], users = [] } = this.props;
    if (option === -1) {
      this.setState({ userTableData: [] });
    } else {
      const [chainData] = chains.filter((chain) => chain.id === option);
      const keyContacts = [chainData.keycontact1, chainData.keycontact2, chainData.keycontact3];

      // Get user data from Key Contacts who are existing users
      // in the system.
      const userData = users.filter((user) =>
        keyContacts.some((contact) => contact === user.email)
      );

      // Add contacts who are listed in the Key Contacts, but
      // who do not exist as a user in the system.
      keyContacts.forEach((contact) => {
        if (contact && !find(userData, (user) => user.email === contact)) {
          userData.push({ id: 0, email: contact, role: "" });
        }
      });

      this.setState({
        userTableData: userData,
      });
    }
  }

  public handleDownloadClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    { blob_url: blobUrl, name }: File
  ) => {
    this.props.createAnalyticsEvent({
      event: EventTypes.SUPPORT_FILE_DOWNLOAD,
      eventType: "Action",
      supportFileName: name,
    });

    return window.open(blobUrl);
  };

  public render() {
    const {
      supportDocs = [],
      supportDocsLoading = true,
      usersLoading = true,
      isMobile,
    } = this.props;

    return (
      <section className="support-container">
        <h1 className="header">BrewBox360 Support</h1>
        <CardList>
          <Card>
            <Card.Header>
              <span data-qa="support-info-header">Support Info</span>
            </Card.Header>
            <Card.Body>
              <span data-qa="support-info-body">
                Please review the following support documents for assistance using BrewBox360. If
                you have additional questions, please visit
                <a
                  href="https://brewbox360.zendesk.com/hc/en-us/requests/new"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "#3c6df1" }}
                >
                  {` here `}
                </a>
                to submit a request or view your existing requests.
              </span>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <span data-qa="support-documentation-header">Support Documentation</span>
            </Card.Header>
            <Table
              loading={supportDocsLoading}
              data={supportDocsLoading ? [] : supportDocs}
              style={{ marginTop: "unset" }}
              emptyState="No Documents Found"
              columns={[
                {
                  field: "name",
                  header: "File Name",
                  dataQa: "support-file-name",
                },
                {
                  field: "blobUrl",
                  header: "",
                  sortable: false,
                  dataQa: "download-support-file",
                  maxWidth: 84,
                  formatter: (value: string, row: File) => {
                    return (
                      <button
                        className="download-button"
                        data-qa="download-support-file-button"
                        onClick={(e) => this.handleDownloadClick(e, row)}
                      >
                        <Icon src={downloadSvg} />
                      </button>
                    );
                  },
                },
              ]}
            />
          </Card>
        </CardList>
        <div className="key-contacts-header-container">
          <h1 className="header" data-qa="key-contacts-header">
            Key Contacts
          </h1>
          <ChainFilter
            dataQa="chain-dropdown"
            onChange={({ id, name }) => {
              if (name !== "Select Chain") {
                this.props.createAnalyticsEvent({
                  event: EventTypes.SUPPORT_KEY_CONTACTS,
                  eventType: "Action",
                  chainName: name,
                });
              }
              this.updateTableData(id);
            }}
          />
        </div>
        <CardList>
          <Card>
            <Table
              isMobile={isMobile}
              data={usersLoading ? [] : this.state.userTableData}
              emptyState="No contacts found"
              columns={[
                {
                  field: "showMobileRow",
                  header: "User Name",
                  dataQa: "user_name",
                  formatter: (id: string, row: any) =>
                    `${row.firstname ? row.firstname : ""} ${row.lastname ? row.lastname : ""}`,
                },
                {
                  field: "company",
                  header: "Company Representing",
                  dataQa: "company_representing",
                },
                { field: "email", header: "Email Address", dataQa: "email" },
                {
                  field: "phone",
                  header: "Phone Number",
                  dataQa: "phone_number",
                },
              ]}
            />
          </Card>
        </CardList>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    chains: state.chains.content,
    users: state.users.content?.data,
    usersLoading: state.users.loading,
    supportDocs: state.support_files.content,
    supportDocsLoading: state.support_files.loading,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  { fetchMyChains, fetchUsers, fetchSupportFiles, createAnalyticsEvent }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(Support);
