import cx from "classnames";
import React from "react";

import hamburgerSvg from "assets/img/hamburger.svg";
import closeSvg from "assets/img/x.svg";
import { LogoLink } from "components/Logo";

const Hamburger: React.FunctionComponent<{
  toggled: boolean;
  onToggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ toggled, onToggle }) => (
  <span className="hamburger-container">
    <button
      aria-pressed={toggled ? "true" : "false"}
      className={cx("hamburger", { toggled })}
      onClick={onToggle}
      data-qa="mobile-nav-open-close"
    >
      {toggled ? (
        <img className="hamburger-icon" src={closeSvg} alt="" />
      ) : (
        <img className="hamburger-icon" src={hamburgerSvg} alt="" />
      )}
    </button>
    <LogoLink />
  </span>
);

export default Hamburger;
