import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Error403 from "components/Error403";
import Error404 from "components/Error404";
import ErrorPage from "components/ErrorPage";
import Constants from "helpers/constants";
import { createAnalyticsEvent, EventTypes } from "state/analytics";

interface Props {}

interface State {
  hasError: boolean;
  errorType?: Constants.ERROR_403 | Constants.ERROR_404;
}

type InternalProps = Props & PropsFromRedux & RouteComponentProps<{}>;

class ErrorBoundary extends React.Component<InternalProps, State> {
  public state: State = { hasError: false };

  public UNSAFE_componentWillReceiveProps(nextProps: InternalProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error: Error) {
    switch (error.message) {
      case Constants.ERROR_403:
      case Constants.ERROR_404:
        this.setState({ hasError: true, errorType: error.message });
        break;
      default:
        this.setState({ hasError: true });
        break;
    }
  }

  public render() {
    const { hasError, errorType } = this.state;
    const {
      location: { pathname },
    } = this.props;

    if (hasError) {
      this.props.createAnalyticsEvent({
        event: EventTypes.PAGEVIEW_ERROR,
        eventType: "Pageview",
        pageviewErrorType:
          errorType && errorType === Constants.ERROR_403
            ? Constants.ERROR_403
            : errorType === Constants.ERROR_404
            ? Constants.ERROR_404
            : "Error Page",
        pageUrl: pathname,
      });

      if (errorType && errorType === Constants.ERROR_403) {
        return <Error403 />;
      }

      if (errorType && errorType === Constants.ERROR_404) {
        return <Error404 />;
      }

      return (
        <ErrorPage
          image={<div />}
          message={
            "Oops! Something went wrong. An unknown error occurred, please try again or contact support"
          }
        />
      );
    }

    return this.props.children;
  }
}

const connector = connect(null, {
  createAnalyticsEvent,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ErrorBoundary));
