import React from "react";
import { connect, ConnectedProps } from "react-redux";

import DonutChart from "components/DonutChart";
import LegendColorIndicator from "components/LegendColorIndicator";
import Loading from "components/Loading";
import Constants from "helpers/constants";
import { Project } from "interfaces/project";
import { State } from "state";
import { fetchFeedbackReviewDashboard } from "state/feedback_review_dashboard";

interface FeedbackReviewDashboardOwnProps {
  project: Project;
  isMobile?: boolean;
}

type FeedbackReviewDashboardProps = FeedbackReviewDashboardOwnProps & PropsFromRedux;

export const ALLOWED_ROLES = [
  Constants.AB_PROJECT_ADMIN,
  Constants.AB_COLLABORATOR,
  Constants.AB_MERCHANDISER,
  Constants.AB_REGION_CAT_DIRECTOR,
  Constants.RETAILER,
  Constants.AB_VIEWER,
];

class FeedbackReviewDashboard extends React.Component<FeedbackReviewDashboardProps> {
  public componentDidMount() {
    this.updateChartData();
  }

  public componentDidUpdate(prevProps: FeedbackReviewDashboardProps) {
    const {
      project: { id },
    } = prevProps;

    if (id !== this.props.project.id) {
      this.updateChartData();
    }
  }

  public updateChartData() {
    if (this.props.project.id) {
      this.props.fetchFeedbackReviewDashboard(this.props.project.id.toString());
    }
  }

  public render() {
    const { isMobile } = this.props;
    const { data, labels, colors, total } = this.props.feedbackreviewdashboard;

    if (!data) {
      return <Loading />;
    }

    return (
      <div className="chart-wrapper" data-qa="feedback-review-dashboard">
        <DonutChart
          isMobile={isMobile}
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor: colors,
              },
            ],
          }}
          total={total || 0}
        />
        <div className="legend">
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="gray" />
              Comments Pending
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="yellow" />
              Comments in Review
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="green" />
              Comments Accepted
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="red" />
              Comments Rejected
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    feedbackreviewdashboard: state.feedbackreviewdashboard.content,
  }),
  {
    fetchFeedbackReviewDashboard,
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(FeedbackReviewDashboard);
