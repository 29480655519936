import React from "react";

interface Base64LogoProps extends React.HTMLAttributes<{}> {
  data?: Uint8Array;
  logo_filename?: string;
}

const Base64Logo: React.FunctionComponent<Base64LogoProps> = ({ data, logo_filename, ...restProps }) => {
  if (!data && !logo_filename) {
    return <div className="placeholder chain-logo" {...restProps} />;
  }

  if (logo_filename) {
    return <img className="chain-logo" src={`${logo_filename}`} alt="" {...restProps} />;
  } else {
    return <img className="chain-logo" src={`data:image/png;base64,${data}`} alt="" {...restProps} />;
  }

};
export default Base64Logo;
