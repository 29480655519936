import cx from "classnames";
import React, {useState, useEffect} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from "react-router-dom";

import { State } from "state";
import emptyTableStateSvg from "assets/img/empty-table-state.svg";
import { RouteProps } from 'containers/App';
import Loading from "components/Loading";
import { getPillProps, Pill } from "components/Pill";
import { fetchStarredProjects } from "state/starredProjects";
import { fetchStarredStores } from "state/starredStores";
import { fetchRecentProjects } from "state/recentProjects";
import { fetchRecentStores } from "state/recentStores";
import Constants from "helpers/constants";

interface BubbleProps extends React.HTMLAttributes<{}> {
    color: string,
    text: string,
}
const Bubble: React.FunctionComponent<BubbleProps> = ({
    color,
    text,
    ...props
}) => {
    return (
        <div className={cx("pill",`pill-color-${color}`)} {...props}>
            <h6 className="pill-text">{text}</h6>
        </div>
    )
}

interface StoreCardProps extends React.HTMLAttributes<{}> {
    data: any,
    history: any,
}
const StoreCard: React.FunctionComponent<StoreCardProps> = ({
    data,
    history,
}) => {
    const pillProps = getPillProps(data.store_status);
    const onClick = () => history.push(`/projects/${data.project_id}/stores/${data.store_id}`);
    return (
        <div className="starred-card" onClick={onClick}>
            <div>
                <Pill {...pillProps}/>
            </div>
            <div className="starred-store-card__title-text truncated">
                {data.store_name}
            </div>
            <div className="starred-store-card__chain-text truncated">
                {data.chain_name}
            </div>
            <div className="starred-store-card__project-text truncated">
                {data.project_name}
            </div>
        </div>
    );
}

interface ProjectCardProps extends React.HTMLAttributes<{}> {
    data: any,
    history: any,
}
const ProjectCard: React.FunctionComponent<ProjectCardProps> = ({
    data,
    history,
}) => {
    const onClick = () => history.push(`/projects/${data.project_id}`)
    return (
        <div className="starred-card starred-project-card" onClick={onClick}>
            <div className="starred-project-card__title-text truncated">
                {data.project_name}
            </div>
            <div className="starred-project-card__type-text1 truncated">
                {data.project_type}
            </div>
            <div className="starred-project-card__type-text2 truncated">
                {data.chain_name}
            </div>
            <div className="starred-project-card__counter-text">
                <Bubble color="blue" text={data.announcement_count} style={{minWidth: 0, width: 30}}/>
                <span>Announcements</span>
            </div>
            <div className="starred-project-card__counter-text">
                <Bubble color="blue" text={data.survey_count} style={{minWidth: 0, width: 30}}/>
                <span>Surveys</span>
            </div>
        </div>
    )
}

interface CardListProps extends React.HTMLAttributes<{}> {
    type: string, //"Store" | "Project"
    data: any,
    history: any,
}
const CardList: React.FunctionComponent<CardListProps> = ({
    type,
    data,
    history,
}) => {
    const dataList = type === "Store" ? data.stores : data.projects;
    const cards = dataList.map((d: any) => type === "Store" ? <StoreCard data={d} history={history}/> : <ProjectCard data={d} history={history}/>);

    if (cards.length === 0) {
        return (
            <div className="empty-card-list">
                <img
                  src={emptyTableStateSvg}
                  data-qa="empty-state-image"
                  alt=""
                />
                <div style={{ marginTop: 12, color: "#8d8d92" }}>
                   {`No ${type.toLowerCase()}s found `}
                </div>
            </div>
        )
    }

    return (
        <div className="starred-card-list">
            {cards}
        </div>
    )
}

interface ToggleViewProps extends React.HTMLAttributes<{}> {
    title: string,
    elements: any,
    history: any,
    user: any,
}
const ToggleView: React.FunctionComponent<ToggleViewProps> = ({
    title,
    elements,
    history,
    user = {},
}) => {
    const [type, setType] = useState("Store");
    const isProjectToggle = type === "Project";
    const isRecentlyViewSection = title === "Recently Viewed";
    const isSystemAdmin = user.role === Constants.AB_SYSTEM_ADMIN;
    let linkValue;

    if (isRecentlyViewSection) {
        if (isProjectToggle) {
            if (isSystemAdmin) {
                linkValue = "/projects";
            } else {
                linkValue = "/projects/me";
            }
        } else {
            linkValue = "/stores/me";
        }

    } else {
        linkValue = "/starred/all";
    }

    return (
        <div style={{ backgroundColor: 'white', width: '97.5%', maxWidth: 1360, minHeight: 350, padding: 20, display: 'flex', flexDirection: 'column', gap: 10, }} >
            <div>
                <span style={{ fontSize: 28, color: '#4A4A4A' }}>
                    {title}
                </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, }} >
                <button onClick={() => setType("Store")} className="card-row__button-toggle" aria-checked={type === "Store"}>
                    Stores
                </button>
                <button onClick={() => setType("Project")} className="card-row__button-toggle" aria-checked={type === "Project"}>
                    Projects
                </button>
            </div>
            <CardList type={type} data={elements} history={history}/>
            <div  className="card-row__link-container">
                <a className="card-row__link" href={linkValue}>
                    View More
                </a>
            </div>
        </div>
    )
}

type HomePageProps = RouteProps & PropsFromRedux & RouteComponentProps;
interface HomePageState {}
class HomePage extends React.Component<HomePageProps, HomePageState> {
    componentDidMount() {
        const {
            user,
            fetchStarredStores,
            fetchStarredProjects,
            fetchRecentStores,
            fetchRecentProjects,
        } = this.props;
        return Promise.all([
            fetchStarredStores(user?.id),
            fetchStarredProjects(user?.id),
            fetchRecentStores(user?.id),
            fetchRecentProjects(user?.id),
        ])
    }

    public render() {
        const {
            starredStores,
            starredProjects,
            recentStores,
            recentProjects,
            isLoading,
            history,
            user,
        } = this.props;

        if (isLoading) {
            return <Loading/>
        }
        
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 40,
                }}
            >
                <ToggleView
                    title="Starred Pages"
                    elements={{ projects: starredProjects, stores: starredStores }}
                    history={history}
                    user={user}
                />
                <ToggleView
                    title="Recently Viewed"
                    elements={{ projects: recentProjects, stores: recentStores }}
                    history={history}
                    user={user}
                />
            </div>
        )
    }
}

const connector = connect(
    (state: State) => ({
        user: state.auth_user.content,
        isLoading: (
            state.starredStores.loading || 
            state.starredProjects.loading ||
            state.recentStores.loading ||
            state.recentProjects.loading 
        ),
        starredStores: state.starredStores.content,
        starredProjects: state.starredProjects.content,
        recentStores: state.recentStores.content,
        recentProjects: state.recentProjects.content,
    }),
    {
        fetchStarredProjects,
        fetchStarredStores,
        fetchRecentProjects,
        fetchRecentStores,
    }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(HomePage));