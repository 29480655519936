import React from "react";

import Dropdown from "components/Dropdown";
import { ProjectComments } from "interfaces/projectComment";
interface ProjectCommentDropdownProps {
  projectComments?: ProjectComments;
  isDisabled: boolean;
  selectedComment?: string;
  placeholder?: string;
  containerStyles: object;
  onChange?: (newValue: any, action: object) => void;
  dataQa?: string;
  breakpoint: string;
}

interface ProjectCommentDropdownState {
  showPlaceholder: boolean;
  projectComments: ProjectComments;
  selectedComment: string;
}

const defaultState = {
  showPlaceholder: true,
  projectComments: [],
  selectedComment: "",
};

/* Length to truncate based on string match from Redux store breakpoint variable */
let TruncateLengthMap = new Map([
  ['large', 160],
  ['medium', 100],
  ['small', 100],
  ['mobile', 100]
]);

export default class ProjectCommentDropdown extends React.Component<
  ProjectCommentDropdownProps,
  ProjectCommentDropdownState
> {
  constructor(props: any) {
    super(props);
    this.state = defaultState;
  }

  public handleOpen = () => {
    this.setState({ showPlaceholder: false });
  };

  public handleClose = () => {
    this.setState({ showPlaceholder: true });
  };

  /* Due to overflow overrides and tooltips on dropdown - this will truncat comment based on breakpoint size from Redux store */
  public truncateComment = (comment: string) => {
    let truncateLength = TruncateLengthMap.get('medium')
    if (this.props.breakpoint) {
      truncateLength = TruncateLengthMap.get(this.props.breakpoint)
    }
    if (truncateLength && comment.length > truncateLength) {
      if (comment.length > truncateLength) {
        return comment.substring(0, truncateLength) + "...";
      }
    }
    else {
      return comment
    }
  }

  public createCommentOptions = (projectComments: ProjectComments) => {
    let commentOptions = projectComments.map((comment) => {
      let productLabel = "Product"
      if (comment.productName && comment.productSubName) {
        productLabel = "Products"
      }
       return {
        label: (
          <div data-qa="project-comment-dropdown-entry">
              {comment.actionText && (<label data-qa="product-dropdown-comment-action">{comment.actionText}</label>)}
              {comment.comment && (<div className="product-dropdown--comment" data-qa="product-dropdown-comment"><label>Comment: </label>{this.truncateComment(comment.comment)}</div>)}
              {comment.reasonText && (<div className="product-dropdown--reason" data-qa="product-dropdown-reason"><label>Reason: </label>{comment.reasonText}</div>)}
              {comment.productName && (
              <div className="product-dropdown--reason" data-qa="product-dropdown-reason" style={{color: "gray"}}>
                <label>{productLabel}: </label>
                {comment.productName && (<span className="product-dropdown--product" >{comment.productName}</span>)}
                {comment.productSubName && (<span className="product-dropdown--sub-product" >{comment.productSubName}</span>)}
              </div>)}
          </div>
        ),
        value: comment.commentId
      };
    });
    return commentOptions;
  };

  public render() {
    const { selectedComment, placeholder, ...props } = this.props;
    const commentOptions = this.createCommentOptions(
      this.props.projectComments ? this.props.projectComments : []
    );

    return (
      <div className="project-comment-dropdown" data-qa="project-comment-dropdown">
        <Dropdown
          {...props}
          options={commentOptions}
          placeholder={"Select Recent Comment"}
          value={this.props.selectedComment}
        />
      </div>
    );
  }
}
