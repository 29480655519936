import uploadBlue from "assets/img/upload-blue.svg";
import loadingSvg from "assets/img/loading.svg";
import Base64Logo from "components/Base64Logo";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import CSVDownload from "components/CSVDownload";
import Dropzone from "components/Dropzone";
import Icon from "components/Icon";
import Table from "components/Table";
import Config from "config";
import { RouteProps } from "containers/App";
import CreateBreadcrumb from "containers/CreateBreadcrumb";
import FileUpload from "containers/FileUpload";
import ManageDataPreview from "containers/ManageDataPreview";
import React from "react";
import Popover from "components/DeletePopover";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { updateChain, deleteChain } from "state/chain";
import { fetchChains } from "state/chains";
import { buildExportFilename } from "utils/helpers";

type ManageChainsProps = PropsFromRedux & RouteProps;
class ManageChains extends React.Component<ManageChainsProps> {
  state = {
    tableData: [],
    chainId: 0,
    popoverOpenId: null,
    pendingChainDeletionId: null,
    errorMessage: null,
  };

  protected CSV_HEADERS = [
    { label: "Chain Name", key: "name" },
    { label: "Channel", key: "channel" },
    { label: "Feedback Limit", key: "feedbacklimit" },
    { label: "Key Contact 1", key: "keycontact1" },
    { label: "Key Contact 2", key: "keycontact2" },
    { label: "Key Contact 3", key: "keycontact3" },
    { label: "Chain Parent", key: "chain_parent" },
    { label: "Catalyst ID", key: "cid"}
  ];

  public onDrop = (files: any[], chainId: number) => {
    const [file] = files;
    if (file) {
      const data = new FormData();
      data.append("file", file);

      this.props.updateChain(chainId, data).then(() => {
        this.props.fetchChains();
      });
    }
  };

  public componentDidMount() {
    this.props.fetchChains();
  }

  public handleDelete = async (chainId: number) => {
    this.setState({pendingChainDeletionId: chainId});
    await this.props.deleteChain(chainId);

    if (this.props.chain.error) {
      this.setState({
        errorMessage: "Something went wrong and unable to delete chain and it's dependencies. Please try again.",
        pendingChainDeletionId: null,
      });
    } else {
      await this.props.fetchChains()
      const errorMessage = this.props.chains.error ?
        "Deletion succeeded. However, something went wrong when fetching new chain list." :
        null;
      this.setState({
        errorMessage,
        pendingChainDeletionId: null,
      });
    }
  }

  public render() {
    return (
      <section className="manage-data-container">
        <CreateBreadcrumb
          crumbs={{
            crumbs: [
              {
                to: "/manage-all-data",
                crumb: "VIEW ALL DATA",
                dataQa: "view-all-data-link",
              },
            ],
          }}
        />
        <div className="manage-data-body">
          <h1 className="header">Manage Master Chain Data</h1>
          <ul className="file-upload-list">
            <li className="file-upload-item">
              <FileUpload
                prefix={"CHAINS"}
                url={`${Config.API_SERVICE_URL}/api/chains`}
                label={"Upload New Chains"}
                dataQa={"manage-chains-file-upload"}
                afterUploadFunction={this.props.fetchChains}
              />
            </li>
            <CSVDownload
              data={this.props.chains.content}
              headers={this.CSV_HEADERS}
              filename={buildExportFilename("csv", "chains")}
            >
              <Button data-qa="export-all-data-btn">EXPORT ALL DATA</Button>
            </CSVDownload>
          </ul>
          <CardList>
            <Card>
              <ManageDataPreview prefix={"CHAINS"} />
            </Card>
          </CardList>
          {this.props.chain.error && <div className="error" >{this.state.errorMessage}</div>}
          <CardList>
            <Card>
              <Table
                data={this.props.chains.content as object[]}
                columns={[
                  {
                    field: "name",
                    header: "CHAIN",
                    dataQa: "chain_name",
                    minWidth: 100,
                    formatter: (value, row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Dropzone
                            className="chain-image-dropzone"
                            data-qa={`update-chain-image-btn-${value}`}
                            accept={"image/*"}
                            onDrop={(files: File[]) => this.onDrop(files, row.id)}
                          >
                            <Icon src={uploadBlue} />
                          </Dropzone>
                          <Base64Logo data={row.logo} data-qa={`${value}-logo`}  logo_filename={row.logo_filename}/>
                          {value && <span style={{ marginLeft: 10 }}>{value}</span>}
                        </div>
                      );
                    },
                  },
                  {
                    field: "channel",
                    header: "CHANNEL",
                    dataQa: "chain_channel",
                  },
                  {
                    field: "keycontact1",
                    header: "KEY CONTACT 1",
                    dataQa: "chain_keycontact1",
                  },
                  {
                    field: "keycontact2",
                    header: "KEY CONTACT 2",
                    dataQa: "chain_keycontact2",
                  },
                  {
                    field: "keycontact3",
                    header: "KEY CONTACT 3",
                    dataQa: "chain_keycontact3",
                  },
                  {
                    field: "feedbacklimit",
                    header: "FEEDBACK LIMIT",
                    dataQa: "chain_feedbacklimit",
                  },
                  {
                    field: "",
                    header: "",
                    sortable: false,
                    dataQa: "delete-chain",
                    maxWidth: 80,
                    formatter: (row: any, index: number) => {
                      return (this.state.pendingChainDeletionId === row.id) ?
                        <img className="spin-right" src={loadingSvg} alt="Loading Icon" /> :
                        <Popover
                          message={"Delete this chain"}
                          id={row.id}
                          index={index}
                          onConfirm={(id) => this.handleDelete(row.id)}
                          buttonStyles={{paddingLeft: 0, paddingRight: 15}}
                        />
                    },
                  },
                ]}
              />
            </Card>
          </CardList>
        </div>
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    chains: state.chains,
    chain: state.chain
  }),
  {
    fetchChains,
    updateChain,
    deleteChain
  }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ManageChains);
