import moment from "moment";
import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import Popover from "react-popover";

import editSvg from "assets/img/edit.svg";
import Button from "components/Button";
import Icon from "components/Icon";
import { Omit } from "types/omit";

interface DatePickerPopoverProps extends Omit<ReactDatePickerProps, "onChange"> {
  onSave: (date: moment.Moment) => any | void;
  defaultValue: string;
  timeInterval?: number;
  field?: boolean;
  fieldValue?: string;
  isMobile?: boolean;
  timeOnly?: boolean;
  dateFormat?: string;
}

interface DatePickerPopoverState {
  value?: moment.Moment;
  defaultValue: moment.Moment | null;
  isOpen: boolean;
}

export default class DatePickerPopover extends React.Component<
  DatePickerPopoverProps,
  DatePickerPopoverState
> {
  protected DEFAULT_TIME_INTERVAL = 5;
  protected DEFAULT_DATE_FORMAT = "MM/D/YYYY h:mm a";

  constructor(props: DatePickerPopoverProps) {
    super(props);
    this.state = {
      isOpen: false,
      defaultValue: this.props.defaultValue ? moment.utc(this.props.defaultValue) : null,
    };
  }

  public handleChange(date: moment.Moment) {
    this.setState({ value: date });
  }

  public handleSave(date: moment.Moment) {
    this.setState({ isOpen: false });
    this.props.onSave(date);
  }

  public handleMobileSave(date: moment.Moment) {
    this.setState({ value: date, isOpen: false });
    this.props.onSave(date);
  }

  public renderPopoverBody() {
    const { ...restProps } = this.props;

    return (
      <div className="datepicker-open-container">
        <DatePicker
          {...restProps}
          selected={this.state.value}
          onChange={(date) => date && this.handleChange(date)}
          showTimeSelect={true}
          className={"date-input centered"}
          timeFormat="h:mm a"
          timeIntervals={this.props.timeInterval || this.DEFAULT_TIME_INTERVAL}
          dateFormat="MM/D/YYYY h:mm a"
          timeCaption="time"
          data-qa={"open-date-picker"}
          placeholderText={
            this.state.defaultValue
              ? moment.utc(this.state.defaultValue).local().format("MM/D/YYYY h:mm a")
              : "mm/dd/yyyy"
          }
        />
        <div className="datepicker-button-group">
          <Button
            className="datepicker-cancel-button"
            secondary={true}
            onClick={() => {
              this.setState({
                isOpen: false,
                value: this.props.fieldValue ? moment(this.props.fieldValue) : undefined,
              });
            }}
            data-qa="datepicker-cancel-button"
          >
            Cancel
          </Button>
          <Button
            className="datepicker-save-button"
            onClick={() => this.state.value && this.handleSave(this.state.value)}
            data-qa="datepicker-save-button"
          >
            Save
          </Button>
        </div>
      </div>
    );
  }

  public renderMobileDatepicker() {
    const { ...restProps } = this.props;

    return (
      <div className="mobile-datepicker">
        <DatePicker
          {...restProps}
          selected={this.state.value}
          onChange={(date) => date && this.handleMobileSave(date)}
          withPortal={true}
          inline={true}
          showTimeSelect={this.props.timeOnly}
          showTimeSelectOnly={this.props.timeOnly}
          timeFormat="h:mm a"
          timeIntervals={this.props.timeInterval || this.DEFAULT_TIME_INTERVAL}
          dateFormat={this.props.dateFormat || this.DEFAULT_DATE_FORMAT}
          timeCaption="Time"
          onClickOutside={(e) => this.setState({ isOpen: false })}
        />
      </div>
    );
  }

  public render() {
    const { isMobile, disabled } = this.props;

    if (this.props.field) {
      if (isMobile) {
        const dateFormat = this.props.timeOnly
          ? "h:mm a"
          : this.props.dateFormat || this.DEFAULT_DATE_FORMAT;
        return (
          <div>
            <input
              className="date-picker-field"
              readOnly={true}
              value={
                this.props.fieldValue
                  ? moment.utc(this.props.fieldValue).local().format(dateFormat)
                  : "Select date"
              }
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            />
            {this.state.isOpen && this.renderMobileDatepicker()}
          </div>
        );
      } else {
        return (
          <Popover body={this.renderPopoverBody()} isOpen={this.state.isOpen}>
            <div>
              <input
                className="date-picker-field"
                readOnly={true}
                value={
                  this.props.fieldValue
                    ? moment.utc(this.props.fieldValue).local().format("MM/D/YYYY h:mm a")
                    : "Select date"
                }
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              />
            </div>
          </Popover>
        );
      }
    } else {
      return (
        <Popover body={this.renderPopoverBody()} isOpen={this.state.isOpen}>
          <div
            className="datepicker-closed-container"
            style={disabled ? { display: "none", cursor: "none" } : {}}
          >
            <button
              disabled={disabled}
              className="datepicker-open-button"
              data-qa="datepicker-open-popover"
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            >
              {!disabled && <Icon small={true} src={editSvg} />}
            </button>
          </div>
        </Popover>
      );
    }
  }
}
