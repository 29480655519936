import Announcement from "components/Announcement";
import { Card, CardList } from "components/Card";
import Page from "components/Page";
import Table from "components/Table";
import { RouteProps } from "containers/App";
import Constants from "helpers/constants";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "state";
import { fetchMyAnnouncements } from "state/announcements";
import { trackPageView, PAGEVIEW_EVENTS } from "state/userEvents";

type IMyAnnouncementsProps = PropsFromRedux & RouteProps;

class MyAnnouncements extends React.Component<IMyAnnouncementsProps> {
  public componentDidMount() {
    this.props.fetchMyAnnouncements();
    this.props.trackPageView({
      event_type: PAGEVIEW_EVENTS.ANNOUNCEMENTS_PAGEVIEW,
      user_id: this.props.user?.id,
      store_id: null,
      project_id: null,
      timestamp: Date.now(),
    });
  }

  public render() {
    const { announcements = [], isMobile } = this.props;

    return (
      <Page>
        <div className="manage-data-body">
          <h1>Announcements</h1>
          <CardList>
            <Card>
              <Table
                columnStyles={{ borderTop: "none" }}
                isMobile={isMobile}
                showEmptyMobileHeader={false}
                showHeader={false}
                data={announcements}
                defaultSort={false}
                columns={[
                  {
                    field: "",
                    header: "",
                    sortable: false,
                    dataQa: "announcement",
                    formatter: (row: any) => <Announcement {...row} />,
                  },
                ]}
                emptyState="No announcements found"
              />
            </Card>
          </CardList>
        </div>
      </Page>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    user: state.auth_user.content,
    announcements: state.announcements.content,
    isMobile: state.breakpoint.content === Constants.BREAKPOINT_MOBILE,
  }),
  { fetchMyAnnouncements, trackPageView }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(MyAnnouncements);
