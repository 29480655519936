import cx from "classnames";
import React from "react";

import whiteLeftArrow from "assets/img/arrow-left-white.svg";
import blueLeftArrow from "assets/img/arrow-left-blue.svg";
import grayRightArrow from "assets/img/arrow-right-gray.svg";
import Link from "components/Link";

interface BreadcrumbProps extends React.HTMLAttributes<{}> {
  theme?: "white" | "blue";
  crumbs?: Array<{ to: string; crumb: string; dataQa?: string }>;
}

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({
  crumbs = [],
  theme = "blue",
  className,
  ...restProps
}) => {
  if (!crumbs || (Array.isArray(crumbs) && crumbs.length === 0)) {
    return null;
  }

  const LeftArrow = () => (
    <img
      src={theme === "white" ? whiteLeftArrow : blueLeftArrow}
      alt={theme === "white" ? "white left arrow" : "blue left arrow"}
    />
  );
  return (
    <ul className={cx("breadcrumb__list", className)} {...restProps}>
      {crumbs.map(({ to, crumb, dataQa }, i) => {
        const first = i === 0;
        const last = i === crumbs.length - 1;

        return (
          <li key={to} className="breadcrumb__list-item">
            {first && last && <LeftArrow />}
            {!first && <img src={grayRightArrow} alt="" />}
            <Link to={to} data-qa={dataQa}>
              <span
                className={cx("h6", "breadcrumb__text", {
                  "breadcrumb__text--active": last,
                  "breadcrumb__text--white": theme === "white",
                })}
              >
                {crumb}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumb;
