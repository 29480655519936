import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";

export const UPDATE_VALIDATE_REQUEST = "COMPLETE_VALIDATE_REQUEST";
export const UPDATE_VALIDATE = "COMPLETE_VALIDATE";
export const UPDATE_VALIDATE_FAILED = "COMPLETE_VALIDATE_FAILED";

// Thunk Action Creator
export const updateValidation: ThunkActionCreator<Promise<void>> =
  (storeId: number, projectId: number) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_VALIDATE_REQUEST });
    return axios
      .patch(
        `${Config.API_SERVICE_URL}/api/projects/${projectId}/stores/${storeId}`,
        JSON.stringify({ validated: true }),
        getHeaders({ "Content-Type": "application/json" })
      )
      .then((response) => {
        dispatch({ type: UPDATE_VALIDATE, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: UPDATE_VALIDATE_FAILED });
      });
  };

const actions = {
  [UPDATE_VALIDATE_REQUEST]: (state: ValidateStoreState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_VALIDATE]: (state: ValidateStoreState, action: AnyAction): ValidateStoreState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [UPDATE_VALIDATE_FAILED]: (state: ValidateStoreState) => ({
    ...state,
    loading: false,
    error: true,
    content: undefined,
  }),
};

export interface ValidateStore {
  id: number;
  project_id: string | number;
  store_id: string | number;
  user_id: number | number;
  store_status: string;
}

export interface ValidateStoreState {
  loading?: boolean;
  error?: boolean;
  content?: ValidateStore;
}

// Initial State
const initialState: ValidateStoreState = {
  loading: false,
  error: false,
  content: undefined,
};

// Reducer
export default handleActions<ValidateStoreState, AnyAction>(actions, initialState);
