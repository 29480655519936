import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { FileQuery } from "interfaces/file";
import { ThunkActionCreator } from "interfaces/thunk";
import {DirFilesQueryParams} from "../interfaces/dir-file";
import {addQueryParamsToUrl} from "./dir-files";

// ACTION TYPE
const FETCH_ZIP_FOR_DOWNLOAD_REQUEST = "FETCH_ZIP_FOR_DOWNLOAD_REQUEST";
const FETCH_ZIP_FOR_DOWNLOAD = "FETCH_ZIP_FOR_DOWNLOAD";
const FETCH_ZIP_FOR_DOWNLOAD_FAILED = "FETCH_ZIP_FOR_DOWNLOAD_FAIL";
const IRIS_FILE_LIMIT = 250;

export { FETCH_ZIP_FOR_DOWNLOAD_REQUEST, FETCH_ZIP_FOR_DOWNLOAD, FETCH_ZIP_FOR_DOWNLOAD_FAILED, IRIS_FILE_LIMIT };

// Thunk Action Creator
export const fetchZipForFilesDownload: ThunkActionCreator<Promise<void>> =
  (project: string, chain: string, files: Array<Partial<FileQuery[]>>, inclusive: boolean = true) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_ZIP_FOR_DOWNLOAD_REQUEST,
    });

    const url = `${Config.API_SERVICE_URL}/api/batch-download/files/zip`;
    const headers = getHeaders({responseType: "blob"});
    const chunkSize = 250;
    const copiedFiles = [...files];
    let chunks = [];
    let promises: any [];

    if (files.length === 0) {
      const promise = axios.post(
        url,
        {chain, project, files, inclusive},
        headers
      );
      promises = [promise];
    } else {
      while (copiedFiles.length > 0) {
        const chunk = copiedFiles.splice(0, chunkSize);
        chunks.push(chunk);
      }

      promises = chunks.map(chunk => {
        return axios.post(
          url,
          {chain, project, files: chunk, inclusive},
          headers
        );
      });
    }

    return Promise.all(promises)
      .then(() => {
        dispatch({ type: FETCH_ZIP_FOR_DOWNLOAD, payload: new Blob(["Ok"]) });
      })
      .catch((error: Error) => {
        console.error("[Batch Download] Error: ", error);
        dispatch({ type: FETCH_ZIP_FOR_DOWNLOAD_FAILED });
      });
  };

export const fetchZipForIrisDownload: ThunkActionCreator<Promise<void>> =
  (queryParams: DirFilesQueryParams, selectedIds: { id: string }[], deselectedIds: { id: string }[], selectAll: boolean) =>
    (dispatch: Dispatch) => {
      dispatch({
        type: FETCH_ZIP_FOR_DOWNLOAD_REQUEST,
      });

      const url = `${Config.API_SERVICE_URL}/api/batch-download/iris-files/zip`;
      const headers = getHeaders({responseType: "blob"});

      const body = {
        selectedIds: selectedIds,
        deselectedIds: deselectedIds,
        selectAll: selectAll
      }
     return  axios.post(
        addQueryParamsToUrl(url, queryParams),
        body,
        headers
      ).then(() => {
          dispatch({ type: FETCH_ZIP_FOR_DOWNLOAD, payload: new Blob(["Ok"]) });
        })
        .catch((error: Error) => {
          console.error("[Batch Download] Error: ", error);
          dispatch({ type: FETCH_ZIP_FOR_DOWNLOAD_FAILED });
        });
    };

// Actions
const actions = {
  [FETCH_ZIP_FOR_DOWNLOAD_REQUEST]: (state: BatchDownloadState) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [FETCH_ZIP_FOR_DOWNLOAD]: (state: BatchDownloadState, action: AnyAction): BatchDownloadState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_ZIP_FOR_DOWNLOAD_FAILED]: (state: BatchDownloadState): BatchDownloadState => ({
    ...state,
    loading: false,
    error: true,
  }),
};

export interface BatchDownloadState {
  error?: boolean;
  loading?: boolean;
  content?: Blob;
}

const initialState: BatchDownloadState = {
  loading: false,
  error: false,
  content: undefined,
};

export default handleActions<BatchDownloadState, AnyAction>(actions, initialState);
