import cx from "classnames";
import transform from "lodash/transform";
import React from "react";

import Button from "components/Button";
import ButtonWithConfirmation, {DialogIcon} from "components/ButtonWithConfirmation";
import Dropdown from "components/Dropdown";
import ProductDropdown from "components/ProductDropdown";
import ProjectCommentDropdown from "components/ProjectCommentDropdown";
import { Tooltip } from "components/Tooltip";
import actionsReasons, { reasons, ActionReason, ActionID, ReasonID } from "helpers/actions-reasons";
import Constants from "helpers/constants";
import { ReactSelectObject } from "interfaces/select";
import { ProjectComments } from "interfaces/projectComment";

interface CommentFormProps {
  onSubmit: (e: React.FormEvent, data: CommentFormState, handleFormClear: () => void) => void;
  isDisabled: boolean;
  products?: ReactSelectObject[];
  projectComments?: ProjectComments;
  breakpoint?: string;
}

interface CommentFormState {
  action_id: string;
  comment: string;
  commentOverLength?: boolean;
  reason_id: string;
  reason: string;
  product_id?: string;
  product_name?: string;
  product_sub_id?: string;
  product_sub_name?: string;
  products_query?: string;
  productsList?: ReactSelectObject[];
  productsSearchResults?: ReactSelectObject[];
  showPlaceholder?: boolean;
  selected_comment?: string;
  disableClearFields?: boolean;
}

const defaultState = {
  action_id: "",
  reason_id: "",
  reason: "",
  product_id: "",
  product_name: "",
  product_sub_id: "",
  product_sub_name: "",
  comment: "",
  commentOverLength: false,
  productsSearchResults: [],
  showPlaceholder: true,
  selected_comment: "",
  disableClearFields: true,
};

const containerStyles = {
  width: "100%",
  background: "#ffffff",
  border: "1px solid #eeecea",
  textTransform: "none",
  fontWeight: "normal",
};

class CommentForm extends React.Component<CommentFormProps, CommentFormState> {
  private formRef: any;
  private MAX_COMMENT_LENGTH = 500;
  public constructor(props: CommentFormProps) {
    super(props);
    this.state = { ...defaultState };
  }
  public handleClear = () => {
    this.setState({ ...defaultState}, () => this.formRef.reset());
  };

  public handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const {
      action_id,
      comment,
      reason_id,
      product_id,
      product_sub_id,
      product_name,
      product_sub_name,
      reason,
    } = this.state;

    this.props.onSubmit(e, {
      action_id,
      comment,
      reason_id,
      product_id,
      product_sub_id,
      product_name,
      product_sub_name,
      reason,
      },
      this.handleClear //Parent will clear the form iff the post comment was a success
    );

    //The resetting of the FormState is handled by parent in CommentsUI.tsx
    //this.setState({ ...defaultState }, () => this.formRef.reset());


  };

  // event: any is used here because react-select doesn't seem
  // to have custom types.
  public handleChange = (event: any, action: any, name: string) => {
    if (name === "copy_recent_comment") {
      let comment = this.props.projectComments?.find(comment => {
        return comment.commentId === event.value
      })
      if (comment) {
        this.setState({
          action_id: `${comment.actionId}`,
          reason_id: `${comment.reasonId ? comment.reasonId : ""}`,
          product_id: `${comment.productId? comment.productId : ""}`,
          product_name: `${comment.productName ? comment.productName : ""}`,
          product_sub_id: `${comment.productSubId?  comment.productSubId : ""}`,
          product_sub_name: `${comment.productSubName ? comment.productSubName : ""}`,
          comment: `${comment.comment ? comment.comment: ""}`
        });
      }
    }

    if (name === "add_comment_action") {
      this.setState({
        action_id: event.value,
        reason_id: "",
        product_id: "",
        product_name: "",
        product_sub_id: "",
        product_sub_name: "",
        reason: ""
      });
    }

    if (name === "add_comment_reason") {
      this.setState({ reason_id: event.value, reason: event.label });
    }

    if (name === "add_comment_product") {
      this.setState({ product_id: event.value, product_name: event.label });
    }

    if (name === "add_comment_product_swap") {
      this.setState({
        product_sub_id: event.value,
        product_sub_name: event.label,
      });
    }

    if (this.state !== defaultState) {
      this.setState({ disableClearFields: false });
    }
  };

  public handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.currentTarget.id === "add_comment") {
      const value = event.currentTarget.value;
      this.setState({
        comment: value,
        commentOverLength: value.length > this.MAX_COMMENT_LENGTH,
      });
    }
  };

  public getActions = (): ReactSelectObject[] => {
    return transform(
      actionsReasons,
      (result: any[], value: ActionReason, key) => {
        result.push({
          label: value.action,
          value: key,
          "data-qa": value["data-qa"],
        });
        return result;
      },
      []
    );
  };

  public getReasons = (actionIndex: ActionID): ReactSelectObject[] => {
    return transform(
      actionsReasons[actionIndex].reasons,
      (result: any[], value) => {
        result.push({
          label: reasons[value],
          value: value,
        });
        return result;
      },
      []
    );
  };

  /* Required Fields
    Action          Comment	Product	Swap Product	Reason
    General         Y	      N 	    N	            N
    Add Product     N	      Y 	    N	            N
    Modify Product	Y	      Y 	    N	            N
    Remove Product	N	      Y 	    N	            N
    Swap Product    N       Y       Y             N/A

    Comments cannot be more than 500 chars. This is checked onChange and is stored in state.
 */

  public checkRequiredFields = () => {
    const { action_id, product_id, product_sub_name, comment, commentOverLength } = this.state;

    if (!action_id) {
      return "Choose an action.";
    }

    // handle comment length
    if (commentOverLength) {
      return "Comment is too long.";
    }

    // handle Comment column
    if (
      action_id === Constants.ACTION_GENERAL_COMMENT ||
      action_id === Constants.ACTION_MODIFY_PRODUCT
    ) {
      if (!comment) {
        return "Comment is required.";
      }
    }

    // handle Product column
    if (
      action_id === Constants.ACTION_ADD_PRODUCT ||
      action_id === Constants.ACTION_MODIFY_PRODUCT ||
      action_id === Constants.ACTION_REMOVE_PRODUCT ||
      action_id === Constants.ACTION_SWAP_PRODUCT
    ) {
      if (!product_id) {
        return "Product is required.";
      }
    }

    // handle Swap Product column
    if (action_id === Constants.ACTION_SWAP_PRODUCT) {
      if (!product_sub_name) {
        return "Substitute Product is required.";
      }
    }
  };

  public render() {

    const {
      action_id,
      product_id,
      product_name,
      product_sub_id,
      product_sub_name,
      reason_id,
      comment,
    } = this.state;

    const toolTip = this.checkRequiredFields() || "";
    const _actions = this.getActions();

    const _reasons = action_id ? this.getReasons(action_id as ActionID) : [];

    return (
      <form
        className="add-comment-form"
        name="add_comment_form"
        id="add_comment_form"
        onSubmit={this.handleOnSubmit}
        ref={(el) => (this.formRef = el)}
      >
        <div className="left-side">
        <ProjectCommentDropdown
            dataQa={"recentComments"}
            isDisabled={this.props.isDisabled}
            containerStyles={containerStyles}
            onChange={(e: any, action: any) => this.handleChange(e, action, "copy_recent_comment")}
            projectComments={this.props.projectComments ? this.props.projectComments : []}
            breakpoint={this.props.breakpoint || ""}
            selectedComment={this.state.selected_comment}
          />
          <Dropdown
            dataQa={"actions"}
            isDisabled={this.props.isDisabled}
            options={_actions}
            value={
              action_id && {
                label: actionsReasons[action_id as ActionID].action,
                value: action_id,
              }
            }
            onChange={(e, action) => this.handleChange(e, action, "add_comment_action")}
            placeholder={"Actions"}
            containerStyles={containerStyles}
            isSearchable
          />
          <ProductDropdown
            dataQa={"products"}
            isDisabled={!action_id}
            containerStyles={containerStyles}
            isSearchable={true}
            placeholder={Constants.COMMENT_FORM_PRODUCT_DROPDOWN_PLACEHOLDER}
            noOptionsMessage={() => Constants.COMMENT_FORM_PRODUCT_DROPDOWN_PLACEHOLDER}
            onChange={(e: any, action: any) => this.handleChange(e, action, "add_comment_product")}
            product_id={product_id}
            product_name={product_name}
            productsList={this.props.products}
          />
          {action_id === Constants.ACTION_SWAP_PRODUCT ? (
            <ProductDropdown
              dataQa={"product-substitution"}
              containerStyles={containerStyles}
              isSearchable={true}
              placeholder={Constants.COMMENT_FORM_SUB_PRODUCT_DROPDOWN_PLACEHOLDER}
              noOptionsMessage={() => Constants.COMMENT_FORM_SUB_PRODUCT_DROPDOWN_PLACEHOLDER}
              onChange={(e: any, action: any) =>
                this.handleChange(e, action, "add_comment_product_swap")
              }
              product_id={product_sub_id}
              product_name={product_sub_name}
              productsList={this.props.products}
            />
          ) : (
            <Dropdown
              dataQa={"reasons"}
              className="implementation-dropdown"
              options={_reasons}
              value={
                reason_id && reason_id !== 'null' && {
                  label: reasons[reason_id as ReasonID],
                  value: reason_id,
                }
              }
              onChange={(e, action) => this.handleChange(e, action, "add_comment_reason")}
              placeholder={"Reason"}
              isDisabled={_reasons.length === 0}
              containerStyles={containerStyles}
              isSearchable
            />
          )}
        </div>
        <div className="right-side">
          <textarea
            className="comment-text"
            id="add_comment"
            data-qa="comment-text"
            required={action_id === "1"}
            placeholder="Type comment here"
            onChange={this.handleTextChange}
            disabled={!this.state.action_id || this.props.isDisabled}
            value={comment}
          />
          <span
            data-qa="comment-char-count"
            className={cx("comment-char-count", {
              warn: this.state.commentOverLength,
            })}
          >
            {this.state.comment.length} / {this.MAX_COMMENT_LENGTH} characters
          </span>
          <div className="char-count-and-submit-group">
            <ButtonWithConfirmation
              onPrimaryAction={this.handleClear}
              disabled={this.state.disableClearFields}
              openDialogButtonContent="Clear Fields"
              primaryButtonContent="Clear"
              secondaryButtonContent="Back"
              primaryContent={() => <p>Are you sure you want to clear fields?</p>}
              secondaryContent={() =>
                <div className="items-center">
                  <p>This cannot be undone.</p>
                </div>
              }
              icon={DialogIcon.CAUTION}
              getIconProps={{ medium: true }}
            />
            <Button
              type="submit"
              data-qa="add-comment-button"
              hasTooltip={!!toolTip}
              disabled={this.props.isDisabled || !!toolTip}
              style={{ marginLeft: "1em" }}
            >
              <Tooltip message={toolTip} />
              Add Comment
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default CommentForm;
