import React from "react";
import { connect, ConnectedProps } from "react-redux";

import DonutChart from "components/DonutChart";
import LegendColorIndicator from "components/LegendColorIndicator";
import Loading from "components/Loading";
import Constants from "helpers/constants";
import { Project } from "interfaces/project";
import { State } from "state";
import { fetchResetStatusDashboard } from "state/reset_status_dashboard";

interface ResetStatusDashboardOwnProps {
  isMobile?: boolean;
  project: Project;
}

type ResetStatusDashboardProps = ResetStatusDashboardOwnProps & PropsFromRedux;

export const ALLOWED_ROLES = [
  Constants.AB_PROJECT_ADMIN,
  Constants.AB_COLLABORATOR,
  Constants.AB_MERCHANDISER,
  Constants.AB_REGION_CAT_DIRECTOR,
  Constants.RETAILER,
  Constants.AB_VIEWER,
  Constants.AB_WHOLESALER,
  Constants.COMPETITOR_WHOLESALER,
  Constants.COMPETITOR_CSM,
];

class ResetStatusDashboard extends React.Component<ResetStatusDashboardProps> {
  public componentDidMount() {
    this.updateChartData();
  }

  public componentDidUpdate(prevProps: ResetStatusDashboardProps) {
    const {
      project: { id },
    } = prevProps;

    if (id !== this.props.project.id) {
      this.updateChartData();
    }
  }

  public updateChartData() {
    if (this.props.project.id) {
      this.props.fetchResetStatusDashboard(this.props.project.id);
    }
  }

  public render() {
    const { isMobile } = this.props;

    const { data, labels, colors, total } = this.props.resetstatusdashboard;

    if (!data) {
      return <Loading />;
    }

    return (
      <div className="chart-wrapper" data-qa="reset-status-dashboard">
        <DonutChart
          isMobile={isMobile}
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor: colors,
              },
            ],
          }}
          total={total || 0}
        />
        <div className="legend">
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="yellow" />
              Reset Not Scheduled
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="orange" />
              Reset Scheduled
            </div>
          </div>
          <div className="legend-label">
            <div>
              <LegendColorIndicator color="green" />
              Reset Complete
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    resetstatusdashboard: state.resetstatusdashboard.content,
  }),
  { fetchResetStatusDashboard }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ResetStatusDashboard);
