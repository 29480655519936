import {datadogRum} from "@datadog/browser-rum";
import Config from "./config";

export const initializeDataDog = () => {
  datadogRum.init({
    applicationId: Config.DATA_DOG_APPLICATION_ID,
    clientToken: Config.DATA_DOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'BrewBox360',
    env: Config.ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
