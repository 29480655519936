import React from "react";
import { connect, ConnectedProps } from "react-redux";

import Dropdown from "components/Dropdown";
import Constants from "helpers/constants";
import { ProjectType } from "interfaces/project";
import { State } from "state";
import { fetchAllPublishedProjects, fetchMyProjects } from "state/my_projects";

export interface FilterOptions {
  id: number;
  name: string;
  type: string;
}

interface ProjectFilterOwnProps {
  name?: string;
  defaultOptionLabel?: string;
  exclude?: ProjectType;
  dataQa?: string;
  placeholder?: string;
  onChange: (options: FilterOptions) => any;
  noShadow?: boolean;
  minWidth?: number;
  minHeight?: number;
  value?: number;
  isSearchable?: boolean;
}
type ProjectFilterProps = ProjectFilterOwnProps & PropsFromRedux;

interface ProjectFilterState {
  currentId: number;
}

const DROPDOWN_PLACEHOLDER = "Select Project";
const defaultState: ProjectFilterState = { currentId: -999 };
class ProjectFilter extends React.Component<ProjectFilterProps, ProjectFilterState> {
  public state = { ...defaultState };

  public componentDidMount() {
    const { isAdmin } = this.props;

    return isAdmin ? this.props.fetchAllPublishedProjects() : this.props.fetchMyProjects();
  }

  public render() {
    const {
      projects = [],
      exclude,
      onChange,
      defaultOptionLabel = DROPDOWN_PLACEHOLDER,
      placeholder = DROPDOWN_PLACEHOLDER,
      isSearchable,
      ...restProps
    } = this.props;

    const dropdownOptions = [{ value: -1, label: defaultOptionLabel }].concat(
      projects
        .filter((proj) => proj.project_type !== exclude)
        .map((project: any) => {
          const chainProjectName = project.chain.name + " - " + project.project_name;

          return {
            "data-qa": chainProjectName,
            value: project.project_id,
            label: chainProjectName,
            type: project.project_type,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    );

    return (
      <Dropdown
        {...restProps}
        value={
          dropdownOptions.find((option) =>
            this.props.value
              ? option.value === this.props.value
              : option.value === this.state.currentId
          ) || (defaultOptionLabel ? dropdownOptions[0] : null)
        }
        containerStyles={{
          marginLeft: "unset",
          background: "white",
          minWidth: this.props.minWidth ? this.props.minWidth : 230,
        }}
        controlStyles={{
          minHeight: this.props.minHeight ? this.props.minHeight : 35,
        }}
        options={dropdownOptions}
        onChange={(selected: any) => {
          this.setState({ currentId: selected.value }, () => {
            if (onChange) {
              onChange({
                id: this.state.currentId,
                name: selected.label,
                type: selected.type,
              });
            }
          });
        }}
        placeholder={placeholder}
        isSearchable={isSearchable}
      />
    );
  }
}

const connecter = connect(
  (state: State) => ({
    isAdmin:
      state.auth_user.content &&
      state.auth_user.content.role &&
      state.auth_user.content.role === Constants.AB_SYSTEM_ADMIN,
    projects: state.my_projects.content,
  }),
  { fetchMyProjects, fetchAllPublishedProjects }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(ProjectFilter);
