import axios from "axios";
import { handleActions } from "redux-actions";

import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Chain } from "interfaces/chain";
import { Dispatch } from "interfaces/dispatch";
import { ThunkActionCreator } from "interfaces/thunk";
import { UPDATE_CHAIN } from "state/chain";

// Actions Types
const FETCH_CHAINS_REQUEST = "FETCH_CHAINS_REQUEST";
const FETCH_CHAINS = "FETCH_CHAINS";
const FETCH_CHAINS_FAILED = "FETCH_CHAINS_FAILED";

const FETCH_BASIC_INFO_CHAINS_REQUEST = "FETCH_BASIC_INFO_CHAINS_REQUEST";
const FETCH_BASIC_INFO_CHAINS = "FETCH_BASIC_INFO_CHAINS";
const FETCH_BASIC_INFO_CHAINS_FAILED = "FETCH_BASIC_INFO_CHAINS_FAILED";

const FETCH_MY_CHAINS_REQUEST = "FETCH_MY_CHAINS_REQUEST";
const FETCH_MY_CHAINS = "FETCH_MY_CHAINS";
const FETCH_MY_CHAINS_FAILED = "FETCH_MY_CHAINS_FAILED";

export {
  FETCH_CHAINS,
  FETCH_CHAINS_FAILED,
  FETCH_CHAINS_REQUEST,
  FETCH_BASIC_INFO_CHAINS,
  FETCH_BASIC_INFO_CHAINS_FAILED,
  FETCH_BASIC_INFO_CHAINS_REQUEST,
  FETCH_MY_CHAINS,
  FETCH_MY_CHAINS_FAILED,
  FETCH_MY_CHAINS_REQUEST,
};

// Thunk Action Creator
const fetchChains: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CHAINS_REQUEST });
  return axios
    .get(`${Config.API_SERVICE_URL}/api/chains`, getHeaders())
    .then((response) => dispatch({ type: FETCH_CHAINS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: FETCH_CHAINS_FAILED }));
};

// Thunk Action Creator
const fetchBasicInfoChains: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_BASIC_INFO_CHAINS_REQUEST });
  return axios
    .get(`${Config.API_SERVICE_URL}/api/chains/info`, getHeaders())
    .then((response) => dispatch({ type: FETCH_BASIC_INFO_CHAINS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: FETCH_BASIC_INFO_CHAINS_FAILED }));
};

const fetchMyChains: ThunkActionCreator = () => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_MY_CHAINS_REQUEST });

  return axios
    .get(`${Config.API_SERVICE_URL}/api/chains/me`, getHeaders())
    .then((response) => dispatch({ type: FETCH_MY_CHAINS, payload: response.data }))
    .catch((error: Error) => dispatch({ type: FETCH_MY_CHAINS_FAILED }));
};

export { fetchChains, fetchBasicInfoChains, fetchMyChains };

// Actions
const actions = {
  [FETCH_CHAINS_REQUEST]: (state: ChainsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_CHAINS]: (state: ChainsState, action: AnyAction): ChainsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_CHAINS_FAILED]: (state: ChainsState): ChainsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [FETCH_BASIC_INFO_CHAINS_REQUEST]: (state: ChainsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_BASIC_INFO_CHAINS]: (state: ChainsState, action: AnyAction): ChainsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_BASIC_INFO_CHAINS_FAILED]: (state: ChainsState): ChainsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  [FETCH_MY_CHAINS_REQUEST]: (state: ChainsState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_MY_CHAINS]: (state: ChainsState, action: AnyAction): ChainsState => ({
    ...state,
    loading: false,
    content: [...action.payload],
  }),
  [FETCH_MY_CHAINS_FAILED]: (state: ChainsState): ChainsState => ({
    ...state,
    loading: false,
    error: true,
    content: [],
  }),
  // replace existing chain to avoid re-fetching
  [UPDATE_CHAIN]: (state: ChainsState, action: AnyAction): ChainsState => {
    const updatedChain = action.payload;
    return {
      ...state,
      loading: false,
      content: (state.content || []).map((c) => (c.id === updatedChain.id ? updatedChain : c)),
    };
  },
};

// Reducer Interface
export interface ChainsState {
  error?: boolean;
  loading?: boolean;
  content?: Chain[];
}

// Initial State
const initialState: ChainsState = {
  loading: false,
  error: false,
  content: [],
};

// reducer
export default handleActions<ChainsState, AnyAction>(actions, initialState);
