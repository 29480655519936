// environment is injected into the container at runtime in production via window.ENV
let windowEnv: NodeJS.WindowENV = {};
if (!process.env.NODE_ENV || process.env.NODE_ENV !== "test") {
  try {
    windowEnv = JSON.parse((window as any).ENV);
  } catch (e) {
    windowEnv = {};
  }
}

const get = (key: string) => process.env[key] || windowEnv[key] || "";

const getArray = (key: string, defaultValue: string[] = [], delimiter: string = ","): string[] => {
  const value = (process.env && process.env[key]) || (windowEnv && windowEnv[key]);
  if (value && typeof value === "string") {
    return value.split(delimiter).map((v) => v.trim());
  }
  return defaultValue;
};

export const hasFeature = (features: string[] = [], key: string) => features.some((f) => f === key);

const config = {
  AZURE_AD_TENANT: get("REACT_APP_AZURE_AD_TENANT"),
  AZURE_AD_TENANT_NAME: get("REACT_APP_AZURE_AD_TENANT_NAME"),
  AZURE_AD_APPLICATIONID: get("REACT_APP_AZURE_AD_APPLICATIONID"),
  AZURE_AD_CLIENTID: get("REACT_APP_AZURE_AD_CLIENTID"),
  AZURE_AD_USERFLOW_LOGIN: get("REACT_APP_AZURE_AD_USERFLOW_LOGIN"),
  AZURE_AD_USERFLOW_PASSWORD_RESET: get("REACT_APP_AZURE_AD_USERFLOW_PASSWORD_RESET"),
  API_SERVICE_URL: get("REACT_APP_API_SERVICE_URL"),
  DATA_DOG_CLIENT_TOKEN: get("REACT_APP_DD_CLIENT_TOKEN"),
  DATA_DOG_APPLICATION_ID: get("REACT_APP_DD_APPLICATION_ID"),
  API_MOCK_SERVICE_URL: get("REACT_APP_API_MOCK_SERVICE_URL"),
  SERVICE_URL: get("REACT_APP_SERVICE_URL"),
  ENV: get("REACT_APP_ENV"),
  FEATURES: getArray("REACT_APP_FEATURES"),
  GOOGLE_MAPS_API_KEY: get("REACT_APP_GOOGLE_MAPS_API_KEY"),
  GOOGLE_TAG_MANAGER_ID: get("REACT_APP_GOOGLE_TAG_MANAGER_ID"),
  FILE_UPLOAD_SIZE_LIMIT: get("REACT_APP_FILE_UPLOAD_SIZE_LIMIT") || "100000000",
  MIXPANEL_TOKEN: get("REACT_APP_MIXPANEL_TOKEN"),
  IDLE_TIMEOUT: get("REACT_APP_IDLE_TIMEOUT") || "86400000",
};

export default config;
