import { handleActions } from "redux-actions";

import { AnyAction } from "interfaces/action";

// Actions Types
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const UNSET_BREADCRUMB = "UNSET_BREADCRUMB";

// Action Creator
const setBreadcrumb = (data: BreadcrumbState) => ({
  type: SET_BREADCRUMB,
  payload: data,
});

const unsetBreadcrumb = () => ({
  type: UNSET_BREADCRUMB,
  payload: {},
});

export { setBreadcrumb, unsetBreadcrumb };

// Actions
const actions = {
  [SET_BREADCRUMB]: (state: BreadcrumbState, action: AnyAction): BreadcrumbState => ({
    ...action.payload,
  }),
  [UNSET_BREADCRUMB]: () => ({ ...initialState }),
};

// Reducer Interface
export interface BreadcrumbState {
  crumbs?: Array<{ to: string; crumb: string; dataQa?: string }>;
}

// Initial State
const initialState: BreadcrumbState = {};

// Reducer
export default handleActions<BreadcrumbState, AnyAction>(actions, initialState);
