import { RouteProps } from "containers/App";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { logout } from "state/auth";
import { removeItem } from "helpers/storage";
import Constants from "helpers/constants";

type LogoutProps = PropsFromRedux & RouteProps;
interface LogoutState {
  loggedOut: boolean;
}

class Logout extends React.Component<LogoutProps, LogoutState> {
  public state = { loggedOut: false };

  public componentDidMount() {
    removeItem(Constants.IRIS_DOWNLOADS_QUERY);
    removeItem(Constants.MY_STORES_QUERY);
    this.props.logout();
  }

  public render() {
    return null;
  }
}

const connecter = connect(null, { logout });

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(Logout);
