import cx from "classnames";
import React from "react";

import blueLeftArrowSvg from "assets/img/arrow-left-blue.svg";
import downloadSvg from "assets/img/download.svg";
import pdfSvg from "assets/img/pdf.svg";
import psaSvg from "assets/img/psa.svg";
import defaultFileSvg from "assets/img/default-file.svg";
import viewSvg from "assets/img/view.svg";
import { Card } from "components/Card";
import Icon from "components/Icon";
import PDF from "components/PDF";
import { getPillProps, Pill } from "components/Pill";
import Table from "components/Table";
import Constants from "helpers/constants";
import { File } from "interfaces/file";
import { isIe } from "utils/helpers";
import moment from "moment";

interface AttachmentsProps {
  attachments?: File[];
  onDownloadOrView: (action: "View" | "Download", file: File) => void;
}

interface AttachmentsState {
  isViewing: boolean;
  blobUrl?: string;
}

class Attachments extends React.Component<AttachmentsProps, AttachmentsState> {
  public state = {
    isViewing: false,
    blobUrl: "",
  };

  public forceReflow = () => {
    // Force a reflow on IE because the attachment column shrinks when
    // switching to the viewer.

    const attachmentContainer = document.getElementsByClassName(
      "project-store-details-body"
    )[0] as any;

    const attachmentContainerItem = attachmentContainer.getElementsByClassName(
      "card-list-item"
    )[0] as any;

    if (attachmentContainer && this.state.isViewing) {
      attachmentContainer.style.display = "inline-block";
      setTimeout(() => {
        attachmentContainer.style.display = "";
      }, 1);
    } else if (!this.state.isViewing) {
      attachmentContainerItem.style.display = "block";
      attachmentContainerItem.style.width = "100%";
      setTimeout(() => {
        attachmentContainerItem.style.display = "";
        attachmentContainerItem.style.width = "";
      }, 1);
    }
  };

  public toggleIsViewing = (blobUrl?: string) => {
    this.setState({ isViewing: !this.state.isViewing, blobUrl }, () => {
      if (isIe) {
        this.forceReflow();
      }
    });
  };

  public handleViewClick = (e: React.MouseEvent<HTMLButtonElement>, file: File) => {
    this.props.onDownloadOrView("View", file);
    this.toggleIsViewing(file.blob_url);
  };

  public handleDownloadClick = (e: React.MouseEvent<HTMLButtonElement>, file: File) => {
    this.props.onDownloadOrView("Download", file);
    return window.open(file.blob_url);
  };

  public renderAttachments() {
    const { isViewing, blobUrl } = this.state;
    const { attachments } = this.props;

    if (isViewing) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="attachments-back-button-container">
            <button
              className="attachments-back-button"
              onClick={() => this.toggleIsViewing()}
              data-qa="view-all-attachments"
            >
              <Icon src={blueLeftArrowSvg} small={true} />
              <h6>View All Attachments</h6>
            </button>
          </div>
          <PDF file={blobUrl} />
        </div>
      );
    }

    return (
      <Table
        data={attachments as object[]}
        headerClassName={"attachments-table-header"}
        columns={[
          {
            field: "blobUrl",
            header: "",
            sortable: false,
            formatter: (value: string, row: File) => {
              const pill = getPillProps(
                row.status ? row.status.toUpperCase() : Constants.NEEDS_VALIDATION
              );
              const isPDF = row.extension && row.extension.toLowerCase() === "pdf";
              const isPSA = row.extension && row.extension.toLowerCase() === "psa";
              return (
                <ul className="attachments-list" data-qa="attachment">
                  <li className="attachments-list-item">
                    <img
                      className="attachments-image"
                      src={isPDF ? pdfSvg : isPSA ? psaSvg : defaultFileSvg}
                      alt={
                        isPDF
                          ? Constants.ATTACHMENTS_PDF_LABEL
                          : isPSA
                          ? Constants.ATTACHMENTS_PSA_LABEL
                          : `${row.extension} File Extension`
                      }
                    />
                  </li>
                  <li className="attachments-list-item">
                    <div className="attachments-data-container">
                      <div><Pill className="attachments-pill" {...pill} />
                      {row.name}
                      </div>
                      <div className="attachments-file-name" data-qa="file-name">
                        <div className="attachments-details" data-qa="attachment-details">
                        {row.updated_at &&(<span data-qa="attachment-uploaded-date">Uploaded on {moment(row.updated_at).format("M/D/YYYY h:mm A")}</span>)}
                        {row.user_name && (<span data-qa="attachment-file-uploaded-by"> by  {row.user_name}</span>)}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="attachments-list-item">
                    <ul className="attachments-icon-list">
                      <li>
                        <button
                          className={cx("view-button", { hidden: !isPDF })}
                          onClick={(e) => this.handleViewClick(e, row)}
                          data-qa={"view-button"}
                        >
                          <Icon src={viewSvg} />
                        </button>
                      </li>
                      <li>
                        <button
                          className="download-button"
                          onClick={(e) => this.handleDownloadClick(e, row)}
                          data-qa={"download-button"}
                        >
                          <Icon src={downloadSvg} />
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              );
            },
          },
        ]}
        emptyState="No attachments found"
      />
    );
  }

  public render() {
    return (
      <Card>
        <Card.Header>
          <h3>Attachments</h3>
        </Card.Header>
        {this.renderAttachments()}
      </Card>
    );
  }
}

export default Attachments;
