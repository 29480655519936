import axios from "axios";
import { handleActions } from "redux-actions";
import { PaginatedQuery, PaginatedResponse } from "interfaces/paginated-query";
import Config from "config";
import { getHeaders } from "helpers/headers";
import { AnyAction } from "interfaces/action";
import { Dispatch } from "interfaces/dispatch";
import { FetchAllStoresOrFilesQueryOptions } from "interfaces/filterQuery";
import { Store } from "interfaces/store";
import { ThunkActionCreator } from "interfaces/thunk";
import { CREATE_PROJECT_REQUEST } from "state/create_project";
import queryString from "query-string";

// Actions Types
const FETCH_STORES_BY_PROJECT_REQUEST = "FETCH_STORES_BY_PROJECT_REQUEST";
const FETCH_STORES_BY_PROJECT = "FETCH_STORES_BY_PROJECT";
const FETCH_STORES_BY_PROJECT_FAILED = "FETCH_STORES_BY_PROJECT_FAILED";

const FETCH_STORES_REQUEST = "FETCH_STORES_REQUEST";
const FETCH_STORES = "FETCH_STORES";
const FETCH_STORES_FAILED = "FETCH_STORES_FAILED";

const FETCH_USER_STORES_REQUEST = "FETCH_USER_STORES_REQUEST";
const FETCH_USER_STORES = "FETCH_USER_STORES";
const FETCH_USER_STORES_FAILED = "FETCH_USER_STORES_FAILED";

const DELETE_STORE_REQUEST = "DELETE_STORES_REQUEST";
const DELETE_STORE = "DELETE_STORES";
const DELETE_STORE_FAILED = "DELETE_STORES_FAILED";

export {
  FETCH_STORES_BY_PROJECT,
  FETCH_STORES_BY_PROJECT_FAILED,
  FETCH_STORES_BY_PROJECT_REQUEST,
  FETCH_STORES,
  FETCH_STORES_REQUEST,
  FETCH_STORES_FAILED,
  FETCH_USER_STORES,
  FETCH_USER_STORES_REQUEST,
  FETCH_USER_STORES_FAILED,
  DELETE_STORE_REQUEST,
  DELETE_STORE,
  DELETE_STORE_FAILED,
};

// Thunk Action Creator
const fetchStoresByProjectId: ThunkActionCreator =
  (projectId: number, queryOptions: PaginatedQuery = { size: 100000000, page: 0, includeAggregate: false }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: FETCH_STORES_BY_PROJECT_REQUEST });

    // TODO: Update default queryOptions once the API endpoint supports requesting all records
    const url = `${Config.API_SERVICE_URL}/api/projects/${projectId}/stores?${queryString.stringify(
      queryOptions
    )}`;

    return axios
      .get(url, getHeaders())
      .then((response) => dispatch({ type: FETCH_STORES_BY_PROJECT, payload: response.data }))
      .catch((error: Error) => dispatch({ type: FETCH_STORES_BY_PROJECT_FAILED }));
  };

const fetchStores: ThunkActionCreator<Promise<void>> =
  (queryOptions: PaginatedQuery = { size: 100000000, page: 0 }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: FETCH_STORES_REQUEST });

    // TODO: Update default queryOptions once the API endpoint supports requesting all records
    const url = `${Config.API_SERVICE_URL}/api/stores?${queryString.stringify(queryOptions)}`;

    return axios
      .get(url, getHeaders())
      .then((response) => {
        dispatch({ type: FETCH_STORES, payload: response.data });
      })
      .catch((error: Error) => {
        dispatch({ type: FETCH_STORES_FAILED });
      });
  };

const fetchUserStores: ThunkActionCreator<Promise<void>> =
  (queryOptions?: FetchAllStoresOrFilesQueryOptions) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_USER_STORES_REQUEST });

    let url = `${Config.API_SERVICE_URL}/api/stores/me`;

    if (queryOptions && Object.keys(queryOptions).length > 0) {
      url = `${url}?${queryString.stringify(queryOptions)}`;
    }

    return axios
      .get(url, getHeaders())
      .then((response) => {        
        dispatch({ type: FETCH_USER_STORES, payload: response.data });
      })
      .catch((_) => {
        dispatch({ type: FETCH_USER_STORES_FAILED });
      });
  };

export const deleteStoreById: ThunkActionCreator<Promise<void>> = (id) => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_STORE_REQUEST });
  return axios
    .delete(`${Config.API_SERVICE_URL}/api/stores/${id}`, getHeaders())
    .then((_) => {
      dispatch({ type: DELETE_STORE });
    })
    .catch((error: Error) => {
      dispatch({ type: DELETE_STORE_FAILED });
    });
};

// Actions
const actions = {
  [CREATE_PROJECT_REQUEST]: () => ({ ...initialState }),
  [FETCH_STORES_BY_PROJECT_REQUEST]: (state: StoresState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_STORES_BY_PROJECT]: (state: StoresState, action: AnyAction): StoresState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_STORES_BY_PROJECT_FAILED]: (state: StoresState, action: AnyAction): StoresState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
  [FETCH_STORES_REQUEST]: (state: StoresState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_STORES]: (state: StoresState, action: AnyAction): StoresState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_STORES_FAILED]: (state: StoresState): StoresState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
  [FETCH_USER_STORES_REQUEST]: (state: StoresState): StoresState => ({
    ...state,
    loading: true,
    error: false,
    content: initialState.content,
  }),
  [FETCH_USER_STORES]: (state: StoresState, action: AnyAction): StoresState => ({
    ...state,
    loading: false,
    content: action.payload,
  }),
  [FETCH_USER_STORES_FAILED]: (state: StoresState): StoresState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
  [DELETE_STORE_REQUEST]: (state: StoresState): StoresState => ({
    ...state,
    loading: true,
  }),
  [DELETE_STORE]: (state: StoresState): StoresState => ({
    ...state,
    loading: false,
    content: initialState.content,
  }),
  [DELETE_STORE_FAILED]: (state: StoresState): StoresState => ({
    ...state,
    loading: false,
    error: true,
    content: initialState.content,
  }),
};

// Reducer Interface
export interface StoresState {
  error: boolean;
  loading: boolean;
  content: PaginatedResponse<Store>;
}

// Initial State
const initialState: StoresState = {
  loading: false,
  error: false,
  content: { data: [] },
};

export { fetchStoresByProjectId, fetchStores, fetchUserStores, initialState };

// Reducer
export default handleActions<StoresState, AnyAction>(actions, initialState);
