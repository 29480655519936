import React from "react";

import { Store } from "interfaces/store";
import { Column } from "react-table-latest";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import cx from "classnames";

import { fetchStoresByProjectId } from "state/stores";
import { fetchProject } from "state/project";
import { State } from "state";
import { PaginatedQuery } from "interfaces/paginated-query";
import DataTable, { parseSortParam } from "components/DataTable";
import { Store2Project } from "interfaces/store2project";
import { kebabCase } from "lodash";
interface CopyCommentsTableOwnProps {
  projectId: number | string;
  feedback_limit: number;
  setSelectedData: any;
  setTotalRecordCount: any;
  setSelectedCount: any;
  store: Store;
}

type CopyCommentsTableProps = CopyCommentsTableOwnProps & PropsFromRedux;

class CopyCommentsTable extends React.Component<CopyCommentsTableProps> {
  //const {feedback_limit} = this.props;
  private COPY_COMMENTS_DEFAULT_PAGE_SIZE = 5;
  //See componentDidMount() for the default sorting parameter
  private COPY_COMMENTS_DEFAULT_SORT = "store_number.asc";

  private defaultPaginationParams: PaginatedQuery = {
    sort: this.COPY_COMMENTS_DEFAULT_SORT,
    size: this.COPY_COMMENTS_DEFAULT_PAGE_SIZE,
    page: 0,
    includeAggregate: true
  };

  private defaultSelectionState = {
    isSelectionInclusive: false,
    deselectedIds: [],
    selectedIds: [],
    selectionTotalCount: 0,
    selectedStoreCount: 0
  };

  public state = { ...this.defaultPaginationParams, initialMountComplete: false, ...this.defaultSelectionState, storeId: this.props.store.store_id };

  /*  Filter out current from store list and disable checkbox if store is unable to receive new comments due to feedback limit being met */
  private convertStoreCheckboxes = (stores: any[]) => {
    const modifiedStores: any[] = stores
    .map((store) => {
      if (this.props.store.store_id === store.store_id) {
        store.disableCheckboxSelector = true;
      }
      else if (this.props.feedback_limit !== null) {
      store.disableCheckboxSelector = store.comment_cnt >= this.props.feedback_limit;
      }
      return store;
    });
    return modifiedStores;
  };

  componentDidMount() {
    window.addEventListener("focus", this.onFocus);
    const { projectId } = this.props;
    return this.props
      .fetchProject(projectId)
      .then(() => {
        this.fetchStores();
      })
      .then(() => {
        //Delays initial render of Stores DataTable until after redux store is properly updated
        //Prevents initialization of DataTable's initalState to stale data from previous pages
        this.setState({ ...this.state, initialMountComplete: true });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = () => {
    this.fetchStores();
  };

  componentDidUpdate(_prevProps: CopyCommentsTableProps, prevState: PaginatedQuery) {
    if (
      prevState.page !== this.state.page ||
      prevState.sort !== this.state.sort ||
      prevState.size !== this.state.size
    ) {
      this.fetchStores();
    }
  }

  updateStoreData() {
    const { setSelectedCount, setSelectedData } = this.props;
    setSelectedCount(this.state.selectedStoreCount);
    setSelectedData(this.state)
  }

  fetchStores() {
    const { projectId } = this.props;
    this.props.fetchStoresByProjectId(projectId, this.state);
  }

  public render() {

    const { stores, loadingStores, storesPageCount, storesPageIndex, feedback_limit, projectId, aggregateStores, setTotalRecordCount } =
      this.props;

    let totalStoreCount = 0;
    let aggregateStoreIds = [];

    if (aggregateStores) {
      totalStoreCount = aggregateStores.length;
      aggregateStoreIds = aggregateStores.map((s:any) => s.store_id)
      setTotalRecordCount(totalStoreCount)
    }

    if (!stores) {
      return null;
    }

    const tableColumnsConfig = [
      {
        Header: "Store No.",
        accessor: "store_number",
        dataQa: "store-no",
        width: 250,
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        dataQa: "store-name",
        width: 250,
        Cell: ({ value, row }) => {
          return (
            <Link to={`/projects/${projectId}/stores/${row.original.store_id}`} target="_blank">
              {value}
            </Link>
          );
        },
      },
      {
        Header: "Address",
        accessor: "store_address",
        dataQa: "store-address",
        width: 250,
      },
      {
        Header: "Comments",
        accessor: "comment_cnt",
        dataQa: "comment_cnt",
        Cell: (row: { value: any }) => {
          const limit = feedback_limit ? feedback_limit : "Unlimited";
          const value = row.value + " / " + limit;
          return value;
        },
      },
    ] as (Column<Store & Partial<Store2Project>> & { dataQa: string; show: boolean })[];

    return (
      <>
        {this.state.initialMountComplete && this.state.sort && (
          <DataTable
            isLoading={loadingStores}
            data={this.convertStoreCheckboxes(stores)}
            renderEmpty={"No stores"}
            columns={tableColumnsConfig}
            getRowId={(row) => String(row.store_id)}
            sortable
            paginated
            manualPagination
            rowSelectable
            pageCount={storesPageCount}
            selectionIds={aggregateStoreIds}
            initialState={{
              pageSize: this.COPY_COMMENTS_DEFAULT_PAGE_SIZE,
              pageIndex: storesPageIndex,
              sortBy: parseSortParam(this.state.sort),
              hiddenColumns: tableColumnsConfig
                .filter((col: any) => col.show === false)
                .map((col) => col.id || col.accessor) as any,
            }}
            onPageChange={(page: number, size: number, sort: string) => {
              if (
                page !== this.state.page ||
                size !== this.state.size ||
                sort !== this.state.sort
              ) {
                this.setState({ ...this.state, size: size, sort: sort, page: page });
              }
            }}
            onRowSelect={({ isInclusive, selectedRowIds, deselectedRowIds, action }) => {
              switch (action.type) {
                case "selectAll":
                  let updatedCount;
                  if (feedback_limit !== null) {
                    updatedCount = aggregateStores.filter((store: any) => {
                      return store.comment_cnt < feedback_limit && store.store_id !== this.props.store.store_id
                    }).length
                    this.setState({ selectedStoreCount: updatedCount }, () => this.updateStoreData() )
                  }
                  else {
                    updatedCount = aggregateStores.filter((store: any) => {
                      return store.store_id !== this.props.store.store_id
                    }).length
                    this.setState({ selectedStoreCount: updatedCount }, () => this.updateStoreData() );
                  }
                  //isInclusive is false
                  break;

                case "deselectAll":
                  isInclusive = false;
                  this.setState({ selectedStoreCount: 0 }, () => this.updateStoreData());
                  break;

                case "select":
                  this.setState({ selectedStoreCount: this.state.selectedStoreCount + 1 }, () => this.updateStoreData());
                  break;

                case "deselect":
                  this.setState({ selectedStoreCount: this.state.selectedStoreCount - 1 }, () => this.updateStoreData());
                  break;

                default:
                  break;
              }

              this.setState(
                {
                  isSelectionInclusive: isInclusive,
                  selectedIds: selectedRowIds,
                  deselectedIds: deselectedRowIds
                }
              );
            }}
            getHeaderProps={(baseProps, { column }) => {
              return {
                ...baseProps,
                className: cx(`table-header-${kebabCase(column.id)}`, {}),
              };
            }}
            getCellProps={(baseProps, { cell }) => {
                return {
                  ...baseProps,
                  className: cx(`table-cell-${kebabCase(cell.column.id)}`, {}),
                };
            }}
            selectAllRowFilter={(row: any) => {
              if (this.props.store.store_id === row.original.store_id) {
                return false;
              }
              else if (feedback_limit === null ) {
                return true;
              } else {
                return row.original.comment_cnt < feedback_limit;
              }
            }}
          />
        )}
      </>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    stores: state.stores.content?.data,
    storesPageCount: state.stores.content?.pageCount,
    storesPageIndex: state.stores.content?.pageIndex,
    loadingStores: state.stores.loading,
    aggregateStores: state.stores.content?.aggregateResponse,
  }),
  { fetchStoresByProjectId, fetchProject }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(CopyCommentsTable);
