import navAnnouncementsActive from "assets/img/announcements-active.svg";
import navAnnouncements from "assets/img/announcements-inactive.svg";
import navCommentDataActive from "assets/img/comment-data-active.svg";
import navCommentData from "assets/img/comment-data-inactive.svg";
import navDownloadActive from "assets/img/downloads-active.svg";
import navDownload from "assets/img/downloads-inactive.svg";
import navDirDownloadsActive from "assets/img/folder-active.svg";
import navDirDownloadsInactive from "assets/img/folder-inactive.svg";
import navChainDataActive from "assets/img/folder-active.svg";
import navChainData from "assets/img/folder-inactive.svg";
import navSupportInactive from "assets/img/help-gray.svg";
import navSupportActive from "assets/img/help-white.svg";
import navLogoutActive from "assets/img/logout-active.svg";
import navLogout from "assets/img/logout-inactive.svg";
import navNearbyStoresActive from "assets/img/map-active.svg";
import navNearbyStoresInactive from "assets/img/map-inactive.svg";
import navMyFeedbackActive from "assets/img/my-feedback-active.svg";
import navMyFeedback from "assets/img/my-feedback-inactive.svg";
import navSurveyActive from "assets/img/my-surveys-active.svg";
import navSurvey from "assets/img/my-surveys-inactive.svg";
import navProjectsActive from "assets/img/my-work-active.svg";
import navProjects from "assets/img/my-work-inactive.svg";
import navResetValidationActive from "assets/img/reset-validation-active.svg";
import navResetValidation from "assets/img/reset-validation-inactive.svg";
import navReviewFeedbackActive from "assets/img/review-feedback-active.svg";
import navReviewFeedback from "assets/img/review-feedback-inactive.svg";
import navSearchActive from "assets/img/search-active.svg";
import navSearch from "assets/img/search-inactive.svg";
import navResetStatusActive from "assets/img/store-reset-active.svg";
import navResetStatus from "assets/img/store-reset-inactive.svg";
import navUploadAttachmentsInactive from "assets/img/upload-inactive.svg";
import navUploadAttachments from "assets/img/upload-nav-active.svg";
import navUserMappingActive from "assets/img/user-mapping-active.svg";
import navUserMapping from "assets/img/user-mapping-inactive.svg";
import navValidationActive from "assets/img/validation-completion-active.svg";
import navValidation from "assets/img/validation-completion-inactive.svg";
import starInActive from "assets/img/star-inactive.svg";
import starActive from "assets/img/star-white.svg";
import Config, { hasFeature } from "config";
import Constants from "helpers/constants";

export const MY_STORES = {
  title: "My Stores",
  route: "/stores/me",
  qa: "navigation-link-my-stores",
  inactiveImage: navSearch,
  activeImage: navSearchActive,
};

export const ADMIN_MY_WORK = {
  title: "My Work",
  route: "/projects",
  subroutes: [/^\/create-project/],
  qa: "navigation-link-projects",
  inactiveImage: navProjects,
  activeImage: navProjectsActive,
  landingPageFor: [Constants.AB_SYSTEM_ADMIN],
};

export const MY_WORK = {
  title: "My Work",
  route: "/projects/me",
  subroutes: [/^\/projects/],
  qa: "navigation-link-mywork",
  inactiveImage: navProjects,
  activeImage: navProjectsActive,
  landingPageFor: [
    Constants.AB_COLLABORATOR,
    Constants.AB_MERCHANDISER,
    Constants.AB_PROJECT_ADMIN,
    Constants.AB_REGION_CAT_DIRECTOR,
    Constants.AB_VIEWER,
    Constants.AB_WHOLESALER,
    Constants.COMPETITOR_CSM,
    Constants.COMPETITOR_WHOLESALER,
    Constants.RETAILER,
    Constants.VIEWER,
  ],
};

export const STARRED = {
  title: "Starred",
  route: "/starred",
  qa: "navigation-link-homepage",
  inactiveImage: starInActive,
  activeImage: starActive,
};

export const ADMIN_ANNOUNCEMENTS = {
  title: "Announcements",
  route: "/announcements",
  qa: "navigation-link-announcements",
  inactiveImage: navAnnouncements,
  activeImage: navAnnouncementsActive,
};

export const MY_ANNOUNCEMENTS = {
  title: "Announcements",
  route: "/announcements/me",
  qa: "navigation-link-announcements",
  inactiveImage: navAnnouncements,
  activeImage: navAnnouncementsActive,
};

export const VIEW_ALL_ADMIN_DATA = {
  title: "Manage Data",
  route: "/manage-all-data",
  qa: "navigation-link-view-all",
  inactiveImage: navChainData,
  activeImage: navChainDataActive,
};

export const VALIDATION_COMPLETION = {
  title: "Validation Completion",
  route: "/validation-completion",
  qa: "navigation-link-validation-completion",
  inactiveImage: navValidation,
  activeImage: navValidationActive,
};

export const REVIEW_FEEDBACK = {
  title: "Review Feedback",
  route: "/review-feedback",
  qa: "navigation-link-review-feedback",
  inactiveImage: navReviewFeedback,
  activeImage: navReviewFeedbackActive,
};

export const RESET_STATUS = {
  title: "Store Reset Status",
  route: "/store-reset-status",
  qa: "navigation-link-store-reset-status",
  inactiveImage: navResetStatus,
  activeImage: navResetStatusActive,
};

export const RESET_VALIDATION = {
  title: "Reset Feedback",
  route: "/store-reset-feedback",
  qa: "navigation-link-store-reset-feedback",
  inactiveImage: navResetValidation,
  activeImage: navResetValidationActive,
};

export const LOGOUT = {
  title: "Sign Out",
  route: "/logout",
  qa: "navigation-link-logout",
  inactiveImage: navLogout,
  activeImage: navLogoutActive,
};

export const ADMIN_MY_SURVEYS = {
  title: "My Surveys",
  route: "/surveys",
  qa: "navigation-link-manage-surveys",
  disabled: false,
  inactiveImage: navSurvey,
  activeImage: navSurveyActive,
};

export const MY_SURVEYS = {
  title: "My Surveys",
  route: "/surveys/me",
  qa: "navigation-link-my-surveys",
  disabled: false,
  inactiveImage: navSurvey,
  activeImage: navSurveyActive,
};

export const COMMENT_DATA = {
  title: "Comment Data",
  route: "/stub",
  qa: "navigation-link",
  disabled: true,
  inactiveImage: navCommentData,
  activeImage: navCommentDataActive,
};

export const USER_MAPPING_DATA = {
  title: "User Mapping Data",
  route: "/manage-mapping-data",
  qa: "navigation-link-manage-user-mappings",
  inactiveImage: navUserMapping,
  activeImage: navUserMappingActive,
};

export const MY_FEEDBACK = {
  title: "My Feedback",
  route: "/my-feedback",
  qa: "navigation-link-my-feedback",
  inactiveImage: navMyFeedback,
  activeImage: navMyFeedbackActive,
};

export const DOWNLOADS = {
  title: "Downloads",
  route: "/downloads",
  qa: "navigation-link-downloads",
  inactiveImage: navDownload,
  activeImage: navDownloadActive,
};

export const DIR_DOWNLOADS = {
  title: "IRIS Downloads",
  route: "/iris-downloads",
  qa: "navigation-link-dir-downloads",
  inactiveImage: navDirDownloadsInactive,
  activeImage: navDirDownloadsActive,
};

export const SUPPORT = {
  title: "Support",
  route: "/support",
  qa: "navigation-link-support",
  inactiveImage: navSupportInactive,
  activeImage: navSupportActive,
  mobileOnly: true,
};

const nearbyStoresFeature = "nearbystores";

export const NEARBY_STORES = {
  title: "Nearby Stores",
  route: "/nearby-stores",
  qa: "navigation-link-nearby-stores",
  inactiveImage: navNearbyStoresInactive,
  activeImage: navNearbyStoresActive,
  showFeature: hasFeature(Config.FEATURES, nearbyStoresFeature),
};

export const UPLOAD_ATTACHMENTS = {
  title: "Upload Attachments",
  route: "/upload-attachments",
  qa: "navigation-link-upload-attachments",
  inactiveImage: navUploadAttachmentsInactive,
  activeImage: navUploadAttachments,
};
