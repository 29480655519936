import React, { ReactNode } from "react";

import MUIDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/Button";

import CautionSVG from "assets/img/dialog-icon-caution.svg";
import SuccessSVG from "assets/img/dialog-icon-success.svg";
import ErrorSVG from "assets/img/dialog-icon-error.svg";
import CloseSVG from "assets/img/dialog-icon-close.svg";
import Icon from "components/Icon";

/**
 * Provides common icon options. See renderIcon function for implementation
 */
export enum DialogIcon {
  SUCCESS = "success",
  ERROR = "error",
  CAUTION = "caution",
}
interface DialogProps {
  onPrimaryAction?: (...arg: any[]) => void;
  primaryButtonContent?: string;
  secondaryButtonContent?: string;
  dialogTitleText?: string;
  primaryContent: () => ReactNode;
  secondaryContent?: () => ReactNode;
  icon?: DialogIcon | (() => ReactNode);
  removeSecondaryButton?: boolean;
  removePrimaryButton?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialogIcon?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  getPrimaryButtonProps?: object;
  getSecondaryButtonProps?: object;
  getIconProps?: object;
  getDialogActionsProps?: object;
  buttonRowContent?: string;
  closeDialogAfterPrimaryAction?: boolean;
  scroll?: "paper" | "body";
}
/**
 * Based on [Material-UI's Dialog](https://material-ui.com/api/dialog/).
 *
 *
* @example
 * Checkout Commit ec991ba2: updated TestingChamber for various examples on how to use Dialog/ButtonWithConfirmation
 * 
 * @see Commit Link: [Azure DevOps](https://dev.azure.com/ab-inbev/ghq-brewbox-360/_git/ghq-brewbox-360/commit/ec991ba2471b0413bf5f5b36a2a0f51ffb47be77?refName=refs%2Fheads%2Fbugfix%2Fbutton-with-copy-comments&path=%2Fsrc%2Fcontainers%2FMyStores.tsx).
 *
 */
const Dialog: React.FC<DialogProps> = ({
  onPrimaryAction = undefined,
  primaryButtonContent,
  secondaryButtonContent,
  dialogTitleText,
  primaryContent,
  secondaryContent,
  icon,
  removeSecondaryButton = false,
  removePrimaryButton = false,
  open,
  setOpen,
  closeDialogIcon = true,
  maxWidth = "xs",
  getPrimaryButtonProps,
  getSecondaryButtonProps,
  getIconProps,
  getDialogActionsProps = {style:{ marginBottom: "6%", justifyContent: "center" }},
  buttonRowContent,
  closeDialogAfterPrimaryAction = true,
  children,
  ...restProps
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    closeDialogAfterPrimaryAction && setOpen(false);
    onPrimaryAction!();
  };

  /**
   * Helper function for allowing the use of preloaded icons or custom icons
   * @param icon
   * @returns Icon component with SVG as src or the custom icon passed in by user
   */
  const renderIcon = (icon: DialogIcon | (() => ReactNode)) => {
    switch (icon) {
      case DialogIcon.CAUTION:
        return <Icon src={CautionSVG} {...getIconProps} />;
      case DialogIcon.SUCCESS:
        return <Icon src={SuccessSVG} {...getIconProps} />;
      case DialogIcon.ERROR:
        return <Icon src={ErrorSVG} {...getIconProps} />;
      default:
        return icon();
    }
  };

  return (
      <MUIDialog
        data-qa="dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={maxWidth}
        {...restProps}
      >
        <DialogTitle id="dialogtitle" data-qa="dialog-title">
          {dialogTitleText}

          {closeDialogIcon && (
            <Button
              data-qa="dialog-close-button"
              className="icon-button hoverable"
              onClick={handleClose}
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <Icon small src={CloseSVG} />
            </Button>
          )}
        </DialogTitle>
        <DialogContent data-qa="dialog-content">
          {icon && <div style={{ textAlign: "center" }}>{renderIcon(icon)}</div>}

          <div className="dialog__main-text">{primaryContent!()}</div>

          {secondaryContent && <div className="dialog__subtext">{secondaryContent()}</div>}
          {children}
        </DialogContent>
        <DialogActions
          data-qa="dialog-actions"
          {...getDialogActionsProps}
        >
          {buttonRowContent &&  <div className="dialog__button-row-text">{buttonRowContent!}</div>}

          {!removeSecondaryButton && (
            <Button
              className="dialog__secondary-button-content"
              onClick={handleClose}
              secondary
              data-qa="dialog-secondary-button"
              {...getSecondaryButtonProps}
            >
              {secondaryButtonContent}
            </Button>
          )}

          {!removePrimaryButton && (
            <Button
              className="dialog__primary-button-content"
              onClick={handleClick}
              data-qa="dialog-primary-button"
              {...getPrimaryButtonProps}
            >
              {primaryButtonContent}
            </Button>
          )}
        </DialogActions>
      </MUIDialog>
  );
};

export default Dialog;
